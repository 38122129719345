import { memo } from "react";

import { Link } from "react-router-dom";

const IsDropdownItem = ({ route, lang, imgSrc, translation, t, isActive }) => (
  <li key={route} className="header-bottom__dropdown-item">
    <Link
      className={isActive ? "active" : ""}
      to={`/${lang ? lang : "ru"}/${route}/`}
    >
      <img
        src={imgSrc}
        loading="lazy"
        width="16"
        height="16"
        alt={t.navigation[translation]}
        aria-hidden="true"
      />
      {t.navigation[translation]}
    </Link>
  </li>
);

export default memo(IsDropdownItem);
