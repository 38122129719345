import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { mergeDeep } from "../utils/helpers";

export const useTranslation = () => {
  // **Props
  const { lang } = useParams();

  // **Redux
  const translation = useSelector((state) => state.translation.translation);

  const currentTranslation =
    lang && translation?.hasOwnProperty(lang)
      ? translation[lang]
      : translation["ru"];

  const defaultTranslation = translation["ru"];

  return mergeDeep({}, defaultTranslation, currentTranslation);
};
