export const getCorrectExaminationHelper = (
  value,
  translationForMins,
  translationForWorking
) => {
  if (typeof value === "string") {
    return `${value} ${translationForMins}`;
  }

  if (value) {
    return translationForWorking.work;
  } else {
    return translationForWorking.notWorked;
  }
};
