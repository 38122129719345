import { memo, useEffect, useState } from "react";

import { pathNamesOther } from "../../../../../../../../../utils/constants";

const IsOtherItem = ({ pathname, t, children }) => {
  const [isActive, setIsActive] = useState("");

  useEffect(() => {
    if (pathNamesOther.includes(pathname)) {
      return setIsActive("active");
    } else {
      return setIsActive("");
    }
  }, [pathname]);

  return (
    <li
      className={`header-bottom__nav-item header-bottom__nav-item--dropdown ${isActive}`}
      style={{ "--arrow": "url(/img/ui/arrow.svg)" }}
    >
      <img
        src="/img/ui/circle.svg"
        loading="lazy"
        width="16"
        height="16"
        alt={t.header.other}
        aria-hidden="true"
      />
      {t.header.other}
      <img
        className="arrow"
        src="/img/ui/arrow.svg"
        loading="lazy"
        width="10"
        height="7"
        alt=""
        aria-hidden="true"
      />
      {children}
    </li>
  );
};

export default memo(IsOtherItem);
