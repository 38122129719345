import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useMatchRoute } from "../../hooks";

export const useFaqSelector = () => {
  const tag = useMatchRoute()?.tag;
  const { lang } = useParams();

  return {
    faqItems: useSelector(
      (state) => state.content.content.store?.[tag]?.[lang || "ru"]?.faq
    )
  };
};
