export const defineCorrectLocationHelper = (data, translation) => {
  if (data?.country && data?.city) {
    return `${data?.country}, ${data?.city}`;
  } else if (data?.country) {
    return data?.country;
  } else if (data?.city) {
    return data?.city;
  }

  return translation;
};
