import { Link, useParams } from "react-router-dom";

import { useTranslation } from "../../../../../hooks";

export const Navigation = () => {
  // **Props
  const { lang } = useParams();

  const t = useTranslation();

  return (
    <>
      <div className="footer__item">
        <div className="footer__item-title">{t.footer.tools}</div>
        <div className="footer__item-wrapper">
          <ul className="footer__item-list">
            <li className="footer__item-list-item">
              <Link to={`/${lang ? lang : "ru"}/proxy-checker/`}>
                {t.navigation.proxyChecker}
              </Link>
            </li>
            <li className="footer__item-list-item">
              <Link to={`/${lang ? lang : "ru"}/my-ip/`}>
                {t.navigation.myIp}
              </Link>
            </li>
            <li className="footer__item-list-item">
              <Link to={`/${lang ? lang : "ru"}/skaner-portov/`}>
                {t.navigation.checkPorts}
              </Link>
            </li>
            <li className="footer__item-list-item">
              <Link to={`/${lang ? lang : "ru"}/ping-ip/`}>
                {t.navigation.pingIp}
              </Link>
            </li>
            <li className="footer__item-list-item">
              <Link to={`/${lang ? lang : "ru"}/fingerprint/`}>
                {t.navigation.fingerPrint}
              </Link>
            </li>
            <li className="footer__item-list-item">
              <Link to={`/${lang ? lang : "ru"}/evercookie/`}>
                {t.navigation.evercookie}
              </Link>
            </li>
          </ul>
          <ul className="footer__item-list">
            <li className="footer__item-list-item">
              <Link to={`/${lang ? lang : "ru"}/generator-utm/`}>
                {t.navigation.utm}
              </Link>
            </li>
            <li className="footer__item-list-item">
              <Link to={`/${lang ? lang : "ru"}/trace/`}>
                {t.navigation.traceIp}
              </Link>
            </li>
            <li className="footer__item-list-item">
              <Link to={`/${lang ? lang : "ru"}/speed/`}>
                {t.navigation.speed}
              </Link>
            </li>
            <li className="footer__item-list-item">
              <Link to={`/${lang ? lang : "ru"}/anonymity-check/`}>
                {t.navigation.anonimityCheck}
              </Link>
            </li>
            <li className="footer__item-list-item">
              <Link to={`/${lang ? lang : "ru"}/web-rtc-check/`}>
                {t.navigation.webRtcChecker}
              </Link>
            </li>
            <li className="footer__item-list-item">
              <Link to={`/${lang ? lang : "ru"}/dns-check/`}>
                {t.navigation.dnsChecker}
              </Link>
            </li>
          </ul>
          <ul className="footer__item-list">
            <li className="footer__item-list-item">
              <Link to={`/${lang ? lang : "ru"}/ip-in-spam-databases/`}>
                {t.navigation.spamBase}
              </Link>
            </li>
            <li className="footer__item-list-item">
              <Link to={`/${lang ? lang : "ru"}/ipv6-support/`}>
                {t.navigation.ipv6Check}
              </Link>
            </li>
            <li className="footer__item-list-item">
              <Link to={`/${lang ? lang : "ru"}/online-proxy-filter/`}>
                {t.navigation.filterProxy}
              </Link>
            </li>
            <li className="footer__item-list-item">
              <Link to={`/${lang ? lang : "ru"}/http-headers/`}>
                {t.navigation.httpHeaders}
              </Link>
            </li>
            <li className="footer__item-list-item">
              <Link to={`/${lang ? lang : "ru"}/browser-info/`}>
                {t.navigation.browserInfo}
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer__item">
        <div className="footer__item-title">{t.footer.company}</div>
        <div className="footer__item-wrapper">
          <ul className="footer__item-list">
            <li className="footer__item-list-item">
              <a
                href="https://proxy-sale.com/isp/"
                target="_blank"
                rel="noreferrer"
              >
                {t.navigation.ispProxy}
              </a>
            </li>

            <li className="footer__item-list-item">
              <a
                href="https://proxy-sale.com/resident/"
                target="_blank"
                rel="noreferrer"
              >
                {t.navigation.resedentialProxy}
              </a>
            </li>

            <li className="footer__item-list-item">
              <a
                href="https://proxy-sale.com/ipv6/"
                target="_blank"
                rel="noreferrer"
              >
                {t.navigation.proxyIpv6}
              </a>
            </li>

            <li className="footer__item-list-item">
              <a
                href="https://proxy-sale.com/"
                target="_blank"
                rel="noreferrer"
              >
                {t.navigation.personalProxy}
              </a>
            </li>

            <li className="footer__item-list-item">
              <a
                href="https://proxy-sale.com/company/articles/"
                target="_blank"
                rel="noreferrer"
              >
                {t.navigation.articles}
              </a>
            </li>

            <li className="footer__item-list-item">
              <a
                href="https://proxy-sale.com/contacts/"
                target="_blank"
                rel="noreferrer"
              >
                {t.navigation.contacts}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
