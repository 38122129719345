import { useEffect, useId, useRef } from "react";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";

import { useTranslation } from "../../../../../hooks";

export const FooterLeft = () => {
  // **Props
  const { pathname, search } = useLocation();
  const { lang } = useParams();
  const navigate = useNavigate();
  const t = useTranslation();

  const langOptions = t.footer.lang.selectLanguage;
  // const langOptions = useSelectLanguage(t, lang);

  // **Ref
  const selectRef = useRef(null);
  const id = useId();

  const languageHandler = (langObj) => {
    navigate(
      `/${langObj?.value}/${
        // eslint-disable-next-line no-extra-boolean-cast
        !!pathname.split("/")[2] ? pathname.split("/")[2] + search : search
      }`
    );
  };

  const getCorrectLang = langOptions.find((option) => option.value === lang);

  useEffect(() => {
    if (selectRef.current) {
      selectRef.current.select.setValue(getCorrectLang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return (
    <div className="footer__left">
      <div className="footer__logo">
        <Link to={`/${lang ? lang : "ru"}/`}>
          <img
            src={
              lang === "ru"
                ? "/img/ui/logo-footer-ru.svg"
                : "/img/ui/logo-footer-en.svg"
            }
            loading="lazy"
            width="238"
            height="63"
            alt={t.footer.logo.alt}
          />
        </Link>
      </div>
      <div className="footer__credits">
        <p>Proxy-Sale.com</p>
        <p>{t.footer.rights}</p>
      </div>
      <div className="footer__language">
        <div className="footer__language-title">{t.footer.lang.title}</div>
        <Select
          inputId={id}
          ref={selectRef}
          defaultValue={getCorrectLang}
          name="language"
          options={langOptions}
          menuPlacement="top"
          className="select"
          classNamePrefix="select"
          onChange={languageHandler}
          aria-label={t.footer.lang.title}
        />
      </div>
    </div>
  );
};
