import { useState } from "react";

import CopyToClipboard from "react-copy-to-clipboard";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useTranslation } from "../../../hooks";
import {
  ProxyCopy,
  ProxyCopyIp,
  ProxyText
} from "../../../pages/MainPage/components/Proxy/components/ProxyTable/ProxyTable.styled";
import { findCountryFlagHelper } from "../../../utils/helpers";
import { Loader } from "../Loader/Loader";
import { Spinner } from "../Spinner/Spinner";

import { CheckMark, CountryName, Tooltip, TooltipText } from "./Table.styled";

export const Table = ({
  getCheckCount,
  totalElements,
  defineStatusOfProxy,
  isNotWorking,
  getDataForTable,
  downloadHandler,
  proxyExport,
  isLoading
}) => {
  const [tooltipIndex, setTooltipIndex] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const t = useTranslation();
  const { lang } = useParams();

  const { countries } = useSelector(
    (state) => state.translation.translation?.[lang || "ru"]
  );

  const handleShowCopyIp = (index) => {
    setHoveredIndex(index);
  };

  const handleHideCopyIp = () => {
    setHoveredIndex(null);
  };

  const columns = [
    {
      name: "IPs",
      selector: (row) => row["ip"],
      sortable: false,
      minwidth: "170px",
      cell: ({ ip, port }, i) => (
        <CopyToClipboard
          text={`${ip}:${port}`}
          onCopy={() => toast.info(t.common.copy, { autoClose: 500 })}
        >
          <ProxyCopy
            onMouseOver={() => handleShowCopyIp(i)}
            onMouseLeave={handleHideCopyIp}
          >
            <ProxyText>
              {ip}:{port}
            </ProxyText>
            {hoveredIndex === i && (
              <ProxyCopyIp>
                <img
                  src="/img/ui/copy-white.svg"
                  width="16"
                  height="16"
                  loading="lazy"
                  alt={t.table.tableColumns.copy}
                />
                {t.table.tableColumns.copy}
              </ProxyCopyIp>
            )}
          </ProxyCopy>
        </CopyToClipboard>
      )
    },
    {
      name: t.table.tableColumns.country,
      selector: (row) => row["country"],
      minwidth: "10px",
      sortable: false,
      cell: ({ country }, rowIndex) => {
        const countrySrc = findCountryFlagHelper(country, countries);
        const countryName =
          t.countries.find(({ dataBaseValue }) => dataBaseValue === country)
            ?.label || country;

        const handleMouseOver = () => {
          setTooltipIndex(rowIndex);
        };

        const handleMouseLeave = () => {
          setTooltipIndex(null);
        };

        return (
          <div
            className="checker__table-text checker__table-country"
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
          >
            {country ? (
              <>
                {countrySrc && (
                  <img
                    src={findCountryFlagHelper(country, countries)}
                    loading="lazy"
                    width="16"
                    height="16"
                    alt={countryName}
                  />
                )}

                {tooltipIndex === rowIndex && country.length > 10 && (
                  <Tooltip>
                    <TooltipText>{countryName}</TooltipText>
                    <CheckMark />
                  </Tooltip>
                )}

                <CountryName>{countryName}</CountryName>
              </>
            ) : (
              <Spinner />
            )}
          </div>
        );
      }
    },
    {
      name: t.proxyChecker.type,
      selector: (row) => row["type"],
      minwidth: "90px",
      sortable: false,
      cell: ({ checkedType, type }) => (
        <div className="checker__table-text">
          {checkedType === false ? (
            <Spinner />
          ) : (
            <>
              <span>{type !== null ? type.toUpperCase() : "NO"}</span>{" "}
            </>
          )}
        </div>
      )
    },
    {
      name: "Google",
      selector: (row) => row["google"],
      minwidth: "90px",
      sortable: false,
      cell: ({ checkedGoogle, google }) => (
        <div className="checker__table-text">
          {checkedGoogle === false ? (
            <Spinner />
          ) : (
            <>
              {google ? (
                <img
                  src="/img/ui/checkSite.svg"
                  width="20"
                  height="20"
                  alt={t.myIp.top.table.imgAlt.country}
                />
              ) : (
                <img
                  src="/img/ui/x.svg"
                  width="20"
                  height="20"
                  alt={t.myIp.top.table.imgAlt.country}
                />
              )}
            </>
          )}
        </div>
      )
    },
    {
      name: "Baidu",
      selector: (row) => row["baidu"],
      minwidth: "90px",
      sortable: false,
      cell: ({ checkedBaidu, baidu }) => (
        <div className="checker__table-text">
          {checkedBaidu === false ? (
            <Spinner />
          ) : (
            <>
              {baidu ? (
                <img
                  src="/img/ui/checkSite.svg"
                  width="20"
                  height="20"
                  alt={t.myIp.top.table.imgAlt.country}
                />
              ) : (
                <img
                  src="/img/ui/x.svg"
                  width="20"
                  height="20"
                  alt={t.myIp.top.table.imgAlt.country}
                />
              )}
            </>
          )}
        </div>
      )
    },
    {
      name: "Bing",
      selector: (row) => row("bing"),
      minwidth: "90px",
      sortable: false,
      cell: ({ checkedBing, bing }) => (
        <div className="checker__table-text">
          {checkedBing === false ? (
            <Spinner />
          ) : (
            <>
              {bing ? (
                <img
                  src="/img/ui/checkSite.svg"
                  width="20"
                  height="20"
                  alt={t.myIp.top.table.imgAlt.country}
                />
              ) : (
                <img
                  src="/img/ui/x.svg"
                  width="20"
                  height="20"
                  alt={t.myIp.top.table.imgAlt.country}
                />
              )}
            </>
          )}
        </div>
      )
    },
    {
      name: t.proxyChecker.ping,
      selector: (row) => row("ping"),
      minwidth: "90px",
      sortable: false,
      cell: ({ checkedPing, ping }) => (
        <div className="checker__table-anon">
          {checkedPing === false ? (
            <Spinner />
          ) : (
            <span>
              {ping} {t.table.tableColumns.anon.ms}
            </span>
          )}
        </div>
      )
    },
    {
      name: t.proxyChecker.condition,
      selector: (row) => row("state"),
      minwidth: "150px",
      sortable: false,
      cell: ({ checkedPing, ping }) => (
        <div className="checker__table-anon">
          <div className="checker__table-text">
            {checkedPing === false ? (
              <Spinner />
            ) : (
              <>
                {ping === 0 && <p>{t.proxyChecker.status}</p>}
                {ping > 0 && t.proxyChecker.workStatus}
              </>
            )}
          </div>
        </div>
      )
    }
  ];

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px"
      }
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px"
      }
    }
  };

  return (
    <>
      <div className="checker__results">
        <div className="checker__results-left">
          <div className="checker__results-title">
            {t.checker.results.title}:
          </div>
          <div className="checker__results-text">
            {t.checker.results.checked}: {getCheckCount()}{" "}
            {t.checker.results.divider} {totalElements}
          </div>
          <div className="checker__results-text">
            {t.checker.results.working}:{" "}
            <span className="green">{defineStatusOfProxy(true)}</span>
          </div>
          <div className="checker__results-text">
            {t.checker.results.notWorking}:{" "}
            <span className="red">{isNotWorking()}</span>
          </div>
        </div>
        <a
          href="https://proxy-sale.com/"
          rel="noreferrer"
          className="section__btn section__btn--dark"
          style={{ width: "max-content" }}
        >
          {t.checker.results.btn}
        </a>
      </div>
      <DataTable
        noHeader
        columns={columns}
        noDataComponent={t.table.noDataComponent}
        data={getDataForTable()}
        progressPending={isLoading}
        progressComponent={<Loader />}
        pagination
        paginationPerPage={11}
        paginationDefaultPage={1}
        customStyles={customStyles}
        paginationComponentOptions={{
          rowsPerPageText: t.table.tableColumns.pagination.rowsPerPage,
          rangeSeparatorText: t.table.tableColumns.pagination.separatorText
        }}
      />
      {proxyExport().length > 0 && (
        <div className="checker__download">
          <div className="checker__download-title">
            {t.checker.download.title}:
          </div>
          <ul className="checker__download-files">
            <li className="checker__download-file">
              <button onClick={() => downloadHandler("txt")}>
                <img
                  src="/img/ui/txt-file.svg"
                  width={20}
                  height={20}
                  alt={t.checker.download.txt}
                />
                {t.checker.download.txt}
              </button>
            </li>
            <li className="checker__download-file">
              <button onClick={() => downloadHandler("excel")}>
                <img
                  src="/img/ui/csv-file.svg"
                  width={20}
                  height={20}
                  alt={t.checker.download.csv}
                />
                {t.checker.download.csv}
              </button>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};
