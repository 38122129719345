import { createSlice } from "@reduxjs/toolkit";

import { contentInitialState } from "./Content.initialState";
import {
  getAllCountryTags,
  getCaptchaInfo,
  getCountryInfo,
  getPageContent,
  serverCountrySave,
  serverCountryTagsSave,
  serverSeoSave,
  toggleBurger
} from "./Content.operations";
import {
  getAllCountryTagsFulfilledReducer,
  getCaptchaInfoFulfilledReducer,
  getCaptchaInfoPendingReducer,
  getCaptchaInfoRejectedReducer,
  getCountryInfoFulfilledReducer,
  getPageContentFulfilledReducer,
  getPageContentPendingReducer,
  getPageContentRejectedReducer,
  serverCountrySaveReducer,
  serverCountryTagsSaveReducer,
  serverSeoSaveReducer,
  toggleBurgerReducer
} from "./Content.reducer";

const contentSlice = createSlice({
  name: "content",
  initialState: contentInitialState,
  extraReducers: (builder) =>
    builder
      .addCase(toggleBurger, toggleBurgerReducer)
      .addCase(serverSeoSave, serverSeoSaveReducer)
      .addCase(serverCountrySave, serverCountrySaveReducer)
      .addCase(serverCountryTagsSave, serverCountryTagsSaveReducer)

      .addCase(getCaptchaInfo.fulfilled, getCaptchaInfoFulfilledReducer)
      .addCase(getCaptchaInfo.pending, getCaptchaInfoPendingReducer)
      .addCase(getCaptchaInfo.rejected, getCaptchaInfoRejectedReducer)

      .addCase(getPageContent.fulfilled, getPageContentFulfilledReducer)
      .addCase(getPageContent.pending, getPageContentPendingReducer)
      .addCase(getPageContent.rejected, getPageContentRejectedReducer)

      .addCase(getAllCountryTags.fulfilled, getAllCountryTagsFulfilledReducer)
      .addCase(getCountryInfo.fulfilled, getCountryInfoFulfilledReducer)
});

export const ContentReducerActions = {
  toggleBurger,
  serverSeoSave,
  serverCountrySave,
  serverCountryTagsSave,
  getCaptchaInfo,
  getPageContent,
  getAllCountryTags,
  getCountryInfo
};

export default contentSlice.reducer;
