import { useEffect, useState } from "react";

export const useProgress = (score, isLoaded) => {
  const [currentProgress, setCurrentProgress] = useState(0);
  const [resetKey, setResetKey] = useState(0);

  useEffect(() => {
    if (!isLoaded || !score) return;

    const { data } = score;
    const interval = setInterval(() => {
      setCurrentProgress((prevProgress) => {
        const newProgress = prevProgress + 1;
        return newProgress <= data ? newProgress : prevProgress;
      });
    }, 20);

    if (currentProgress >= data) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [currentProgress, score, resetKey, isLoaded]);

  const resetProgress = () => {
    setCurrentProgress(0);
    setResetKey((prevKey) => prevKey + 1);
  };

  return { currentProgress, resetProgress };
};
