export const utmTrafficConstant = {
  google: {
    source: "google",
    medium: "cpc",
    campaign: "{campaignid}",
    content: "{creative}",
    term: "{keyword}"
  },
  yandex: {
    source: "yandex",
    medium: "cpc",
    campaign: "{campaign_name_lat}",
    content: "{ad_id}",
    term: "{keyword}"
  },
  vk: {
    source: "vk",
    medium: "cpc",
    campaign: "{campaign_id}",
    content: "{ad_id}",
    term: ""
  },
  facebook: {
    source: "facebook",
    medium: "cpc",
    campaign: "",
    content: "",
    term: ""
  },
  instagram: {
    source: "instagram",
    medium: "cpc",
    campaign: "",
    content: "",
    term: ""
  },
  targetmailru: {
    source: "targetmailru",
    medium: "cpc",
    campaign: "{{campaign_id}}",
    content: "{{banner_id}}",
    term: "{{geo}}.{{gender}}.{{age}}"
  }
};
