export const removeProxyReducer = (state, action) => {
  state.proxies.data = state.proxies.data.filter(
    (proxy) => proxy.id !== action.payload
  );
};

export const changeFilterOptionsReducer = (state, action) => {
  state.filterOptions.data = action.payload;
};

export const clearFilterOptionsReducer = (state) => {
  state.filterOptions.data = {
    countries: [],
    proxyTypes: [],
    anonimityType: []
  };
};

export const saveProxyTableDataReducer = (state, action) => {
  state.proxies.isDataLoaded = true;
  state.proxies.data = [...action.payload.content];
  state.proxies.totalElements = action.payload.totalElements;
  state.proxies.totalPages = action.payload.totalPages;
  state.proxies.isLoading = false;
};

export const saveProxiesStatisticReducer = (state, action) => {
  state.proxiesStatistic.isDataLoaded = true;
  state.proxiesStatistic.data = action.payload;
  state.proxiesStatistic.isLoading = false;
};

export const setPrevFilterOptionsReducer = (state, action) => {
  state.prevFilterOptions = action.payload;
};

export const setIsFirstFilterReducer = (state, action) => {
  state.isFirstFilter = action.payload;
};

export const clearProxiesForExportReducer = (state) => {
  state.proxiesForExport.data = [];
  state.proxiesForExport.isDataLoaded = false;
};

export const getProxyTableDataFulfilledReducer = (state, action) => {
  state.proxies.isDataLoaded = true;
  state.proxies.data = [...action.payload.content];
  state.proxies.totalElements = action.payload.totalElements;
  state.proxies.totalPages = action.payload.totalPages;
  state.proxies.isLoading = false;
};

export const getProxyTableDataPendingReducer = (state) => {
  state.proxies.isLoading = true;
};

export const getProxyTableDataRejectReducer = (state, action) => {
  state.proxies.isDataLoaded = false;
  state.proxies.isFetchError = action.payload;
  state.proxies.isLoading = false;
};

export const allProxiesStatisticFulfilledReducer = (state, action) => {
  state.proxiesStatistic.isDataLoaded = true;
  state.proxiesStatistic.data = action.payload;
  state.proxiesStatistic.isLoading = false;
};

export const allProxiesStatisticPendingReducer = (state) => {
  state.proxiesStatistic.isLoading = true;
};

export const allProxiesStatisticRejectedReducer = (state, action) => {
  state.proxiesStatistic.isDataLoaded = false;
  state.proxiesStatistic.isFetchError = action.payload;
  state.proxiesStatistic.isLoading = false;
};

export const checkProxyByIdFulfilledReducer = (state, action) => {
  state.checkProxy.isDataLoaded = true;
  state.proxies.data = state.proxies.data.map((proxy) => {
    if (proxy.id === action.payload.id) {
      return {
        ...proxy,
        lastCheckInMinutes: action.payload.response === 0
      };
    } else {
      return proxy;
    }
  });
  state.checkProxy.isLoading = false;
};

export const checkProxyByIdPendingReducer = (state) => {
  state.checkProxy.isLoading = true;
};

export const checkProxyByIdRejectedReducer = (state, action) => {
  state.checkProxy.isDataLoaded = false;
  state.checkProxy.isFetchError = action.payload;
  state.checkProxy.isLoading = false;
};

export const getAllProxiesForExportFulfilledReducer = (state, action) => {
  state.proxiesForExport.isDataLoaded = true;
  state.proxiesForExport.data = action.payload;
  state.proxiesForExport.isLoading = false;
};

export const getAllProxiesForExportPendingReducer = (state) => {
  state.proxiesForExport.isLoading = true;
};

export const getAllProxiesForExportRejectedReducer = (state, action) => {
  state.proxiesForExport.isDataLoaded = false;
  state.proxiesForExport.isFetchError = action.payload;
  state.proxiesForExport.isLoading = false;
};
