// eslint-disable-next-line import/namespace
import { Helmet } from "react-helmet-async";

import { useMetaDate } from "../../../hooks";
import { useFaqSelector } from "../../../store/selectors/useFaqSelector";
import { getRobotContent } from "../../../utils/helpers";
import { Translations } from "../../../utils/translations";

export const MetaDate = () => {
  const seo = useMetaDate();
  const { faqItems } = useFaqSelector();

  return (
    <Helmet>
      <html lang={seo?.lang} />

      <meta charset="utf-8" />
      <link rel="icon" href={`${process.env.REACT_APP_HOME_URL}/favicon.ico`} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />

      <meta name="yandex-verification" content="e67f51a61252f7ee" />
      <link
        rel="apple-touch-icon"
        href={`${process.env.REACT_APP_HOME_URL}/apple-touch-icon.png`}
      />
      <link
        rel="manifest"
        href={`${process.env.REACT_APP_HOME_URL}/manifest.json`}
      />

      <meta itemProp="name" content={seo?.title} />
      <meta itemProp="description" content={seo?.description} />
      <meta name="description" content={seo?.description} />
      <meta name="robots" content={getRobotContent()} />
      <meta property="og:title" content={seo?.title} />
      <meta property="og:description" content={seo?.description} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="1200" />
      <meta name="twitter:title" content={seo?.title} />
      <meta name="twitter:description" content={seo?.description} />

      <title>{seo?.title}</title>

      {/*<link rel="canonical" href="/" />*/}
      <link
        rel="alternate"
        hrefLang="x-default"
        href={process.env.REACT_APP_HOME_URL}
      />
      {Object.keys(Translations).map((el, i) => (
        <link
          key={i}
          rel="alternate"
          hrefLang={el}
          href={`${process.env.REACT_APP_HOME_URL}/${el}/`}
        />
      ))}

      {seo?.countryTags?.map((el, i) => (
        <link
          key={`lang-${i}`}
          rel="alternate"
          href={`${process.env.REACT_APP_HOME_URL}/${seo?.lang}/${el.countryTag}/`}
        />
      ))}

      {faqItems?.length > 0 && (
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: faqItems.map(({ question, answer }) => ({
              "@type": "Question",
              name: question,
              acceptedAnswer: {
                "@type": "Answer",
                text: answer
              }
            }))
          })}
        </script>
      )}

      <script>
        {`(function(ss,ex){
          window.ldfdr = window.ldfdr || function() {
              (ldfdr._q = ldfdr._q || []).push([].slice.call(arguments));
          };
          (function(d,s){
          var fs = d.getElementsByTagName(s)[0];
          function ce(src){
          var cs = d.createElement(s);
          cs.src=src;
          cs.async=1;
          fs.parentNode.insertBefore(cs,fs);
      };
          ce('https://sc.lfeeder.com/lftracker_v1_' + ss + (ex ? '_' + ex : '') + '.js');
      })(document,'script');
      })('JMvZ8gzPwpPa2pOd');`}
      </script>
    </Helmet>
  );
};
