import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

export const Input = ({ option, type, onChangeHandler, ...rest }) => {
  // **Props
  const { filter } = useParams();

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(filter === option?.route);
  }, [filter, option?.route]);

  const handleCheck = (e) => {
    setIsChecked(e.target.checked);

    onChangeHandler(e);
  };

  return (
    <li className="radio__item">
      <label>
        {type === "checkbox" && (
          <input
            value={option.value}
            checked={isChecked}
            type={type}
            onChange={(e) => {
              handleCheck(e);
            }}
            {...rest}
          />
        )}
        {type === "radio" && (
          <input
            value={option.value}
            defaultChecked={option.defaultChecked}
            type={type}
            onChange={onChangeHandler}
            {...rest}
          />
        )}
        <span>{option.label}</span>
      </label>
    </li>
  );
};
