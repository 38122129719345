export const getBlackList = (object) => {
  const blackListArray = Object.entries(object).reduce((acc, [key, value]) => {
    if (value === true) {
      acc.push(key);
    }
    return acc;
  }, []);

  return blackListArray.join(", ");
};
