import { useEffect } from "react";

import { useParams } from "react-router-dom";

import { MetaDate } from "../components/common";
import { useDispatchedActions } from "../hooks";
import { isSSR } from "../utils/helpers";
import { collectServerSideState } from "../utils/helpers/collectServerSideState";

export const ExportLayout = () => {
  const { lang } = useParams();
  const proxiesForExport = !isSSR()
    ? JSON.parse(localStorage.getItem("proxiesForExport"))
    : null;

  const { getPageContent } = useDispatchedActions();

  const getSeoData = () => {
    const TAG = "export";

    getPageContent({ locale: lang, tag: TAG });
  };

  useEffect(() => {
    getSeoData();

    window.addEventListener("beforeunload", () => {
      localStorage.removeItem("proxiesForExport");
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MetaDate />
      <div
        style={{
          wordWrap: "break-word",
          whiteSpace: "pre-wrap",
          padding: "10px"
        }}
      >
        {proxiesForExport ? proxiesForExport.join("\n") : "No data"}
      </div>
    </>
  );
};

ExportLayout.getServerSideState = async (req, store, params) => {
  await collectServerSideState(params, store);
};
