export const getAllTranslationSuccessReducer = (state, action) => {
  state.langs.data = action.payload.filter((lang) =>
    state.translation.hasOwnProperty(lang.code.toLowerCase())
  );
  state.langs.isDataLoaded = true;
  state.langs.isLoading = false;
};

export const getAllTranslationPendingReducer = (state) => {
  state.langs.isLoading = true;
};

export const getAllTranslationRejectedReducer = (state, action) => {
  state.langs.isFetchError = action.payload || true;
  state.langs.data.push({
    alpha2: "EN",
    code: "EN",
    internationalName: "English",
    name: "English",
    originalName: "English"
  });
  state.langs.isDataLoaded = true;
  state.langs.isLoading = false;
};

export const saveTranslationSuccessReducer = (state, action) => {
  state.langs.data = action.payload.filter((lang) =>
    state.translation.hasOwnProperty(lang.code.toLowerCase())
  );
  state.langs.isDataLoaded = true;
  state.langs.isLoading = false;
};
