import { startTransition, useEffect } from "react";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useDispatchedActions, useTranslation } from "./hooks";

export const App = ({ routerProvider: RouterProvider, routerProps }) => {
  const { langs } = useSelector((state) => state.translation);
  const { countryTags, captcha } = useSelector((state) => state.content);

  const t = useTranslation();

  const { getAllTranslation, getAllCountryTags, getCaptchaInfo } =
    useDispatchedActions();

  useEffect(() => {
    if (!langs.isDataLoaded) {
      startTransition(() => getAllTranslation());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [langs.isDataLoaded]);

  useEffect(() => {
    if (!countryTags.isDataLoaded) {
      startTransition(() => getAllCountryTags());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryTags.isDataLoaded]);

  useEffect(() => {
    if (!captcha.isDataLoaded) {
      startTransition(() => getCaptchaInfo());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captcha.isDataLoaded]);

  useEffect(() => {
    if (captcha.isFetchError || langs.isFetchError) {
      toast.error(t.errorTranslation.apiError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captcha.isFetchError, langs.isFetchError]);

  return (
    <GoogleReCaptchaProvider reCaptchaKey={captcha.data.captchaKey}>
      <RouterProvider {...routerProps} />
    </GoogleReCaptchaProvider>
  );
};
