export const proxyTypesConstant = {
  values: [
    {
      value: "HTTP HTTPS",
      label: "HTTP/HTTPS",
      defaultChecked: false,
      route: "http"
    },

    {
      value: "SOCKS4 SOCKS5",
      label: "SOCKS4/5",
      defaultChecked: false,
      route: "socks4"
    }
  ],
  name: "proxyType"
};
