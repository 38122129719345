export const getCorrectAnonStatusHelper = (name, translation) => {
  if (name.slice(0, -4) === "el-elit") {
    return translation.elite;
  }

  if (name.slice(0, -4) === "an-anonim") {
    return translation.anon;
  }

  if (name.slice(0, -4) === "pr-proz") {
    return translation.proz;
  }

  return translation.notFounded;
};
