import Icon from "../../../../../../../ui/Icon";

const LangItem = ({ code, languageHandler, internationalName }) => (
  <li className="header-top__lang-item" onClick={() => languageHandler(code)}>
    <Icon
      src={`/img/flags/${code.toLowerCase()}.svg`}
      width="24"
      height="24"
      alt={internationalName}
    />

    {code.toUpperCase()}
  </li>
);

export default LangItem;
