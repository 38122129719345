export const contentInitialState = {
  captcha: {
    data: {
      isCaptchaActive: false,
      captchaKey: ""
    },
    isDataLoaded: false,
    isLoading: false,
    isFetchError: null
  },
  content: {
    store: {},
    isDataLoaded: false,
    isLoading: false,
    isFetchError: null,
    burgerFlag: false
  },
  country: {
    isDataLoaded: false,
    data: {}
  },
  countryTags: {
    isDataLoaded: false,
    data: []
  }
};
