import { useParams } from "react-router-dom";

import { useDispatchedActions, useTranslation } from "../../../hooks";

import { Img, Link } from "./Logo.styled";

export const Logo = ({ imgSrc }) => {
  const { lang } = useParams();

  const t = useTranslation();

  const { getProxyTableData } = useDispatchedActions();

  const filterOption = {
    anonimityType: [],
    countries: [],
    proxyTypes: []
  };

  const handleLinkClick = () => {
    getProxyTableData(filterOption);
  };

  return (
    <Link
      onClick={handleLinkClick}
      // className="logotype"
      to={`/${lang && lang !== "ru" ? lang : "ru"}/`}
      aria-label={t.header.ariaLabelMain}
    >
      <Img src={imgSrc} loading="lazy" alt={t.header.altImgLogo} />
    </Link>
  );
};
