export const En = {
  header: {
    ariaLabelMain: "Go to home page",
    altImgLogo: "Company logo FreeProxySale",
    other: "More Tools",
    ariaLabelOther: "Expansion rate of additional menu items",
    close: "Close"
  },
  footer: {
    logo: {
      alt: "Proxy-Sale company logo"
    },
    rights: "All rights reserved",
    lang: {
      title: "Choose language",
      selectLanguage: [
        { value: "de", label: "German" },
        { value: "en", label: "English" },
        { value: "es", label: "Spanish" },
        { value: "fr", label: "French" },
        { value: "it", label: "Italian" },
        { value: "pl", label: "Polish" },
        { value: "ru", label: "Russian" },
        { value: "tr", label: "Turkish" }
      ]
    },
    tools: "Instruments",
    company: "Company"
  },
  navigation: {
    freeProxy: "Free proxy",
    personalProxy: "Personal proxy",
    proxyIpv6: "Proxy IPv6",
    articles: "Articles",
    contacts: "Contacts",
    proxyChecker: "Proxy Checker",
    myIp: "My IP",
    checkPorts: "Port Scanner",
    pingIp: "Ping IP",
    anonimityCheck: "Anonymity Check",
    utm: "UTM Builder",
    speed: "Proxy Speed Test",
    ipv6Check: "IPv6 Validator",
    spamBase: "IP Blacklist Check",
    filterProxy: "Proxy List Filter",
    traceIp: "IP Tracing",
    claster: "Grouping requests",
    webRtcChecker: "WebRTC Check",
    dnsChecker: "DNS Check",
    fingerPrint: "Fingerprint scanner",
    evercookie: "Evercookie Check",
    httpHeaders: "Viewing HTTP headers",
    browserInfo: "Browser information",
    resedentialProxy: "Residential proxies",
    ispProxy: "ISP proxy"
  },
  mainPage: {
    proxyTable: {
      sidebarPart: {
        title: "Configuring a proxy in the browser:",
        countries: {
          placeholder: "Select countriesConstant"
        },
        type: {
          title: "Type of:"
        },
        anonimity: {
          title: "Anonymous:"
        },
        export: {
          title: "Export:",
          ip: "IP:Port",
          excel: "Excel",
          window: "In a new window"
        },
        applyFilter: "Apply filter",
        clearFilter: "Clear filter",
        infoLeft: "Our free proxy lists",
        infoRight:
          "are not recommended for tasks requiring high levels of anonymity and stability.",
        buyProxy: "Buy personal proxies",
        personalProxyAdvantage: [
          "For greater anonymity, speed, and stability, consider our personal proxy service."
        ]
      }
    },
    faq: "Almost every Internet user has heard about what a proxy server is and why it is needed. They became especially popular when there was a massive need to bypass the blocking of popular web resources - social networks, Yandex services, video hosting, and the like. Of course, most are interested in free proxies. There are plenty of them on the network, but it is not always obvious how to choose them correctly and what they are suitable for, and for which tasks it is better to buy a proxy."
  },
  anonimityCheck: {
    mainTitle: "My anonymity",
    headText:
      "Using this service, you can check how anonymous you are on the network, how much the data provided by your computer/browser matches the data provided by your IP address.",
    top: {
      ip: "Your IP :",
      from: "Where are you from:",
      camouflage: "Your disguise:",
      anon: "Your anonymity:",
      restartButton: "Restart scan",
      waitButton: "Loading",

      result: {
        title: "Your result:",
        notAnonymity: "No anonymity",
        badAnonymity: "Poor anonymity",
        lackAnonymity: "Insufficient anonymity",
        sufficientAnonymity: "Sufficient anonymity",
        checking: "Check, please wait",
        highAnonymity: "High anonymity"
      }
    },
    table: {
      titleIpInfo: "IP information",
      device: "Device",
      anonymityTitle: "Anonymity and security",
      standardTimeZone: "Standard Time Zone",
      hour: "hours",
      ip: "My IP",
      hideip: "Hide IP",
      host: "Host/Provider",
      country: "Country",
      city: "City",
      index: "Index",
      coordinates: "Coordinates",
      os: "OS",
      browser: "Browser",
      timezone: "Time zone",
      ipTime: "IP time",
      systemTime: "System time",
      userAgent: "UserAgent",
      userAgentJs: "UserAgent JS",
      language: "Language",
      languageJs: "Language JS",
      screen: "Screen",
      proxy: "Proxy",
      vpn: "VPN",
      tor: "Tor",
      anonimazer: {
        title: "Anonymizer",
        info: "It is necessary to close ports. Open ports can cause significant harm to your device or may have already caused harm."
      },
      cookies: {
        info: "Disabling may prevent tracking by advertising networks and various resources, enhancing greater privacy. It is recommended to disable cookies or periodically clear them."
      },
      blackList: {
        title: "Black list",
        checks: "Checks:",
        inBlackList: "Blacklisted:",
        buyProxy: "Buy pure proxy"
      },
      flash: "Flash",
      java: "Java",
      activeX: {
        title: "ActiveX",
        info: "The ActiveX control has security vulnerabilities and can be used to infect your computer. The more ActiveX controls you install, the more sites can exploit their issues to damage your device."
      },
      webRtc: "WebRTC",
      webRtcIp: "WebRTC IPs",
      openPorts: {
        title: "Open ports",
        links: "Port scanner"
      },
      openPortsHTTP: {
        title: "Open HTTP proxy ports",
        info: "Ports need to be closed. Open ports can significantly harm or have already harmed your device."
      },
      openPortsVPN: {
        title: "Open VPN Ports",
        info: "It is necessary to close the ports. Open ports can significantly harm or have already harmed your device."
      },
      openPortsWeb: "Open Web proxy ports",
      doNotTrack: {
        title: "DoNotTrack",
        info: "Enabling this option adds some uniqueness to the browser's digital fingerprint.",
        link: "Enable DNT"
      },
      dnsLeak: {
        title: "DNS Leak",
        info: "Third parties can track all your actions on the network. DNS settings need to be changed.",
        links: "Change DNS"
      },
      suspiciousHeadlines: "Suspicious headlines",
      suspiciousPorts: "Suspicious ports",
      twowayPing: "Two-way ping",
      replacementUserAgent: {
        title: "UserAgent Replacement",
        info: "Based on the data forming the UserAgent from the device, if the data differs, it means a replacement is taking place."
      },
      yourHeadings: "Your Headings",
      showButton: {
        more: "Show more",
        less: "Hide"
      }
    },
    faq: {
      text: []
    }
  },
  checkPorts: {
    analis: "Analysis in progress... Wait.",
    port: "Port",
    status: "Status",
    statusTrue: "available",
    statusFalse: "unavailable",
    description: "Description",
    portStatus: "Port status on the server",
    scanType: "Scan type",
    mainTitle: "Checking open ports online",
    videoTitle: "What is a port scanner and why?",
    headText:
      "Checking open ports using a scanner shows which ports are open on your or someone else's computer / site and what they answer. The check is performed through the NMAP scanner and shows how open your access to PC / site for the outside world.",
    ipTitle: "Display:",
    ipElem: {
      placeholder: "Enter the IP address or domain",
      insertIp: "Paste my IP address"
    },
    scanTypes: {
      title: "Type of ports scanned:"
    },
    scanTitle: "Scan Type:",
    startScan: "Start Scan",
    resultCheck: "Test results:",
    resultCaption:
      "* If the result is 'Host is down,' then the firewall or router is being checked and blocking IP addresses.",
    faq: {
      text: []
    }
  },
  claster: {
    mainTitle: "Keyword Grouping Tool",
    checker: {
      regionTitle: "Checking region",
      selectPlaceholder: "Choose region",
      clasterTitle: "Clustering threshold",
      groupBtn: "Group",
      queryTitle: "Requests"
    },
    headText:
      "Thanks to this service, online query clustering is available to you. With it, you can automatically group keywords based on Yandex and Google search results. Competent construction of the site structure will increase your chances of getting your site to the top positions. Our online query clustering service helps you categorize your keywords across your pages in a way to get the most SEO traffic."
  },
  filterProxy: {
    mainTitle: "Proxy List Filter",
    headText:
      "Proxy filtering is the process of sending a proxy filter to the main server to select specific recommendations.",
    filterOut: "Filter out",
    save: "Save to file"
  },
  ipv6: {
    mainTitle: "Website IPv6 Accessibility Validator",
    headText:
      "The version of the IPv6 protocol, which provides maximum protection on the network, requires the use of a site-specific support tool for the new proxy protocol for more efficient operation.",
    checker: {
      addressTitle: "Enter the site address for verification",
      checkProxyBtn: "Check proxy",
      tip: "The test may take a while",
      resultTitle: "Test results:",
      result: {
        success: "Congratulations, this site supports IPv6",
        error: "Unfortunately, the site does not support IPv6 at the moment"
      }
    },

    faq: {
      text: []
    }
  },
  myIp: {
    top: {
      title:
        "And this is just a small part of the information that can be tracked by your IP address:",
      ipv4: "Your IPv4 address:",
      ipv6: "Your IPv6 address:",
      changeIp: "Change your IP address",
      table: {
        check: "Check",
        country: "Country",
        city: "City",
        index: "Postcode",
        host: "Host",
        system: "System",
        browser: "Your browser",
        from: "Where are you from",
        provider: "Provider",
        anon: "Anonymizer",
        proxy: "Proxy",
        protocol: "Protocol",
        imgAlt: {
          country: "Country",
          city: "City",
          index: "Postcode",
          screen: "Monitor",
          os: "Operating system",
          browser: "Browser version",
          location: "Location",
          server: "Server",
          anon: "Anonymizer",
          proxy: "Proxy",
          protocol: "Protocol",
          blacklist: "Blacklist"
        }
      },
      map: {
        title: "Your location on the map!",
        hide: "You can hide your location",
        text: "It is enough to use our simple, convenient and reliable tool. Hide personal data, protect traffic and remove all limits from your Internet",
        hideIp: "Hide IP address"
      },
      caution: {
        top: "As you can see, quite detailed information is provided by IP.",
        bottom:
          "When connected to the Internet, the computer is assigned a number called IP (Internet Protocol Address). It should be noted that the assignment is not arbitrary, but the IP that is available to the provider and is free. IP changes after disconnecting when reconnecting. These IPs are called dynamic. If you want it to be unchanged, contact your ISP. Usually, non-dynamic is available for a fee. It is also called external. It is often tracked by specialized online programs, determining the accompanying information about the owner. Internal IP or local is called the individual address of a PC (laptop) in a local network."
      }
    },
    faq: {
      text: []
    }
  },
  pingIp: {
    mainTitle: "IP address or website Ping Test",
    whatIsPing: "What is ping IP and why?",
    headText: [
      "Click on the 'Insert my IP address' button or use the IP or site to which you want to check the ping. Select 3-5 test packets and click the 'Ping IP' button. You will see the desired value in the 'time = 'section.",
      "Ping is considered normal when it is in the range of 40-110 ms. From 110-210 ms, you will start to experience problems in online games, but this will hardly affect your visits to sites. If the timeoutConstant exceeds 110 ms, then this is a reason to think about the quality of work your ISP. "
    ],
    checker: {
      showTitle: "Display:",
      showSubtitle: "Enter a valid IP address (0.0.0.0) or hostname (host.com)",
      ipInsert: "Paste my IP address",
      addTitle: "Number of packages:",
      selectPlaceholder: "Choose a country",
      startScan: "Start Scan",
      resultTitle: "Start Scan:",
      resultCaption:
        "* If the result is 'Host is down', then the firewall or router is being checked and blocking IP addresses."
    },
    faq: {
      text: []
    }
  },
  proxyChecker: {
    mainTitle: "Instant proxy checker tool",
    condition: "Status",
    ping: "Ping",
    type: "Type",
    status: "Not working",
    workStatus: "Available",
    head: {
      videoTitle: "How and why to use a proxy checker?",
      headText:
        "Use our free online proxy checker to verify the validity of proxy servers. It offers various options to evaluate your proxy: status, country, speed, type of anonymity, and protocol. The proxy checker tool is available for free with unlimited IP address checks.",
      titleFirst: "Proxy checker can:",
      listFirst: [
        "checking IPv4, IPv6 for country ownership;",
        "determination of the type of protocol HTTPS, SOCKS5 and current speed;",
        "checking the anonymity of the address;",
        "group, high-speed multithreaded proxy check in 64 threads;",
        "the ability to upload a list and upload the result of the check;",
        "Convenient display of results using a display filter."
      ],
      titleSecond: "In what format should a proxy be added to the checker?",
      listSecond: [
        {
          content:
            "If you have <span>public proxies</span> (without login and password), then - IP: PORT"
        },
        {
          content:
            "If you have <span>private proxies</span> (with authorization by login and password), then - IP: PORT: USER: PASS"
        }
      ],
      titleThird:
        "P.S .: If you <a href='https://proxy-sale.com/' rel='noreferrer' target='_blank'> bought a proxy </a> with us, then they private!"
    },
    faq: {
      text: []
    }
  },
  siteSpeed: {
    mainTitle: "Test Proxy Speed through required website",
    head: {
      listFirst: [
        "Determining the speed of data transmission through HTTP and SOCKS proxy",
        "Ability to determine the speed of loading pages directly",
        "Check site access"
      ],
      caution: "In what format should the check be done?",
      subtitle:
        "To check the speed through a proxy, you need to drive a resource to which you need access and an IP address in the format:",
      listSecond: [
        {
          text: "Public proxies (without login and password), then —",
          value: "IP:PORT"
        },
        {
          text: "Private proxies (with login and password authorization), then —",
          value: "IP:PORT:USER:PASS"
        }
      ],
      post: "P.S.: If you bought a proxy with us, then they private!"
    },
    checker: {
      adress: "Enter site url to check speed",
      proxy: "Proxy address in the format",
      format: "127.0.0.1:8080",
      subtitle: [
        "To test the speed of data transmission through a proxy, enter the address and port of the proxy server",
        "For a normal test of the speed of loading pages of the site, leave this field blank."
      ],
      type: {
        title: "Proxy server type"
      },
      timeout: {
        title: "Timeout:",
        placeholder: "Timeout"
      },
      access: {
        title: "Check site access",
        body: "Access and speed checks may take some time"
      },
      btn: "Check speed",
      result: {
        title: "Test results:",
        result: {
          titleFirst: "Check - the speed of access to the site through proxy",
          checkSite: "Checking site",
          checkProxy: "Check proxy",
          timeout: "Timeout",
          sec: "sec",
          amountOfDownloads: "Test downloads manufactured",
          titleSecond: "Check completed",
          averageSpeed: "Average download speed",
          generalSpeed: "Total average speed",
          speedValue: "Kb/sec",
          access: "Access to site - Title from downloaded page",
          emptyTitle: "Failed to get Title",
          isProxyUsing: {
            yes: "Using proxy",
            no: "Without a proxy, load directly"
          }
        }
      }
    },
    faq: {
      text: []
    },
    errors: {
      url: "Enter site url"
    }
  },
  spamBase: {
    mainTitle: "IP Blacklist Check",
    head: {
      text: "As you know, spam can often cause IP to be blacklisted, which noticeably disrupts all network operations. To avoid this, you should use the IP check server in spam databases."
    },
    checker: {
      ip: {
        title: "Please enter the correct IP address",
        insert: "Paste my IP address"
      },
      check: "Examination",
      test: "The test may take a while.",
      list: {
        checkedAll: "Checks:",
        checked: "Checked out:",
        blackList: "Blacklisted:"
      },
      result: {
        listed: "Listed",
        notListed: "Not listed"
      }
    },
    faq: {
      text: []
    }
  },
  trace: {
    mainTitle: "IP tracing",
    head: {
      text: "Thanks to the service on this page, you have access to a free and almost instantaneous trace of the IP address or site. It will help you perform network diagnostics, which determines the speed of sending requests through servers on the Internet when you open a web resource in a browser. determine the causes of possible problems associated with slow loading of the site. "
    },
    checker: {
      ip: "Enter the correct IP address (0.0.0.0) or hostname (domain.ru)",
      route: {
        title: "Show package route by country",
        placeholder: "Select an item"
      },
      btn: "Trace IP",
      result: "Test results:"
    }
  },
  webRtcCheck: {
    mainTitle: "WebRTC check",
    head: {
      text: "Checking for leaks via WebRTC. Your data may be at risk even if you are connected to a VPN."
    },
    top: {
      myIp: "Your IP address:",
      from: "Where are you from:",
      state: "Status:",
      warning: "Potential threat"
    },
    info: {
      top: "As you can see, quite detailed information is provided by IP.",
      bottom:
        "We checked whether WebRTC is activated in your browser and whether you have a real IP address. As it turns out, there is a risk of a potential leak, WebRTC is enabled and may reveal your real IP address.",
      disableBottom:
        "We checked whether WebRTC is activated in your browser and whether you have a real IP address. As it turns out, your real IP address remains unknown and WebRTC is not activated in your browser."
    },
    resetButton: "Restart WebRTC leak test",
    status: {
      type: "Type",
      state: "Status",
      topText: "WebRTC enabled",
      bottomText: "Threat to reveal your real IP address!",
      warning: "Threat of leakage",
      checking: "Check, please wait"
    }
  },
  dnsCheck: {
    top: {
      myIp: "Your IP address:",
      from: "Where are you from:",
      state: "Status:",
      warning: "Potential threat"
    },
    info: {
      top: "As you can see, when checking your IP, a DNS leak was detected",
      bottom:
        "We scanned your IP and found a DNS leak, your queries are compromised and your connection is not secure. This means that your DNS administrator can track information about the sites and programs you use.",
      disableBottom:
        "We have scanned your IP and found no leaks through DNS, your requests are not disclosed, and the connection is secure. This means that the administrator of your DNS cannot track information about the sites and programs you are using."
    },
    resetButton: "Restart DNS leak test",
    status: {
      type: "Type",
      state: "Status",
      topText: "DNS enabled",
      bottomText: "Threat to disclose your requests!",
      warning: "Threat of leakage",
      checking: "Check, please wait"
    }
  },
  fingerPrintScanner: {
    mainTitle: "Browser Fingerprint Scanner",
    head: {
      text: "Browser fingerprints are a unique snapshot of your computer's settings, and special website mechanisms can track you."
    },
    top: {
      myIp: "Your IP address:",
      fingerprint: "Browser Fingerprint:"
    },
    table: {
      location: "Location",
      provider: "Provider",
      host: "Host",
      os: "OS",
      browser: "Browser",
      plugin: "Plugin:",
      status: "Status:",
      isOn: "enabled",
      isOff: "turned off",
      device: "Device",
      browserLanguage: "Browser language",
      screenSize: "Screen size",
      timeZone: "Timezone"
    },
    imgAlt: {
      location: "Location",
      provider: "Provider",
      host: "Host",
      os: "operating system",
      browser: "Browser",
      language: "Browser language",
      size: "Screen size",
      timeZone: "Timezone"
    },
    bottom: {
      method: "JS method"
    }
  },
  createEvercookie: {
    mainTitle: "Evercookie check",
    head: {
      text: "Click the button to create an evercookie. Your anonymity will not be compromised: a cookie is a random number between 1 and 1000 that we use to test evercookies. We do not track you using it."
    },
    top: {
      button: "Create Evercookie"
    }
  },
  httpHeaders: {
    requestHeaders: "Request Headers",
    responseHeaders: "Response Headers",
    submitButton: "Submit Request"
  },
  browserInfo: {
    mainTitle: "Information from your browser",
    head: {
      text:
        "Using the browser, various sites and services have the ability to track the user. This is necessary, for example, in order to identify a person even if he has changed his IP address. Advertising systems also use tracking capabilities in order to know the user’s interests.\n" +
        "Our service will help you determine which tracking methods are enabled in your browser."
    },
    table: {
      type: "Type",
      discovered: "Discovered",
      cookieMeaning: "Cookie meaning",
      parameter: "Parameter",
      value: "Value",
      resolution: "Screen resolution:",
      desktop: "Desktop",
      colorDepth: "Color depth",
      bit: "bit"
    },
    button: {
      createCookie: "Create cookies",
      userTracking: "User tracking"
    }
  },
  utm: {
    mainTitle: "Online UTM Builder",
    checker: {
      linkUtm: "Link with UTM tag will appear here",
      copy: "Copy",
      shortUrl: "Short URL",
      traffic: [
        { text: "Google ADS", source: "google" },
        { text: "Yandex.Direct", source: "yandex" },
        { text: "VK.com", source: "vk" },
        { text: "Facebook", source: "facebook" },
        { text: "Instagram", source: "instagram" },
        { text: "Target.Mail.ru", source: "targetmailru" },
        { reset: true, text: "Reset" }
      ]
    },
    faq: {
      text: []
    }
  },
  pageHead: {
    ip: "Your IP",
    changeIp: "Change IP",
    anon: "Your anonymity",
    moreAnon: "Learn more about anonymity"
  },
  checker: {
    country: "Choose a country:",
    add: "Add first:",
    show: {
      title: "Display"
    },
    displayType: "Display",
    checkProxy: "Check proxy",
    fileName: "Load from .txt or .csv",
    typeProxy: {
      title: "Enter proxy:",
      placeholder: "Type your text here"
    },
    info: {
      text: "Ensure uninterrupted access and avoid being blocked. Personal proxies, suitable for all your tasks.",
      buy: "Buy proxy"
    },
    results: {
      title: "Test Results",
      checked: "Checked",
      working: "Working",
      notWorking: "Not working",
      divider: "of",
      btn: "Buy working"
    },
    download: {
      title: "Download only work proxy",
      txt: "download .txt",
      csv: "table in .csv"
    }
  },
  common: {
    faqTitle: "We answer your questions",
    yes: "Yes",
    no: "No",
    notDefined: "Not defined",
    copy: "Copied!",
    select: {
      placeholderCountry: "Choose country"
    },
    buyAnonProxy: "Buy anonimity",
    buyWorkingProxy: "Buy working"
  },
  table: {
    top: {
      workProxy: "Working proxies",
      anon: "Anonymous",
      socks: "Socks 4/5",
      http: "HTTP/HTTPS",
      proxyInBase: "Proxy in the database"
    },
    tableColumns: {
      copy: "Copy to clipboard",
      country: "Country",
      type: "Type of",
      anon: {
        title: "Anonymity",
        speed: "Speed",
        s: "s",
        ms: "ms",
        m: "m",
        h: "h",
        types: {
          anon: "Anonimity",
          proz: "Transparent",
          elite: "Elite",
          notFounded: "Not founded"
        }
      },
      check: {
        title: "Examination",
        tip: "Prompt",
        buy: "Buy working",
        reload: "Reboot",
        ago: "mins. ago",
        status: {
          work: "Working",
          notWorked: "Not working"
        }
      },
      condition: {
        title: "Condition"
      },
      login: "Login",
      password: "Password",
      pagination: {
        rowsPerPage: "Proxies for page",
        separatorText: "of"
      },
      requests: "Requests",
      requestsCluster: "Requests on clusters",
      competitors: "Competitors"
    },
    noDataComponent: "There are no proxies"
  },
  errorTranslation: {
    somethingWrong: "Something went wrong, try again",
    largeProxyList:
      "The number of proxies for verification should not exceed 100",
    emptyProxy: "No proxies for export",
    apiError: "Something went wrong!",
    wrongFile: "Invalid file type. Only allowed .txt or .csv",
    nonReadableFile: "Unable to read file!",
    emptyProxyList: "Enter proxy!",
    emptyClusterQuery: "Enter requests!",
    clusterQueryMinValue: "Enter not less than 2 requests!",
    incorrectIpOrDomain: "Incorrect IP or domain! ",
    incorrectIp: "Incorrect IP! ",
    proxyFilterEmpty: "Enter proxy!",
    proxyIsEmpty: "This country doesn't have proxies!",
    proxyFilterIncorrect: "Incorrect proxy!",
    emptyIpOrDomain: "Enter the correct IP address or domain!",
    emptySiteUrl: "Enter site url!",
    emptyUserAgent: "Select user agent!",
    invalidUrl: "Invalid URL format!",
    timeError: "Try again in a couple of minutes!",
    IncorrectUrlFormat: "Incorrect URL format!"
  },
  anonimityTypes: [
    {
      value: "an-anonim.txt",
      label: "Anonymous",
      defaultChecked: false,
      route: "anonimnyj"
    },
    {
      value: "el-elit.txt",
      label: "Elite",
      defaultChecked: false,
      route: "elite"
    },
    {
      value: "pr-proz.txt",
      label: "Transparent",
      defaultChecked: false,
      route: "transparent"
    }
  ],
  browsers: [
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/chrome.svg",
      value: "Google Chrome"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/safari.svg",
      value: "Safari"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/opera.svg",
      value: "Opera"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/firefox.svg",
      value: "Firefox"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/yandex.svg",
      value: "Yandex"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/android.svg",
      value: "Android"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/explorer.svg",
      value: "Internet Explorer"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/browser1.svg",
      value: "Browser"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      value: "+",
      last: true
    }
  ],
  checkerProxyTypes: [
    {
      value: "HTTP,HTTPS",
      label: "HTTP/HTTPS",
      defaultChecked: true
    },
    {
      value: "SOCKS4,SOCKS5",
      label: "SOCKS 4/5",
      defaultChecked: true
    },
    {
      value: "NO",
      label: "Non-working",
      defaultChecked: true
    }
  ],
  checkPortsTypes: [
    {
      value: "POPULAR",
      label: "Popular Ports",
      defaultChecked: true
    },
    {
      value: "SERVER",
      label: "Server Ports",
      defaultChecked: false
    },
    {
      value: "GAME",
      label: "Game Ports",
      defaultChecked: false
    },
    {
      value: "APPLICATION",
      label: "Application Ports",
      defaultChecked: false
    },
    {
      value: "P2P",
      label: "P2P Ports",
      defaultChecked: false
    }
  ],
  utmInputs: [
    {
      label: "Landing page url",
      name: "url",
      placeholder: "Paste your link or landing page here"
    },
    {
      label: "UTM-source*",
      name: "source",
      placeholder: "Traffic source. For example: google"
    },
    {
      label: "UTM-medium*",
      name: "medium",
      placeholder: "Traffic type. For example: cpc, email, banner"
    },
    {
      label: "UTM-campaign*",
      name: "campaign",
      placeholder: "Advertising company, For example: google-poisk"
    },
    {
      label: "UTM-content",
      name: "content",
      placeholder: "For example: cpc, email, banner"
    },
    {
      label: "UTM-term",
      name: "term",
      placeholder: "Keyword. For example: macro (keyword)"
    }
  ],
  yesNo: [
    { label: "Yes", value: true },
    { label: "No", value: false }
  ],
  timeout: [
    { label: "10 sec", value: 10 },
    { label: "15 sec", value: 15 },
    { label: "30 sec", value: 30 },
    { label: "60 sec", value: 60 }
  ],
  countries: [
    {
      dataBaseValue: "Andorra",
      value: "Andorra",
      label: "Andorra",
      imgSrc: "/img/flags/ad.svg"
    },
    {
      dataBaseValue: "UAE",
      value: "UAE",
      label: "UAE",
      imgSrc: "/img/flags/ae.svg"
    },
    {
      dataBaseValue: "Afghanistan",
      value: "Afghanistan",
      label: "Afghanistan",
      imgSrc: "/img/flags/af.svg"
    },
    {
      dataBaseValue: "Antigua and Barbuda",
      value: "Antigua and Barbuda",
      label: "Antigua and Barbuda",
      imgSrc: "/img/flags/ag.svg"
    },
    {
      dataBaseValue: "Anguilla",
      value: "Anguilla",
      label: "Anguilla",
      imgSrc: "/img/flags/ai.svg"
    },
    {
      dataBaseValue: "Albania",
      value: "Albania",
      label: "Albania",
      imgSrc: "/img/flags/al.svg"
    },
    {
      dataBaseValue: "Armenia",
      value: "Armenia",
      label: "Armenia",
      imgSrc: "/img/flags/am.svg"
    },
    {
      dataBaseValue: "Antilles",
      value: "Antilles",
      label: "Antilles",
      imgSrc: "/img/flags/an.svg"
    },
    {
      dataBaseValue: "Angola",
      value: "Angola",
      label: "Angola",
      imgSrc: "/img/flags/ao.svg"
    },
    {
      dataBaseValue: "Antarctic",
      value: "Antarctic",
      label: "Antarctic",
      imgSrc: "/img/flags/aq.svg"
    },
    {
      dataBaseValue: "Argentina",
      value: "Argentina",
      label: "Argentina",
      imgSrc: "/img/flags/ar.svg"
    },
    {
      dataBaseValue: "American Samoa",
      value: "American Samoa",
      label: "American Samoa",
      imgSrc: "/img/flags/as.svg"
    },
    {
      dataBaseValue: "Austria",
      value: "Austria",
      label: "Austria",
      imgSrc: "/img/flags/at.svg"
    },
    {
      dataBaseValue: "Australia",
      value: "Australia",
      label: "Australia",
      imgSrc: "/img/flags/au.svg"
    },
    {
      dataBaseValue: "Aruba",
      value: "Aruba",
      label: "Aruba",
      imgSrc: "/img/flags/aw.svg"
    },
    {
      dataBaseValue: "Åland Islands",
      value: "Åland Islands",
      label: "Åland Islands",
      imgSrc: "/img/flags/ax.svg"
    },
    {
      dataBaseValue: "Azerbaijan",
      value: "Azerbaijan",
      label: "Azerbaijan",
      imgSrc: "/img/flags/az.svg"
    },
    {
      dataBaseValue: "Bosnia and Herzegovina",
      value: "Bosnia and Herzegovina",
      label: "Bosnia and Herzegovina",
      imgSrc: "/img/flags/ba.svg"
    },
    {
      dataBaseValue: "Barbados",
      value: "Barbados",
      label: "Barbados",
      imgSrc: "/img/flags/bb.svg"
    },
    {
      dataBaseValue: "Bangladesh",
      value: "Bangladesh",
      label: "Bangladesh",
      imgSrc: "/img/flags/bd.svg"
    },
    {
      dataBaseValue: "Belgium",
      value: "Belgium",
      label: "Belgium",
      imgSrc: "/img/flags/be.svg"
    },
    {
      dataBaseValue: "Burkina Faso",
      value: "Burkina Faso",
      label: "Burkina Faso",
      imgSrc: "/img/flags/bf.svg"
    },
    {
      dataBaseValue: "Bulgaria",
      value: "Bulgaria",
      label: "Bulgaria",
      imgSrc: "/img/flags/bg.svg"
    },
    {
      dataBaseValue: "Bahrain",
      value: "Bahrain",
      label: "Bahrain",
      imgSrc: "/img/flags/bh.svg"
    },
    {
      dataBaseValue: "Burundi",
      value: "Burundi",
      label: "Burundi",
      imgSrc: "/img/flags/bi.svg"
    },
    {
      dataBaseValue: "Benin",
      value: "Benin",
      label: "Benin",
      imgSrc: "/img/flags/bj.svg"
    },
    {
      dataBaseValue: "Saint Barthélemy",
      value: "Saint Barthélemy",
      label: "Saint Barthélemy",
      imgSrc: "/img/flags/bl.svg"
    },
    {
      dataBaseValue: "Bermuda",
      value: "Bermuda",
      label: "Bermuda",
      imgSrc: "/img/flags/bm.svg"
    },
    {
      dataBaseValue: "Brunei",
      value: "Brunei",
      label: "Brunei",
      imgSrc: "/img/flags/bn.svg"
    },
    {
      dataBaseValue: "Bolivia",
      value: "Bolivia",
      label: "Bolivia",
      imgSrc: "/img/flags/bo.svg"
    },
    {
      dataBaseValue: "Bonaire",
      value: "Bonaire",
      label: "Bonaire",
      imgSrc: "/img/flags/bq.svg"
    },
    {
      dataBaseValue: "Brazil",
      value: "Brazil",
      label: "Brazil",
      imgSrc: "/img/flags/br.svg"
    },
    {
      dataBaseValue: "Bahamas",
      value: "Bahamas",
      label: "Bahamas",
      imgSrc: "/img/flags/bs.svg"
    },
    {
      dataBaseValue: "Butane",
      value: "Butane",
      label: "Butane",
      imgSrc: "/img/flags/bt.svg"
    },
    {
      dataBaseValue: "Bouvet Island",
      value: "Bouvet Island",
      label: "Bouvet Island",
      imgSrc: "/img/flags/bv.svg"
    },
    {
      dataBaseValue: "Botswana",
      value: "Botswana",
      label: "Botswana",
      imgSrc: "/img/flags/bw.svg"
    },
    {
      dataBaseValue: "Belarus",
      value: "Belarus",
      label: "Belarus",
      imgSrc: "/img/flags/by.svg"
    },
    {
      dataBaseValue: "Belize",
      value: "Belize",
      label: "Belize",
      imgSrc: "/img/flags/bz.svg"
    },
    {
      dataBaseValue: "Canada",
      value: "Canada",
      label: "Canada",
      imgSrc: "/img/flags/ca.svg"
    },
    {
      dataBaseValue: "Coconut islands",
      value: "Coconut islands",
      label: "Coconut islands",
      imgSrc: "/img/flags/cc.svg"
    },
    {
      dataBaseValue: "DR Congo",
      value: "DR Congo",
      label: "DR Congo",
      imgSrc: "/img/flags/cd.svg"
    },
    {
      dataBaseValue: "Central African Republic",
      value: "Central African Republic",
      label: "Central African Republic",
      imgSrc: "/img/flags/cf.svg"
    },
    {
      dataBaseValue: "Congo",
      value: "Congo",
      label: "Congo",
      imgSrc: "/img/flags/cg.svg"
    },
    {
      dataBaseValue: "Switzerland",
      value: "Switzerland",
      label: "Switzerland",
      imgSrc: "/img/flags/ch.svg"
    },
    {
      dataBaseValue: "Ivory Coast",
      value: "Ivory Coast",
      label: "Ivory Coast",
      imgSrc: "/img/flags/ci.svg"
    },
    {
      dataBaseValue: "Cook Islands",
      value: "Cook Islands",
      label: "Cook Islands",
      imgSrc: "/img/flags/ck.svg"
    },
    {
      dataBaseValue: "Chile",
      value: "Chile",
      label: "Chile",
      imgSrc: "/img/flags/cl.svg"
    },
    {
      dataBaseValue: "Cameroon",
      value: "Cameroon",
      label: "Cameroon",
      imgSrc: "/img/flags/cm.svg"
    },
    {
      dataBaseValue: "China",
      value: "China",
      label: "China",
      imgSrc: "/img/flags/cn.svg"
    },
    {
      dataBaseValue: "Colombia",
      value: "Colombia",
      label: "Colombia",
      imgSrc: "/img/flags/co.svg"
    },
    {
      dataBaseValue: "Costa Rica",
      value: "Costa Rica",
      label: "Costa Rica",
      imgSrc: "/img/flags/cr.svg"
    },
    {
      dataBaseValue: "Cuba",
      value: "Cuba",
      label: "Cuba",
      imgSrc: "/img/flags/cu.svg"
    },
    {
      dataBaseValue: "Cape Verde",
      value: "Cape Verde",
      label: "Cape Verde",
      imgSrc: "/img/flags/cv.svg"
    },
    {
      dataBaseValue: "Curacao",
      value: "Curacao",
      label: "Curacao",
      imgSrc: "/img/flags/cw.svg"
    },
    {
      dataBaseValue: "Christmas Island",
      value: "Christmas Island",
      label: "Christmas Island",
      imgSrc: "/img/flags/cx.svg"
    },
    {
      dataBaseValue: "Cyprus",
      value: "Cyprus",
      label: "Cyprus",
      imgSrc: "/img/flags/cy.svg"
    },
    {
      dataBaseValue: "Czech Republic",
      value: "Czech",
      label: "Czech",
      imgSrc: "/img/flags/cz.svg"
    },
    {
      dataBaseValue: "Germany",
      value: "Germany",
      label: "Germany",
      imgSrc: "/img/flags/de.svg"
    },
    {
      dataBaseValue: "Djibouti",
      value: "Djibouti",
      label: "Djibouti",
      imgSrc: "/img/flags/dj.svg"
    },
    {
      dataBaseValue: "Denmark",
      value: "Denmark",
      label: "Denmark",
      imgSrc: "/img/flags/dk.svg"
    },
    {
      dataBaseValue: "Dominica",
      value: "Dominica",
      label: "Dominica",
      imgSrc: "/img/flags/dm.svg"
    },
    {
      dataBaseValue: "Dominican Republic",
      value: "Dominican Republic",
      label: "Dominican Republic",
      imgSrc: "/img/flags/do.svg"
    },
    {
      dataBaseValue: "Algeria",
      value: "Algeria",
      label: "Algeria",
      imgSrc: "/img/flags/dz.svg"
    },
    {
      dataBaseValue: "Ecuador",
      value: "Ecuador",
      label: "Ecuador",
      imgSrc: "/img/flags/ec.svg"
    },
    {
      dataBaseValue: "Estonia",
      value: "Estonia",
      label: "Estonia",
      imgSrc: "/img/flags/ee.svg"
    },
    {
      dataBaseValue: "Egypt",
      value: "Egypt",
      label: "Egypt",
      imgSrc: "/img/flags/eg.svg"
    },
    {
      dataBaseValue: "West Sahara",
      value: "West Sahara",
      label: "West Sahara",
      imgSrc: "/img/flags/eh.svg"
    },
    {
      dataBaseValue: "Eritrea",
      value: "Eritrea",
      label: "Eritrea",
      imgSrc: "/img/flags/er.svg"
    },
    {
      dataBaseValue: "Spain",
      value: "Spain",
      label: "Spain",
      imgSrc: "/img/flags/es.svg"
    },
    {
      dataBaseValue: "Ethiopia",
      value: "Ethiopia",
      label: "Ethiopia",
      imgSrc: "/img/flags/et.svg"
    },
    {
      dataBaseValue: "Europe",
      value: "Europe",
      label: "Europe",
      imgSrc: "/img/flags/eu.svg"
    },
    {
      dataBaseValue: "Finland",
      value: "Finland",
      label: "Finland",
      imgSrc: "/img/flags/fi.svg"
    },
    {
      dataBaseValue: "Netherlands",
      value: "Netherlands",
      label: "Netherlands",
      imgSrc: "/img/flags/nl.svg"
    },
    {
      dataBaseValue: "Fiji",
      value: "Fiji",
      label: "Fiji",
      imgSrc: "/img/flags/fj.svg"
    },
    {
      dataBaseValue: "Falkland Islands",
      value: "Falkland Islands",
      label: "Falkland Islands",
      imgSrc: "/img/flags/fk.svg"
    },
    {
      dataBaseValue: "Federated States of Micronesia",
      value: "Federated States of Micronesia",
      label: "Federated States of Micronesia",
      imgSrc: "/img/flags/fm.svg"
    },
    {
      dataBaseValue: "Faroe Islands",
      value: "Faroe Islands",
      label: "Faroe Islands",
      imgSrc: "/img/flags/fo.svg"
    },
    {
      dataBaseValue: "France",
      value: "France",
      label: "France",
      imgSrc: "/img/flags/fr.svg"
    },
    {
      dataBaseValue: "Georgia",
      value: "Georgia",
      label: "Georgia",
      imgSrc: "/img/flags/ge.svg"
    },
    // {
    //   dataBaseValue: "United Kingdom",
    //   value: "United Kingdom",
    //   label: "United Kingdom",
    //   imgSrc: "/img/flags/en.svg"
    // },
    {
      dataBaseValue: "Northern Ireland",
      value: "Northern Ireland",
      label: "Northern Ireland",
      imgSrc: "/img/flags/nir.svg"
    },
    {
      dataBaseValue: "Scotland",
      value: "Scotland",
      label: "Scotland",
      imgSrc: "/img/flags/sct.svg"
    },
    {
      dataBaseValue: "Wales",
      value: "Wales",
      label: "Wales",
      imgSrc: "/img/flags/wls.svg"
    },
    {
      dataBaseValue: "Grenada",
      value: "Grenada",
      label: "Grenada",
      imgSrc: "/img/flags/gd.svg"
    },
    {
      dataBaseValue: "French Guiana",
      value: "French Guiana",
      label: "French Guiana",
      imgSrc: "/img/flags/gf.svg"
    },
    {
      dataBaseValue: "Guernsey",
      value: "Guernsey",
      label: "Guernsey",
      imgSrc: "/img/flags/gg.svg"
    },
    {
      dataBaseValue: "Ghana",
      value: "Ghana",
      label: "Ghana",
      imgSrc: "/img/flags/gh.svg"
    },
    {
      dataBaseValue: "Gibraltar",
      value: "Gibraltar",
      label: "Gibraltar",
      imgSrc: "/img/flags/gi.svg"
    },
    {
      dataBaseValue: "Greenland",
      value: "Greenland",
      label: "Greenland",
      imgSrc: "/img/flags/gl.svg"
    },
    {
      dataBaseValue: "Gambia",
      value: "Gambia",
      label: "Gambia",
      imgSrc: "/img/flags/gm.svg"
    },
    {
      dataBaseValue: "Guinea",
      value: "Guinea",
      label: "Guinea",
      imgSrc: "/img/flags/gn.svg"
    },
    {
      dataBaseValue: "Guadeloupe",
      value: "Guadeloupe",
      label: "Guadeloupe",
      imgSrc: "/img/flags/gp.svg"
    },
    {
      dataBaseValue: "Equatorial Guinea",
      value: "Equatorial Guinea",
      label: "Equatorial Guinea",
      imgSrc: "/img/flags/gq.svg"
    },
    {
      dataBaseValue: "Greece",
      value: "Greece",
      label: "Greece",
      imgSrc: "/img/flags/gr.svg"
    },
    {
      dataBaseValue: "South Georgia and the South Sandwich Islands",
      value: "South Georgia and the South Sandwich Islands",
      label: "South Georgia and the South Sandwich Islands",
      imgSrc: "/img/flags/gs.svg"
    },
    {
      dataBaseValue: "Guatemala",
      value: "Guatemala",
      label: "Guatemala",
      imgSrc: "/img/flags/gt.svg"
    },
    {
      dataBaseValue: "Guam",
      value: "Guam",
      label: "Guam",
      imgSrc: "/img/flags/gu.svg"
    },
    {
      dataBaseValue: "Guinea-Bissau",
      value: "Guinea-Bissau",
      label: "Guinea-Bissau",
      imgSrc: "/img/flags/gw.svg"
    },
    {
      dataBaseValue: "Guyana",
      value: "Guyana",
      label: "Guyana",
      imgSrc: "/img/flags/gy.svg"
    },
    {
      dataBaseValue: "Hong Kong",
      value: "Hong Kong",
      label: "Hong Kong",
      imgSrc: "/img/flags/hk.svg"
    },
    {
      dataBaseValue: "Honduras",
      value: "Honduras",
      label: "Honduras",
      imgSrc: "/img/flags/hn.svg"
    },
    {
      dataBaseValue: "Croatia",
      value: "Croatia",
      label: "Croatia",
      imgSrc: "/img/flags/hr.svg"
    },
    {
      dataBaseValue: "Haiti",
      value: "Haiti",
      label: "Haiti",
      imgSrc: "/img/flags/ht.svg"
    },
    {
      dataBaseValue: "Hungary",
      value: "Hungary",
      label: "Hungary",
      imgSrc: "/img/flags/hu.svg"
    },
    {
      dataBaseValue: "Indonesia",
      value: "Indonesia",
      label: "Indonesia",
      imgSrc: "/img/flags/id.svg"
    },
    {
      dataBaseValue: "Ireland",
      value: "Ireland",
      label: "Ireland",
      imgSrc: "/img/flags/ie.svg"
    },
    {
      dataBaseValue: "Israel",
      value: "Israel",
      label: "Israel",
      imgSrc: "/img/flags/il.svg"
    },
    {
      dataBaseValue: "Isle of Man",
      value: "Isle of Man",
      label: "Isle of Man",
      imgSrc: "/img/flags/im.svg"
    },
    {
      dataBaseValue: "India",
      value: "India",
      label: "India",
      imgSrc: "/img/flags/in.svg"
    },
    {
      dataBaseValue: "British Indian Ocean Territory",
      value: "British Indian Ocean Territory",
      label: "British Indian Ocean Territory",
      imgSrc: "/img/flags/io.svg"
    },
    {
      dataBaseValue: "Iraq",
      value: "Iraq",
      label: "Iraq",
      imgSrc: "/img/flags/iq.svg"
    },
    {
      dataBaseValue: "Iran",
      value: "Iran",
      label: "Iran",
      imgSrc: "/img/flags/ir.svg"
    },
    {
      dataBaseValue: "Italy",
      value: "Italy",
      label: "Italy",
      imgSrc: "/img/flags/it.svg"
    },
    {
      dataBaseValue: "Jersey",
      value: "Jersey",
      label: "Jersey",
      imgSrc: "/img/flags/je.svg"
    },
    {
      dataBaseValue: "Jamaica",
      value: "Jamaica",
      label: "Jamaica",
      imgSrc: "/img/flags/jm.svg"
    },
    {
      dataBaseValue: "Jordan",
      value: "Jordan",
      label: "Jordan",
      imgSrc: "/img/flags/jo.svg"
    },
    {
      dataBaseValue: "Japan",
      value: "Japan",
      label: "Japan",
      imgSrc: "/img/flags/jp.svg"
    },
    {
      dataBaseValue: "Kenya",
      value: "Kenya",
      label: "Kenya",
      imgSrc: "/img/flags/ke.svg"
    },
    {
      dataBaseValue: "Kyrgyzstan",
      value: "Kyrgyzstan",
      label: "Kyrgyzstan",
      imgSrc: "/img/flags/kg.svg"
    },
    {
      dataBaseValue: "Cambodia",
      value: "Cambodia",
      label: "Cambodia",
      imgSrc: "/img/flags/kh.svg"
    },
    {
      dataBaseValue: "Kiribati",
      value: "Kiribati",
      label: "Kiribati",
      imgSrc: "/img/flags/ki.svg"
    },
    {
      dataBaseValue: "Comoros",
      value: "Comoros",
      label: "Comoros",
      imgSrc: "/img/flags/km.svg"
    },
    {
      dataBaseValue: "Saint Kitts and Nevis",
      value: "Saint Kitts and Nevis",
      label: "Saint Kitts and Nevis",
      imgSrc: "/img/flags/kn.svg"
    },
    {
      dataBaseValue: "Democratic People's Republic of Korea",
      value: "Democratic People's Republic of Korea",
      label: "Democratic People's Republic of Korea",
      imgSrc: "/img/flags/kp.svg"
    },
    {
      dataBaseValue: "South Korea",
      value: "South Korea",
      label: "South Korea",
      imgSrc: "/img/flags/kr.svg"
    },
    {
      dataBaseValue: "Kuwait",
      value: "Kuwait",
      label: "Kuwait",
      imgSrc: "/img/flags/kw.svg"
    },
    {
      dataBaseValue: "Cayman Islands",
      value: "Cayman Islands",
      label: "Cayman Islands",
      imgSrc: "/img/flags/ky.svg"
    },
    {
      dataBaseValue: "Kazakhstan",
      value: "Kazakhstan",
      label: "Kazakhstan",
      imgSrc: "/img/flags/kz.svg"
    },
    {
      dataBaseValue: "Laos",
      value: "Laos",
      label: "Laos",
      imgSrc: "/img/flags/la.svg"
    },
    {
      dataBaseValue: "Lebanon",
      value: "Lebanon",
      label: "Lebanon",
      imgSrc: "/img/flags/lb.svg"
    },
    {
      dataBaseValue: "Saint Lucia",
      value: "Saint Lucia",
      label: "Saint Lucia",
      imgSrc: "/img/flags/lc.svg"
    },
    {
      dataBaseValue: "Liechtenstein",
      value: "Liechtenstein",
      label: "Liechtenstein",
      imgSrc: "/img/flags/li.svg"
    },
    {
      dataBaseValue: "Sri Lanka",
      value: "Sri Lanka",
      label: "Sri Lanka",
      imgSrc: "/img/flags/lk.svg"
    },
    {
      dataBaseValue: "Liberia",
      value: "Liberia",
      label: "Liberia",
      imgSrc: "/img/flags/lr.svg"
    },
    {
      dataBaseValue: "Lesotho",
      value: "Lesotho",
      label: "Lesotho",
      imgSrc: "/img/flags/ls.svg"
    },
    {
      dataBaseValue: "Lithuania",
      value: "Lithuania",
      label: "Lithuania",
      imgSrc: "/img/flags/lt.svg"
    },
    {
      dataBaseValue: "Luxembourg",
      value: "Luxembourg",
      label: "Luxembourg",
      imgSrc: "/img/flags/lu.svg"
    },
    {
      dataBaseValue: "Latvia",
      value: "Latvia",
      label: "Latvia",
      imgSrc: "/img/flags/lv.svg"
    },
    {
      dataBaseValue: "Libya",
      value: "Libya",
      label: "Libya",
      imgSrc: "/img/flags/ly.svg"
    },
    {
      dataBaseValue: "Morocco",
      value: "Morocco",
      label: "Morocco",
      imgSrc: "/img/flags/ma.svg"
    },
    {
      dataBaseValue: "Monaco",
      value: "Monaco",
      label: "Monaco",
      imgSrc: "/img/flags/mc.svg"
    },
    {
      dataBaseValue: "Moldova",
      value: "Moldova",
      label: "Moldova",
      imgSrc: "/img/flags/md.svg"
    },
    {
      dataBaseValue: "Montenegro",
      value: "Montenegro",
      label: "Montenegro",
      imgSrc: "/img/flags/me.svg"
    },
    {
      dataBaseValue: "Saint Martin",
      value: "Saint Martin",
      label: "Saint Martin",
      imgSrc: "/img/flags/mf.svg"
    },
    {
      dataBaseValue: "Madagascar",
      value: "Madagascar",
      label: "Madagascar",
      imgSrc: "/img/flags/mg.svg"
    },
    {
      dataBaseValue: "Marshall Islands",
      value: "Marshall Islands",
      label: "Marshall Islands",
      imgSrc: "/img/flags/mh.svg"
    },
    {
      dataBaseValue: "Macedonia",
      value: "Macedonia",
      label: "Macedonia",
      imgSrc: "/img/flags/mk.svg"
    },
    {
      dataBaseValue: "Mali",
      value: "Mali",
      label: "Mali",
      imgSrc: "/img/flags/ml.svg"
    },
    {
      dataBaseValue: "Myanmar",
      value: "Myanmar",
      label: "Myanmar",
      imgSrc: "/img/flags/mm.svg"
    },
    {
      dataBaseValue: "Mongolia",
      value: "Mongolia",
      label: "Mongolia",
      imgSrc: "/img/flags/mn.svg"
    },
    {
      dataBaseValue: "Macau",
      value: "Macau",
      label: "Macau",
      imgSrc: "/img/flags/mo.svg"
    },
    {
      dataBaseValue: "Northern Mariana Islands",
      value: "Northern Mariana Islands",
      label: "Northern Mariana Islands",
      imgSrc: "/img/flags/mp.svg"
    },
    {
      dataBaseValue: "Martinique",
      value: "Martinique",
      label: "Martinique",
      imgSrc: "/img/flags/mq.svg"
    },
    {
      dataBaseValue: "Mauritius",
      value: "Mauritania",
      label: "Mauritania",
      imgSrc: "/img/flags/mr.svg"
    },
    {
      dataBaseValue: "Montserrat",
      value: "Montserrat",
      label: "Montserrat",
      imgSrc: "/img/flags/ms.svg"
    },
    {
      dataBaseValue: "Malta",
      value: "Malta",
      label: "Malta",
      imgSrc: "/img/flags/mt.svg"
    },
    {
      dataBaseValue: "O. Mauritius",
      value: "O. Mauritius",
      label: "O. Mauritius",
      imgSrc: "/img/flags/mu.svg"
    },
    {
      dataBaseValue: "Maldives",
      value: "Maldives",
      label: "Maldives",
      imgSrc: "/img/flags/mv.svg"
    },
    {
      dataBaseValue: "Malawi",
      value: "Malawi",
      label: "Malawi",
      imgSrc: "/img/flags/mw.svg"
    },
    {
      dataBaseValue: "Mexico",
      value: "Mexico",
      label: "Mexico",
      imgSrc: "/img/flags/mx.svg"
    },
    {
      dataBaseValue: "Malaysia",
      value: "Malaysia",
      label: "Malaysia",
      imgSrc: "/img/flags/my.svg"
    },
    {
      dataBaseValue: "Mozambique",
      value: "Mozambique",
      label: "Mozambique",
      imgSrc: "/img/flags/mz.svg"
    },
    {
      dataBaseValue: "Namibia",
      value: "Namibia",
      label: "Namibia",
      imgSrc: "/img/flags/na.svg"
    },
    {
      dataBaseValue: "New Caledonia",
      value: "New Caledonia",
      label: "New Caledonia",
      imgSrc: "/img/flags/nc.svg"
    },
    {
      dataBaseValue: "Niger",
      value: "Niger",
      label: "Niger",
      imgSrc: "/img/flags/ne.svg"
    },
    {
      dataBaseValue: "Norfolk Island",
      value: "Norfolk Island",
      label: "Norfolk Island",
      imgSrc: "/img/flags/nf.svg"
    },
    {
      dataBaseValue: "Nigeria",
      value: "Nigeria",
      label: "Nigeria",
      imgSrc: "/img/flags/ng.svg"
    },
    {
      dataBaseValue: "Nicaragua",
      value: "Nicaragua",
      label: "Nicaragua",
      imgSrc: "/img/flags/ni.svg"
    },
    {
      dataBaseValue: "Norway",
      value: "Norway",
      label: "Norway",
      imgSrc: "/img/flags/no.svg"
    },
    {
      dataBaseValue: "Nepal",
      value: "Nepal",
      label: "Nepal",
      imgSrc: "/img/flags/np.svg"
    },
    {
      dataBaseValue: "Nauru",
      value: "Nauru",
      label: "Nauru",
      imgSrc: "/img/flags/nr.svg"
    },
    {
      dataBaseValue: "Niue",
      value: "Niue",
      label: "Niue",
      imgSrc: "/img/flags/nu.svg"
    },
    {
      dataBaseValue: "New Zealand",
      value: "New Zealand",
      label: "New Zealand",
      imgSrc: "/img/flags/nz.svg"
    },
    {
      dataBaseValue: "Oman",
      value: "Oman",
      label: "Oman",
      imgSrc: "/img/flags/om.svg"
    },
    {
      dataBaseValue: "Panama",
      value: "Panama",
      label: "Panama",
      imgSrc: "/img/flags/pa.svg"
    },
    {
      dataBaseValue: "Peru",
      value: "Peru",
      label: "Peru",
      imgSrc: "/img/flags/pe.svg"
    },
    {
      dataBaseValue: "French polynesia",
      value: "French polynesia",
      label: "French polynesia",
      imgSrc: "/img/flags/pf.svg"
    },
    {
      dataBaseValue: "Papua New Guinea",
      value: "Papua New Guinea",
      label: "Papua New Guinea",
      imgSrc: "/img/flags/pg.svg"
    },
    {
      dataBaseValue: "Philippines",
      value: "Philippines",
      label: "Philippines",
      imgSrc: "/img/flags/ph.svg"
    },
    {
      dataBaseValue: "Pakistan",
      value: "Pakistan",
      label: "Pakistan",
      imgSrc: "/img/flags/pk.svg"
    },
    {
      dataBaseValue: "Poland",
      value: "Poland",
      label: "Poland",
      imgSrc: "/img/flags/pl.svg"
    },
    {
      dataBaseValue: "Saint Pierre and Miquelon",
      value: "Saint Pierre and Miquelon",
      label: "Saint Pierre and Miquelon",
      imgSrc: "/img/flags/pm.svg"
    },
    {
      dataBaseValue: "Pitcairn Islands",
      value: "Pitcairn Islands",
      label: "Pitcairn Islands",
      imgSrc: "/img/flags/pn.svg"
    },
    {
      dataBaseValue: "Puerto Rico",
      value: "Puerto Rico",
      label: "Puerto Rico",
      imgSrc: "/img/flags/pr.svg"
    },
    {
      dataBaseValue: "Palestine",
      value: "Palestine",
      label: "Palestine",
      imgSrc: "/img/flags/ps.svg"
    },
    {
      dataBaseValue: "Portugal",
      value: "Portugal",
      label: "Portugal",
      imgSrc: "/img/flags/pt.svg"
    },
    {
      dataBaseValue: "Paraguay",
      value: "Paraguay",
      label: "Paraguay",
      imgSrc: "/img/flags/py.svg"
    },
    {
      dataBaseValue: "Qatar",
      value: "Qatar",
      label: "Qatar",
      imgSrc: "/img/flags/qa.svg"
    },
    {
      dataBaseValue: "Reunion",
      value: "Reunion",
      label: "Reunion",
      imgSrc: "/img/flags/re.svg"
    },
    {
      dataBaseValue: "Romania",
      value: "Romania",
      label: "Romania",
      imgSrc: "/img/flags/ro.svg"
    },
    {
      dataBaseValue: "Serbia",
      value: "Serbia",
      label: "Serbia",
      imgSrc: "/img/flags/rs.svg"
    },
    {
      dataBaseValue: "Russia",
      value: "Russia",
      label: "Russia",
      imgSrc: "/img/flags/ru.svg"
    },
    {
      dataBaseValue: "Rwanda",
      value: "Rwanda",
      label: "Rwanda",
      imgSrc: "/img/flags/rw.svg"
    },
    {
      dataBaseValue: "South Africa",
      value: "South Africa",
      label: "South Africa",
      imgSrc: "/img/flags/sa.svg"
    },
    {
      dataBaseValue: "Solomon Islands",
      value: "Solomon Islands",
      label: "Solomon Islands",
      imgSrc: "/img/flags/sb.svg"
    },
    {
      dataBaseValue: "Seychelles",
      value: "Seychelles",
      label: "Seychelles",
      imgSrc: "/img/flags/sc.svg"
    },
    {
      dataBaseValue: "Sudan",
      value: "Sudan",
      label: "Sudan",
      imgSrc: "/img/flags/sd.svg"
    },
    {
      dataBaseValue: "Sweden",
      value: "Sweden",
      label: "Sweden",
      imgSrc: "/img/flags/se.svg"
    },
    {
      dataBaseValue: "Singapore",
      value: "Singapore",
      label: "Singapore",
      imgSrc: "/img/flags/sg.svg"
    },
    {
      dataBaseValue: "O. St. Helena",
      value: "O. St. Helena",
      label: "O. St. Helena",
      imgSrc: "/img/flags/sh.svg"
    },
    {
      dataBaseValue: "Slovenia",
      value: "Slovenia",
      label: "Slovenia",
      imgSrc: "/img/flags/si.svg"
    },
    {
      dataBaseValue: "Svalbard",
      value: "Svalbard",
      label: "Svalbard",
      imgSrc: "/img/flags/sj.svg"
    },
    {
      dataBaseValue: "Slovakia",
      value: "Slovakia",
      label: "Slovakia",
      imgSrc: "/img/flags/sk.svg"
    },
    {
      dataBaseValue: "Sierra Leone",
      value: "Sierra Leone",
      label: "Sierra Leone",
      imgSrc: "/img/flags/sl.svg"
    },
    {
      dataBaseValue: "San Marino",
      value: "San Marino",
      label: "San Marino",
      imgSrc: "/img/flags/sm.svg"
    },
    {
      dataBaseValue: "Senegal",
      value: "Senegal",
      label: "Senegal",
      imgSrc: "/img/flags/sn.svg"
    },
    {
      dataBaseValue: "Somalia",
      value: "Somalia",
      label: "Somalia",
      imgSrc: "/img/flags/so.svg"
    },
    {
      dataBaseValue: "Suriname",
      value: "Suriname",
      label: "Suriname",
      imgSrc: "/img/flags/sr.svg"
    },
    {
      dataBaseValue: "South Sudan",
      value: "South Sudan",
      label: "South Sudan",
      imgSrc: "/img/flags/ss.svg"
    },
    {
      dataBaseValue: "Sao Tome and Principe",
      value: "Sao Tome and Principe",
      label: "Sao Tome and Principe",
      imgSrc: "/img/flags/st.svg"
    },
    {
      dataBaseValue: "Salvador",
      value: "Salvador",
      label: "Salvador",
      imgSrc: "/img/flags/sv.svg"
    },
    {
      dataBaseValue: "Sint Maarten",
      value: "Sint Maarten",
      label: "Sint Maarten",
      imgSrc: "/img/flags/sx.svg"
    },
    {
      dataBaseValue: "Syria",
      value: "Syria",
      label: "Syria",
      imgSrc: "/img/flags/sy.svg"
    },
    {
      dataBaseValue: "Eswatini",
      value: "Eswatini",
      label: "Eswatini",
      imgSrc: "/img/flags/sz.svg"
    },
    {
      dataBaseValue: "Turks and Caicos Islands",
      value: "Turks and Caicos Islands",
      label: "Turks and Caicos Islands",
      imgSrc: "/img/flags/tc.svg"
    },
    {
      dataBaseValue: "Chad",
      value: "Chad",
      label: "Chad",
      imgSrc: "/img/flags/td.svg"
    },
    {
      dataBaseValue: "Togo",
      value: "Togo",
      label: "Togo",
      imgSrc: "/img/flags/tg.svg"
    },
    {
      dataBaseValue: "Thailand",
      value: "Thailand",
      label: "Thailand",
      imgSrc: "/img/flags/th.svg"
    },
    {
      dataBaseValue: "Tajikistan",
      value: "Tajikistan",
      label: "Tajikistan",
      imgSrc: "/img/flags/tj.svg"
    },
    {
      dataBaseValue: "Tokelau",
      value: "Tokelau",
      label: "Tokelau",
      imgSrc: "/img/flags/tk.svg"
    },
    {
      dataBaseValue: "East Timor",
      value: "East Timor",
      label: "East Timor",
      imgSrc: "/img/flags/tl.svg"
    },
    {
      dataBaseValue: "Turkmenistan",
      value: "Turkmenistan",
      label: "Turkmenistan",
      imgSrc: "/img/flags/tm.svg"
    },
    {
      dataBaseValue: "Tunisia",
      value: "Tunisia",
      label: "Tunisia",
      imgSrc: "/img/flags/tn.svg"
    },
    {
      dataBaseValue: "Turkey",
      value: "Turkey",
      label: "Turkey",
      imgSrc: "/img/flags/tr.svg"
    },
    {
      dataBaseValue: "Trinidad and Tobago",
      value: "Trinidad and Tobago",
      label: "Trinidad and Tobago",
      imgSrc: "/img/flags/tt.svg"
    },
    {
      dataBaseValue: "Tuvalu",
      value: "Tuvalu",
      label: "Tuvalu",
      imgSrc: "/img/flags/tv.svg"
    },
    {
      dataBaseValue: "Taiwan",
      value: "Taiwan",
      label: "Taiwan",
      imgSrc: "/img/flags/tw.svg"
    },
    {
      dataBaseValue: "Tanzania",
      value: "Tanzania",
      label: "Tanzania",
      imgSrc: "/img/flags/tz.svg"
    },
    {
      dataBaseValue: "Ukraine",
      value: "Ukraine",
      label: "Ukraine",
      imgSrc: "/img/flags/ua.svg"
    },
    {
      dataBaseValue: "Uganda",
      value: "Uganda",
      label: "Uganda",
      imgSrc: "/img/flags/ug.svg"
    },
    {
      dataBaseValue: "Minor Outlying Islands (USA)",
      value: "Minor Outlying Islands (USA)",
      label: "Minor Outlying Islands (USA)",
      imgSrc: "/img/flags/um.svg"
    },
    {
      dataBaseValue: "United States",
      value: "United States of America",
      label: "United States of America",
      imgSrc: "/img/flags/us.svg"
    },
    {
      dataBaseValue: "United Kingdom",
      value: "United Kingdom",
      label: "United Kingdom",
      imgSrc: "/img/flags/uk.svg"
    },
    {
      dataBaseValue: "Uruguay",
      value: "Uruguay",
      label: "Uruguay",
      imgSrc: "/img/flags/uy.svg"
    },
    {
      dataBaseValue: "Uzbekistan",
      value: "Uzbekistan",
      label: "Uzbekistan",
      imgSrc: "/img/flags/uz.svg"
    },
    {
      dataBaseValue: "Vatican City State",
      value: "Vatican City State",
      label: "Vatican City State",
      imgSrc: "/img/flags/va.svg"
    },
    {
      dataBaseValue: "Saint Vincent and the Grenadines",
      value: "Saint Vincent and the Grenadines",
      label: "Saint Vincent and the Grenadines",
      imgSrc: "/img/flags/vc.svg"
    },
    {
      dataBaseValue: "Venezuela",
      value: "Venezuela",
      label: "Venezuela",
      imgSrc: "/img/flags/ve.svg"
    },
    {
      dataBaseValue: "British Virgin Islands",
      value: "British Virgin Islands",
      label: "British Virgin Islands",
      imgSrc: "/img/flags/vg.svg"
    },
    {
      dataBaseValue: "US Virgin Islands",
      value: "US Virgin Islands",
      label: "US Virgin Islands",
      imgSrc: "/img/flags/vi.svg"
    },
    {
      dataBaseValue: "Vietnam",
      value: "Vietnam",
      label: "Vietnam",
      imgSrc: "/img/flags/vn.svg"
    },
    {
      dataBaseValue: "Vanuatu",
      value: "Vanuatu",
      label: "Vanuatu",
      imgSrc: "/img/flags/vu.svg"
    },
    {
      dataBaseValue: "Wallis and Futuna Islands",
      value: "Wallis and Futuna Islands",
      label: "Wallis and Futuna Islands",
      imgSrc: "/img/flags/wf.svg"
    },
    {
      dataBaseValue: "Samoa",
      value: "Samoa",
      label: "Samoa",
      imgSrc: "/img/flags/ws.svg"
    },
    {
      dataBaseValue: "Kosovo",
      value: "Kosovo",
      label: "Kosovo",
      imgSrc: "/img/flags/xk.svg"
    },
    {
      dataBaseValue: "Yemen",
      value: "Yemen",
      label: "Yemen",
      imgSrc: "/img/flags/ye.svg"
    },
    {
      dataBaseValue: "Mayotte",
      value: "Mayotte",
      label: "Mayotte",
      imgSrc: "/img/flags/yt.svg"
    },
    {
      dataBaseValue: "South Africa",
      value: "South Africa",
      label: "South Africa",
      imgSrc: "/img/flags/za.svg"
    },
    {
      dataBaseValue: "Zambia",
      value: "Zambia",
      label: "Zambia",
      imgSrc: "/img/flags/zm.svg"
    },
    {
      dataBaseValue: "Zimbabwe",
      value: "Zimbabwe",
      label: "Zimbabwe",
      imgSrc: "/img/flags/zw.svg"
    }
  ],
  filterProxyItems: [
    {
      id: 1,
      value: "Format IP:PORT",
      regExp: /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(\s{0,}|:)\d{1,5}/,
      divider: [":"]
    },
    // {
    //   id: 2,
    //   value: "Format KeyCollector",
    //   regExp:
    //     /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(\s{0,}|:)\d{1,5}(\s{0,}|@)\S+(\s{0,}|:)\S+/,
    //   divider: [":", "@"]
    // },
    {
      id: 3,
      value: "Format IP:PORT@Login:Password",
      regExp:
        /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(\s{0,}|:)\d{1,5}(\s{0,}|@)\S+(\s{0,}|:)\S+/,
      divider: [":", "@"]
    },
    {
      id: 4,
      value: "Format IP.PORT:Login:Password",
      regExp:
        /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(\s{0,}|:)\d{1,5}(\s{0,}|:)\S+(\s{0,}|:)\S+/,
      divider: [":"]
    }
  ],
  quantity: [
    { label: "10 pcs.", value: 10 },
    { label: "15 pcs.", value: 15 },
    { label: "20 pcs.", value: 20 },
    { label: "50 pcs.", value: 50 }
  ],
  regions: [
    { value: "MOSCOW", label: "Moscow" },
    { value: "KYIV", label: "Kyiv" },
    { value: "ST. PETERSBURG", label: "St. Petersburg" }
  ]
};
