export const getRequestHeadersFulfilledReducer = (state, action) => {
  state.isLoading = false;
  state.isError = null;

  state.headers = action.payload;
};

export const getHeadersPendingReducer = (state) => {
  state.isLoading = true;
};

export const getHeadersRejectReducer = (state, action) => {
  state.isLoading = false;
  state.isError = action.payload;
};
