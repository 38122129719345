import { createSlice } from "@reduxjs/toolkit";

import { webRtcInitialState } from "./WebRtc.initialState";
import { setWebRtc } from "./WebRtc.operations";
import { webRtcStatusReducer } from "./WebRtc.reducer";

const webRtcSlice = createSlice({
  name: "webRtc",
  initialState: webRtcInitialState,
  extraReducers: (builder) => builder.addCase(setWebRtc, webRtcStatusReducer)
});

export default webRtcSlice.reducer;

export const WebRtcReducerActions = {
  setWebRtc
};
