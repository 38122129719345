import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export const useTranslationSelector = () => {
  const { lang } = useParams();

  return {
    countries: useSelector(
      (state) => state.translation.translation?.[lang || "ru"].countries
    )
  };
};
