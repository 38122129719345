export const clientInitialState = {
  client: {
    data: {
      anonymizer: null,
      asn: "",
      browser: "",
      browserFamily: "",
      city: "",
      country: "",
      countryCode: "",
      httpVersion: "",
      ip: "",
      os: "",
      osFamily: "",
      proxy: false
    },
    isDataLoaded: false,
    isLoading: false,
    isFetchError: null
  },

  anonimityScore: {
    data: "",
    isDataLoaded: false,
    isLoading: false,
    isFetchError: null
  },

  anonimityInfo: {
    data: {
      activeX: false,
      anonymizer: false,
      browser: "",
      city: "",
      coordinates: "",
      country: "",
      flash: false,
      host: "",
      ip: "",
      java: false,
      language: "",
      languageJs: "",
      os: "",
      proxy: false,
      screen: "",
      systemTime: "",
      time: "",
      timeZone: "",
      tor: false,
      userAgent: "",
      userAgentJs: "",
      vpn: false,
      webRtc: false,
      webRtcIps: "",
      zipcode: "",
      doNotTrack: false
    },
    isDataLoaded: false,
    isLoading: false,
    isFetchError: null
  },

  blacklist: {
    data: {},
    find: 0,
    inBlackList: false,
    checkCount: 0,
    isDataLoaded: false,
    isLoading: false,
    isFetchError: null
  },

  openPorts: {
    data: false,
    isDataLoaded: false,
    isLoading: false,
    isFetchError: null
  }
};
