import { lazy } from "react";

import { Route } from "react-router-dom";

import { IndexLayout } from "../../../layout";
import ExportProxyPage from "../../../pages/ExportProxyPage";
import { routesPath } from "../../../utils/constants/routes.path";

const MainPage = lazy(() => import("../../../pages/MainPage/MainPage"));
const ProxyCheckerPage = lazy(() =>
  import("../../../pages/ProxyCheckerPage/ProxyCheckerPage")
);
const ClasterPage = lazy(() =>
  import("../../../pages/ClasterPage/ClasterPage")
);
const CheckPortPage = lazy(() =>
  import("../../../pages/CheckPortPage/CheckPortPage")
);
const PingIpPage = lazy(() => import("../../../pages/PingIpPage/PingIpPage"));
const UtmPage = lazy(() => import("../../../pages/UtmPage/UtmPage"));
const SiteSpeedPage = lazy(() =>
  import("../../../pages/SiteSpeedPage/SiteSpeedPage")
);
const Ipv6CheckPage = lazy(() =>
  import("../../../pages/Ipv6CheckPage/Ipv6CheckPage")
);
const SpamBasePage = lazy(() =>
  import("../../../pages/SpamBasePage/SpamBasePage")
);
const TracePage = lazy(() => import("../../../pages/TracePage/TracePage"));
const FilterProxyPage = lazy(() =>
  import("../../../pages/FilterProxyPage/FilterProxyPage")
);
const WebRtcCheckPage = lazy(() =>
  import("../../../pages/WebRtcCheckPage/WebRtcCheckPage")
);
const DnsCheckPage = lazy(() =>
  import("../../../pages/DnsCheckPage/DnsCheckPage")
);
const FingerPrintScannerPage = lazy(() =>
  import("../../../pages/FingerPrintScannerPage/FingerPrintScannerPage")
);
const EvercookiePage = lazy(() =>
  import("../../../pages/EvercookiePage/EvercookiePage")
);
const HttpHeadersPage = lazy(() =>
  import("../../../pages/HttpHeadersPage/HttpHeadersPage")
);
const BrowserInfoPage = lazy(() =>
  import("../../../pages/BrowserInfoPage/BrowserInfoPage")
);
const MyIpPage = lazy(() => import("../../../pages/MyIpPage/MyIpPage"));
const AnonimityCheckPage = lazy(() =>
  import("../../../pages/AnonimityCheckPage/AnonimityCheckPage")
);

export const getRouter = () => (
  <>
    <Route path={routesPath.root} element={<IndexLayout />}>
      <Route index element={<MainPage />} />
      <Route path={routesPath.filter} element={<MainPage />} />
      <Route path={routesPath.claster} element={<ClasterPage />} />
      <Route path={routesPath.proxyChecker} element={<ProxyCheckerPage />} />
      <Route path={routesPath.checkPorts} element={<CheckPortPage />} />
      <Route path={routesPath.pingIp} element={<PingIpPage />} />
      <Route path={routesPath.utm} element={<UtmPage />} />
      <Route path={routesPath.siteSpeed} element={<SiteSpeedPage />} />
      <Route path={routesPath.ipv6Check} element={<Ipv6CheckPage />} />
      <Route path={routesPath.spamBase} element={<SpamBasePage />} />
      <Route path={routesPath.trace} element={<TracePage />} />
      <Route path={routesPath.filterProxy} element={<FilterProxyPage />} />
      <Route path={routesPath.webRtcCheck} element={<WebRtcCheckPage />} />
      <Route path={routesPath.dnsCheck} element={<DnsCheckPage />} />
      <Route
        path={routesPath.fingerprint}
        element={<FingerPrintScannerPage />}
      />
      <Route path={routesPath.evercookie} element={<EvercookiePage />} />
      <Route path={routesPath.httpHeaders} element={<HttpHeadersPage />} />
      <Route path={routesPath.browserInfo} element={<BrowserInfoPage />} />
      <Route path={routesPath.myIp} element={<MyIpPage />} />
      <Route
        path={routesPath.anonimityCheck}
        element={<AnonimityCheckPage />}
      />
    </Route>
    <Route path={routesPath.export} element={<ExportProxyPage />} />
  </>
);
