import { useEffect, useRef, useState } from "react";

export const FaqItem = ({ id, question, answer, isActive, onChange }) => {
  const [maxHeight, setMaxHeight] = useState(0);

  // **Ref
  const content = useRef(null);

  useEffect(() => {
    setMaxHeight(isActive ? content.current.scrollHeight : 0);
  }, [isActive]);

  return (
    <li className={isActive ? "faq__item faq__item--active" : "faq__item"}>
      <div className="faq__item-head" onClick={() => onChange(id, !isActive)}>
        {question}
        <img
          src="/img/ui/arrow.svg"
          width="10"
          height="20"
          alt=""
          aria-hidden="true"
          className="faq__item-toggler"
        />
      </div>
      <div ref={content} style={{ maxHeight }} className="faq__item-body">
        {answer}
      </div>
    </li>
  );
};
