export const mobileCompanyNavigation = [
  {
    href: "https://proxy-sale.com/isp/",
    text: "ispProxy"
  },
  {
    href: "https://proxy-sale.com/resident/",
    text: "resedentialProxy"
  },
  {
    href: "https://proxy-sale.com/",
    text: "personalProxy"
  },
  {
    href: "https://proxy-sale.com/ipv6/",
    text: "proxyIpv6"
  },
  {
    href: "https://proxy-sale.com/company/articles/",
    text: "articles"
  },
  {
    href: "https://proxy-sale.com/contacts/",
    text: "contacts"
  }
];
