import { createSlice } from "@reduxjs/toolkit";

import { clientInitialState } from "./Client.initialState";
import {
  getAnonimityInfo,
  getAnonimityScore,
  getBlackList,
  getClientInfo,
  getOpenPorts,
  saveAnonimityScore,
  saveClientInfo
} from "./Client.operations";
import {
  getAnonimityInfoPendingReducer,
  getAnonimityInfoRejectedReducer,
  getAnonimityInfoSuccessReducer,
  getAnonimityScorePendingReducer,
  getAnonimityScoreRejectedReducer,
  getAnonimityScoreSuccessReducer,
  getBlackListPendingReducer,
  getBlackListRejectedReducer,
  getBlackListSuccessReducer,
  getClientInfoPendingReducer,
  getClientInfoRejectedReducer,
  getClientInfoSuccessReducer,
  getOpenPortsPendingReducer,
  getOpenPortsRejectedReducer,
  getOpenPortsSuccessReducer,
  saveAnonimityScoreSuccessReducer,
  saveClientInfoSuccessReducer
} from "./Client.reducer";

const clientSlice = createSlice({
  name: "client",
  initialState: clientInitialState,

  extraReducers: (builder) =>
    builder
      .addCase(saveClientInfo, saveClientInfoSuccessReducer)
      .addCase(saveAnonimityScore, saveAnonimityScoreSuccessReducer)

      .addCase(getClientInfo.fulfilled, getClientInfoSuccessReducer)
      .addCase(getAnonimityScore.pending, getAnonimityScorePendingReducer)
      .addCase(getClientInfo.rejected, getClientInfoRejectedReducer)

      .addCase(getAnonimityScore.fulfilled, getAnonimityScoreSuccessReducer)
      .addCase(getClientInfo.pending, getClientInfoPendingReducer)
      .addCase(getAnonimityScore.rejected, getAnonimityScoreRejectedReducer)

      .addCase(getAnonimityInfo.fulfilled, getAnonimityInfoSuccessReducer)
      .addCase(getAnonimityInfo.pending, getAnonimityInfoPendingReducer)
      .addCase(getAnonimityInfo.rejected, getAnonimityInfoRejectedReducer)

      .addCase(getBlackList.fulfilled, getBlackListSuccessReducer)
      .addCase(getBlackList.pending, getBlackListPendingReducer)
      .addCase(getBlackList.rejected, getBlackListRejectedReducer)

      .addCase(getOpenPorts.fulfilled, getOpenPortsSuccessReducer)
      .addCase(getOpenPorts.pending, getOpenPortsPendingReducer)
      .addCase(getOpenPorts.rejected, getOpenPortsRejectedReducer)
});

export default clientSlice.reducer;

export const ClientReducerActions = {
  getClientInfo,
  getAnonimityScore,
  getAnonimityInfo,
  getBlackList,
  getOpenPorts,
  saveClientInfo,
  saveAnonimityScore
};
