import {
  InfoTextBottom,
  InfoTextTop,
  InfoWraper,
  Wraper
} from "./LeakInfo.styled";

export const LeakInfo = ({ status, t }) => (
  <Wraper>
    <img
      src="/img/ui/sign.svg"
      width="30"
      height="30"
      loading="lazy"
      alt="warning"
      aria-hidden="true"
    />
    <InfoWraper>
      <InfoTextTop>{t.info.top}</InfoTextTop>
      <InfoTextBottom>
        {status ? t.info.bottom : t.info.disableBottom}
      </InfoTextBottom>
    </InfoWraper>
  </Wraper>
);
