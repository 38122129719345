import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useTranslation } from "../../../hooks";
import { Container, Loader } from "../../ui";
import ProgressBar from "../ProgressBar";

import { Link, PageHeadWrapper, Title } from "./PageHead.styled";

import "./PageHead.scss";

export const PageHead = ({ children, showUserData, title }) => {
  const [showData, setShowData] = useState(false);
  const { lang } = useParams();
  const t = useTranslation();

  useEffect(() => {
    setShowData((prevState) => !prevState);
  }, []);

  const { client, anonimityScore } = useSelector((state) => state.client);

  return (
    <section className="page-head">
      <Container>
        <PageHeadWrapper>
          <Title>{title}</Title>

          {showData && showUserData && (
            <div className="page-head__data">
              <div className="page-head__data-ip">
                <div className="page-head__data-your-ip">
                  <Link to={`/${lang ? lang : "ru"}/my-ip/`}>
                    <div>
                      <span className="name">{t.pageHead.ip}v4: </span>
                      <span className="ip" title={client.data.ip}>
                        {client.data.ipv4 ? (
                          client.data.ipv4
                        ) : (
                          <Loader mini width={20} height={20} color="#000" />
                        )}
                      </span>
                    </div>

                    <div>
                      <span className="name">{t.pageHead.ip}v6: </span>
                      <span className="ip" title={client.data.ip}>
                        {client.data.ipv6 ? client.data.ipv6 : "-"}
                      </span>
                    </div>
                  </Link>
                </div>
                <a
                  href="https://proxy-sale.com/"
                  rel="noreferrer"
                  target="_blank"
                  className="page-head__data-link"
                >
                  {t.pageHead.changeIp}
                </a>
              </div>
              <ProgressBar score={anonimityScore} />
            </div>
          )}
        </PageHeadWrapper>
        {children}
      </Container>
    </section>
  );
};
