import { useEffect } from "react";

import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const useRedirect = () => {
  // **Props
  const { pathname, search } = useLocation();
  const { lang } = useParams();
  const navigate = useNavigate();

  // **Redux state
  const translation = useSelector((state) => state.translation.translation);

  useEffect(() => {
    // Adding slash to the route if route does not contain slash at the end
    if (pathname.slice(-1) !== "/") {
      if (search) {
        navigate(`${pathname}/${search}`, { replace: true });
      } else {
        navigate(`${pathname}/`, { replace: true });
      }
    }

    if (lang && translation?.hasOwnProperty(lang)) return;

    const splittedPath = pathname.split("/");

    const path = splittedPath.join("/");

    navigate(path.slice(-1) === "/" ? path : `${path}/`, { replace: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, pathname]);
};
