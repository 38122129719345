import styled from "@emotion/styled";
import { Link as StyledLink } from "react-router-dom";

export const Link = styled(StyledLink)`
  @media (hover) {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
`;

export const PageHeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1200px) {
    display: block;
    text-align: center;
  }
`;

export const Title = styled.h1`
  margin-top: 20px;
  font-weight: 700;
  font-size: 32px;

  @media (max-width: 1200px) {
    margin-bottom: 20px;
  }

  @media (max-width: 767px) {
    font-size: 24px;
  }

  @media (max-width: 576px) {
    font-size: 22px;
  }
`;
