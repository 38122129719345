import { De } from "./De";
import { En } from "./En";
import { Es } from "./Es";
import { Fr } from "./Fr";
import { It } from "./It";
import { Pol } from "./Pol";
import { Ru } from "./Ru";
import { Tr } from "./Tr";

export const Translations = {
  en: En,
  ru: Ru,
  de: De,
  fr: Fr,
  pl: Pol,
  it: It,
  es: Es,
  tr: Tr
};
