export const Es = {
  header: {
    ariaLabelMain: "Ir a la página de inicio",
    altImgLogo: "Logo de la compañía FreeProxySale",
    other: "Más herramientas",
    ariaLabelOther: "Tasa de expansión de elementos de menú adicionales",
    close: "Cerca"
  },
  footer: {
    logo: {
      alt: "Proxy-Sale logo de la compañía"
    },
    rights: "Reservados todos los derechos",
    lang: {
      title: "Elige lengua",
      selectLanguage: [
        { value: "de", label: "Alemán" },
        { value: "en", label: "Inglés" },
        { value: "es", label: "Español" },
        { value: "fr", label: "Francés" },
        { value: "it", label: "Italiano" },
        { value: "pl", label: "Polaco" },
        { value: "ru", label: "Ruso" },
        { value: "tr", label: "Turco" }
      ]
    },
    tools: "Instrumentos",
    company: "Compañía"
  },
  navigation: {
    freeProxy: "Proxy gratuito",
    personalProxy: "Apoderado personal",
    proxyIpv6: "Apoderado IPv6",
    articles: "Artículos",
    contacts: "Contacts",
    proxyChecker: "Comprobador de proxy",
    myIp: "Mi IP",
    checkPorts: "Escáner de puertos",
    pingIp: "Silbido IP",
    anonimityCheck: "Comprobación de anonimato",
    utm: "UTM Constructor",
    speed: "Prueba de velocidad de proxy",
    ipv6Check: "IPv6 Validador",
    spamBase: "IP Comprobación de lista negra",
    filterProxy: "Filtro de lista de proxy",
    traceIp: "Seguimiento de IP",
    claster: "Solicitudes de agrupación",
    webRtcChecker: "Comprobación WebRTC",
    dnsChecker: "Comprobación DNS",
    fingerPrint: "Escáner de huellas dactilares",
    evercookie: "Verificación de cookies",
    httpHeaders: "Ver encabezados HTTP",
    browserInfo: "Información del navegador",
    resedentialProxy: "Apoderados residenciales",
    ispProxy: "Proxy ISP"
  },
  mainPage: {
    proxyTable: {
      sidebarPart: {
        title: "Configurar un proxy en el navegador:",
        countries: {
          placeholder: "Seleccione countriesConstant"
        },
        type: {
          title: "Tipo de:"
        },
        anonimity: {
          title: "Anónimo:"
        },
        export: {
          title: "Export:",
          ip: "IP:Port",
          excel: "Excel",
          window: "En una nueva ventana"
        },
        applyFilter: "Aplicar filtro",
        clearFilter: "Filtro claro",

        infoLeft: "Los proxies ",
        infoRight:
          "gratuitos excluyen cualquier posibilidad de trabajar con redes sociales, analizar motores de búsqueda y trabajar con software privado. No somos responsables del uso de la lista de proxy gratuita. La lista de IP se proporciona solo con fines informativos",
        buyProxy: "Comprar proxies personales",
        personalProxyAdvantage: [
          "Los apoderados personales le proporcionarán un alto anonimato y estabilidad de trabajo."
        ]
      }
    },
    faq: "Casi todos los usuarios de Internet han oído hablar de qué es un servidor proxy y por qué es necesario. Se hicieron especialmente populares cuando había una necesidad masiva de evitar el bloqueo de recursos web populares: redes sociales, servicios de Yandex, alojamiento de videos y similares. Por supuesto, la mayoría está interesada en los proxies gratuitos, hay muchos en la red, pero no siempre es obvio cómo elegirlos correctamente y para qué son adecuados, y para qué tareas es mejor comprar un proxy. "
  },
  anonimityCheck: {
    mainTitle: "Mi anonimato",
    headText:
      "Al utilizar este servicio, puede comprobar su anonimato en la red y en qué medida los datos proporcionados por su ordenador/navegador coinciden con los datos proporcionados por su dirección IP.",
    top: {
      ip: "Tu dirección IP :",
      from: "De dónde eres:",
      camouflage: "Tu disfraz:",
      anon: "Tu anonimato:",
      restartButton: "Reiniciar escaneo",
      waitButton: "Cargando",
      result: {
        title: "Su resultado:",
        notAnonymity: "Sin anonimato",
        badAnonymity: "Anonimato deficiente",
        lackAnonymity: "Falta de anonimato",
        sufficientAnonymity: "Anonimato suficiente",
        checking: "Comprobando, por favor espere",
        highAnonymity: "Alto anonimato"
      }
    },
    table: {
      titleIpInfo: "IP información",
      standardTimeZone: "Zona horaria estándar",
      anonymityTitle: "Anonimato y seguridad",
      hour: "horas",
      device: "Dispositivo",
      ip: "Mi IP",
      hideip: "Esconder IP",
      host: "Anfitrión/Proveedor",
      country: "País",
      city: "Ciudad",
      index: "Índice",
      coordinates: "Coordenadas",
      os: "OS",
      browser: "Navegador",
      timezone: "Zona horaria",
      ipTime: "IP tiempo",
      systemTime: "Hora del sistema",
      userAgent: "UserAgent",
      userAgentJs: "UserAgent JS",
      language: "Idioma",
      languageJs: "Idioma JS",
      screen: "Pantalla",
      proxy: "Apoderado",
      vpn: "VPN",
      tor: "Tor",
      anonimazer: {
        title: "Anonimizador",
        info: "Es necesario cerrar los puertos. Los puertos abiertos pueden causar daños significativos a su dispositivo o pueden haber causado daños."
      },
      cookies: {
        info: "Desactivar puede evitar el rastreo por parte de redes publicitarias y diversos recursos, lo que aumenta la privacidad. Se recomienda desactivar las cookies o borrarlas periódicamente."
      },
      blackList: {
        title: "Lista negra",
        checks: "Cheques:",
        inBlackList: "En la lista negra:",
        buyProxy: "Comprar proxy puro"
      },
      flash: "Flash",
      java: "Java",
      activeX: {
        title: "ActiveX",
        info: "El control ActiveX tiene vulnerabilidades de seguridad y puede ser utilizado para infectar su computadora. Cuantos más controles ActiveX instale, más sitios pueden aprovechar sus problemas para dañar su dispositivo."
      },
      webRtc: "WebRTC",
      webRtcIp: "WebRTC IPs",
      openPorts: {
        title: "Puertos abiertos",
        links: "Escáner de puertos"
      },
      openPortsHTTP: {
        title: "Puertos abiertos de proxy HTTP",
        info: "Los puertos deben estar cerrados. Los puertos abiertos pueden dañar significativamente o ya haber dañado su dispositivo."
      },
      openPortsVPN: {
        title: "Puertos VPN abiertos",
        info: "Es necesario cerrar los puertos. Los puertos abiertos pueden causar un daño significativo o ya han causado daño a su dispositivo."
      },
      openPortsWeb: "Abrir puertos proxy Web",
      doNotTrack: {
        title: "DoNotTrack",
        info: "Activar esta opción añade cierta singularidad a la huella digital del navegador.",
        link: "Activar DNT"
      },
      dnsLeak: {
        title: "Fuga de DNS",
        info: "Terceros pueden rastrear todas tus acciones en la red. Es necesario cambiar la configuración DNS.",
        links: "Cambiar DNS"
      },
      suspiciousHeadlines: "Titulares sospechosos",
      suspiciousPorts: "Puertos sospechosos",
      twowayPing: "Ping bidireccional",
      replacementUserAgent: {
        title: "Reemplazo del UserAgent",
        info: "Basado en los datos que forman el UserAgent del dispositivo, si los datos son diferentes, significa que se está realizando un reemplazo."
      },
      yourHeadings: "Tus títulos",
      showButton: {
        more: "Mostrar más",
        less: "Ocultar"
      }
    },
    faq: {
      text: []
    }
  },
  checkPorts: {
    analis: "Análisis en progreso... Espera.",
    port: "Puerto",
    status: "Estado",
    statusTrue: "disponible",
    statusFalse: "indisponible",
    description: "Descripción",
    portStatus: "Estado del puerto en el servidor",
    scanType: "Tipo de scaneado",
    mainTitle: "Comprobación de puertos abiertos en línea",
    videoTitle: "Qué es un escáner de puertos y por qué?",
    headText:
      "La verificación de puertos abiertos con un escáner muestra qué puertos están abiertos en su computadora/sitio o en la de otra persona y qué responden. La verificación se realiza a través del escáner NMAP y muestra cómo abre su acceso a la PC/sitio para el mundo exterior",
    ipTitle: "Display:",
    ipElem: {
      placeholder: "Introduce la dirección IP o el dominio",
      insertIp: "Pegar mi dirección IP"
    },
    scanTypes: {
      title: "Tipos de puertos escaneados:"
    },
    scanTitle: "Tipo de scaneado:",
    startScan: "Iniciar escaneo",
    resultCheck: "Resultados de la prueba:",
    resultCaption:
      "* Si el resultado es 'El host está inactivo', entonces el firewall o el enrutador están siendo revisados y están bloqueando las direcciones IP.",
    faq: {
      text: []
    }
  },
  claster: {
    mainTitle: "Herramienta de agrupación de palabras clave",
    checker: {
      regionTitle: "Comprobando región",
      selectPlaceholder: "Elige región",
      clasterTitle: "Umbral de agrupamiento",
      groupBtn: "Grupo",
      queryTitle: "Peticiones"
    },
    headText:
      "Gracias a este servicio, la agrupación de consultas en línea está disponible para usted. Con él, puede agrupar automáticamente palabras clave según los resultados de búsqueda de Yandex y Google. La construcción competente de la estructura del sitio aumentará sus posibilidades de colocar su sitio en las primeras posiciones. Nuestro El servicio de agrupación de consultas en línea lo ayuda a categorizar sus palabras clave en sus páginas para obtener la mayor cantidad de tráfico de SEO"
  },
  filterProxy: {
    mainTitle: "Filtro de proxy",
    headText:
      "El filtrado de proxy es el proceso de enviar un filtro de proxy al servidor principal para seleccionar recomendaciones específicas.",
    filterOut: "Filtrar",
    save: "Guardar en archivo"
  },
  ipv6: {
    mainTitle: "Comprobación de los sitios web habilitados para IPv6",
    headText:
      "La versión del protocolo IPv6, que brinda la máxima protección en la red, requiere el uso de una herramienta de soporte específica del sitio para el nuevo protocolo proxy para una operación más eficiente",
    checker: {
      addressTitle: "Ingrese la dirección del sitio para la verificación",
      checkProxyBtn: "Comprobar proxy",
      tip: "La prueba puede tomar un tiempo",
      resultTitle: "Resultados de la prueba:",
      result: {
        success: "Felicitaciones, este sitio es compatible con IPv6",
        error:
          "Desafortunadamente, el sitio no es compatible con IPv6 en este momento."
      }
    },

    faq: {
      text: []
    }
  },
  myIp: {
    top: {
      title:
        "Y esto es solo una pequeña parte de la información que puede ser rastreada por su dirección IP:",
      ipv4: "Su dirección IPv4:",
      ipv6: "Su dirección IPv6:",
      changeIp: "Cambia tu dirección IP",
      table: {
        check: "Controlar",
        country: "País",
        city: "Ciudad",
        index: "Postcode",
        host: "Anfitrión",
        system: "Sistema",
        browser: "Tu navegador",
        from: "De dónde eres",
        provider: "Proveedor",
        anon: "Anonimizador",
        proxy: "Apoderado",
        protocol: "Protocolo",
        imgAlt: {
          country: "País",
          city: "Ciudad",
          index: "Postcode",
          screen: "Monitor",
          os: "Sistema operativo",
          browser: "Versión del navegador",
          location: "Localización",
          server: "Servidor",
          anon: "Anonimizador",
          proxy: "Apoderado",
          protocol: "Protocolo",
          blacklist: "Lista negra"
        }
      },
      map: {
        title: "Tu ubicación en el mapa!",
        hide: "Puedes ocultar tu ubicación",
        text: "Basta con usar nuestra herramienta simple, conveniente y confiable. Oculte datos personales, proteja el tráfico y elimine todos los límites de su Internet",
        hideIp: "Ocultar dirección IP"
      },
      caution: {
        top: "Como puede ver, IP proporciona información bastante detallada..",
        bottom:
          "Cuando se conecta a Internet, a la computadora se le asigna un número llamado IP (Dirección de Protocolo de Internet). Cabe señalar que la asignación no es arbitraria, sino la IP que está disponible para el proveedor y es gratuita. La IP cambia después de desconectarse cuando reconectando. Estas direcciones IP se denominan dinámicas. Si desea que no cambien, comuníquese con su ISP. Por lo general, no dinámicas están disponibles por una tarifa. También se denominan externas. A menudo se rastrean mediante programas en línea especializados, determinando la información adjunta. sobre el propietario IP interna o local se denomina dirección individual de una PC (computadora portátil) en una red local"
      }
    },
    faq: {
      text: []
    }
  },
  pingIp: {
    mainTitle: "Verificar ping a la dirección IP o a un sitio web",
    whatIsPing: "Qué es ping IP y por qué?",
    headText: [
      "Haga clic en el botón 'Insertar mi dirección IP' o use la IP o el sitio en el que desea verificar el ping. Seleccione 3-5 paquetes de prueba y haga clic en el botón 'Ping IP'. Verá el valor deseado en el ' tiempo = 'sección.",
      "El ping se considera normal cuando está en el rango de 40 a 110 ms. A partir de 110 a 210 ms, comenzará a experimentar problemas en los juegos en línea, pero esto apenas afectará sus visitas a los sitios. Si el tiempo de espera constante supera los 110 ms, entonces esta es una razón para pensar en la calidad del trabajo de su ISP"
    ],
    checker: {
      showTitle: "Mostrar:",
      showSubtitle:
        "Ingrese una dirección IP válida (0.0.0.0) o nombre de host (host.com)",
      ipInsert: "Pegar mi dirección IP",
      addTitle: "Numero de paquetes:",
      selectPlaceholder: "Elige un país",
      startScan: "Iniciar escaneo",
      resultTitle: "Iniciar escaneo:",
      resultCaption:
        "* Si el resultado es 'El host está inactivo', entonces el firewall o el enrutador están siendo revisados y están bloqueando las direcciones IP."
    },
    faq: {
      text: []
    }
  },
  proxyChecker: {
    mainTitle: "Comprobador de proxy",
    condition: "Estado",
    ping: "Silbido",
    type: "Tipo",
    status: "No funciona",
    workStatus: "Disponible",
    head: {
      videoTitle: "Cómo y por qué usar un comprobador de proxy?",
      headText:
        "Verificador de proxy en línea para verificar la validez de los servidores proxy. Análisis de un proxy o grupo de direcciones para disponibilidad, país, velocidad, anonimato, tipo.",
      titleFirst: "El comprobador de proxy puede:",
      listFirst: [
        "verificando IPv4, IPv6 para la propiedad del país;",
        "determinación del tipo de protocolo HTTPS, SOCKS5 y velocidad actual;",
        "comprobando el anonimato de la dirección;",
        "grupo, verificación de proxy multiproceso de alta velocidad en 64 subprocesos;",
        "la capacidad de cargar una lista y cargar el resultado de la verificación;",
        "Visualización conveniente de resultados usando un filtro de visualización"
      ],
      titleSecond: "En qué formato se debe agregar un proxy al verificador?",
      listSecond: [
        {
          content:
            "Si tiene <span>proxies públicos</span> (sin inicio de sesión ni contraseña), entonces - IP: PUERTO"
        },
        {
          content:
            "Si tiene <span>proxies privados</span> (con autorización por inicio de sesión y contraseña), luego - IP: PUERTO: USUARIO: CONTRASEÑA"
        }
      ],
      titleThird:
        "P.D.: Si <a href='https://proxy-sale.com/' rel='noreferrer' target='_blank'> compraste un proxy </a> con nosotros, ¡entonces son privados!"
    },
    faq: {
      text: []
    }
  },
  siteSpeed: {
    mainTitle: "Verifique la velocidad de acceso al sitio a través de un proxy",
    head: {
      listFirst: [
        "Determinación de la velocidad de transmisión de datos a través de proxy HTTP y SOCKS",
        "Capacidad de determinar la velocidad de carga de páginas directamente",
        "Comprobar el acceso al sitio"
      ],
      caution: "En que formato se debe hacer el cheque?",
      subtitle:
        "Para verificar la velocidad a través de un proxy, debe conducir un recurso al que necesita acceder y una dirección IP en el formato:",
      listSecond: [
        {
          text: "Proxies públicos(sin nombre de usuario y contraseña), luego —",
          value: "IP:PUERTO"
        },
        {
          text: "Proxies privados(con autorización de inicio de sesión y contraseña), luego —",
          value: "IP:PUERTO:USUARIO:CONTRASEÑA"
        }
      ],
      post: "P.D.: Si compraste un proxy con nosotros, ¡entonces son privados!"
    },
    checker: {
      adress: "Ingrese la URL del sitio para verificar la velocidad",
      proxy: "Dirección de proxy en el formato",
      format: "127.0.0.1:8080",
      subtitle: [
        "Para probar la velocidad de transmisión de datos a través de un proxy, ingrese la dirección y el puerto del servidor proxy",
        "Para una prueba normal de la velocidad de carga de las páginas del sitio, deje este campo en blanco"
      ],
      type: {
        title: "Tipo de servidor proxy"
      },
      timeout: {
        title: "Se acabó el tiempo:",
        placeholder: "Se acabó el tiempo"
      },
      access: {
        title: "Verifique el acceso al sitio",
        body: "Las comprobaciones de acceso y velocidad pueden tardar algún tiempo"
      },
      btn: "Comprobar la velocidad",
      result: {
        title: "Resultados de la prueba:",
        result: {
          titleFirst:
            "Verificar: la velocidad de acceso al sitio a través de proxy",
          checkSite: "Comprobando el sitio",
          checkProxy: "Comprobar proxy",
          timeout: "Se acabó el tiempo",
          sec: "segundo",
          amountOfDownloads: "Descargas de prueba fabricadas",
          titleSecond: "Comprobación completada",
          averageSpeed: "Velocidad media de descarga",
          generalSpeed: "Velocidad media total",
          speedValue: "Kb/sec",
          access: "Acceso al sitio - Título de la página descargada",
          emptyTitle: "No se pudo obtener el título",
          isProxyUsing: {
            yes: "Uso de proxy",
            no: "Sin un proxy, carga directamente"
          }
        }
      }
    },
    faq: {
      text: []
    },
    errors: {
      url: "Ingrese la URL del sitio"
    }
  },
  spamBase: {
    mainTitle: "Presencia de una IP en las bases spam",
    head: {
      text: "Como sabe, el correo no deseado a menudo puede hacer que la IP se incluya en la lista negra, lo que interrumpe notablemente todas las operaciones de la red. Para evitar esto, debe usar el servidor de verificación de IP en las bases de datos de correo no deseado"
    },
    checker: {
      ip: {
        title: "Por favor ingrese la dirección IP correcta",
        insert: "Pegar mi dirección IP"
      },
      check: "Examen",
      test: "La prueba puede tomar un tiempo.",
      list: {
        checkedAll: "Cheques:",
        checked: "Controlado:",
        blackList: "En la lista negra:"
      },
      result: {
        listed: "Listado",
        notListed: "No enlistado"
      }
    },
    faq: {
      text: []
    }
  },
  trace: {
    mainTitle: "IP rastreo",
    head: {
      text: "Gracias al servicio de esta página, tiene acceso a un seguimiento gratuito y casi instantáneo de la dirección IP o del sitio. Le ayudará a realizar diagnósticos de red, que determinan la velocidad de envío de solicitudes a través de servidores en Internet cuando abra un recurso web en un navegador. determine las causas de los posibles problemas asociados con la carga lenta del sitio "
    },
    checker: {
      ip: "Ingrese la dirección IP correcta (0.0.0.0) o el nombre de host (domain.ru)",
      route: {
        title: "Mostrar ruta de paquetes por país",
        placeholder: "Selecciona un artículo"
      },
      btn: "Rastrear IP",
      result: "Resultados de la prueba:"
    }
  },
  webRtcCheck: {
    mainTitle: "Comprobación WebRTC",
    head: {
      text: "Comprobación de fugas a través de WebRTC. Tus datos pueden estar en riesgo incluso si estás conectado a una VPN."
    },
    top: {
      myIp: "Su dirección IP:",
      from: "De dónde eres:",
      state: "Estado:",
      warning: "Amenaza potencial"
    },
    info: {
      top: "Como puede ver, IP proporciona información bastante detallada.",
      bottom:
        "erificamos si WebRTC está activado en su navegador y si tiene una dirección IP real. Resulta que existe el riesgo de una posible filtración, WebRTC está habilitado y puede revelar su dirección IP real.",
      disableBottom:
        "Verificamos si WebRTC está activado en su navegador y si tiene una dirección IP real. Resulta que su dirección IP real sigue siendo desconocida y WebRTC no está activado en su navegador."
    },
    resetButton: "Reinicie la prueba de fugas WebRTC",
    status: {
      type: "Tipo",
      state: "Estado",
      topText: "WebRTC habilitado",
      bottomText: "Amenaza con revelar su dirección IP real!",
      warning: "Amenaza de fuga",
      checking: "Compruebe, por favor espere"
    }
  },
  dnsCheck: {
    top: {
      myIp: "Su dirección IP:",
      from: "De dónde eres:",
      state: "Estado:",
      warning: "Amenaza potencial"
    },
    info: {
      top: "Como puede ver, al verificar su IP se detectó una fuga de DNS.",
      bottom:
        "Hemos escaneado su IP y hemos detectado una fuga a través de DNS. Sus solicitudes han sido reveladas y la conexión no es segura. Esto significa que el administrador de su DNS puede rastrear la información sobre los sitios web y programas que usted utiliza.",
      disableBottom:
        "Hemos escaneado su IP y no hemos detectado ninguna fuga a través de DNS. Sus solicitudes no han sido reveladas y la conexión es segura. Esto significa que el administrador de su DNS no puede rastrear la información sobre los sitios web y programas que usted utiliza."
    },
    resetButton: "Reinicie la prueba de fugas DNS",
    status: {
      type: "Tipo",
      state: "Estado",
      topText: "DNS habilitado",
      bottomText: "Amenaza de revelar tus solicitudes!",
      warning: "Amenaza de fuga",
      checking: "Compruebe, por favor espere"
    }
  },
  fingerPrintScanner: {
    mainTitle: "Escáner de huellas dactilares del navegador",
    head: {
      text: "Las huellas digitales del navegador son una instantánea única de la configuración de su computadora y mecanismos especiales del sitio web pueden rastrearlo."
    },
    top: {
      myIp: "Su dirección IP:",
      fingerprint: "Huella digital del navegador:"
    },
    table: {
      location: "Ubicación",
      provider: "Proveedor",
      host: "Anfitrión",
      os: "SO",
      browser: "Navegador",
      plugin: "Enchufar:",
      status: "Estado:",
      isOn: "activado",
      isOff: "apagado",
      device: "Dispositivo",
      browserLanguage: "Idioma del navegador",
      screenSize: "Tamaño de pantalla",
      timeZone: "Zona horaria"
    },
    imgAlt: {
      location: "Ubicación",
      provider: "Proveedor",
      host: "Anfitrión",
      os: "Sistema operativo",
      browser: "Navegador",
      language: "Idioma del navegador",
      size: "Tamaño de pantalla",
      timeZone: "Zona horaria"
    },
    bottom: {
      method: "método JS"
    }
  },
  createEvercookie: {
    mainTitle: "Verificación de cookies",
    head: {
      text: "Haga clic en el botón para crear una cookie permanente. Su anonimato no se verá comprometido: una cookie es un número aleatorio entre 1 y 1000 que utilizamos para probar todas las cookies. No lo rastreamos usándolo."
    },
    top: {
      button: "Crear cada cookie"
    }
  },
  httpHeaders: {
    requestHeaders: "Encabezados de solicitud",
    responseHeaders: "Encabezados de respuesta",
    submitButton: "Enviar peticion"
  },
  browserInfo: {
    mainTitle: "Información de tu navegador",
    head: {
      text:
        "Al utilizar el navegador, varios sitios y servicios tienen la capacidad de rastrear al usuario. Esto es necesario, por ejemplo, para identificar a una persona incluso si ha cambiado su dirección IP. Los sistemas de publicidad también utilizan capacidades de seguimiento para conocer los intereses del usuario.\n" +
        "Nuestro servicio le ayudará a determinar qué métodos de seguimiento están habilitados en su navegador."
    },
    table: {
      type: "Tipo",
      discovered: "Descubierto",
      cookieMeaning: "Significado de las galletas",
      parameter: "Parámetro",
      value: "Valor",
      resolution: "Resolución de la pantalla:",
      desktop: "Escritorio",
      colorDepth: "Profundidad del color",
      bit: "poco"
    },
    button: {
      createCookie: "Crear cookies",
      userTracking: "Seguimiento de usuarios"
    }
  },
  utm: {
    mainTitle: "Generador de etiquetas UTM en línea",
    checker: {
      linkUtm: "El enlace con la etiqueta UTM aparecerá aquí",
      copy: "Dupdo",
      shortUrl: "Corto URL",
      traffic: [
        { text: "Google ADS", source: "google" },
        { text: "Yandex.Direct", source: "yandex" },
        { text: "VK.com", source: "vk" },
        { text: "Facebook", source: "facebook" },
        { text: "Instagram", source: "instagram" },
        { text: "Target.Mail.ru", source: "targetmailru" },
        { reset: true, text: "Reset" }
      ]
    },
    faq: {
      text: []
    }
  },
  pageHead: {
    ip: "Tu dirección Ip",
    changeIp: "Cambiar IP",
    anon: "Tu anonimato",
    moreAnon: "Más información sobre el anonimato"
  },
  checker: {
    country: "Elige un país:",
    add: "Agregar primero:",
    show: {
      title: "Mostrar"
    },
    displayType: "Mostrar",
    checkProxy: "Comprobar",
    fileName: "Cargar desde .txt o .csv",
    typeProxy: {
      title: "Ingresar proxy:",
      placeholder: "Escribe tu texto aquí"
    },
    info: {
      text: "Libérate de restricciones e inconvenientes, del riesgo de ser baneado o de cambiar permanentemente tu dirección IP. Proxies personales para trabajar con redes sociales, Avito, Key Collector y otros fines.",
      buy: "Comprar protección"
    },
    results: {
      title: "Resultados de la prueba",
      checked: "Comprobado",
      working: "Trabajando",
      notWorking: "No funciona",
      divider: "de",
      btn: "Comprar trabajando"
    },
    download: {
      title: "Descargar solo proxy de trabajo",
      txt: "descargar .txt",
      csv: "mesa en .csv"
    }
  },
  common: {
    faqTitle: "Nosotros respondemos tus preguntas",
    yes: "Sí",
    no: "No",
    notDefined: "No definida",
    copy: "Copiado!",
    select: {
      placeholderCountry: "Elige país"
    },
    buyAnonProxy: "Comprar anonimato",
    buyWorkingProxy: "Comprar trabajando"
  },
  table: {
    top: {
      workProxy: "Proxies de trabajo",
      anon: "Anónimo",
      socks: "Socks 4/5",

      http: "HTTP/HTTPS",
      proxyInBase: "Proxy en la base de datos"
    },
    tableColumns: {
      copy: "Copiar al portapapeles",
      country: "País",
      type: "Tipo de",
      anon: {
        title: "Anonimato",
        speed: "Speed",
        s: "s",
        ms: "ms",
        m: "m",
        h: "h",
        types: {
          anon: "Anonimato",
          proz: "Transparente",
          elite: "Élite",
          notFounded: "No encontrado"
        }
      },
      check: {
        title: "Examen",
        tip: "Oportuno",
        buy: "Buy working",
        reload: "Reboot",
        ago: "mins. ago",
        status: {
          work: "Working",
          notWorked: "No funciona"
        }
      },
      condition: {
        title: "Condición"
      },
      login: "Acceso",
      password: "Clave",
      pagination: {
        rowsPerPage: "Proxies para la página",
        separatorText: "de"
      },
      requests: "Peticiones",
      requestsCluster: "Solicitudes en clústeres",
      competitors: "Competidores"
    },
    noDataComponent: "No hay apoderados"
  },
  errorTranslation: {
    somethingWrong: "Algo salió mal, intenta de nuevo",
    largeProxyList:
      "El número de poderes para la verificación no debe exceder los 100",
    emptyProxy: "Faltan indicadores para la exportación",
    apiError: "Algo salió mal!",
    wrongFile: "Tipo de archivo invalido. Solo se permite .txt o .csv",
    nonReadableFile: "Imposible leer el archivo!",
    emptyProxyList: "Ingresar proxy!",
    emptyClusterQuery: "Introducir solicitudes!",
    clusterQueryMinValue: "Ingrese no menos de 2 solicitudes!",
    incorrectIpOrDomain: "IP o dominio incorrecto! ",
    incorrectIp: "Incorrect IP! ",
    proxyFilterEmpty: "Ingresar proxy!",
    proxyIsEmpty: "Este país no tiene proxies!",
    proxyFilterIncorrect: "Proxy incorrecto!",
    emptyIpOrDomain: "Introduzca la dirección IP o el dominio correctos!",
    emptySiteUrl: "Ingrese la URL del sitio!",
    emptyUserAgent: "Seleccione un agente de usuario!",
    invalidUrl: "Formato de URL no válido!",
    timeError: "Vuelve a intentarlo en un par de minutos!",
    IncorrectUrlFormat: "Formato de URL incorrecto!"
  },
  anonimityTypes: [
    {
      value: "an-anonim.txt",
      label: "Anónimo",
      defaultChecked: false,
      route: "anonimnyj"
    },
    {
      value: "el-elit.txt",
      label: "Élite",
      defaultChecked: false,
      route: "elite"
    },
    {
      value: "pr-proz.txt",
      label: "Transparente",
      defaultChecked: false,
      route: "transparent"
    }
  ],
  browsers: [
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/chrome.svg",
      value: "Google Chrome"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/safari.svg",
      value: "Safari"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/opera.svg",
      value: "Opera"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/firefox.svg",
      value: "Firefox"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/yandex.svg",
      value: "Yandex"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/android.svg",
      value: "Android"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/explorer.svg",
      value: "Internet Explorer"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/browser1.svg",
      value: "Browser"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      value: "+",
      last: true
    }
  ],
  checkerProxyTypes: [
    {
      value: "HTTP,HTTPS",
      label: "HTTP/HTTPS",
      defaultChecked: true
    },
    {
      value: "SOCKS4,SOCKS5",
      label: "SOCKS 4/5",
      defaultChecked: true
    },
    {
      value: "NO",
      label: "No funciona",
      defaultChecked: true
    }
  ],
  checkPortsTypes: [
    {
      value: "POPULAR",
      label: "Puertos populares",
      defaultChecked: true
    },
    {
      value: "SERVER",
      label: "Puertos de servidor",
      defaultChecked: false
    },
    {
      value: "GAME",
      label: "Puertos de juegos",
      defaultChecked: false
    },
    {
      value: "APPLICATION",
      label: "Puertos de aplicaciones",
      defaultChecked: false
    },
    {
      value: "P2P",
      label: "Puertos P2P",
      defaultChecked: false
    }
  ],
  utmInputs: [
    {
      label: "Página de destino url",
      name: "url",
      placeholder: "Pegue su enlace o página de destino aquí"
    },
    {
      label: "UTM-fuente*",
      name: "source",
      placeholder: "Fuente de trafico. Por ejemplo: google"
    },
    {
      label: "UTM-medio*",
      name: "medium",
      placeholder:
        "Tipo de tráfico. Por ejemplo: cpc, correo electrónico, banner"
    },
    {
      label: "UTM-campaña*",
      name: "campaign",
      placeholder: "Empresa de publicidad, Por ejemplo: google-poisk"
    },
    {
      label: "UTM-contenido",
      name: "content",
      placeholder: "Por ejemplo: cpc, correo electrónico, banner"
    },
    {
      label: "UTM-término",
      name: "term",
      placeholder: "Palabra clave. Por ejemplo: macro (palabra clave)"
    }
  ],
  yesNo: [
    { label: "Sí", value: true },
    { label: "No", value: false }
  ],
  timeout: [
    { label: "10 segundo", value: 10 },
    { label: "15 segundo", value: 15 },
    { label: "30 segundo", value: 30 },
    { label: "60 segundo", value: 60 }
  ],
  countries: [
    {
      dataBaseValue: "Andorra",
      value: "Andorra",
      label: "Andorra",
      imgSrc: "/img/flags/ad.svg"
    },
    {
      dataBaseValue: "UAE",
      value: "Emiratos Árabes Unidos",
      label: "Emiratos Árabes Unidos",
      imgSrc: "/img/flags/ae.svg"
    },
    {
      dataBaseValue: "Afghanistan",
      value: "Afganistán",
      label: "Afganistán",
      imgSrc: "/img/flags/af.svg"
    },
    {
      dataBaseValue: "Antigua and Barbuda",
      value: "Antigua y Barbuda",
      label: "Antigua y Barbuda",
      imgSrc: "/img/flags/ag.svg"
    },
    {
      dataBaseValue: "Anguilla",
      value: "Anguila",
      label: "Anguila",
      imgSrc: "/img/flags/ai.svg"
    },
    {
      dataBaseValue: "Albania",
      value: "Albania",
      label: "Albania",
      imgSrc: "/img/flags/al.svg"
    },
    {
      dataBaseValue: "Armenia",
      value: "Armenia",
      label: "Armenia",
      imgSrc: "/img/flags/am.svg"
    },
    {
      dataBaseValue: "Antilles",
      value: "Antillas",
      label: "Antillas",
      imgSrc: "/img/flags/an.svg"
    },
    {
      dataBaseValue: "Angola",
      value: "Angola",
      label: "Angola",
      imgSrc: "/img/flags/ao.svg"
    },
    {
      dataBaseValue: "Antarctic",
      value: "Antarctic",
      label: "Antarctic",
      imgSrc: "/img/flags/aq.svg"
    },
    {
      dataBaseValue: "Argentina",
      value: "Argentina",
      label: "Argentina",
      imgSrc: "/img/flags/ar.svg"
    },
    {
      dataBaseValue: "American Samoa",
      value: "Samoa Americana",
      label: "Samoa Americana",
      imgSrc: "/img/flags/as.svg"
    },
    {
      dataBaseValue: "Austria",
      value: "Austria",
      label: "Austria",
      imgSrc: "/img/flags/at.svg"
    },
    {
      dataBaseValue: "Australia",
      value: "Australia",
      label: "Australia",
      imgSrc: "/img/flags/au.svg"
    },
    {
      dataBaseValue: "Aruba",
      value: "Aruba",
      label: "Aruba",
      imgSrc: "/img/flags/aw.svg"
    },
    {
      dataBaseValue: "Åland Islands",
      value: "Islas Aland",
      label: "Islas Aland",
      imgSrc: "/img/flags/ax.svg"
    },
    {
      dataBaseValue: "Azerbaijan",
      value: "Azerbaiyán",
      label: "Azerbaiyán",
      imgSrc: "/img/flags/az.svg"
    },
    {
      dataBaseValue: "Bosnia and Herzegovina",
      value: "Bosnia y Herzegovina",
      label: "Bosnia y Herzegovina",
      imgSrc: "/img/flags/ba.svg"
    },
    {
      dataBaseValue: "Barbados",
      value: "Barbados",
      label: "Barbados",
      imgSrc: "/img/flags/bb.svg"
    },
    {
      dataBaseValue: "Bangladesh",
      value: "Bangladesh",
      label: "Bangladesh",
      imgSrc: "/img/flags/bd.svg"
    },
    {
      dataBaseValue: "Belgium",
      value: "Bélgica",
      label: "Bélgica",
      imgSrc: "/img/flags/be.svg"
    },
    {
      dataBaseValue: "Burkina Faso",
      value: "Burkina Faso",
      label: "Burkina Faso",
      imgSrc: "/img/flags/bf.svg"
    },
    {
      dataBaseValue: "Bulgaria",
      value: "Bulgaria",
      label: "Bulgaria",
      imgSrc: "/img/flags/bg.svg"
    },
    {
      dataBaseValue: "Bahrain",
      value: "Baréin",
      label: "Baréin",
      imgSrc: "/img/flags/bh.svg"
    },
    {
      dataBaseValue: "Burundi",
      value: "Burundi",
      label: "Burundi",
      imgSrc: "/img/flags/bi.svg"
    },
    {
      dataBaseValue: "Benin",
      value: "Benín",
      label: "Benín",
      imgSrc: "/img/flags/bj.svg"
    },
    {
      dataBaseValue: "Saint Barthélemy",
      value: "San Bartolomé",
      label: "San Bartolomé",
      imgSrc: "/img/flags/bl.svg"
    },
    {
      dataBaseValue: "Bermuda",
      value: "Islas Bermudas",
      label: "Islas Bermudas",
      imgSrc: "/img/flags/bm.svg"
    },
    {
      dataBaseValue: "Brunei",
      value: "Brunéi",
      label: "Brunéi",
      imgSrc: "/img/flags/bn.svg"
    },
    {
      dataBaseValue: "Bolivia",
      value: "Bolivia",
      label: "Bolivia",
      imgSrc: "/img/flags/bo.svg"
    },
    {
      dataBaseValue: "Bonaire",
      value: "Bonaire",
      label: "Bonaire",
      imgSrc: "/img/flags/bq.svg"
    },
    {
      dataBaseValue: "Brazil",
      value: "Brasil",
      label: "Brasil",
      imgSrc: "/img/flags/br.svg"
    },
    {
      dataBaseValue: "Bahamas",
      value: "Bahamas",
      label: "Bahamas",
      imgSrc: "/img/flags/bs.svg"
    },
    {
      dataBaseValue: "Butane",
      value: "Butano",
      label: "Butano",
      imgSrc: "/img/flags/bt.svg"
    },
    {
      dataBaseValue: "Bouvet Island",
      value: "Isla Bouvet",
      label: "Isla Bouvet",
      imgSrc: "/img/flags/bv.svg"
    },
    {
      dataBaseValue: "Botswana",
      value: "Botsuano",
      label: "Botsuano",
      imgSrc: "/img/flags/bw.svg"
    },
    {
      dataBaseValue: "Belarus",
      value: "Bielorrusia",
      label: "Bielorrusia",
      imgSrc: "/img/flags/by.svg"
    },
    {
      dataBaseValue: "Belize",
      value: "Belice",
      label: "Belice",
      imgSrc: "/img/flags/bz.svg"
    },
    {
      dataBaseValue: "Canada",
      value: "Canadá",
      label: "Canadá",
      imgSrc: "/img/flags/ca.svg"
    },
    {
      dataBaseValue: "Coconut islands",
      value: "Coconut islands",
      label: "Coconut islands",
      imgSrc: "/img/flags/cc.svg"
    },
    {
      dataBaseValue: "DR Congo",
      value: "DR Congo",
      label: "DR Congo",
      imgSrc: "/img/flags/cd.svg"
    },
    {
      dataBaseValue: "Central African Republic",
      value: "República Centroafricana",
      label: "República Centroafricana",
      imgSrc: "/img/flags/cf.svg"
    },
    {
      dataBaseValue: "Congo",
      value: "Congo",
      label: "Congo",
      imgSrc: "/img/flags/cg.svg"
    },
    {
      dataBaseValue: "Switzerland",
      value: "Suiza",
      label: "Suiza",
      imgSrc: "/img/flags/ch.svg"
    },
    {
      dataBaseValue: "Ivory Coast",
      value: "Costa de Marfil",
      label: "Costa de Marfil",
      imgSrc: "/img/flags/ci.svg"
    },
    {
      dataBaseValue: "Cook Islands",
      value: "Islas Cook",
      label: "Islas Cook",
      imgSrc: "/img/flags/ck.svg"
    },
    {
      dataBaseValue: "Chile",
      value: "Chile",
      label: "Chile",
      imgSrc: "/img/flags/cl.svg"
    },
    {
      dataBaseValue: "Cameroon",
      value: "Camerún",
      label: "Camerún",
      imgSrc: "/img/flags/cm.svg"
    },
    {
      dataBaseValue: "China",
      value: "Porcelana",
      label: "Porcelana",
      imgSrc: "/img/flags/cn.svg"
    },
    {
      dataBaseValue: "Colombia",
      value: "Colombia",
      label: "Colombia",
      imgSrc: "/img/flags/co.svg"
    },
    {
      dataBaseValue: "Costa Rica",
      value: "Costa Rica",
      label: "Costa Rica",
      imgSrc: "/img/flags/cr.svg"
    },
    {
      dataBaseValue: "Cuba",
      value: "Cuba",
      label: "Cuba",
      imgSrc: "/img/flags/cu.svg"
    },
    {
      dataBaseValue: "Cape Verde",
      value: "Cabo Verde",
      label: "Cabo Verde",
      imgSrc: "/img/flags/cv.svg"
    },
    {
      dataBaseValue: "Curacao",
      value: "Curazao",
      label: "Curazao",
      imgSrc: "/img/flags/cw.svg"
    },
    {
      dataBaseValue: "Christmas Island",
      value: "Isla de Navidad",
      label: "Isla de Navidad",
      imgSrc: "/img/flags/cx.svg"
    },
    {
      dataBaseValue: "Cyprus",
      value: "Chipre",
      label: "Chipre",
      imgSrc: "/img/flags/cy.svg"
    },
    {
      dataBaseValue: "Czech Republic",
      value: "Republica checa",
      label: "Republica checa",
      imgSrc: "/img/flags/cz.svg"
    },
    {
      dataBaseValue: "Germany",
      value: "Alemania",
      label: "Alemania",
      imgSrc: "/img/flags/de.svg"
    },
    {
      dataBaseValue: "Djibouti",
      value: "Yibuti",
      label: "Yibuti",
      imgSrc: "/img/flags/dj.svg"
    },
    {
      dataBaseValue: "Denmark",
      value: "Dinamarca",
      label: "Dinamarca",
      imgSrc: "/img/flags/dk.svg"
    },
    {
      dataBaseValue: "Dominica",
      value: "Dominica",
      label: "Dominica",
      imgSrc: "/img/flags/dm.svg"
    },
    {
      dataBaseValue: "Dominican Republic",
      value: "República Dominicana",
      label: "República Dominicana",
      imgSrc: "/img/flags/do.svg"
    },
    {
      dataBaseValue: "Algeria",
      value: "Argelia",
      label: "Argelia",
      imgSrc: "/img/flags/dz.svg"
    },
    {
      dataBaseValue: "Ecuador",
      value: "Ecuador",
      label: "Ecuador",
      imgSrc: "/img/flags/ec.svg"
    },
    {
      dataBaseValue: "Estonia",
      value: "Estonia",
      label: "Estonia",
      imgSrc: "/img/flags/ee.svg"
    },
    {
      dataBaseValue: "Egypt",
      value: "Egipto",
      label: "Egipto",
      imgSrc: "/img/flags/eg.svg"
    },
    {
      dataBaseValue: "West Sahara",
      value: "Sáhara Occidental",
      label: "Sáhara Occidental",
      imgSrc: "/img/flags/eh.svg"
    },
    {
      dataBaseValue: "Eritrea",
      value: "Eritrea",
      label: "Eritrea",
      imgSrc: "/img/flags/er.svg"
    },
    {
      dataBaseValue: "Spain",
      value: "España",
      label: "España",
      imgSrc: "/img/flags/es.svg"
    },
    {
      dataBaseValue: "Ethiopia",
      value: "Etiopía",
      label: "Etiopía",
      imgSrc: "/img/flags/et.svg"
    },
    {
      dataBaseValue: "Europe",
      value: "Europa",
      label: "Europa",
      imgSrc: "/img/flags/eu.svg"
    },
    {
      dataBaseValue: "Finland",
      value: "Finlandia",
      label: "Finlandia",
      imgSrc: "/img/flags/fi.svg"
    },
    {
      dataBaseValue: "Netherlands",
      value: "Países Bajos",
      label: "Países Bajos",
      imgSrc: "/img/flags/nl.svg"
    },
    {
      dataBaseValue: "Fiji",
      value: "Fiyi",
      label: "Fiyi",
      imgSrc: "/img/flags/fj.svg"
    },
    {
      dataBaseValue: "Falkland Islands",
      value: "Islas Malvinas",
      label: "Islas Malvinas",
      imgSrc: "/img/flags/fk.svg"
    },
    {
      dataBaseValue: "Federated States of Micronesia",
      value: "Estados Federados de Micronesia",
      label: "Estados Federados de Micronesia",
      imgSrc: "/img/flags/fm.svg"
    },
    {
      dataBaseValue: "Faroe Islands",
      value: "Islas Faroe",
      label: "Islas Faroe",
      imgSrc: "/img/flags/fo.svg"
    },
    {
      dataBaseValue: "France",
      value: "Francia",
      label: "Francia",
      imgSrc: "/img/flags/fr.svg"
    },
    {
      dataBaseValue: "Georgia",
      value: "Georgia",
      label: "Georgia",
      imgSrc: "/img/flags/ge.svg"
    },
    // {
    //   dataBaseValue: "Great Britain",
    //   value: "Reino Unido",
    //   label: "Reino Unido",
    //   imgSrc: "/img/flags/en.svg"
    // },
    {
      dataBaseValue: "Northern Ireland",
      value: "Irlanda del Norte",
      label: "Irlanda del Norte",
      imgSrc: "/img/flags/nir.svg"
    },
    {
      dataBaseValue: "Scotland",
      value: "Escocia",
      label: "Escocia",
      imgSrc: "/img/flags/sct.svg"
    },
    {
      dataBaseValue: "Wales",
      value: "Gales",
      label: "Gales",
      imgSrc: "/img/flags/wls.svg"
    },
    {
      dataBaseValue: "Grenada",
      value: "Granada",
      label: "Granada",
      imgSrc: "/img/flags/gd.svg"
    },
    {
      dataBaseValue: "French Guiana",
      value: "Guayana Francesa",
      label: "Guayana Francesa",
      imgSrc: "/img/flags/gf.svg"
    },
    {
      dataBaseValue: "Guernsey",
      value: "Guernesey",
      label: "Guernesey",
      imgSrc: "/img/flags/gg.svg"
    },
    {
      dataBaseValue: "Ghana",
      value: "Ghana",
      label: "Ghana",
      imgSrc: "/img/flags/gh.svg"
    },
    {
      dataBaseValue: "Gibraltar",
      value: "Gibraltar",
      label: "Gibraltar",
      imgSrc: "/img/flags/gi.svg"
    },
    {
      dataBaseValue: "Greenland",
      value: "Groenlandia",
      label: "Groenlandia",
      imgSrc: "/img/flags/gl.svg"
    },
    {
      dataBaseValue: "Gambia",
      value: "Gambia",
      label: "Gambia",
      imgSrc: "/img/flags/gm.svg"
    },
    {
      dataBaseValue: "Guinea",
      value: "Guinea",
      label: "Guinea",
      imgSrc: "/img/flags/gn.svg"
    },
    {
      dataBaseValue: "Guadeloupe",
      value: "Guadalupe",
      label: "Guadalupe",
      imgSrc: "/img/flags/gp.svg"
    },
    {
      dataBaseValue: "Equatorial Guinea",
      value: "Guinea Ecuatorial",
      label: "Guinea Ecuatorial",
      imgSrc: "/img/flags/gq.svg"
    },
    {
      dataBaseValue: "Greece",
      value: "Grecia",
      label: "Grecia",
      imgSrc: "/img/flags/gr.svg"
    },
    {
      dataBaseValue: "South Georgia and the South Sandwich Islands",
      value: "Georgia del sur y las islas Sandwich del sur",
      label: "Georgia del sur y las islas Sandwich del sur",
      imgSrc: "/img/flags/gs.svg"
    },
    {
      dataBaseValue: "Guatemala",
      value: "Guatemala",
      label: "Guatemala",
      imgSrc: "/img/flags/gt.svg"
    },
    {
      dataBaseValue: "Guam",
      value: "Guam",
      label: "Guam",
      imgSrc: "/img/flags/gu.svg"
    },
    {
      dataBaseValue: "Guinea-Bissau",
      value: "Guinea-Bisáu",
      label: "Guinea-Bisáu",
      imgSrc: "/img/flags/gw.svg"
    },
    {
      dataBaseValue: "Guyana",
      value: "Guyana",
      label: "Guyana",
      imgSrc: "/img/flags/gy.svg"
    },
    {
      dataBaseValue: "Hong Kong",
      value: "Hong Kong",
      label: "Hong Kong",
      imgSrc: "/img/flags/hk.svg"
    },
    {
      dataBaseValue: "Honduras",
      value: "Honduras",
      label: "Honduras",
      imgSrc: "/img/flags/hn.svg"
    },
    {
      dataBaseValue: "Croatia",
      value: "Croacia",
      label: "Croacia",
      imgSrc: "/img/flags/hr.svg"
    },
    {
      dataBaseValue: "Haiti",
      value: "Haití",
      label: "Haití",
      imgSrc: "/img/flags/ht.svg"
    },
    {
      dataBaseValue: "Hungary",
      value: "Hungría",
      label: "Hungría",
      imgSrc: "/img/flags/hu.svg"
    },
    {
      dataBaseValue: "Indonesia",
      value: "Indonesia",
      label: "Indonesia",
      imgSrc: "/img/flags/id.svg"
    },
    {
      dataBaseValue: "Ireland",
      value: "Irlanda",
      label: "Irlanda",
      imgSrc: "/img/flags/ie.svg"
    },
    {
      dataBaseValue: "Israel",
      value: "Israel",
      label: "Israel",
      imgSrc: "/img/flags/il.svg"
    },
    {
      dataBaseValue: "Isle of Man",
      value: "Isla del hombre",
      label: "Isla del hombre",
      imgSrc: "/img/flags/im.svg"
    },
    {
      dataBaseValue: "India",
      value: "India",
      label: "India",
      imgSrc: "/img/flags/in.svg"
    },
    {
      dataBaseValue: "British Indian Ocean Territory",
      value: "Territorio Británico del Océano Índico",
      label: "Territorio Británico del Océano Índico",
      imgSrc: "/img/flags/io.svg"
    },
    {
      dataBaseValue: "Iraq",
      value: "Irak",
      label: "Irak",
      imgSrc: "/img/flags/iq.svg"
    },
    {
      dataBaseValue: "Iran",
      value: "Irán",
      label: "Irán",
      imgSrc: "/img/flags/ir.svg"
    },
    {
      dataBaseValue: "Italy",
      value: "Italia",
      label: "Italia",
      imgSrc: "/img/flags/it.svg"
    },
    {
      dataBaseValue: "Jersey",
      value: "Jersey",
      label: "Jersey",
      imgSrc: "/img/flags/je.svg"
    },
    {
      dataBaseValue: "Jamaica",
      value: "Jamaica",
      label: "Jamaica",
      imgSrc: "/img/flags/jm.svg"
    },
    {
      dataBaseValue: "Jordan",
      value: "Jordán",
      label: "Jordán",
      imgSrc: "/img/flags/jo.svg"
    },
    {
      dataBaseValue: "Japan",
      value: "Japón",
      label: "Japón",
      imgSrc: "/img/flags/jp.svg"
    },
    {
      dataBaseValue: "Kenya",
      value: "Kenia",
      label: "Kenia",
      imgSrc: "/img/flags/ke.svg"
    },
    {
      dataBaseValue: "Kyrgyzstan",
      value: "Kirguistán",
      label: "Kirguistán",
      imgSrc: "/img/flags/kg.svg"
    },
    {
      dataBaseValue: "Cambodia",
      value: "Camboya",
      label: "Camboya",
      imgSrc: "/img/flags/kh.svg"
    },
    {
      dataBaseValue: "Kiribati",
      value: "Kiribati",
      label: "Kiribati",
      imgSrc: "/img/flags/ki.svg"
    },
    {
      dataBaseValue: "Comoros",
      value: "Comoras",
      label: "Comoras",
      imgSrc: "/img/flags/km.svg"
    },
    {
      dataBaseValue: "Saint Kitts and Nevis",
      value: "San Cristóbal y Nieves",
      label: "San Cristóbal y Nieves",
      imgSrc: "/img/flags/kn.svg"
    },
    {
      dataBaseValue: "Democratic People's Republic of Korea",
      value: "República Popular Democrática de Corea",
      label: "República Popular Democrática de Corea",
      imgSrc: "/img/flags/kp.svg"
    },
    {
      dataBaseValue: "South Korea",
      value: "Corea del Sur",
      label: "Corea del Sur",
      imgSrc: "/img/flags/kr.svg"
    },
    {
      dataBaseValue: "Kuwait",
      value: "Kuwait",
      label: "Kuwait",
      imgSrc: "/img/flags/kw.svg"
    },
    {
      dataBaseValue: "Cayman Islands",
      value: "Islas Caimán",
      label: "Islas Caimán",
      imgSrc: "/img/flags/ky.svg"
    },
    {
      dataBaseValue: "Kazakhstan",
      value: "Kazajstán",
      label: "Kazajstán",
      imgSrc: "/img/flags/kz.svg"
    },
    {
      dataBaseValue: "Laos",
      value: "Laos",
      label: "Laos",
      imgSrc: "/img/flags/la.svg"
    },
    {
      dataBaseValue: "Lebanon",
      value: "Líbano",
      label: "Líbano",
      imgSrc: "/img/flags/lb.svg"
    },
    {
      dataBaseValue: "Saint Lucia",
      value: "Santa Lucía",
      label: "Santa Lucía",
      imgSrc: "/img/flags/lc.svg"
    },
    {
      dataBaseValue: "Liechtenstein",
      value: "Liechtenstein",
      label: "Liechtenstein",
      imgSrc: "/img/flags/li.svg"
    },
    {
      dataBaseValue: "Sri Lanka",
      value: "Sri Lanka",
      label: "Sri Lanka",
      imgSrc: "/img/flags/lk.svg"
    },
    {
      dataBaseValue: "Liberia",
      value: "Liberia",
      label: "Liberia",
      imgSrc: "/img/flags/lr.svg"
    },
    {
      dataBaseValue: "Lesotho",
      value: "Lesoto",
      label: "Lesoto",
      imgSrc: "/img/flags/ls.svg"
    },
    {
      dataBaseValue: "Lithuania",
      value: "Lituania",
      label: "Lituania",
      imgSrc: "/img/flags/lt.svg"
    },
    {
      dataBaseValue: "Luxembourg",
      value: "Luxemburgo",
      label: "Luxemburgo",
      imgSrc: "/img/flags/lu.svg"
    },
    {
      dataBaseValue: "Latvia",
      value: "Letonia",
      label: "Letonia",
      imgSrc: "/img/flags/lv.svg"
    },
    {
      dataBaseValue: "Libya",
      value: "Libia",
      label: "Libia",
      imgSrc: "/img/flags/ly.svg"
    },
    {
      dataBaseValue: "Morocco",
      value: "Marruecos",
      label: "Marruecos",
      imgSrc: "/img/flags/ma.svg"
    },
    {
      dataBaseValue: "Monaco",
      value: "Mónaco",
      label: "Mónaco",
      imgSrc: "/img/flags/mc.svg"
    },
    {
      dataBaseValue: "Moldova",
      value: "Moldavia",
      label: "Moldavia",
      imgSrc: "/img/flags/md.svg"
    },
    {
      dataBaseValue: "Montenegro",
      value: "Montenegro",
      label: "Montenegro",
      imgSrc: "/img/flags/me.svg"
    },
    {
      dataBaseValue: "Saint Martin",
      value: "San Martín",
      label: "San Martín",
      imgSrc: "/img/flags/mf.svg"
    },
    {
      dataBaseValue: "Madagascar",
      value: "Madagascar",
      label: "Madagascar",
      imgSrc: "/img/flags/mg.svg"
    },
    {
      dataBaseValue: "Marshall Islands",
      value: "Islas Marshall",
      label: "Islas Marshall",
      imgSrc: "/img/flags/mh.svg"
    },
    {
      dataBaseValue: "Macedonia",
      value: "Macedonia",
      label: "Macedonia",
      imgSrc: "/img/flags/mk.svg"
    },
    {
      dataBaseValue: "Mali",
      value: "Malí",
      label: "Malí",
      imgSrc: "/img/flags/ml.svg"
    },
    {
      dataBaseValue: "Myanmar",
      value: "Birmania",
      label: "Birmania",
      imgSrc: "/img/flags/mm.svg"
    },
    {
      dataBaseValue: "Mongolia",
      value: "Mongolia",
      label: "Mongolia",
      imgSrc: "/img/flags/mn.svg"
    },
    {
      dataBaseValue: "Macau",
      value: "Macao",
      label: "Macao",
      imgSrc: "/img/flags/mo.svg"
    },
    {
      dataBaseValue: "Northern Mariana Islands",
      value: "Islas Marianas del Norte",
      label: "Islas Marianas del Norte",
      imgSrc: "/img/flags/mp.svg"
    },
    {
      dataBaseValue: "Martinique",
      value: "Martinica",
      label: "Martinica",
      imgSrc: "/img/flags/mq.svg"
    },
    {
      dataBaseValue: "Mauritius",
      value: "Mauricio",
      label: "Mauricio",
      imgSrc: "/img/flags/mr.svg"
    },
    {
      dataBaseValue: "Montserrat",
      value: "Montserrat",
      label: "Montserrat",
      imgSrc: "/img/flags/ms.svg"
    },
    {
      dataBaseValue: "Malta",
      value: "Malta",
      label: "Malta",
      imgSrc: "/img/flags/mt.svg"
    },
    {
      dataBaseValue: "O. Mauritius",
      value: "O. Mauritius",
      label: "O. Mauritius",
      imgSrc: "/img/flags/mu.svg"
    },
    {
      dataBaseValue: "Maldives",
      value: "Maldivas",
      label: "Maldivas",
      imgSrc: "/img/flags/mv.svg"
    },
    {
      dataBaseValue: "Malawi",
      value: "Malaui",
      label: "Malaui",
      imgSrc: "/img/flags/mw.svg"
    },
    {
      dataBaseValue: "Mexico",
      value: "México",
      label: "México",
      imgSrc: "/img/flags/mx.svg"
    },
    {
      dataBaseValue: "Malaysia",
      value: "Malasia",
      label: "Malasia",
      imgSrc: "/img/flags/my.svg"
    },
    {
      dataBaseValue: "Mozambique",
      value: "Mozambique",
      label: "Mozambique",
      imgSrc: "/img/flags/mz.svg"
    },
    {
      dataBaseValue: "Namibia",
      value: "Namibia",
      label: "Namibia",
      imgSrc: "/img/flags/na.svg"
    },
    {
      dataBaseValue: "New Caledonia",
      value: "Nueva Caledonia",
      label: "Nueva Caledonia",
      imgSrc: "/img/flags/nc.svg"
    },
    {
      dataBaseValue: "Niger",
      value: "Níger",
      label: "Níger",
      imgSrc: "/img/flags/ne.svg"
    },
    {
      dataBaseValue: "Norfolk Island",
      value: "Isla Norfolk",
      label: "Isla Norfolk",
      imgSrc: "/img/flags/nf.svg"
    },
    {
      dataBaseValue: "Nigeria",
      value: "Nigeria",
      label: "Nigeria",
      imgSrc: "/img/flags/ng.svg"
    },
    {
      dataBaseValue: "Nicaragua",
      value: "Nicaragua",
      label: "Nicaragua",
      imgSrc: "/img/flags/ni.svg"
    },
    {
      dataBaseValue: "Norway",
      value: "Noruega",
      label: "Noruega",
      imgSrc: "/img/flags/no.svg"
    },
    {
      dataBaseValue: "Nepal",
      value: "Nepal",
      label: "Nepal",
      imgSrc: "/img/flags/np.svg"
    },
    {
      dataBaseValue: "Nauru",
      value: "Nauru",
      label: "Nauru",
      imgSrc: "/img/flags/nr.svg"
    },
    {
      dataBaseValue: "Niue",
      value: "Niue",
      label: "Niue",
      imgSrc: "/img/flags/nu.svg"
    },
    {
      dataBaseValue: "New Zealand",
      value: "Nueva Zelanda",
      label: "Nueva Zelanda",
      imgSrc: "/img/flags/nz.svg"
    },
    {
      dataBaseValue: "Oman",
      value: "Omán",
      label: "Omán",
      imgSrc: "/img/flags/om.svg"
    },
    {
      dataBaseValue: "Panama",
      value: "Panamá",
      label: "Panamá",
      imgSrc: "/img/flags/pa.svg"
    },
    {
      dataBaseValue: "Peru",
      value: "Perú",
      label: "Perú",
      imgSrc: "/img/flags/pe.svg"
    },
    {
      dataBaseValue: "French polynesia",
      value: "Polinesia francés",
      label: "Polinesia francés",
      imgSrc: "/img/flags/pf.svg"
    },
    {
      dataBaseValue: "Papua New Guinea",
      value: "Papúa Nueva Guinea",
      label: "Papúa Nueva Guinea",
      imgSrc: "/img/flags/pg.svg"
    },
    {
      dataBaseValue: "Philippines",
      value: "Filipinas",
      label: "Filipinas",
      imgSrc: "/img/flags/ph.svg"
    },
    {
      dataBaseValue: "Pakistan",
      value: "Pakistán",
      label: "Pakistán",
      imgSrc: "/img/flags/pk.svg"
    },
    {
      dataBaseValue: "Poland",
      value: "Polonia",
      label: "Polonia",
      imgSrc: "/img/flags/pl.svg"
    },
    {
      dataBaseValue: "Saint Pierre and Miquelon",
      value: "San Pedro y Miquelón",
      label: "San Pedro y Miquelón",
      imgSrc: "/img/flags/pm.svg"
    },
    {
      dataBaseValue: "Pitcairn Islands",
      value: "Islas Pitcairn",
      label: "Islas Pitcairn",
      imgSrc: "/img/flags/pn.svg"
    },
    {
      dataBaseValue: "Puerto Rico",
      value: "Puerto Rico",
      label: "Puerto Rico",
      imgSrc: "/img/flags/pr.svg"
    },
    {
      dataBaseValue: "Palestine",
      value: "Palestino",
      label: "Palestino",
      imgSrc: "/img/flags/ps.svg"
    },
    {
      dataBaseValue: "Portugal",
      value: "Portugal",
      label: "Portugal",
      imgSrc: "/img/flags/pt.svg"
    },
    {
      dataBaseValue: "Paraguay",
      value: "Paraguay",
      label: "Paraguay",
      imgSrc: "/img/flags/py.svg"
    },
    {
      dataBaseValue: "Qatar",
      value: "Katar",
      label: "Katar",
      imgSrc: "/img/flags/qa.svg"
    },
    {
      dataBaseValue: "Reunion",
      value: "Reunión",
      label: "Reunión",
      imgSrc: "/img/flags/re.svg"
    },
    {
      dataBaseValue: "Romania",
      value: "Rumania",
      label: "Rumania",
      imgSrc: "/img/flags/ro.svg"
    },
    {
      dataBaseValue: "Serbia",
      value: "Serbia",
      label: "Serbia",
      imgSrc: "/img/flags/rs.svg"
    },
    {
      dataBaseValue: "Russia",
      value: "Federación Rusa",
      label: "Federación Rusa",
      imgSrc: "/img/flags/ru.svg"
    },
    {
      dataBaseValue: "Rwanda",
      value: "Ruanda",
      label: "Ruanda",
      imgSrc: "/img/flags/rw.svg"
    },
    {
      dataBaseValue: "South Africa",
      value: "Sudáfrica",
      label: "Sudáfrica",
      imgSrc: "/img/flags/sa.svg"
    },
    {
      dataBaseValue: "Solomon Islands",
      value: "Islas Salomón",
      label: "Islas Salomón",
      imgSrc: "/img/flags/sb.svg"
    },
    {
      dataBaseValue: "Seychelles",
      value: "Seychelles",
      label: "Seychelles",
      imgSrc: "/img/flags/sc.svg"
    },
    {
      dataBaseValue: "Sudan",
      value: "Sudán",
      label: "Sudán",
      imgSrc: "/img/flags/sd.svg"
    },
    {
      dataBaseValue: "Sweden",
      value: "Suecia",
      label: "Suecia",
      imgSrc: "/img/flags/se.svg"
    },
    {
      dataBaseValue: "Singapore",
      value: "Singapur",
      label: "Singapur",
      imgSrc: "/img/flags/sg.svg"
    },
    {
      dataBaseValue: "O. St. Helena",
      value: "O. St. Helena",
      label: "O. St. Helena",
      imgSrc: "/img/flags/sh.svg"
    },
    {
      dataBaseValue: "Slovenia",
      value: "Eslovenia",
      label: "Eslovenia",
      imgSrc: "/img/flags/si.svg"
    },
    {
      dataBaseValue: "Svalbard",
      value: "Svalbard",
      label: "Svalbard",
      imgSrc: "/img/flags/sj.svg"
    },
    {
      dataBaseValue: "Slovakia",
      value: "Eslovaquia",
      label: "Eslovaquia",
      imgSrc: "/img/flags/sk.svg"
    },
    {
      dataBaseValue: "Sierra Leone",
      value: "Sierra Leona",
      label: "Sierra Leona",
      imgSrc: "/img/flags/sl.svg"
    },
    {
      dataBaseValue: "San Marino",
      value: "San Marino",
      label: "San Marino",
      imgSrc: "/img/flags/sm.svg"
    },
    {
      dataBaseValue: "Senegal",
      value: "Senegal",
      label: "Senegal",
      imgSrc: "/img/flags/sn.svg"
    },
    {
      dataBaseValue: "Somalia",
      value: "Somalia",
      label: "Somalia",
      imgSrc: "/img/flags/so.svg"
    },
    {
      dataBaseValue: "Suriname",
      value: "Surinam",
      label: "Surinam",
      imgSrc: "/img/flags/sr.svg"
    },
    {
      dataBaseValue: "South Sudan",
      value: "Sudán del Sur",
      label: "Sudán del Sur",
      imgSrc: "/img/flags/ss.svg"
    },
    {
      dataBaseValue: "Sao Tome and Principe",
      value: "Santo Tomé y Príncipe",
      label: "Santo Tomé y Príncipe",
      imgSrc: "/img/flags/st.svg"
    },
    {
      dataBaseValue: "Salvador",
      value: "el Salvador",
      label: "el Salvador",
      imgSrc: "/img/flags/sv.svg"
    },
    {
      dataBaseValue: "Sint Maarten",
      value: "San Martín",
      label: "San Martín",
      imgSrc: "/img/flags/sx.svg"
    },
    {
      dataBaseValue: "Syria",
      value: "Siria",
      label: "Siria",
      imgSrc: "/img/flags/sy.svg"
    },
    {
      dataBaseValue: "Eswatini",
      value: "Esuatini",
      label: "Esuatini",
      imgSrc: "/img/flags/sz.svg"
    },
    {
      dataBaseValue: "Turks and Caicos Islands",
      value: "Islas Turcas y Caicos",
      label: "Islas Turcas y Caicos",
      imgSrc: "/img/flags/tc.svg"
    },
    {
      dataBaseValue: "Chad",
      value: "Chad",
      label: "Chad",
      imgSrc: "/img/flags/td.svg"
    },
    {
      dataBaseValue: "Togo",
      value: "Togo",
      label: "Togo",
      imgSrc: "/img/flags/tg.svg"
    },
    {
      dataBaseValue: "Thailand",
      value: "Tailandia",
      label: "Tailandia",
      imgSrc: "/img/flags/th.svg"
    },
    {
      dataBaseValue: "Tajikistan",
      value: "Tayikistán",
      label: "Tayikistán",
      imgSrc: "/img/flags/tj.svg"
    },
    {
      dataBaseValue: "Tokelau",
      value: "Tokelau",
      label: "Tokelau",
      imgSrc: "/img/flags/tk.svg"
    },
    {
      dataBaseValue: "East Timor",
      value: "Timor Oriental",
      label: "Timor Oriental",
      imgSrc: "/img/flags/tl.svg"
    },
    {
      dataBaseValue: "Turkmenistan",
      value: "Turkmenistán",
      label: "Turkmenistán",
      imgSrc: "/img/flags/tm.svg"
    },
    {
      dataBaseValue: "Tunisia",
      value: "Túnez",
      label: "Túnez",
      imgSrc: "/img/flags/tn.svg"
    },
    {
      dataBaseValue: "Turkey",
      value: "Pavo",
      label: "Pavo",
      imgSrc: "/img/flags/tr.svg"
    },
    {
      dataBaseValue: "Trinidad and Tobago",
      value: "Trinidad y Tobago",
      label: "Trinidad y Tobago",
      imgSrc: "/img/flags/tt.svg"
    },
    {
      dataBaseValue: "Tuvalu",
      value: "Tuvalu",
      label: "Tuvalu",
      imgSrc: "/img/flags/tv.svg"
    },
    {
      dataBaseValue: "Taiwan",
      value: "Taiwán",
      label: "Taiwán",
      imgSrc: "/img/flags/tw.svg"
    },
    {
      dataBaseValue: "Tanzania",
      value: "Tanzania",
      label: "Tanzania",
      imgSrc: "/img/flags/tz.svg"
    },
    {
      dataBaseValue: "Ukraine",
      value: "Ucrania",
      label: "Ucrania",
      imgSrc: "/img/flags/ua.svg"
    },
    {
      dataBaseValue: "Uganda",
      value: "Uganda",
      label: "Uganda",
      imgSrc: "/img/flags/ug.svg"
    },
    {
      dataBaseValue: "Minor Outlying Islands (USA)",
      value: "Islas menores alejadas (EE. UU.)",
      label: "Islas menores alejadas (EE. UU.)",
      imgSrc: "/img/flags/um.svg"
    },
    {
      dataBaseValue: "United States",
      value: "Estados Unidos de América",
      label: "Estados Unidos de América",
      imgSrc: "/img/flags/us.svg"
    },
    {
      dataBaseValue: "United Kingdom",
      value: "Reino Unido",
      label: "Reino Unido",
      imgSrc: "/img/flags/uk.svg"
    },
    {
      dataBaseValue: "Uruguay",
      value: "Uruguay",
      label: "Uruguay",
      imgSrc: "/img/flags/uy.svg"
    },
    {
      dataBaseValue: "Uzbekistan",
      value: "Uzbekistán",
      label: "Uzbekistán",
      imgSrc: "/img/flags/uz.svg"
    },
    {
      dataBaseValue: "Vatican City State",
      value: "Estado de la Ciudad del Vaticano",
      label: "Estado de la Ciudad del Vaticano",
      imgSrc: "/img/flags/va.svg"
    },
    {
      dataBaseValue: "Saint Vincent and the Grenadines",
      value: "San Vicente y las Granadinas",
      label: "San Vicente y las Granadinas",
      imgSrc: "/img/flags/vc.svg"
    },
    {
      dataBaseValue: "Venezuela",
      value: "Venezuela",
      label: "Venezuela",
      imgSrc: "/img/flags/ve.svg"
    },
    {
      dataBaseValue: "British Virgin Islands",
      value: "Islas Vírgenes Británicas",
      label: "Islas Vírgenes Británicas",
      imgSrc: "/img/flags/vg.svg"
    },
    {
      dataBaseValue: "US Virgin Islands",
      value: "Islas Vírgenes de EE.UU",
      label: "Islas Vírgenes de EE.UU",
      imgSrc: "/img/flags/vi.svg"
    },
    {
      dataBaseValue: "Vietnam",
      value: "Vietnam",
      label: "Vietnam",
      imgSrc: "/img/flags/vn.svg"
    },
    {
      dataBaseValue: "Vanuatu",
      value: "Vanuatu",
      label: "Vanuatu",
      imgSrc: "/img/flags/vu.svg"
    },
    {
      dataBaseValue: "Wallis and Futuna Islands",
      value: "Islas Wallis y Futuna",
      label: "Islas Wallis y Futuna",
      imgSrc: "/img/flags/wf.svg"
    },
    {
      dataBaseValue: "Samoa",
      value: "Samoa",
      label: "Samoa",
      imgSrc: "/img/flags/ws.svg"
    },
    {
      dataBaseValue: "Kosovo",
      value: "Kosovo",
      label: "Kosovo",
      imgSrc: "/img/flags/xk.svg"
    },
    {
      dataBaseValue: "Yemen",
      value: "Yemen",
      label: "Yemen",
      imgSrc: "/img/flags/ye.svg"
    },
    {
      dataBaseValue: "Mayotte",
      value: "Mayotte",
      label: "Mayotte",
      imgSrc: "/img/flags/yt.svg"
    },
    {
      dataBaseValue: "South Africa",
      value: "Sudáfrica",
      label: "Sudáfrica",
      imgSrc: "/img/flags/za.svg"
    },
    {
      dataBaseValue: "Zambia",
      value: "Zambia",
      label: "Zambia",
      imgSrc: "/img/flags/zm.svg"
    },
    {
      dataBaseValue: "Zimbabwe",
      value: "Zimbabue",
      label: "Zimbabue",
      imgSrc: "/img/flags/zw.svg"
    }
  ],
  filterProxyItems: [
    {
      id: 1,
      value: "Format IP:PORT",
      regExp: /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(\s{0,}|:)\d{1,5}/,
      divider: [":"]
    },
    // {
    //   id: 2,
    //   value: "Format KeyCollector",
    //   regExp:
    //     /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(\s{0,}|:)\d{1,5}(\s{0,}|@)\S+(\s{0,}|:)\S+/,
    //   divider: [":", "@"]
    // },
    {
      id: 3,
      value: "Format IP:PORT@Login:Password",
      regExp:
        /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(\s{0,}|:)\d{1,5}(\s{0,}|@)\S+(\s{0,}|:)\S+/,
      divider: [":", "@"]
    },
    {
      id: 4,
      value: "Format IP.PORT:Login:Password",
      regExp:
        /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(\s{0,}|:)\d{1,5}(\s{0,}|:)\S+(\s{0,}|:)\S+/,
      divider: [":"]
    }
  ],
  quantity: [
    { label: "10 piezas.", value: 10 },
    { label: "15 piezas.", value: 15 },
    { label: "20 piezas.", value: 20 },
    { label: "50 piezas.", value: 50 }
  ],
  regions: [
    { value: "MOSCÚ", label: "Moscú" },
    { value: "KIEV", label: "Kiev" },
    { value: "S T. PETERSBURGO", label: "San Petersburgo" }
  ]
};
