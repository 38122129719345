import { Translations } from "../../../utils/translations";

export const translationInitialState = {
  translation: Translations,
  langs: {
    data: [],
    isDataLoaded: false,
    isLoading: false,
    isFetchError: null
  },
  nearest: ["az", "hy", "be", "kk", "ky", "ru", "tg", "uz", "uk"]
};
