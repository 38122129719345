export const siteNavigationConstant = [
  {
    route: "proxy-checker",
    imgSrc: "/img/ui/check.svg",
    translation: "proxyChecker",
    isDropdown: false
  },
  {
    route: "my-ip",
    imgSrc: "/img/ui/my-ip.svg",
    translation: "myIp",
    isDropdown: false
  },
  {
    route: "skaner-portov",
    imgSrc: "/img/ui/scaner.svg",
    translation: "checkPorts",
    isDropdown: false
  },
  {
    route: "ping-ip",
    imgSrc: "/img/ui/ping.svg",
    translation: "pingIp",
    isDropdown: false
  },
  {
    route: "anonymity-check",
    imgSrc: "/img/ui/vendetta.svg",
    translation: "anonimityCheck",
    isDropdown: false
  },
  {
    route: "generator-utm",
    imgSrc: "/img/ui/utm.png",
    translation: "utm",
    isDropdown: true
  },
  {
    route: "speed",
    imgSrc: "/img/ui/speed.png",
    translation: "speed",
    isDropdown: true
  },
  {
    route: "ipv6-support",
    imgSrc: "/img/ui/ipv6-support.png",
    translation: "ipv6Check",
    isDropdown: true
  },
  {
    route: "ip-in-spam-databases",
    imgSrc: "/img/ui/spam-base.png",
    translation: "spamBase",
    isDropdown: true
  },
  {
    route: "online-proxy-filter",
    imgSrc: "/img/ui/proxy-filtr.png",
    translation: "filterProxy",
    isDropdown: true
  },
  {
    route: "trace",
    imgSrc: "/img/ui/trace-ip.png",
    translation: "traceIp",
    isDropdown: true
  },
  {
    route: "web-rtc-check",
    imgSrc: "/img/ui/webrtc.svg",
    translation: "webRtcChecker",
    isDropdown: true
  },
  {
    route: "dns-check",
    imgSrc: "/img/ui/DNS-icon.svg",
    translation: "dnsChecker",
    isDropdown: true
  },
  {
    route: "fingerprint",
    imgSrc: "/img/ui/fingerprint.svg",
    translation: "fingerPrint",
    isDropdown: true
  },
  {
    route: "evercookie",
    imgSrc: "/img/ui/cookie.svg",
    translation: "evercookie",
    isDropdown: true
  },
  {
    route: "http-headers",
    imgSrc: "/img/ui/http.svg",
    translation: "httpHeaders",
    isDropdown: true
  },
  {
    route: "browser-info",
    imgSrc: "/img/ui/browser.svg",
    translation: "browserInfo",
    isDropdown: true
  }
];
