import { Input } from "./components/Input/Input";

import "./CheckWrapper.scss";

export const CheckWrapper = ({
  title,
  type,
  options,
  horizontal,
  vertical,
  fiftyGrid,
  handler,
  name
}) => {
  const getListClassname = () => {
    let classes = "radio__list";

    if (vertical) {
      classes += " radio__list--vertical";
    } else if (horizontal) {
      classes += " radio__list--horizontal";
    } else if (fiftyGrid) {
      classes += " radio__list--fifty";
    }

    return classes;
  };

  return (
    <div className="radio__wrapper">
      <div className="radio__title">{title}</div>
      <ul className={getListClassname()}>
        {options?.map((option) => (
          <Input
            key={option.value}
            option={option}
            onChangeHandler={handler}
            type={type}
            name={name}
          />
        ))}
      </ul>
    </div>
  );
};
