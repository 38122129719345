import styled from "@emotion/styled";

export const RestartRtcButton = styled("button")`
  width: 300px;
  height: 48px;
  margin-bottom: ${({ status }) => (status ? "60px" : "0")};
  border: none;
  border-radius: 111px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  background: #131430;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #fff;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;

  :hover {
    background-color: transparent;
    color: #131430;
    border: 2px solid #131430;
  }

  @media screen and (min-width: 420px) {
    width: 405px;
  }
`;
