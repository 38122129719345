import { useSelector } from "react-redux";

import {
  useDispatchedActions,
  useTranslation
} from "../../../../../../../hooks";

import BurgerItem from "./components/BurgerItem";

import "./Burger.scss";

export const Burger = () => {
  const t = useTranslation();

  const { burgerFlag } = useSelector((state) => state.content.content);
  // Dispatch
  const { toggleBurger } = useDispatchedActions();

  const menuHandler = () => {
    toggleBurger(!burgerFlag);
  };

  return (
    <>
      <div className="burger__mobile-wrapper">
        <button
          aria-label="burger button"
          className={"burger__btn"}
          onClick={menuHandler}
        >
          <span></span>
        </button>
      </div>

      <div className="burger__wrapper">
        <button aria-label="burger button" className="burger__btn">
          <span></span>
        </button>

        <ul className="burger__dropdown">
          <BurgerItem
            to="https://proxy-sale.com/isp/"
            text={t.navigation.ispProxy}
          />

          <BurgerItem
            to="https://proxy-sale.com/resident/"
            text={t.navigation.resedentialProxy}
          />

          <BurgerItem
            to="https://proxy-sale.com/ipv6/"
            text={t.navigation.proxyIpv6}
          />

          <BurgerItem
            to="https://proxy-sale.com/company/articles/"
            text={t.navigation.articles}
          />

          <BurgerItem
            to="https://proxy-sale.com/contacts/"
            text={t.navigation.contacts}
          />
        </ul>
      </div>
    </>
  );
};
