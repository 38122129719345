import { ToastContainer } from "react-toastify";

const ToastNotificationContainer = () => (
  <ToastContainer
    closeOnClick={false}
    hideProgressBar="true"
    autoClose={6000}
    pauseOnHover={false}
    pauseOnFocusLoss={false}
    draggable={false}
    limit={3}
  />
);

export default ToastNotificationContainer;
