export const proxyInitialState = {
  proxies: {
    data: [],
    totalElements: 0,
    totalPages: 0,
    isDataLoaded: false,
    isLoading: false,
    isFetchError: null
  },

  filterOptions: {
    data: {
      countries: [],
      proxyTypes: [],
      anonimityType: []
    }
  },

  prevFilterOptions: {
    page: 0,
    size: 10,
    countries: [],
    proxyProtocols: [],
    proxyTypes: []
  },

  isFirstFilter: true,

  proxiesStatistic: {
    data: {
      anonymityCount: 0,
      httpCount: 0,
      httpsCount: 0,
      socksCount: 0,
      worksCount: 0
    },
    isDataLoaded: false,
    isLoading: false,
    isFetchError: null
  },

  checkProxy: {
    isDataLoaded: false,
    isLoading: false,
    isFetchError: null
  },

  proxiesForExport: {
    data: [],
    isDataLoaded: false,
    isLoading: false,
    isFetchError: null
  }
};
