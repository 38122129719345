import { useEffect, useState } from "react";

import { Table } from "../../ui";

const CheckerTable = ({
  totalElements,
  proxyList,
  checkerNotWorking,
  displayOptions
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const defineStatusOfProxy = (condition) => {
    if (proxyList.length > 0) {
      return proxyList.filter(
        (proxy) => proxy.state === condition || proxy.status === condition
      ).length;
    }

    return 0;
  };

  const isNotProxyWorking = () => {
    if (proxyList.length > 0) {
      return proxyList.filter(
        ({ ping, checkedPing }) => checkedPing && ping === 0
      ).length;
    }
  };

  const getDataForProxyTable = () => {
    if (!checkerNotWorking) {
      return proxyList.filter(
        ({ type }) => displayOptions.indexOf(type) !== -1
      );
    }

    return proxyList;
  };

  const getCheckCount = () => {
    const result = proxyList.filter((el) => el.finished);
    return result.length;
  };

  const proxyExport = () =>
    proxyList.filter(
      (proxy) => !["NO"].includes(proxy.type) && proxy.ping !== 0
    );

  const downloadHandler = (type) => {
    let proxiesForExport = proxyExport();

    proxiesForExport = proxiesForExport.map(
      (proxy) => `${proxy.ip}:${proxy.port}`
    );

    const a = document.createElement("a");

    if (type === "txt") {
      a.setAttribute(
        "href",
        `data:text/json;charset=utf-8,${encodeURIComponent(
          proxiesForExport.join("\n")
        )}`
      );

      a.setAttribute("download", "proxies.txt");
    } else if (type === "excel") {
      a.setAttribute(
        "href",
        `data:text/json;charset=utf-8,${encodeURIComponent(
          proxiesForExport.map((ip) => ip.replace(/:/, ";")).join("\n")
        )}`
      );

      a.setAttribute("download", "proxies.csv");
    }

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  useEffect(() => {
    if (proxyList.length > 0 && isLoading) {
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proxyList.length]);

  return (
    <>
      <Table
        proxyList={proxyList}
        totalElements={totalElements}
        defineStatusOfProxy={defineStatusOfProxy}
        isNotWorking={isNotProxyWorking}
        getDataForTable={getDataForProxyTable}
        isLoading={isLoading}
        downloadHandler={downloadHandler}
        proxyExport={proxyExport}
        getCheckCount={getCheckCount}
      />
    </>
  );
};

export default CheckerTable;
