import { useEffect, useState } from "react";

import { useLocation, useParams } from "react-router-dom";

import { useTranslation } from "../../../../../../../hooks";
import { siteNavigationConstant } from "../../../../../../../utils/constants/";

import IsDropdownItem from "./components/IsDropdownItem";
import NotDropdownItem from "./components/NotDropdownItem";
import IsOtherItem from "./components/isOtherItem";

export const Navigation = () => {
  const { lang } = useParams();

  const t = useTranslation();
  const location = useLocation();
  const [, pathname] = location.pathname.split("/").reverse();

  const [activeRoute, setActiveRoute] = useState("");

  useEffect(() => {
    setActiveRoute(pathname);
  }, [pathname]);

  return (
    <nav className="header-bottom__nav">
      <ul className="header-bottom__nav-list">
        {siteNavigationConstant.map(
          ({ route, imgSrc, translation, isDropdown }) =>
            !isDropdown ? (
              <NotDropdownItem
                key={route}
                route={route}
                lang={lang}
                imgSrc={imgSrc}
                translation={translation}
                t={t}
                isActive={activeRoute === route}
              />
            ) : null
        )}

        <IsOtherItem pathname={pathname} t={t}>
          <ul className="header-bottom__dropdown">
            {siteNavigationConstant.map(
              ({ route, imgSrc, translation, isDropdown }) =>
                isDropdown ? (
                  <IsDropdownItem
                    key={route}
                    route={route}
                    lang={lang}
                    imgSrc={imgSrc}
                    translation={translation}
                    t={t}
                    isActive={activeRoute === route}
                  />
                ) : null
            )}
          </ul>
        </IsOtherItem>
      </ul>
    </nav>
  );
};
