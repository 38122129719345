import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";

import { ApiService } from "../../../services/ApiService";
import { anonimityService } from "../../../utils/helpers";

export const getClientInfo = createAsyncThunk(
  "client/getClientInfo",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiService.getClientIpInfo();

      if (response && response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);

export const getAnonimityScore = createAsyncThunk(
  "client/getAnonimityScore",
  async (_, { getState, rejectWithValue }) => {
    try {
      const dateJs = moment.utc().format();
      const userAgentJs = navigator.userAgent;
      const languageJs = navigator.language;
      const windowSizes = {
        width: window.screen.width,
        height: window.screen.height
      };
      const flash =
        typeof navigator.plugins !== "undefined" &&
        typeof navigator.plugins["Shockwave Flash"] === "object";

      const java = navigator.javaEnabled();

      const activeX = typeof window.ActiveXObject === "undefined";

      const response = await ApiService.getAnonimityScore(
        dateJs,
        userAgentJs,
        languageJs,
        windowSizes,
        flash,
        java,
        activeX,
        true
      );

      if (response && response.status !== 200) {
        throw response;
      }

      const localObj = {
        score: response.data,
        ip: getState().client.client.data.ip
      };

      window.localStorage.setItem("localClientInfo", JSON.stringify(localObj));

      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);

export const getAnonimityInfo = createAsyncThunk(
  "client/getAnonimityInfo",
  async (_, { rejectWithValue }) => {
    try {
      return await anonimityService();
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);

export const getBlackList = createAsyncThunk(
  "client/getBlackList",
  async (ip, { rejectWithValue }) => {
    try {
      const response = await ApiService.checkIpInSpamBase(ip);

      if (response && response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);

export const getOpenPorts = createAsyncThunk(
  "client/getOpenPorts",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiService.getOpenPorts();

      if (response && response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);

export const saveClientInfo = createAction("client/saveClientInfo");
export const saveAnonimityScore = createAction("client/saveAnonimityScore");
