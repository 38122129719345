import { routesPath } from "../utils/constants/routes.path";

export const ExportRoutes = [
  {
    path: routesPath.export,
    tag: "export",
    title: "Export page",
    description: "Export page",
    component: () => import("../pages/ExportProxyPage/ExportProxyPage")
  }
];
