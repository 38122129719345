import axios from "axios";

import { isSSR } from "./isSSR";

export const instance = axios.create({
  baseURL: isSSR() ? process.env.REACT_APP_SSR_API_URL : null,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json"
  }
});

export const ipvInstance = axios.create({
  baseURL: "https://ipv4-free.proxy-sale.com/api/",

  headers: {
    "Content-Type": "application/json",
    Accept: "application/json"
  }
});
