export const getDnsFulfilledReducer = (state, action) => {
  state.isLoading = false;
  state.isError = null;
  state.ip = [action.payload];
  state.dnsStatus = action.payload.leak;
};

export const getDnsPendingReducer = (state) => {
  state.isLoading = true;
};

export const getDnsRejectReducer = (state, action) => {
  state.isLoading = true;
  state.isError = action.payload;
};
