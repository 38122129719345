export const Fr = {
  header: {
    ariaLabelMain: "Aller à la page d'accueil",
    altImgLogo: "Logo d'entreprise FreeProxySale",
    other: "Plus d'outils",
    ariaLabelOther: "Taux d'expansion des éléments de menu supplémentaires",
    close: "Fermer"
  },
  footer: {
    logo: {
      alt: "Proxy-Sale logo d'entreprise"
    },
    rights: "Tous les droits sont réservés",
    lang: {
      title: "Choisissez la langue",
      selectLanguage: [
        { value: "de", label: "Allemand" },
        { value: "en", label: "Anglais" },
        { value: "es", label: "Espagnol" },
        { value: "fr", label: "Français" },
        { value: "it", label: "Italien" },
        { value: "pl", label: "Polonais" },
        { value: "ru", label: "Russe" },
        { value: "tr", label: "Turc" }
      ]
    },
    tools: "Instruments",
    company: "Compagnie"
  },
  navigation: {
    freeProxy: "Proxy gratuit",
    personalProxy: "Mandataire personnel",
    proxyIpv6: "Procuration IPv6",
    articles: "Des articles",
    contacts: "Contacts",
    proxyChecker: "Vérificateur de proxy",
    myIp: "Mon IP",
    checkPorts: "Analyseur de ports",
    pingIp: "Ping IP",
    anonimityCheck: "Vérification de l'anonymat",
    utm: "UTM Constructeur",
    speed: "Test de vitesse proxy",
    ipv6Check: "IPv6 Validateur",
    spamBase: "IP Vérification de la liste noire",
    filterProxy: "Filtre de liste de proxy",
    traceIp: "IP Tracé",
    claster: "Regroupement des demandes",
    webRtcChecker: "Vérification WebRTC",
    dnsChecker: "Vérification DNS",
    fingerPrint: "Scanner d'empreintes digitales",
    evercookie: "Vérification des cookies",
    httpHeaders: "Affichage des en-têtes HTTP",
    browserInfo: "Informations sur le navigateur",
    resedentialProxy: "Procurations résidentielles",
    ispProxy: "Procurations ISP"
  },
  mainPage: {
    proxyTable: {
      sidebarPart: {
        title: "Configuration d'un proxy dans le navigateur:",
        countries: {
          placeholder: "Sélectionner countriesConstant"
        },
        type: {
          title: "Type de:"
        },
        anonimity: {
          title: "Anonyme:"
        },
        export: {
          title: "Exporter:",
          ip: "IP:Port",
          excel: "Excel",
          window: "Dans une nouvelle fenêtre"
        },
        applyFilter: "Appliquer le filtre",
        clearFilter: "Effacer le filtre",
        infoLeft: "Les proxys ",
        infoRight:
          "gratuits excluent toute possibilité de travailler avec les réseaux sociaux, d'analyser les moteurs de recherche et de travailler avec des logiciels privés. Nous ne sommes pas responsables de l'utilisation de la liste de proxy gratuite. La liste IP est fournie à titre informatif uniquement.",
        buyProxy: "Acheter des procurations personnelles",
        personalProxyAdvantage: [
          "Les procurations personnelles vous offriront un anonymat élevé et une stabilité de travail"
        ]
      }
    },
    faq: "Presque tous les internautes ont entendu parler de ce qu'est un serveur proxy et pourquoi il est nécessaire. Ils sont devenus particulièrement populaires lorsqu'il y avait un besoin massif de contourner le blocage des ressources Web populaires - réseaux sociaux, services Yandex, hébergement vidéo, etc. Bien sûr, la plupart sont intéressés par les proxys gratuits. Il y en a beaucoup sur le réseau, mais il n'est pas toujours évident de savoir comment les choisir correctement et à quoi ils conviennent, et pour quelles tâches il est préférable d'acheter un proxy. "
  },
  anonimityCheck: {
    mainTitle: "Mon anonymat",
    headText:
      "Grâce à ce service, vous pouvez vérifier à quel point vous êtes anonyme sur le réseau, dans quelle mesure les données fournies par votre ordinateur/navigateur correspondent aux données fournies par votre adresse IP.",
    top: {
      ip: "Ton IP :",
      from: "D'où venez-vous:",
      camouflage: "Votre déguisement:",
      anon: "Votre anonymat:",
      restartButton: "Redémarrer l'analyse",
      waitButton: "Chargement",
      result: {
        title: "Votre résultat:",
        notAnonymity: "Pas d'anonymat",
        badAnonymity: "Mauvais anonymat",
        lackAnonymity: "Manque d'anonymat",
        sufficientAnonymity: "Anonymat suffisant",
        checking: "Vérification, veuillez patienter",
        highAnonymity: "Anonymat élevé"
      }
    },
    table: {
      titleIpInfo: "Information IP",
      standardTimeZone: "Fuseau horaire standard",
      anonymityTitle: "Anonymat et sécurité",
      hour: "heures",
      device: "Appareil",
      ip: "Mon IP",
      hideip: "Cacher IP",
      host: "Hôte/Fournisseur",
      country: "Pays",
      city: "Ville",
      index: "Indice",
      coordinates: "Coordonnés",
      os: "OS",
      browser: "Navigateur",
      timezone: "Fuseau horaire",
      ipTime: "IP temps",
      systemTime: "Le temps du système",
      userAgent: "UserAgent",
      userAgentJs: "UserAgent JS",
      language: "Langue",
      languageJs: "Langue JS",
      screen: "Filtrer",
      proxy: "Procuration",
      vpn: "VPN",
      tor: "Tor",
      anonimazer: {
        title: "Anonymiseur",
        info: "Il est nécessaire de fermer les ports. Les ports ouverts peuvent causer des dommages importants à votre appareil ou ont peut-être déjà causé des dommages."
      },
      cookies: {
        info: "La désactivation peut empêcher le suivi par les réseaux publicitaires et diverses ressources, ce qui renforce la confidentialité. Il est recommandé de désactiver les cookies ou de les effacer périodiquement."
      },
      blackList: {
        title: "Liste noire",
        checks: "Chèques:",
        inBlackList: "Liste noire:",
        buyProxy: "Acheter un proxy pur"
      },
      flash: "Flash",
      java: "Java",
      activeX: {
        title: "ActiveX",
        info: "Le contrôle ActiveX présente des vulnérabilités de sécurité et peut être utilisé pour infecter votre ordinateur. Plus vous installez de contrôles ActiveX, plus les sites peuvent exploiter leurs problèmes pour endommager votre appareil."
      },
      webRtc: "WebRTC",
      webRtcIp: "WebRTC IPs",
      openPorts: {
        title: "Ports ouverts",
        links: "Scanner de ports"
      },
      openPortsHTTP: {
        title: "Ports ouverts de proxy HTTP",
        info: "Les ports doivent être fermés. Les ports ouverts peuvent causer des dommages importants ou ont déjà causé des dommages à votre appareil."
      },
      openPortsVPN: {
        title: "Ports VPN ouverts",
        info: "Il est nécessaire de fermer les ports. Les ports ouverts peuvent causer des dommages importants ou ont déjà causé des dommages à votre appareil."
      },
      openPortsWeb: "Ouvrir les ports proxy Web",
      doNotTrack: {
        title: "DoNotTrack",
        info: "Activer cette option ajoute une certaine unicité à l'empreinte numérique du navigateur.",
        link: "Activer DNT"
      },
      dnsLeak: {
        title: "Fuite DNS",
        info: "Des tiers peuvent suivre toutes vos actions sur le réseau. Il est nécessaire de modifier les paramètres DNS.",
        links: "Modifier les DNS"
      },
      suspiciousHeadlines: "Titres suspects",
      suspiciousPorts: "Ports suspects",
      twowayPing: "Ping bidirectionnel",
      replacementUserAgent: {
        title: "Remplacement de l'UserAgent",
        info: "Basé sur les données formant l'UserAgent de l'appareil, si les données diffèrent, cela signifie qu'un remplacement est en cours."
      },
      yourHeadings: "Vos rubriques",
      showButton: {
        more: "Afficher plus",
        less: "Masquer"
      }
    },
    faq: {
      text: []
    }
  },
  checkPorts: {
    analis: "Analyse en cours... Attendez.",
    port: "Port",
    status: "Statut",
    statusTrue: "disponible",
    statusFalse: "indisponible",
    description: "Description",
    portStatus: "État du port sur le serveur",
    scanType: "Type de numérisation",
    mainTitle: "Vérification des ports ouverts en ligne",
    videoTitle: "Qu'est-ce qu'un scanner de port et pourquoi?",
    headText:
      "La vérification des ports ouverts à l'aide d'un scanner montre quels ports sont ouverts sur votre ordinateur / site ou celui de quelqu'un d'autre et ce qu'ils répondent. La vérification est effectuée via le scanner NMAP et montre comment ouvrir votre accès au PC / site pour le monde extérieur.",
    ipTitle: "Display:",
    ipElem: {
      placeholder: "Entrez l'adresse IP ou le domaine",
      insertIp: "Coller mon adresse IP"
    },
    scanTypes: {
      title: "Types de ports analysés:"
    },
    scanTitle: "Type de numérisation:",
    startScan: "Lancer l'analyse",
    resultCheck: "Résultats de test:",
    resultCaption:
      "* Si le résultat est 'hôte est en panne', le pare-feu ou le routeur est en cours de vérification et bloque les adresses IP.",
    faq: {
      text: []
    }
  },
  claster: {
    mainTitle: "Outil de regroupement par mots-clés",
    checker: {
      regionTitle: "Vérification de la région",
      selectPlaceholder: "Choisissez la région",
      clasterTitle: "Seuil de regroupement",
      groupBtn: "Grouper",
      queryTitle: "Demandes"
    },
    headText:
      "Grâce à ce service, le regroupement de requêtes en ligne est à votre disposition. Grâce à lui, vous pouvez automatiquement regrouper des mots-clés en fonction des résultats de recherche Yandex et Google. Une construction compétente de la structure du site augmentera vos chances d'amener votre site aux premières positions. Notre Le service de clustering de requêtes en ligne vous aide à catégoriser vos mots-clés sur vos pages de manière à obtenir le plus de trafic SEO."
  },
  filterProxy: {
    mainTitle: "Filtre proxy",
    headText:
      "Le filtrage proxy est le processus d'envoi d'un filtre proxy au serveur principal pour sélectionner des recommandations spécifiques.",
    filterOut: "Filtrer",
    save: "Enregistrer dans un fichier"
  },
  ipv6: {
    mainTitle: "Vérification des sites pour le support d’IPv6",
    headText:
      "La version du protocole IPv6, qui offre une protection maximale sur le réseau, nécessite l'utilisation d'un outil de support spécifique au site pour le nouveau protocole proxy pour un fonctionnement plus efficace.",
    checker: {
      addressTitle: "Entrez l'adresse du site pour vérification",
      checkProxyBtn: "Vérifier la procuration",
      tip: "Le test peut prendre un certain temps",
      resultTitle: "Résultats de test:",
      result: {
        success: "Félicitations, ce site prend en charge IPv6",
        error:
          "Malheureusement, le site ne prend pas en charge IPv6 pour le moment"
      }
    },

    faq: {
      text: []
    }
  },
  myIp: {
    top: {
      title:
        "Et ce n'est qu'une petite partie des informations qui peuvent être suivies par votre adresse IP:",
      ipv4: "Votre adresse IPv4:",
      ipv6: "Votre adresse IPv6:",
      changeIp: "Changer votre adresse IP",
      table: {
        check: "Vérifier",
        country: "Pays",
        city: "Ville",
        index: "Code postal",
        host: "Hôte",
        system: "Système",
        browser: "Ton navigateur",
        from: "D'où venez-vous",
        provider: "Fournisseur",
        anon: "Anonymiseur",
        proxy: "Procuration",
        protocol: "Protocole",
        imgAlt: {
          country: "Pays",
          city: "Ville",
          index: "Code postal",
          screen: "Moniteur",
          os: "Système opérateur",
          browser: "Version du navigateur",
          location: "Emplacement",
          server: "Serveur",
          anon: "Anonymiseur",
          proxy: "Procuration",
          protocol: "Protocole",
          blacklist: "Liste noire"
        }
      },
      map: {
        title: "Your location on the map!",
        hide: "You can hide your location",
        text: "It is enough to use our simple, convenient and reliable tool. Hide personal data, protect traffic and remove all limits from your Internet",
        hideIp: "Hide IP address"
      },
      caution: {
        top: "As you can see, quite detailed information is provided by IP.",
        bottom:
          "When connected to the Internet, the computer is assigned a number called IP (Internet Protocol Address). It should be noted that the assignment is not arbitrary, but the IP that is available to the provider and is free. IP changes after disconnecting when reconnecting. These IPs are called dynamic. If you want it to be unchanged, contact your ISP. Usually, non-dynamic is available for a fee. It is also called external. It is often tracked by specialized online programs, determining the accompanying information about the owner. Internal IP or local is called the individual address of a PC (laptop) in a local network."
      }
    },
    faq: {
      text: []
    }
  },
  pingIp: {
    mainTitle: "Vérifier le ping d'une adresse IP ou d'un site",
    whatIsPing: "Qu'est-ce que le ping IP et pourquoi?",
    headText: [
      "Cliquez sur le bouton 'Insérer mon adresse IP' ou utilisez l'adresse IP ou le site auquel vous souhaitez vérifier le ping. Sélectionnez 3 à 5 paquets de test et cliquez sur le bouton 'Ping IP'. Vous verrez la valeur souhaitée dans le ' heure = 'section.",
      "Le ping est considéré comme normal lorsqu'il est compris entre 40 et 110ms. À partir de 110-210ms, vous commencerez à rencontrer des problèmes dans les jeux en ligne, mais cela n'affectera guère vos visites sur les sites. Si le timeoutConstant dépasse 110ms, alors c'est une raison de penser à la qualité du travail de votre FAI."
    ],
    checker: {
      showTitle: "Affichage:",
      showSubtitle:
        "Entrez une adresse IP valide (0.0.0.0) ou un nom d'hôte (host.com)",
      ipInsert: "Coller mon adresse IP",
      addTitle: "Nombre de colis:",
      selectPlaceholder: "Choisissez un pays",
      startScan: "Lancer l'analyse",
      resultTitle: "Lancer l'analyse:",
      resultCaption:
        "* Si le résultat est 'Lhôte est en panne', le pare-feu ou le routeur est en cours de vérification et bloque les adresses IP."
    },
    faq: {
      text: []
    }
  },
  proxyChecker: {
    mainTitle: "Vérificateur de procuration",
    condition: "Statut",
    ping: "Ping",
    type: "Taper",
    status: "Ca ne fonctionne pas",
    workStatus: "Disponible",
    head: {
      videoTitle: "Comment et pourquoi utiliser un vérificateur de proxy?",
      headText:
        "Vérificateur de proxy en ligne pour vérifier la validité des serveurs proxy. Analyse d'un proxy ou d'un groupe d'adresses pour la disponibilité, le pays, la vitesse, l'anonymat, le type.",
      titleFirst: "Proxy checker can:",
      listFirst: [
        "vérifier IPv4, IPv6 pour la propriété du pays;",
        "détermination du type de protocole HTTPS, SOCKS5 et de la vitesse actuelle;",
        "vérification de l'anonymat de l'adresse;",
        "vérification de proxy multithread de groupe à grande vitesse dans 64 threads;",
        "la possibilité de télécharger une liste et de télécharger le résultat de la vérification;",
        "Affichage pratique des résultats à l'aide d'un filtre d'affichage."
      ],
      titleSecond:
        "Dans quel format un proxy doit-il être ajouté au vérificateur?",
      listSecond: [
        {
          content:
            "Si vous avez des <span>proxys publics</span> (sans identifiant ni mot de passe), puis - IP : PORT"
        },
        {
          content:
            "Si vous avez des <span>proxys privés</span> (avec autorisation de connexion et mot de passe), puis - IP : PORT : USER : PASS"
        }
      ],
      titleThird:
        "P.S.: Si vous <a href='https://proxy-sale.com/' rel='noreferrer' target='_blank'> achetez un proxy </a> avec nous, alors ils sont privés!"
    },
    faq: {
      text: []
    }
  },
  siteSpeed: {
    mainTitle: "Vérifier la vitesse d'accès du site via un proxy",
    head: {
      listFirst: [
        "Détermination de la vitesse de transmission des données via HTTP et proxy SOCKS",
        "Possibilité de déterminer directement la vitesse de chargement des pages",
        "Vérifier l'accès au site"
      ],
      caution: "Dans quel format le contrôle doit-il être effectué?",
      subtitle:
        "Pour vérifier la vitesse via un proxy, vous devez piloter une ressource à laquelle vous avez besoin d'accéder et une adresse IP au format:",
      listSecond: [
        {
          text: "Proxys publics(sans login ni mot de passe), puis —",
          value: "IP: PORT"
        },
        {
          text: "Proxies privés(avec autorisation de connexion et de mot de passe), puis —",
          value: "IP:PORT:USER:PASS"
        }
      ],
      post: "P.S.: Si vous avez acheté un proxy chez nous, il sera privé!"
    },
    checker: {
      adress: "Entrez l'URL du site pour vérifier la vitesse",
      proxy: "Adresse proxy au format",
      format: "127.0.0.1:8080",
      subtitle: [
        "Pour tester la vitesse de transmission des données via un proxy, entrez l'adresse et le port du serveur proxy",
        "Pour un test normal de la vitesse de chargement des pages du site, laissez ce champ vide."
      ],
      type: {
        title: "Type de serveur proxy"
      },
      timeout: {
        title: "Temps libre:",
        placeholder: "Temps libre"
      },
      access: {
        title: "Vérifier l'accès au site",
        body: "Les contrôles d'accès et de vitesse peuvent prendre un certain temps"
      },
      btn: "Vérifier la vitesse",
      result: {
        title: "Résultats de test:",
        result: {
          titleFirst: "Vérifier - la vitesse d'accès au site via proxy",
          checkSite: "Vérification du site",
          checkProxy: "Vérifier la procuration",
          timeout: "Temps libre",
          sec: "seconde",
          amountOfDownloads: "Téléchargements de test fabriqués",
          titleSecond: "Chèque terminé",
          averageSpeed: "Vitesse de téléchargement moyenne",
          generalSpeed: "Vitesse moyenne totale",
          speedValue: "Kb/seconde",
          access: "Accès au site - Titre de la page téléchargée",
          emptyTitle: "Impossible d'obtenir le titre",
          isProxyUsing: {
            yes: "Utilisation d'un proxy",
            no: "Sans proxy, chargez directement"
          }
        }
      }
    },
    faq: {
      text: []
    },
    errors: {
      url: "Entrez l'URL du site"
    }
  },
  spamBase: {
    mainTitle:
      "Présence et vérification de l’adresse IP dans les bases de spam",
    head: {
      text: "Comme vous le savez, le spam peut souvent entraîner la mise sur liste noire de l'IP, ce qui perturbe sensiblement toutes les opérations du réseau. Pour éviter cela, vous devez utiliser le serveur de vérification IP dans les bases de données de spam."
    },
    checker: {
      ip: {
        title: "Veuillez entrer la bonne adresse IP",
        insert: "Coller mon adresse IP"
      },
      check: "Examen",
      test: "Le test peut prendre un certain temps.",
      list: {
        checkedAll: "Chèques:",
        checked: "Vérifié:",
        blackList: "Liste noire:"
      },
      result: {
        listed: "Listé",
        notListed: "Non listé"
      }
    },
    faq: {
      text: []
    }
  },
  trace: {
    mainTitle: "Traçage IP",
    head: {
      text: "Grâce au service de cette page, vous avez accès à une trace gratuite et quasi instantanée de l'adresse IP ou du site. Il vous aidera à effectuer un diagnostic réseau, qui détermine la vitesse d'envoi des requêtes via des serveurs sur Internet lorsque vous ouvrir une ressource Web dans un navigateur. déterminer les causes des éventuels problèmes liés à la lenteur du chargement du site. "
    },
    checker: {
      ip: "Entrez l'adresse IP correcte (0.0.0.0) ou le nom d'hôte (domain.ru)",
      route: {
        title: "Afficher l'itinéraire du colis par pays",
        placeholder: "Sélectionnez un élément"
      },
      btn: "AdresseIP de suivi",
      result: "Résultats de test:"
    }
  },
  webRtcCheck: {
    mainTitle: "Vérification WebRTC",
    head: {
      text: "Recherche de fuites via WebRTC. Vos données peuvent être en danger même si vous êtes connecté à un VPN."
    },
    top: {
      myIp: "Votre adresse IP:",
      from: "D'où venez-vous:",
      state: "Statut:",
      warning: "Menace potentielle"
    },
    info: {
      top: "Comme vous pouvez le constater, des informations assez détaillées sont fournies par IP.",
      bottom:
        "Nous avons vérifié si WebRTC est activé dans votre navigateur et si vous disposez d'une véritable adresse IP. Il s'avère qu'il existe un risque de fuite potentielle, WebRTC est activé et peut révéler votre véritable adresse IP.",
      disableBottom:
        "Nous avons vérifié si WebRTC est activé dans votre navigateur et si vous disposez d'une véritable adresse IP. Il s'avère que votre véritable adresse IP reste inconnue et WebRTC n'est pas activé dans votre navigateur."
    },
    resetButton: "Redémarrer le test de fuite WebRTC",
    status: {
      type: "Taper",
      state: "Statut",
      topText: "WebRTC activé",
      bottomText: "Menace de révéler votre véritable adresse IP!",
      warning: "Menace de fuite",
      checking: "Vérifiez, veuillez patienter"
    }
  },
  dnsCheck: {
    top: {
      myIp: "Votre adresse IP:",
      from: "D'où venez-vous:",
      state: "Statut:",
      warning: "Menace potentielle"
    },
    info: {
      top: "Comme vous pouvez le voir, lors de la vérification de votre IP, une fuite DNS a été détectée.",
      bottom:
        "Nous avons analysé votre IP et détecté une fuite via DNS. Vos requêtes ont été révélées et la connexion n'est pas sécurisée. Cela signifie que l'administrateur de votre DNS peut suivre les informations sur les sites web et les programmes que vous utilisez.",
      disableBottom:
        "Nous avons analysé votre IP et n'avons détecté aucune fuite via DNS. Vos requêtes n'ont pas été révélées et la connexion est sécurisée. Cela signifie que l'administrateur de votre DNS ne peut pas suivre les informations sur les sites web et les programmes que vous utilisez."
    },
    resetButton: "Redémarrer le test de fuite DNS",
    status: {
      type: "Taper",
      state: "Statut",
      topText: "DNS activé",
      bottomText: "Menace de divulguer vos demandes !",
      warning: "Menace de fuite",
      checking: "Vérifiez, veuillez patienter"
    }
  },
  fingerPrintScanner: {
    mainTitle: "Scanner d'empreintes digitales du navigateur",
    head: {
      text: "Les empreintes digitales du navigateur sont un instantané unique des paramètres de votre ordinateur, et des mécanismes spéciaux de sites Web peuvent vous suivre."
    },
    top: {
      myIp: "Votre adresse IP:",
      fingerprint: "Empreinte digitale du navigateur:"
    },
    table: {
      location: "Emplacement",
      provider: "Fournisseur",
      host: "Hôte",
      os: "OS",
      browser: "Navigateur",
      plugin: "Brancher:",
      status: "Statut:",
      isOn: "activé",
      isOff: "éteindre",
      device: "Appareil",
      browserLanguage: "Langue du navigateur",
      screenSize: "Taille de l'écran",
      timeZone: "Fuseau horaire"
    },
    imgAlt: {
      location: "Emplacement",
      provider: "Fournisseur",
      host: "Hôte",
      os: "système opérateur",
      browser: "Navigateur",
      language: "Langue du navigateur",
      size: "Taille de l'écran",
      timeZone: "Fuseau horaire"
    },
    bottom: {
      method: "Méthode JS"
    }
  },
  createEvercookie: {
    mainTitle: "Vérification des cookies",
    head: {
      text: "Cliquez sur le bouton pour créer un evercookie. Votre anonymat ne sera pas compromis : un cookie est un nombre aléatoire compris entre 1 et 1000 que nous utilisons pour tester evercookies. Nous ne suivons pas votre utilisation."
    },
    top: {
      button: "Créer un cookie éternel"
    }
  },
  httpHeaders: {
    requestHeaders: "En-têtes de demande",
    responseHeaders: "En-têtes de réponse",
    submitButton: "Envoyer la demande"
  },
  browserInfo: {
    mainTitle: "Informations de votre navigateur",
    head: {
      text:
        "À l'aide du navigateur, divers sites et services ont la possibilité de suivre l'utilisateur. Cela est nécessaire, par exemple, pour identifier une personne même si elle a modifié son adresse IP. Les systèmes publicitaires utilisent également des capacités de suivi afin de connaître les intérêts de l’utilisateur.\n" +
        "Notre service vous aidera à déterminer quelles méthodes de suivi sont activées dans votre navigateur."
    },
    table: {
      type: "Taper",
      discovered: "Découvert",
      cookieMeaning: "Signification des cookies",
      parameter: "Paramètre",
      value: "Valeur",
      resolution: "Résolution d'écran:",
      desktop: "Bureau",
      colorDepth: "La profondeur de la couleur",
      bit: "peu"
    },
    button: {
      createCookie: "Créer des cookies",
      userTracking: "Suivi des utilisateurs"
    }
  },
  utm: {
    mainTitle: "Générateur de balises UTM en ligne",
    checker: {
      linkUtm: "Le lien avec la balise UTM apparaîtra ici",
      copy: "Copie",
      shortUrl: "Court URL",
      traffic: [
        { text: "Google ADS", source: "google" },
        { text: "Yandex.Direct", source: "yandex" },
        { text: "VK.com", source: "vk" },
        { text: "Facebook", source: "facebook" },
        { text: "Instagram", source: "instagram" },
        { text: "Target.Mail.ru", source: "targetmailru" },
        { reset: true, text: "Reset" }
      ]
    },
    faq: {
      text: []
    }
  },
  pageHead: {
    ip: "Ton IP",
    changeIp: "Changer IP",
    anon: "Votre anonymat",
    moreAnon: "En savoir plus sur l'anonymat"
  },
  checker: {
    country: "Choisissez un pays:",
    add: "Ajouter d'abord:",
    show: {
      title: "Affichage"
    },
    displayType: "Affichage",
    checkProxy: "Vérifier la procuration",
    fileName: "Charger depuis .txt ou .csv",
    typeProxy: {
      title: "Entrez le mandataire:",
      placeholder: "Écrivez votre texte ici"
    },
    info: {
      text: "Soulagez-vous des restrictions et des inconvénients, du risque d'être banni ou de changer définitivement votre adresse IP. Proxies personnels pour travailler avec les réseaux sociaux, Avito, Key Collector et à d'autres fins.",
      buy: "Acheter une protection"
    },
    results: {
      title: "Résultats de test",
      checked: "Vérifié",
      working: "Travail",
      notWorking: "Ca ne fonctionne pas",
      divider: "de",
      btn: "Acheter travail"
    },
    download: {
      title: "Télécharger uniquement le proxy professionnel",
      txt: "Télécharger .txt",
      csv: "tableau dans .csv"
    }
  },
  common: {
    faqTitle: "Nous répondons à vos questions",
    yes: "Oui",
    no: "Non",
    notDefined: "Non défini",
    copy: "Copié!",
    select: {
      placeholderCountry: "Choisissez un pays"
    },
    buyAnonProxy: "Acheter l'anonymat",
    buyWorkingProxy: "Acheter travail"
  },
  table: {
    top: {
      workProxy: "Procurations de travail",
      anon: "Anonyme",
      socks: "Socks 4/5",
      http: "HTTP/HTTPS",
      proxyInBase: "Proxy dans la base de données"
    },
    tableColumns: {
      copy: "Copier dans le presse-papier",
      country: "Pays",
      type: "Type de",
      anon: {
        title: "Anonymat",
        speed: "La vitesse",
        s: "s",
        ms: "ms",
        m: "m",
        h: "h",
        types: {
          anon: "Anonymat",
          proz: "Transparent",
          elite: "Élite",
          notFounded: "Non fondée"
        }
      },
      check: {
        title: "Examen",
        tip: "Rapide",
        buy: "Acheter travail",
        reload: "Redémarrer",
        ago: "mins. ago",
        status: {
          work: "Travail",
          notWorked: "Ca ne fonctionne pas"
        }
      },
      condition: {
        title: "État"
      },
      login: "Connexion",
      password: "Mot de passe",
      pagination: {
        rowsPerPage: "Proxy pour la page",
        separatorText: "de"
      },
      requests: "Demandes",
      requestsCluster: "Requêtes sur clusters",
      competitors: "Concurrents"
    },
    noDataComponent: "Il n'y a pas de proxy"
  },
  errorTranslation: {
    somethingWrong: "Quelque chose s'est mal passé essaie encore",
    largeProxyList:
      "Le nombre de procurations pour la vérification ne doit pas dépasser 100",
    emptyProxy: "Proxy manquants pour l'exportation",
    apiError: "Quelque chose s'est mal passé!",
    wrongFile:
      "Type de fichier invalide. Seuls les fichiers .txt ou .csv sont autorisés",
    nonReadableFile: "Impossible de lire le fichier!",
    emptyProxyList: "Entrez le mandataire!",
    emptyClusterQuery: "Saisir les demandes!",
    clusterQueryMinValue: "Entrez pas moins de 2 demandes!",
    incorrectIpOrDomain: "IP ou domaine incorrect! ",
    incorrectIp: "IP incorrecte! ",
    proxyFilterEmpty: "Entrez le mandataire!",
    proxyIsEmpty: "Ce pays n'a pas de procurations!",
    proxyFilterIncorrect: "Mandataire incorrect!",
    emptyIpOrDomain: "Entrez l'adresse IP ou le domaine correct!",
    emptySiteUrl: "Entrez l'URL du site!",
    emptyUserAgent: "Sélectionnez un agent utilisateur!",
    invalidUrl: "Format d'URL invalide!",
    timeError: "Réessayez dans quelques minutes!",
    IncorrectUrlFormat: "Format d'URL incorrect!"
  },
  anonimityTypes: [
    {
      value: "an-anonim.txt",
      label: "Anonyme",
      defaultChecked: false,
      route: "anonimnyj"
    },
    {
      value: "el-elit.txt",
      label: "Élite",
      defaultChecked: false,
      route: "elite"
    },
    {
      value: "pr-proz.txt",
      label: "Transparent",
      defaultChecked: false,
      route: "transparent"
    }
  ],
  browsers: [
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/chrome.svg",
      value: "Google Chrome"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/safari.svg",
      value: "Safari"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/opera.svg",
      value: "Opera"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/firefox.svg",
      value: "Firefox"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/yandex.svg",
      value: "Yandex"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/android.svg",
      value: "Android"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/explorer.svg",
      value: "Internet Explorer"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/browser1.svg",
      value: "Browser"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      value: "+",
      last: true
    }
  ],
  checkerProxyTypes: [
    {
      value: "HTTP,HTTPS",
      label: "HTTP/HTTPS",
      defaultChecked: true
    },
    {
      value: "SOCKS4,SOCKS5",
      label: "SOCKS 4/5",
      defaultChecked: true
    },
    {
      value: "NO",
      label: "Ne fonctionne pas",
      defaultChecked: true
    }
  ],
  checkPortsTypes: [
    {
      value: "POPULAR",
      label: "Ports populaires",
      defaultChecked: true
    },
    {
      value: "SERVER",
      label: "Ports serveur",
      defaultChecked: false
    },
    {
      value: "GAME",
      label: "Ports de jeu",
      defaultChecked: false
    },
    {
      value: "APPLICATION",
      label: "Ports d'application",
      defaultChecked: false
    },
    {
      value: "P2P",
      label: "Ports P2P",
      defaultChecked: false
    }
  ],
  utmInputs: [
    {
      label: "URL de la page de destination",
      name: "url",
      placeholder: "Collez votre lien ou page de destination ici"
    },
    {
      label: "UTM la source*",
      name: "source",
      placeholder: "Origine du trafic. Par exemple : google"
    },
    {
      label: "UTM-moyen*",
      name: "medium",
      placeholder: "Type de trafic. Par exemple: CPC, e-mail, bannière"
    },
    {
      label: "UTM-campagne*",
      name: "campaign",
      placeholder: "Société de publicité, par exemple: google-poisk"
    },
    {
      label: "UTM-content",
      name: "content",
      placeholder: "Par exemple: CPC, e-mail, bannière"
    },
    {
      label: "UTM-terme",
      name: "term",
      placeholder: "Mot-clé. Par exemple: macro (mot-clé)"
    }
  ],
  yesNo: [
    { label: "Oui", value: true },
    { label: "Non", value: false }
  ],
  timeout: [
    { label: "10 seconde", value: 10 },
    { label: "15 seconde", value: 15 },
    { label: "30 seconde", value: 30 },
    { label: "60 seconde", value: 60 }
  ],
  countries: [
    {
      dataBaseValue: "Andorra",
      value: "Andorre",
      label: "Andorre",
      imgSrc: "/img/flags/ad.svg"
    },
    {
      dataBaseValue: "UAE",
      valeur: "EAU",
      label: "EAU",
      imgSrc: "/img/flags/ae.svg"
    },
    {
      dataBaseValue: "Afghanistan",
      valeur: "Afghanistan",
      label: " Afghanistan ",
      imgSrc: "/img/flags/af.svg"
    },
    {
      dataBaseValue: "Antigua and Barbuda",
      value: "Antigua-et-Barbuda",
      label: "Antigua-et-Barbuda",
      imgSrc: "/img/flags/ag.svg"
    },
    {
      dataBaseValue: "Anguilla",
      value: "Anguilla",
      label: "Anguilla",
      imgSrc: "/img/flags/ai.svg"
    },
    {
      dataBaseValue: "Albania",
      value: "Albanie",
      label: "Albanie",
      imgSrc: "/img/flags/al.svg"
    },
    {
      dataBaseValue: "Armenia",
      value: "Arménie",
      label: "Arménie",
      imgSrc: "/img/flags/am.svg"
    },
    {
      dataBaseValue: "Antilles",
      value: "Antilles",
      label: "Antilles",
      imgSrc: "/img/flags/an.svg"
    },
    {
      dataBaseValue: "Angola",
      value: "Angola",
      label: "Angola",
      imgSrc: "/img/flags/ao.svg"
    },
    {
      dataBaseValue: "Antarctic",
      value: "Antarctique",
      label: "Antarctique",
      imgSrc: "/img/flags/aq.svg"
    },
    {
      dataBaseValue: "Argentina",
      value: "Argentine",
      label: "Argentine",
      imgSrc: "/img/flags/ar.svg"
    },
    {
      dataBaseValue: "American Samoa",
      value: "Samoa américaines",
      label: "Samoa américaines",
      imgSrc: "/img/flags/as.svg"
    },
    {
      dataBaseValue: "Austria",
      value: "L'Autriche",
      label: "L'Autriche",
      imgSrc: "/img/flags/at.svg"
    },
    {
      dataBaseValue: "Australia",
      value: "Australie",
      label: "Australie",
      imgSrc: "/img/flags/au.svg"
    },
    {
      dataBaseValue: "Aruba",
      value: "Aruba",
      label: "Aruba",
      imgSrc: "/img/flags/aw.svg"
    },
    {
      dataBaseValue: "Åland Islands",
      value: "Iles Aland",
      label: "Iles Aland",
      imgSrc: "/img/flags/ax.svg"
    },
    {
      dataBaseValue: "Azerbaijan",
      value: "Azerbaïdjan",
      label: "Azerbaïdjan",
      imgSrc: "/img/flags/az.svg"
    },
    {
      dataBaseValue: "Bosnia and Herzegovina",
      value: "Bosnie Herzégovine",
      label: "Bosnie Herzégovine",
      imgSrc: "/img/flags/ba.svg"
    },
    {
      dataBaseValue: "Barbados",
      value: "Barbade",
      label: "Barbade",
      imgSrc: "/img/flags/bb.svg"
    },
    {
      dataBaseValue: "Bangladesh",
      value: "Bengladesh",
      label: "Bengladesh",
      imgSrc: "/img/flags/bd.svg"
    },
    {
      dataBaseValue: "Belgium",
      value: "Belgique",
      label: "Belgique",
      imgSrc: "/img/flags/be.svg"
    },
    {
      dataBaseValue: "Burkina Faso",
      value: "Burkina Faso",
      label: "Burkina Faso",
      imgSrc: "/img/flags/bf.svg"
    },
    {
      dataBaseValue: "Bulgaria",
      value: "Bulgarie",
      label: "Bulgarie",
      imgSrc: "/img/flags/bg.svg"
    },
    {
      dataBaseValue: "Bahrain",
      value: "Bahreïn",
      label: "Bahreïn",
      imgSrc: "/img/flags/bh.svg"
    },
    {
      dataBaseValue: "Burundi",
      value: "Burundi",
      label: "Burundi",
      imgSrc: "/img/flags/bi.svg"
    },
    {
      dataBaseValue: "Benin",
      value: "Bénin",
      label: "Bénin",
      imgSrc: "/img/flags/bj.svg"
    },
    {
      dataBaseValue: "Saint Barthélemy",
      value: "Saint Barthélemy",
      label: "Saint Barthélemy",
      imgSrc: "/img/flags/bl.svg"
    },
    {
      dataBaseValue: "Bermuda",
      value: "Bermudes",
      label: "Bermudes",
      imgSrc: "/img/flags/bm.svg"
    },
    {
      dataBaseValue: "Brunei",
      value: "Brunéi",
      label: "Brunéi",
      imgSrc: "/img/flags/bn.svg"
    },
    {
      dataBaseValue: "Bolivia",
      value: "Bolivie",
      label: "Bolivie",
      imgSrc: "/img/flags/bo.svg"
    },
    {
      dataBaseValue: "Bonaire",
      value: "Bonaire",
      label: "Bonaire",
      imgSrc: "/img/flags/bq.svg"
    },
    {
      dataBaseValue: "Brazil",
      value: "Brésil",
      label: "Brésil",
      imgSrc: "/img/flags/br.svg"
    },
    {
      dataBaseValue: "Bahamas",
      value: "Bahamas",
      label: "Bahamas",
      imgSrc: "/img/flags/bs.svg"
    },
    {
      dataBaseValue: "Butane",
      value: "Butane",
      label: "Butane",
      imgSrc: "/img/flags/bt.svg"
    },
    {
      dataBaseValue: "Bouvet Island",
      value: "Île Bouvet",
      label: "Île Bouvet",
      imgSrc: "/img/flags/bv.svg"
    },
    {
      dataBaseValue: "Botswana",
      value: "Botswana",
      label: "Botswana",
      imgSrc: "/img/flags/bw.svg"
    },
    {
      dataBaseValue: "Belarus",
      value: "Biélorussie",
      label: "Biélorussie",
      imgSrc: "/img/flags/by.svg"
    },
    {
      dataBaseValue: "Belize",
      value: "Bélize",
      label: "Bélize",
      imgSrc: "/img/flags/bz.svg"
    },
    {
      dataBaseValue: "Canada",
      value: "Canada",
      label: "Canada",
      imgSrc: "/img/flags/ca.svg"
    },
    {
      dataBaseValue: "Coconut islands",
      value: "Coconut islands",
      label: "Coconut islands",
      imgSrc: "/img/flags/cc.svg"
    },
    {
      dataBaseValue: "DR Congo",
      value: "DR Congo",
      label: "DR Congo",
      imgSrc: "/img/flags/cd.svg"
    },
    {
      dataBaseValue: "Central African Republic",
      value: "République centrafricaine",
      label: "République centrafricaine",
      imgSrc: "/img/flags/cf.svg"
    },
    {
      dataBaseValue: "Congo",
      value: "Congo",
      label: "Congo",
      imgSrc: "/img/flags/cg.svg"
    },
    {
      dataBaseValue: "Switzerland",
      value: "Suisse",
      label: "Suisse",
      imgSrc: "/img/flags/ch.svg"
    },
    {
      dataBaseValue: "Ivory Coast",
      value: "Côte d'Ivoire",
      label: "Côte d'Ivoire",
      imgSrc: "/img/flags/ci.svg"
    },
    {
      dataBaseValue: "Cook Islands",
      value: "les Îles Cook",
      label: "les Îles Cook",
      imgSrc: "/img/flags/ck.svg"
    },
    {
      dataBaseValue: "Chile",
      value: "Chili",
      label: "Chili",
      imgSrc: "/img/flags/cl.svg"
    },
    {
      dataBaseValue: "Cameroon",
      value: "Cameroun",
      label: "Cameroun",
      imgSrc: "/img/flags/cm.svg"
    },
    {
      dataBaseValue: "China",
      value: "Chine",
      label: "Chine",
      imgSrc: "/img/flags/cn.svg"
    },
    {
      dataBaseValue: "Colombia",
      value: "Colombie",
      label: "Colombie",
      imgSrc: "/img/flags/co.svg"
    },
    {
      dataBaseValue: "Costa Rica",
      value: "Costa Rica",
      label: "Costa Rica",
      imgSrc: "/img/flags/cr.svg"
    },
    {
      dataBaseValue: "Cuba",
      value: "Cuba",
      label: "Cuba",
      imgSrc: "/img/flags/cu.svg"
    },
    {
      dataBaseValue: "Cape Verde",
      value: "Cap-Vert",
      label: "Cap-Vert",
      imgSrc: "/img/flags/cv.svg"
    },
    {
      dataBaseValue: "Curacao",
      value: "Curacao",
      label: "Curacao",
      imgSrc: "/img/flags/cw.svg"
    },
    {
      dataBaseValue: "Christmas Island",
      value: "L'île de noël",
      label: "L'île de noël",
      imgSrc: "/img/flags/cx.svg"
    },
    {
      dataBaseValue: "Cyprus",
      value: "Chypre",
      label: "Chypre",
      imgSrc: "/img/flags/cy.svg"
    },
    {
      dataBaseValue: "Czech Republic",
      value: "République Tchèque",
      label: "République Tchèque",
      imgSrc: "/img/flags/cz.svg"
    },
    {
      dataBaseValue: "Germany",
      value: "Allemagne",
      label: "Allemagne",
      imgSrc: "/img/flags/de.svg"
    },
    {
      dataBaseValue: "Djibouti",
      value: "Djibouti",
      label: "Djibouti",
      imgSrc: "/img/flags/dj.svg"
    },
    {
      dataBaseValue: "Denmark",
      value: "Danemark",
      label: "Danemark",
      imgSrc: "/img/flags/dk.svg"
    },
    {
      dataBaseValue: "Dominica",
      value: "Dominique",
      label: "Dominique",
      imgSrc: "/img/flags/dm.svg"
    },
    {
      dataBaseValue: "Dominican Republic",
      value: "République dominicaine",
      label: "République dominicaine",
      imgSrc: "/img/flags/do.svg"
    },
    {
      dataBaseValue: "Algeria",
      value: "Algérie",
      label: "Algérie",
      imgSrc: "/img/flags/dz.svg"
    },
    {
      dataBaseValue: "Ecuador",
      value: "Equateur",
      label: "Equateur",
      imgSrc: "/img/flags/ec.svg"
    },
    {
      dataBaseValue: "Estonia",
      value: "Estonie",
      label: "Estonie",
      imgSrc: "/img/flags/ee.svg"
    },
    {
      dataBaseValue: "Egypt",
      value: "Egypte",
      label: "Egypte",
      imgSrc: "/img/flags/eg.svg"
    },
    {
      dataBaseValue: "West Sahara",
      value: "Sahara occidental",
      label: "Sahara occidental",
      imgSrc: "/img/flags/eh.svg"
    },
    {
      dataBaseValue: "Eritrea",
      value: "Érythrée",
      label: "Érythrée",
      imgSrc: "/img/flags/er.svg"
    },
    {
      dataBaseValue: "Spain",
      value: "Espagne",
      label: "Espagne",
      imgSrc: "/img/flags/es.svg"
    },
    {
      dataBaseValue: "Ethiopia",
      value: "Ethiopie",
      label: "Ethiopie",
      imgSrc: "/img/flags/et.svg"
    },
    {
      dataBaseValue: "Europe",
      value: "L'Europe",
      label: "L'Europe",
      imgSrc: "/img/flags/eu.svg"
    },
    {
      dataBaseValue: "Finland",
      value: "Finlande",
      label: "Finlande",
      imgSrc: "/img/flags/fi.svg"
    },
    {
      dataBaseValue: "Netherlands",
      value: "Pays-Bas",
      label: "Pays-Bas",
      imgSrc: "/img/flags/nl.svg"
    },
    {
      dataBaseValue: "Fiji",
      value: "Fidji",
      label: "Fidji",
      imgSrc: "/img/flags/fj.svg"
    },
    {
      dataBaseValue: "Falkland Islands",
      value: "les îles Falkland",
      label: "les îles Falkland",
      imgSrc: "/img/flags/fk.svg"
    },
    {
      dataBaseValue: "Federated States of Micronesia",
      value: "États fédérés de Micronésie",
      label: "États fédérés de Micronésie",
      imgSrc: "/img/flags/fm.svg"
    },
    {
      dataBaseValue: "Faroe Islands",
      value: "Îles Féroé",
      label: "Îles Féroé",
      imgSrc: "/img/flags/fo.svg"
    },
    {
      dataBaseValue: "France",
      value: "France",
      label: "France",
      imgSrc: "/img/flags/fr.svg"
    },
    {
      dataBaseValue: "Georgia",
      value: "Géorgie",
      label: "Géorgie",
      imgSrc: "/img/flags/ge.svg"
    },
    // {
    //   dataBaseValue: "United Kingdom",
    //   value: "Royaume-Uni",
    //   label: "Royaume-Uni",
    //   imgSrc: "/img/flags/en.svg"
    // },
    {
      dataBaseValue: "Northern Ireland",
      value: "Irlande du Nord",
      label: "Irlande du Nord",
      imgSrc: "/img/flags/nir.svg"
    },
    {
      dataBaseValue: "Scotland",
      value: "Écosse",
      label: "Écosse",
      imgSrc: "/img/flags/sct.svg"
    },
    {
      dataBaseValue: "Wales",
      value: "Pays de Galles",
      label: "Pays de Galles",
      imgSrc: "/img/flags/wls.svg"
    },
    {
      dataBaseValue: "Grenada",
      value: "Grenada",
      label: "Grenada",
      imgSrc: "/img/flags/gd.svg"
    },
    {
      dataBaseValue: "French Guiana",
      value: "Guyane Française",
      label: "Guyane Française",
      imgSrc: "/img/flags/gf.svg"
    },
    {
      dataBaseValue: "Guernsey",
      value: "Guernesey",
      label: "Guernesey",
      imgSrc: "/img/flags/gg.svg"
    },
    {
      dataBaseValue: "Ghana",
      value: "Ghana",
      label: "Ghana",
      imgSrc: "/img/flags/gh.svg"
    },
    {
      dataBaseValue: "Gibraltar",
      value: "Gibraltar",
      label: "Gibraltar",
      imgSrc: "/img/flags/gi.svg"
    },
    {
      dataBaseValue: "Greenland",
      value: "Groenland",
      label: "Groenland",
      imgSrc: "/img/flags/gl.svg"
    },
    {
      dataBaseValue: "Gambia",
      value: "Gambie",
      label: "Gambie",
      imgSrc: "/img/flags/gm.svg"
    },
    {
      dataBaseValue: "Guinea",
      value: "Guinée",
      label: "Guinée",
      imgSrc: "/img/flags/gn.svg"
    },
    {
      dataBaseValue: "Guadeloupe",
      value: "Guadeloupe",
      label: "Guadeloupe",
      imgSrc: "/img/flags/gp.svg"
    },
    {
      dataBaseValue: "Equatorial Guinea",
      value: "Guinée Équatoriale",
      label: "Guinée Équatoriale",
      imgSrc: "/img/flags/gq.svg"
    },
    {
      dataBaseValue: "Greece",
      value: "Grèce",
      label: "Grèce",
      imgSrc: "/img/flags/gr.svg"
    },
    {
      dataBaseValue: "South Georgia and the South Sandwich Islands",
      value: "Géorgie du Sud et îles Sandwich du Sud",
      label: "Géorgie du Sud et îles Sandwich du Sud",
      imgSrc: "/img/flags/gs.svg"
    },
    {
      dataBaseValue: "Guatemala",
      value: "Guatemala",
      label: "Guatemala",
      imgSrc: "/img/flags/gt.svg"
    },
    {
      dataBaseValue: "Guam",
      value: "Guam",
      label: "Guam",
      imgSrc: "/img/flags/gu.svg"
    },
    {
      dataBaseValue: "Guinea-Bissau",
      value: "Guinée-Bissau",
      label: "Guinée-Bissau",
      imgSrc: "/img/flags/gw.svg"
    },
    {
      dataBaseValue: "Guyana",
      value: "Guyane",
      label: "Guyane",
      imgSrc: "/img/flags/gy.svg"
    },
    {
      dataBaseValue: "Hong Kong",
      value: "Hong Kong",
      label: "Hong Kong",
      imgSrc: "/img/flags/hk.svg"
    },
    {
      dataBaseValue: "Honduras",
      value: "Honduras",
      label: "Honduras",
      imgSrc: "/img/flags/hn.svg"
    },
    {
      dataBaseValue: "Croatia",
      value: "Croatie",
      label: "Croatie",
      imgSrc: "/img/flags/hr.svg"
    },
    {
      dataBaseValue: "Haiti",
      value: "Haïti",
      label: "Haïti",
      imgSrc: "/img/flags/ht.svg"
    },
    {
      dataBaseValue: "Hungary",
      value: "Hongrie",
      label: "Hongrie",
      imgSrc: "/img/flags/hu.svg"
    },
    {
      dataBaseValue: "Indonesia",
      value: "Indonésie",
      label: "Indonésie",
      imgSrc: "/img/flags/id.svg"
    },
    {
      dataBaseValue: "Ireland",
      value: "Irlande",
      label: "Irlande",
      imgSrc: "/img/flags/ie.svg"
    },
    {
      dataBaseValue: "Israel",
      value: "Israël",
      label: "Israël",
      imgSrc: "/img/flags/il.svg"
    },
    {
      dataBaseValue: "Isle of Man",
      value: "île de Man",
      label: "île de Man",
      imgSrc: "/img/flags/im.svg"
    },
    {
      dataBaseValue: "India",
      value: "Inde",
      label: "Inde",
      imgSrc: "/img/flags/in.svg"
    },
    {
      dataBaseValue: "British Indian Ocean Territory",
      value: "Territoire britannique de l'océan Indien",
      label: "Territoire britannique de l'océan Indien",
      imgSrc: "/img/flags/io.svg"
    },
    {
      dataBaseValue: "Iraq",
      value: "Irak",
      label: "Irak",
      imgSrc: "/img/flags/iq.svg"
    },
    {
      dataBaseValue: "Iran",
      value: "Iran",
      label: "Iran",
      imgSrc: "/img/flags/ir.svg"
    },
    {
      dataBaseValue: "Italy",
      value: "Italie",
      label: "Italie",
      imgSrc: "/img/flags/it.svg"
    },
    {
      dataBaseValue: "Jersey",
      value: "Jersey",
      label: "Jersey",
      imgSrc: "/img/flags/je.svg"
    },
    {
      dataBaseValue: "Jamaica",
      value: "Jamaïque",
      label: "Jamaïque",
      imgSrc: "/img/flags/jm.svg"
    },
    {
      dataBaseValue: "Jordan",
      value: "Jordan",
      label: "Jordan",
      imgSrc: "/img/flags/jo.svg"
    },
    {
      dataBaseValue: "Japan",
      value: "Japon",
      label: "Japon",
      imgSrc: "/img/flags/jp.svg"
    },
    {
      dataBaseValue: "Kenya",
      value: "Kenya",
      label: "Kenya",
      imgSrc: "/img/flags/ke.svg"
    },
    {
      dataBaseValue: "Kyrgyzstan",
      value: "Kirghizistan",
      label: "Kirghizistan",
      imgSrc: "/img/flags/kg.svg"
    },
    {
      dataBaseValue: "Cambodia",
      value: "Cambodge",
      label: "Cambodge",
      imgSrc: "/img/flags/kh.svg"
    },
    {
      dataBaseValue: "Kiribati",
      value: "Kiribati",
      label: "Kiribati",
      imgSrc: "/img/flags/ki.svg"
    },
    {
      dataBaseValue: "Comoros",
      value: "Comores",
      label: "Comores",
      imgSrc: "/img/flags/km.svg"
    },
    {
      dataBaseValue: "Saint Kitts and Nevis",
      value: "Saint-Christophe-et-Niévès",
      label: "Saint-Christophe-et-Niévès",
      imgSrc: "/img/flags/kn.svg"
    },
    {
      dataBaseValue: "Democratic People's Republic of Korea",
      value: "République Populaire Démocratique de Corée",
      label: "République Populaire Démocratique de Corée",
      imgSrc: "/img/flags/kp.svg"
    },
    {
      dataBaseValue: "South Korea",
      value: "Corée du Sud",
      label: "Corée du Sud",
      imgSrc: "/img/flags/kr.svg"
    },
    {
      dataBaseValue: "Kuwait",
      value: "Koweit",
      label: "Koweit",
      imgSrc: "/img/flags/kw.svg"
    },
    {
      dataBaseValue: "Cayman Islands",
      value: "Îles Caïmans",
      label: "Îles Caïmans",
      imgSrc: "/img/flags/ky.svg"
    },
    {
      dataBaseValue: "Kazakhstan",
      value: "Kazakhstan",
      label: "Kazakhstan",
      imgSrc: "/img/flags/kz.svg"
    },
    {
      dataBaseValue: "Laos",
      value: "Laos",
      label: "Laos",
      imgSrc: "/img/flags/la.svg"
    },
    {
      dataBaseValue: "Lebanon",
      value: "Liban",
      label: "Liban",
      imgSrc: "/img/flags/lb.svg"
    },
    {
      dataBaseValue: "Saint Lucia",
      value: "Sainte-Lucie",
      label: "Sainte-Lucie",
      imgSrc: "/img/flags/lc.svg"
    },
    {
      dataBaseValue: "Liechtenstein",
      value: "Liechtenstein",
      label: "Liechtenstein",
      imgSrc: "/img/flags/li.svg"
    },
    {
      dataBaseValue: "Sri Lanka",
      value: "Sri Lanka",
      label: "Sri Lanka",
      imgSrc: "/img/flags/lk.svg"
    },
    {
      dataBaseValue: "Liberia",
      value: "Libéria",
      label: "Libéria",
      imgSrc: "/img/flags/lr.svg"
    },
    {
      dataBaseValue: "Lesotho",
      value: "Lesotho",
      label: "Lesotho",
      imgSrc: "/img/flags/ls.svg"
    },
    {
      dataBaseValue: "Lithuania",
      value: "Lituanie",
      label: "Lituanie",
      imgSrc: "/img/flags/lt.svg"
    },
    {
      dataBaseValue: "Luxembourg",
      value: "Luxembourg",
      label: "Luxembourg",
      imgSrc: "/img/flags/lu.svg"
    },
    {
      dataBaseValue: "Latvia",
      value: "Lettonie",
      label: "Lettonie",
      imgSrc: "/img/flags/lv.svg"
    },
    {
      dataBaseValue: "Libya",
      value: "Libye",
      label: "Libye",
      imgSrc: "/img/flags/ly.svg"
    },
    {
      dataBaseValue: "Morocco",
      value: "Maroc",
      label: "Maroc",
      imgSrc: "/img/flags/ma.svg"
    },
    {
      dataBaseValue: "Monaco",
      value: "Monaco",
      label: "Monaco",
      imgSrc: "/img/flags/mc.svg"
    },
    {
      dataBaseValue: "Moldova",
      value: "Moldavie",
      label: "Moldavie",
      imgSrc: "/img/flags/md.svg"
    },
    {
      dataBaseValue: "Montenegro",
      value: "Monténégro",
      label: "Monténégro",
      imgSrc: "/img/flags/me.svg"
    },
    {
      dataBaseValue: "Saint Martin",
      value: "Saint Martin",
      label: "Saint Martin",
      imgSrc: "/img/flags/mf.svg"
    },
    {
      dataBaseValue: "Madagascar",
      value: "Madagascar",
      label: "Madagascar",
      imgSrc: "/img/flags/mg.svg"
    },
    {
      dataBaseValue: "Marshall Islands",
      value: "Marshall Islands",
      label: "Marshall Islands",
      imgSrc: "/img/flags/mh.svg"
    },
    {
      dataBaseValue: "Macedonia",
      value: "Macédoine",
      label: "Macédoine",
      imgSrc: "/img/flags/mk.svg"
    },
    {
      dataBaseValue: "Mali",
      value: "Mali",
      label: "Mali",
      imgSrc: "/img/flags/ml.svg"
    },
    {
      dataBaseValue: "Myanmar",
      value: "Birmanie",
      label: "Birmanie",
      imgSrc: "/img/flags/mm.svg"
    },
    {
      dataBaseValue: "Mongolia",
      value: "Mongolie",
      label: "Mongolie",
      imgSrc: "/img/flags/mn.svg"
    },
    {
      dataBaseValue: "Macau",
      value: "Macao",
      label: "Macao",
      imgSrc: "/img/flags/mo.svg"
    },
    {
      dataBaseValue: "Northern Mariana Islands",
      value: "Îles Mariannes du Nord",
      label: "Îles Mariannes du Nord",
      imgSrc: "/img/flags/mp.svg"
    },
    {
      dataBaseValue: "Martinique",
      value: "Martinique",
      label: "Martinique",
      imgSrc: "/img/flags/mq.svg"
    },
    {
      dataBaseValue: "Mauritius",
      value: "Maurice",
      label: "Maurice",
      imgSrc: "/img/flags/mr.svg"
    },
    {
      dataBaseValue: "Montserrat",
      value: "Montserrat",
      label: "Montserrat",
      imgSrc: "/img/flags/ms.svg"
    },
    {
      dataBaseValue: "Malta",
      value: "Malte",
      label: "Malte",
      imgSrc: "/img/flags/mt.svg"
    },
    {
      dataBaseValue: "O. Mauritius",
      value: "O. Maurice",
      label: "O. Maurice",
      imgSrc: "/img/flags/mu.svg"
    },
    {
      dataBaseValue: "Maldives",
      value: "Maldives",
      label: "Maldives",
      imgSrc: "/img/flags/mv.svg"
    },
    {
      dataBaseValue: "Malawi",
      value: "Malawi",
      label: "Malawi",
      imgSrc: "/img/flags/mw.svg"
    },
    {
      dataBaseValue: "Mexico",
      value: "Mexique",
      label: "Mexique",
      imgSrc: "/img/flags/mx.svg"
    },
    {
      dataBaseValue: "Malaysia",
      value: "Malaisie",
      label: "Malaisie",
      imgSrc: "/img/flags/my.svg"
    },
    {
      dataBaseValue: "Mozambique",
      value: "Mozambique",
      label: "Mozambique",
      imgSrc: "/img/flags/mz.svg"
    },
    {
      dataBaseValue: "Namibie",
      value: "Namibie",
      label: "Namibie",
      imgSrc: "/img/flags/na.svg"
    },
    {
      dataBaseValue: "New Caledonia",
      value: "Nouvelle Calédonie",
      label: "Nouvelle Calédonie",
      imgSrc: "/img/flags/nc.svg"
    },
    {
      dataBaseValue: "Niger",
      value: "Niger",
      label: "Niger",
      imgSrc: "/img/flags/ne.svg"
    },
    {
      dataBaseValue: "Norfolk Island",
      value: "Norfolk Island",
      label: "Norfolk Island",
      imgSrc: "/img/flags/nf.svg"
    },
    {
      dataBaseValue: "Nigeria",
      value: "Nigeria",
      label: "Nigeria",
      imgSrc: "/img/flags/ng.svg"
    },
    {
      dataBaseValue: "Nicaragua",
      value: "Nicaragua",
      label: "Nicaragua",
      imgSrc: "/img/flags/ni.svg"
    },
    {
      dataBaseValue: "Norway",
      value: "Norvège",
      label: "Norvège",
      imgSrc: "/img/flags/no.svg"
    },
    {
      dataBaseValue: "Nepal",
      value: "Népal",
      label: "Népal",
      imgSrc: "/img/flags/np.svg"
    },
    {
      dataBaseValue: "Nauru",
      value: "Nauru",
      label: "Nauru",
      imgSrc: "/img/flags/nr.svg"
    },
    {
      dataBaseValue: "Niue",
      value: "Niué",
      label: "Niué",
      imgSrc: "/img/flags/nu.svg"
    },
    {
      dataBaseValue: "New Zealand",
      value: "Nouvelle-Zélande",
      label: "Nouvelle-Zélande",
      imgSrc: "/img/flags/nz.svg"
    },
    {
      dataBaseValue: "Oman",
      value: "Oman",
      label: "Oman",
      imgSrc: "/img/flags/om.svg"
    },
    {
      dataBaseValue: "Panama",
      value: "Panama",
      label: "Panama",
      imgSrc: "/img/flags/pa.svg"
    },
    {
      dataBaseValue: "Peru",
      value: "Pérou",
      label: "Pérou",
      imgSrc: "/img/flags/pe.svg"
    },
    {
      dataBaseValue: "French polynesia",
      value: "Polynésie française",
      label: "Polynésie française",
      imgSrc: "/img/flags/pf.svg"
    },
    {
      dataBaseValue: "Papua New Guinea",
      value: "Papouasie Nouvelle Guinée",
      label: "Papouasie Nouvelle Guinée",
      imgSrc: "/img/flags/pg.svg"
    },
    {
      dataBaseValue: "Philippines",
      value: "Philippines",
      label: "Philippines",
      imgSrc: "/img/flags/ph.svg"
    },
    {
      dataBaseValue: "Pakistan",
      value: "Pakistan",
      label: "Pakistan",
      imgSrc: "/img/flags/pk.svg"
    },
    {
      dataBaseValue: "Poland",
      value: "Pologne",
      label: "Pologne",
      imgSrc: "/img/flags/pl.svg"
    },
    {
      dataBaseValue: "Saint Pierre and Miquelon",
      value: "Saint-Pierre-et-Miquelon",
      label: "Saint-Pierre-et-Miquelon",
      imgSrc: "/img/flags/pm.svg"
    },
    {
      dataBaseValue: "Pitcairn Islands",
      value: "Îles Pitcairn",
      label: "Îles Pitcairn",
      imgSrc: "/img/flags/pn.svg"
    },
    {
      dataBaseValue: "Puerto Rico",
      value: "Porto Rico",
      label: "Porto Rico",
      imgSrc: "/img/flags/pr.svg"
    },
    {
      dataBaseValue: "Palestine",
      value: "Palestine",
      label: "Palestine",
      imgSrc: "/img/flags/ps.svg"
    },
    {
      dataBaseValue: "Portugal",
      value: "le Portugal",
      label: "le Portugal",
      imgSrc: "/img/flags/pt.svg"
    },
    {
      dataBaseValue: "Paraguay",
      value: "Paraguay",
      label: "Paraguay",
      imgSrc: "/img/flags/py.svg"
    },
    {
      dataBaseValue: "Qatar",
      value: "Qatar",
      label: "Qatar",
      imgSrc: "/img/flags/qa.svg"
    },
    {
      dataBaseValue: "Reunion",
      value: "Réunion",
      label: "Réunion",
      imgSrc: "/img/flags/re.svg"
    },
    {
      dataBaseValue: "Romania",
      value: "Roumanie",
      label: "Roumanie",
      imgSrc: "/img/flags/ro.svg"
    },
    {
      dataBaseValue: "Serbia",
      value: "Serbie",
      label: "Serbie",
      imgSrc: "/img/flags/rs.svg"
    },
    {
      dataBaseValue: "Russia",
      value: "Fédération Russe",
      label: "Fédération Russe",
      imgSrc: "/img/flags/ru.svg"
    },
    {
      dataBaseValue: "Rwanda",
      value: "Rwanda",
      label: "Rwanda",
      imgSrc: "/img/flags/rw.svg"
    },
    {
      dataBaseValue: "South Africa",
      value: "Afrique du Sud",
      label: "Afrique du Sud",
      imgSrc: "/img/flags/sa.svg"
    },
    {
      dataBaseValue: "Solomon Islands",
      value: "îles Salomon",
      label: "îles Salomon",
      imgSrc: "/img/flags/sb.svg"
    },
    {
      dataBaseValue: "Seychelles",
      value: "les Seychelles",
      label: "les Seychelles",
      imgSrc: "/img/flags/sc.svg"
    },
    {
      dataBaseValue: "Sudan",
      value: "Soudan",
      label: "Soudan",
      imgSrc: "/img/flags/sd.svg"
    },
    {
      dataBaseValue: "Sweden",
      value: "Suède",
      label: "Suède",
      imgSrc: "/img/flags/se.svg"
    },
    {
      dataBaseValue: "Singapore",
      value: "Singapour",
      label: "Singapour",
      imgSrc: "/img/flags/sg.svg"
    },
    {
      dataBaseValue: "O. St. Helena",
      value: "O. St. Helena",
      label: "O. St. Helena",
      imgSrc: "/img/flags/sh.svg"
    },
    {
      dataBaseValue: "Slovenia",
      value: "Slovénie",
      label: "Slovénie",
      imgSrc: "/img/flags/si.svg"
    },
    {
      dataBaseValue: "Svalbard",
      value: "Svalbard",
      label: "Svalbard",
      imgSrc: "/img/flags/sj.svg"
    },
    {
      dataBaseValue: "Slovakia",
      value: "Slovaquie",
      label: "Slovaquie",
      imgSrc: "/img/flags/sk.svg"
    },
    {
      dataBaseValue: "Sierra Leone",
      value: "Sierra Leone",
      label: "Sierra Leone",
      imgSrc: "/img/flags/sl.svg"
    },
    {
      dataBaseValue: "San Marino",
      value: "Saint Marin",
      label: "Saint Marin",
      imgSrc: "/img/flags/sm.svg"
    },
    {
      dataBaseValue: "Senegal",
      value: "Sénégal",
      label: "Sénégal",
      imgSrc: "/img/flags/sn.svg"
    },
    {
      dataBaseValue: "Somalia",
      value: "Somalie",
      label: "Somalie",
      imgSrc: "/img/flags/so.svg"
    },
    {
      dataBaseValue: "Suriname",
      value: "Suriname",
      label: "Suriname",
      imgSrc: "/img/flags/sr.svg"
    },
    {
      dataBaseValue: "South Sudan",
      value: "Soudan du sud",
      label: "Soudan du sud",
      imgSrc: "/img/flags/ss.svg"
    },
    {
      dataBaseValue: "Sao Tome and Principe",
      value: "Sao Tomé et Principe",
      label: "Sao Tomé et Principe",
      imgSrc: "/img/flags/st.svg"
    },
    {
      dataBaseValue: "Salvador",
      value: "Salvador",
      label: "Salvador",
      imgSrc: "/img/flags/sv.svg"
    },
    {
      dataBaseValue: "Sint Maarten",
      value: "Saint-Martin",
      label: "Saint-Martin",
      imgSrc: "/img/flags/sx.svg"
    },
    {
      dataBaseValue: "Syria",
      value: "Syrie",
      label: "Syrie",
      imgSrc: "/img/flags/sy.svg"
    },
    {
      dataBaseValue: "Eswatini",
      value: "Eswatini",
      label: "Eswatini",
      imgSrc: "/img/flags/sz.svg"
    },
    {
      dataBaseValue: "Turks and Caicos Islands",
      value: "îles Turques-et-Caïques",
      label: "îles Turques-et-Caïques",
      imgSrc: "/img/flags/tc.svg"
    },
    {
      dataBaseValue: "Chad",
      value: "Tchad",
      label: "Tchad",
      imgSrc: "/img/flags/td.svg"
    },
    {
      dataBaseValue: "Togo",
      value: "Aller",
      label: "Aller",
      imgSrc: "/img/flags/tg.svg"
    },
    {
      dataBaseValue: "Thailand",
      value: "Thaïlande",
      label: "Thaïlande",
      imgSrc: "/img/flags/th.svg"
    },
    {
      dataBaseValue: "Tajikistan",
      value: "Tadjikistan",
      label: "Tadjikistan",
      imgSrc: "/img/flags/tj.svg"
    },
    {
      dataBaseValue: "Tokelau",
      value: "Tokélaou",
      label: "Tokélaou",
      imgSrc: "/img/flags/tk.svg"
    },
    {
      dataBaseValue: "East Timor",
      value: "Timor oriental",
      label: "Timor oriental",
      imgSrc: "/img/flags/tl.svg"
    },
    {
      dataBaseValue: "Turkmenistan",
      value: "Turkménistan",
      label: "Turkménistan",
      imgSrc: "/img/flags/tm.svg"
    },
    {
      dataBaseValue: "Tunisia",
      value: "Tunisie",
      label: "Tunisie",
      imgSrc: "/img/flags/tn.svg"
    },
    {
      dataBaseValue: "Turkey",
      value: "Turquie",
      label: "Turquie",
      imgSrc: "/img/flags/tr.svg"
    },
    {
      dataBaseValue: "Trinidad and Tobago",
      value: "Trinité-et-Tobago",
      label: "Trinité-et-Tobago",
      imgSrc: "/img/flags/tt.svg"
    },
    {
      dataBaseValue: "Tuvalu",
      value: "Tuvalu",
      label: "Tuvalu",
      imgSrc: "/img/flags/tv.svg"
    },
    {
      dataBaseValue: "Taiwan",
      value: "Taïwan",
      label: "Taïwan",
      imgSrc: "/img/flags/tw.svg"
    },
    {
      dataBaseValue: "Tanzania",
      value: "Tanzanie",
      label: "Tanzanie",
      imgSrc: "/img/flags/tz.svg"
    },
    {
      dataBaseValue: "Ukraine",
      value: "Ukraine",
      label: "Ukraine",
      imgSrc: "/img/flags/ua.svg"
    },
    {
      dataBaseValue: "Uganda",
      value: "Uganda",
      label: "Uganda",
      imgSrc: "/img/flags/ug.svg"
    },
    {
      dataBaseValue: "Minor Outlying Islands (USA)",
      value: "Îles mineures éloignées (États-Unis)",
      label: "Îles mineures éloignées (États-Unis)",
      imgSrc: "/img/flags/um.svg"
    },
    {
      dataBaseValue: "United States",
      value: "les états-unis d'Amérique",
      label: "les états-unis d'Amérique",
      imgSrc: "/img/flags/us.svg"
    },
    {
      dataBaseValue: "United Kingdom",
      value: "Royaume-Uni",
      label: "Royaume-Uni",
      imgSrc: "/img/flags/uk.svg"
    },
    {
      dataBaseValue: "Uruguay",
      value: "Uruguay",
      label: "Uruguay",
      imgSrc: "/img/flags/uy.svg"
    },
    {
      dataBaseValue: "Uzbekistan",
      value: "Ouzbékistan",
      label: "Ouzbékistan",
      imgSrc: "/img/flags/uz.svg"
    },
    {
      dataBaseValue: "Vatican City State",
      value: "État de la Cité du Vatican",
      label: "État de la Cité du Vatican",
      imgSrc: "/img/flags/va.svg"
    },
    {
      dataBaseValue: "Saint Vincent and the Grenadines",
      value: "Saint-Vincent-et-les-Grenadines",
      label: "Saint-Vincent-et-les-Grenadines",
      imgSrc: "/img/flags/vc.svg"
    },
    {
      dataBaseValue: "Venezuela",
      value: "Venezuela",
      label: "Venezuela",
      imgSrc: "/img/flags/ve.svg"
    },
    {
      dataBaseValue: "British Virgin Islands",
      value: "Îles Vierges britanniques",
      label: "Îles Vierges britanniques",
      imgSrc: "/img/flags/vg.svg"
    },
    {
      dataBaseValue: "US Virgin Islands",
      value: "Îles Vierges américaines",
      label: "Îles Vierges américaines",
      imgSrc: "/img/flags/vi.svg"
    },
    {
      dataBaseValue: "Vietnam",
      value: "Viêt Nam",
      label: "Viêt Nam",
      imgSrc: "/img/flags/vn.svg"
    },
    {
      dataBaseValue: "Vanuatu",
      value: "Vanuatu",
      label: "Vanuatu",
      imgSrc: "/img/flags/vu.svg"
    },
    {
      dataBaseValue: "Wallis and Futuna Islands",
      value: "Iles Wallis et Futuna",
      label: "Iles Wallis et Futuna",
      imgSrc: "/img/flags/wf.svg"
    },
    {
      dataBaseValue: "Samoa",
      value: "Samoa",
      label: "Samoa",
      imgSrc: "/img/flags/ws.svg"
    },
    {
      dataBaseValue: "Kosovo",
      value: "Kosovo",
      label: "Kosovo",
      imgSrc: "/img/flags/xk.svg"
    },
    {
      dataBaseValue: "Yemen",
      value: "Yémen",
      label: "Yémen",
      imgSrc: "/img/flags/ye.svg"
    },
    {
      dataBaseValue: "Mayotte",
      value: "Mayotte",
      label: "Mayotte",
      imgSrc: "/img/flags/yt.svg"
    },
    {
      dataBaseValue: "South Africa",
      value: "Afrique du Sud",
      label: "Afrique du Sud",
      imgSrc: "/img/flags/za.svg"
    },
    {
      dataBaseValue: "Zambia",
      value: "Zambie",
      label: "Zambie",
      imgSrc: "/img/flags/zm.svg"
    },
    {
      dataBaseValue: "Zimbabwe",
      value: "Zimbabwe",
      label: "Zimbabwe",
      imgSrc: "/img/flags/zw.svg"
    }
  ],
  filterProxyItems: [
    {
      id: 1,
      value: "Format IP:PORT",
      regExp: /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(\s{0,}|:)\d{1,5}/,
      divider: [":"]
    },
    // {
    //   id: 2,
    //   value: "Format KeyCollector",
    //   regExp:
    //     /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(\s{0,}|:)\d{1,5}(\s{0,}|@)\S+(\s{0,}|:)\S+/,
    //   divider: [":", "@"]
    // },
    {
      id: 3,
      value: "Format IP:PORT@Login:Password",
      regExp:
        /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(\s{0,}|:)\d{1,5}(\s{0,}|@)\S+(\s{0,}|:)\S+/,
      divider: [":", "@"]
    },
    {
      id: 4,
      value: "Format IP.PORT:Login:Password",
      regExp:
        /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(\s{0,}|:)\d{1,5}(\s{0,}|:)\S+(\s{0,}|:)\S+/,
      divider: [":"]
    }
  ],
  quantity: [
    { label: "10 pièces.", value: 10 },
    { label: "15 pièces.", value: 15 },
    { label: "20 pièces.", value: 20 },
    { label: "50 pièces.", value: 50 }
  ],
  regions: [
    { value: "MOSCOU", label: "Moscou" },
    { value: "KIEV", label: "Kiev" },
    { value: "ST. PETERSBOURG", label: "Saint-Pétersbourg" }
  ]
};
