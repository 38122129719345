export const mobileInstrumentsNavigation = [
  {
    route: "proxy-checker",
    imgSrc: "/img/ui/check.svg",
    translation: "proxyChecker"
  },
  {
    route: "my-ip",
    imgSrc: "/img/ui/my-ip.svg",
    translation: "myIp"
  },
  {
    route: "skaner-portov",
    imgSrc: "/img/ui/scaner.svg",
    translation: "checkPorts"
  },
  {
    route: "ping-ip",
    imgSrc: "/img/ui/ping.svg",
    translation: "pingIp"
  },
  {
    route: "anonymity-check",
    imgSrc: "/img/ui/vendetta.svg",
    translation: "anonimityCheck"
  },
  {
    route: "generator-utm",
    imgSrc: "/img/ui/utm.png",
    translation: "utm"
  },
  {
    route: "speed",
    imgSrc: "/img/ui/speed.png",
    translation: "speed"
  },
  {
    route: "ipv6-support",
    imgSrc: "/img/ui/ipv6-support.png",
    translation: "ipv6Check"
  },
  {
    route: "ip-in-spam-databases",
    imgSrc: "/img/ui/spam-base.png",
    translation: "spamBase"
  },
  {
    route: "online-proxy-filter",
    imgSrc: "/img/ui/proxy-filtr.png",
    translation: "filterProxy"
  },
  {
    route: "trace",
    imgSrc: "/img/ui/trace-ip.png",
    translation: "traceIp"
  },
  {
    route: "web-rtc-check",
    imgSrc: "/img/ui/webrtc.svg",
    translation: "webRtcChecker"
  },
  {
    route: "dns-check",
    imgSrc: "/img/ui/DNS-icon.svg",
    translation: "dnsChecker"
  },
  {
    route: "fingerprint",
    imgSrc: "/img/ui/fingerprint.svg",
    translation: "fingerPrint"
  },
  {
    route: "evercookie",
    imgSrc: "/img/ui/cookie.svg",
    translation: "evercookie"
  },
  {
    route: "http-headers",
    imgSrc: "/img/ui/http.svg",
    translation: "httpHeaders"
  },
  {
    route: "browser-info",
    imgSrc: "/img/ui/browser.svg",
    translation: "browserInfo"
  }
];
