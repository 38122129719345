import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { isSSR } from "../utils/helpers";

import { useMatchRoute } from "./useMatchRoute";
import { useTranslation } from "./useTranslation";

export const useMetaDate = () => {
  const [currentSeo, setCurrentSeo] = useState({});

  const tag = useMatchRoute()?.tag;
  const { lang, filter } = useParams();
  const t = useTranslation();
  const { content, countryTags } = useSelector((state) => state.content);
  const { data, isDataLoaded } = useSelector((state) => state.content.country);

  const locale = lang || "ru";
  const pageTag = filter || tag || "home";

  useEffect(() => {
    const contentMeta = content?.store?.[pageTag]?.[locale]?.metaDate;

    if (
      contentMeta &&
      countryTags.isDataLoaded &&
      Object.entries(contentMeta).length !== 0
    ) {
      setCurrentSeo((prevState) => ({
        ...prevState,
        ...contentMeta,
        countryTags: countryTags.data
      }));
    } else {
      const countryContent = data?.content?.find(
        ({ languageCode }) => languageCode === locale.toUpperCase()
      );

      if (countryContent) {
        setCurrentSeo((prevState) => ({
          ...prevState,
          title: countryContent.htmlTitle,
          description: countryContent.htmlDescription,
          countryTags: countryTags.data
        }));
      }
    }
  }, [
    content.store,
    countryTags.data,
    countryTags.isDataLoaded,
    data.content,
    locale,
    pageTag
  ]);

  // Handling error
  useEffect(() => {
    if (content.isFetchError) {
      toast.error(t.errorTranslation.apiError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content.isFetchError]);

  if (isSSR()) {
    if (
      isDataLoaded &&
      data?.content?.find((el) => el.languageCode === locale.toUpperCase()) &&
      countryTags.isDataLoaded
    ) {
      const meta = data.content.find(
        (el) => el.languageCode === locale.toUpperCase()
      );

      return {
        title: meta?.htmlTitle,
        description: meta?.htmlDescription,
        lang: locale,
        countryTags: countryTags?.data
      };
    } else if (content.store?.[pageTag]?.[locale]?.metaDate) {
      return {
        ...content.store?.[pageTag]?.[locale]?.metaDate,
        lang: locale,
        countryTags: countryTags.data
      };
    }
  }

  return { ...currentSeo, lang };
};
