import styled from "@emotion/styled";
import { Link as NavLink } from "react-router-dom";

export const StyledLink = styled(NavLink)`
  overflow: hidden;
  white-space: nowrap;
  text-decoration: none;
  text-overflow: ellipsis;
  color: rgba(19, 20, 48, 0.8);
  cursor: pointer;
`;

export const Tooltip = styled("div")`
  position: absolute;
  top: -25px;
  left: 300px;
`;

export const TooltipText = styled("p")`
  padding: 7px 16px;
  font-size: 16px;
  border-radius: 4px;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  color: #131430;
`;

export const CountryName = styled("p")`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;

  font-size: 14px;

  line-height: 1.5;
`;

export const CheckMark = styled("div")`
  position: absolute;
  bottom: -8px;
  left: 20px;
  width: 0;
  height: 0;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  border-bottom: 10px solid #fff;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
  transform: rotate(180deg);
`;

export const Table = styled.div`
  min-width: 800px;
`;

export const TableHead = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  align-items: center;

  width: 100%;
  height: 47px;
  padding-left: 8px;
  padding-right: 8px;

  flex-shrink: 0;

  border-radius: 4px 4px 0 0;
  border: 2px solid rgba(19, 20, 48, 0.65);

  background: #131430;
`;

export const TableHeadText = styled.p`
  color: #fff;

  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
`;

export const TableRow = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  align-items: center;
  min-height: 60px;
  padding-left: 8px;
  padding-right: 8px;

  border-bottom: 1px solid #ececec;
`;

export const ProxyText = styled.p`
  font-size: 14px;
  color: rgba(19, 20, 48, 0.8);

  line-height: 1.5;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

export const NoProxiesData = styled.p`
  margin-top: 20px;
  text-align: center;
`;

export const ProxyCountry = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;

  text-overflow: ellipsis;

  div {
    //overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  img {
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }
`;

export const Link = styled(NavLink)`
  color: rgba(19, 20, 48, 0.8);
  text-decoration: none;
`;

export const RefreshProxyButton = styled.button`
  display: block;
  margin-left: 6px;
  animation-name: ${({ animated }) => (animated ? "rotate" : "none")};
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-play-state: ${({ animated }) => (animated ? "running" : "paused")};
  border: none;
  background-color: transparent;

  img {
    width: 30px;
    height: 30px;
  }

  &:hover,
  &:focus {
    animation-play-state: running;
  }
`;

export const ProxyCopy = styled.div`
  position: relative;
`;

export const ProxyIpText = styled.p``;

export const ProxyCopyIp = styled.div`
  position: absolute;
  left: 20%;
  bottom: calc(100% + 10px);
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 7px 16px;
  width: 230px;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  color: #131430;
  background-color: #fff;

  cursor: pointer;

  img {
    margin-right: 13px;
    width: 16px;
    height: 16px;
  }

  ::before {
    content: "";
    position: absolute;
    left: 20px;
    top: 100%;
    border-style: solid;
    border-width: 6px 4px 0 4px;
    border-color: #fff transparent transparent transparent;
    width: 0;
    height: 0;
  }

  //:hover,
  //:focus {
  //  opacity: 1;
  //  visibility: visible;
  //  transition: opacity 0.3ms, visibility 0.3ms;
  //}
`;
