import { Link, useParams } from "react-router-dom";

import { useTranslation } from "../../../../../../../hooks";

export const Navigation = () => {
  // **Props
  const { lang } = useParams();

  const t = useTranslation();

  return (
    <nav className="header-top__nav">
      <ul className="header-top__nav-list">
        <li className="header-top__nav-item">
          <Link to={`/${lang}/`}>{t?.navigation?.freeProxy}</Link>
        </li>
        <li className="header-top__nav-item">
          <a href="https://proxy-sale.com/" target="_blank" rel="noreferrer">
            {t?.navigation?.personalProxy}
          </a>
        </li>
      </ul>
    </nav>
  );
};
