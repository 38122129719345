import { useParams } from "react-router-dom";

import { countriesConstant } from "../utils/constants";

export const useCountryName = () => {
  const { lang, filter } = useParams();

  if (!filter) return;

  const findCountry = countriesConstant.find(({ path }) => path === filter);

  if (!findCountry) return;

  return findCountry[lang];
};
