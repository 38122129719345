import { forwardRef } from "react";

import { useParams } from "react-router-dom";

import { Container, Logo } from "../../../../ui";

import { HeaderTopLayout } from "./HeaderTop.styled";
import { Burger } from "./components/Burger/Burger";
import { LangDropdown } from "./components/LangDropdown/LangDropdown";
import { Navigation } from "./components/Navigation/Navigation";

import "./HeaderTop.scss";

export const HeaderTop = forwardRef((props, ref) => {
  const { lang } = useParams();

  return (
    <HeaderTopLayout ref={ref}>
      <Container>
        <div className="header-top__wrapper">
          <Logo
            imgSrc={
              lang === "ru" ? "/img/ui/logo-ru.svg" : "/img/ui/logo-en.svg"
            }
          />
          <Navigation />
          <LangDropdown />
          <Burger />
        </div>
      </Container>
    </HeaderTopLayout>
  );
});
