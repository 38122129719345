import { useEffect, useState } from "react";

import { useTranslation } from "../../../hooks";
import { Container } from "../../ui";

import { FaqItem } from "./components/FaqItem/FaqItem";

import "./Faq.scss";

export const Faq = ({ children, faqItems }) => {
  const [newFaqItems, setNewFaqItems] = useState([]);

  useEffect(() => {
    if (faqItems?.length > 0) {
      const updatedFaqItems = faqItems.map((el, index) => ({
        ...el,
        isActive: index === 0
      }));
      setNewFaqItems(updatedFaqItems);
    }
  }, [faqItems]);

  const t = useTranslation();

  if (
    !faqItems ||
    (faqItems.length === 0 &&
      (!children || !children.props || !children.props.children))
  ) {
    return null;
  }

  const setActiveItems = (id, isActive) => {
    setNewFaqItems((prev) =>
      prev.map((el) =>
        el.id === id ? { ...el, isActive } : { ...el, isActive: false }
      )
    );
  };

  return (
    <section className="faq">
      <Container>
        {newFaqItems?.length > 0 && (
          <>
            <div className="section__title">{t.common.faqTitle}</div>

            <ul className="faq__list">
              {newFaqItems.map(({ question, answer, id, isActive }) => (
                <FaqItem
                  key={id}
                  id={id}
                  question={question}
                  answer={answer}
                  isActive={isActive}
                  onChange={setActiveItems}
                />
              ))}
            </ul>
          </>
        )}
        {children}
      </Container>
    </section>
  );
};
