export const countriesConstant = [
  {
    path: "afghanistan",
    de: "Afghanistan",
    en: "Afghanistan",
    es: "Afganistán",
    fr: "Afghanistan",
    it: "Afghanistan",
    pl: "Afganistanie",
    ru: "Афганистана",
    tr: "Afganistan"
  },
  {
    path: "aland_islands",
    de: "Aland Islands",
    en: "Aland Islands",
    es: "Islas Aland",
    fr: "îles Aland",
    it: "Isole Aland",
    pl: "Wyspy Alandzkie",
    ru: "Аландских островов",
    tr: "Aland Adaları"
  },
  {
    path: "albania",
    de: "Albanien",
    en: "Albania",
    es: "Albania",
    fr: "Albanie",
    it: "Albania",
    pl: "Albania",
    ru: "Албании",
    tr: "Arnavutluk"
  },
  {
    path: "algeria",
    de: "Algerien",
    en: "Algeria",
    es: "Argelia",
    fr: "Algérie",
    it: "Algeria",
    pl: "Algieria",
    ru: "Алжира",
    tr: "Cezayir"
  },
  {
    path: "andorra",
    de: "Andorra",
    en: "Andorra",
    es: "Andorra",
    fr: "Andorre",
    it: "Andorra",
    pl: "Andora",
    ru: "Андорры",
    tr: "Andorra"
  },
  {
    path: "angola",
    de: "Angola",
    en: "Angola",
    es: "Angola",
    fr: "Angola",
    it: "Angola",
    pl: "Angola",
    ru: "Анголы",
    tr: "Angola"
  },
  {
    path: "armenia",
    de: "Armenien",
    en: "Armenia",
    es: "Armenia",
    fr: "Arménie",
    it: "Armenia",
    pl: "Armenia",
    ru: "Армении",
    tr: "Ermenistan"
  },
  {
    path: "asia-pacific_region",
    de: "Asien-Pazifik-Region",
    en: "Asia-Pacific Region",
    es: "Región de Asia-Pacífico",
    fr: "Région Asie-Pacifique",
    it: "Regione Asia-Pacifico",
    pl: "Region Azji i Pacyfiku",
    ru: "Азии/Тихоокеанский регион",
    tr: "Asya-Pasifik Bölgesi"
  },
  {
    path: "australia",
    de: "Australien",
    en: "Australia",
    es: "Australia",
    fr: "Australie",
    it: "Australia",
    pl: "Australia",
    ru: "Австралии",
    tr: "Avustralya"
  },
  {
    path: "austria",
    de: "Österreich",
    en: "Austria",
    es: "Austria",
    fr: "Autriche",
    it: "Austria",
    pl: "Austria",
    ru: "Австрии",
    tr: "Avusturya"
  },
  {
    path: "azerbaijan",
    de: "Aserbaidschan",
    en: "Azerbaijan",
    es: "Azerbaiyán",
    fr: "Azerbaïdjan",
    it: "Azerbaigian",
    pl: "Azerbejdżan",
    ru: "Азербайджана",
    tr: "Azerbaycan"
  },
  {
    path: "bahamas",
    de: "Bahamas",
    en: "Bahamas",
    es: "Bahamas",
    fr: "Bahamas",
    it: "Bahamas",
    pl: "Bahamy",
    ru: "Богамских островов",
    tr: "Bahamalar"
  },
  {
    path: "belgium",
    de: "Belgien",
    en: "Belgium",
    es: "Bélgica",
    fr: "Belgique",
    it: "Belgio",
    pl: "Belgia",
    ru: "Бельгии",
    tr: "Belçika"
  },
  {
    path: "bangladesh",
    de: "Bangladesch",
    en: "Bangladesh",
    es: "Bangladesh",
    fr: "Bangladesh",
    it: "Bangladesh",
    pl: "Bangladesz",
    ru: "Бангладеша",
    tr: "Bangladeş"
  },
  {
    path: "belarus",
    de: "Weißrussland",
    en: "Belarus",
    es: "Bielorrusia",
    fr: "Biélorussie",
    it: "Bielorussia",
    pl: "Białoruś",
    ru: "Белоруссии",
    tr: "Beyaz Rusya"
  },
  {
    path: "bolivia",
    de: "Bolivien",
    en: "Bolivia",
    es: "Bolivia",
    fr: "Bolivie",
    it: "Bolivia",
    pl: "Boliwia",
    ru: "Боливии",
    tr: "Bolivya"
  },
  {
    path: "bosnia_and_herzegovina",
    de: "Bosnien und Herzegowina",
    en: "Bosnia and Herzegovina",
    es: "Bosnia y Herzegovina",
    fr: "Bosnie-Herzégovine",
    it: "Bosnia ed Erzegovina",
    pl: "Bośnia i Hercegowina",
    ru: "Боснии и Герцеговины",
    tr: "Bosna-Hersek"
  },
  {
    path: "botswana",
    de: "Botswana",
    en: "Botswana",
    es: "Botsuana",
    fr: "Botswana",
    it: "Botswana",
    pl: "Botswana",
    ru: "Ботсваны",
    tr: "Botsvana"
  },
  {
    path: "brazil",
    de: "Brasilien",
    en: "Brazil",
    es: "Brasil",
    fr: "Brésil",
    it: "Brasile",
    pl: "Brazylia",
    ru: "Бразилии",
    tr: "Brezilya"
  },
  {
    path: "bulgaria",
    de: "Bulgarien",
    en: "Bulgaria",
    es: "Bulgaria",
    fr: "Bulgarie",
    it: "Bulgaria",
    pl: "Bułgaria",
    ru: "Болгарии",
    tr: "Bulgaristan"
  },
  {
    path: "burkina_faso",
    de: "Burkina Faso",
    en: "Burkina Faso",
    es: "Burkina Faso",
    fr: "Burkina Faso",
    it: "Burkina Faso",
    pl: "Burkina Faso",
    ru: "Буркина-Фасо",
    tr: "Burkina Faso"
  },
  {
    path: "burundi",
    de: "Burundi",
    en: "Burundi",
    es: "Burundi",
    fr: "Burundi",
    it: "Burundi",
    pl: "Burundi",
    ru: "Бурунди",
    tr: "Burundi"
  },
  {
    path: "cape_verde",
    de: "Kap Verde",
    en: "Cape Verde",
    es: "Cabo Verde",
    fr: "Cap-Vert",
    it: "Capo Verde",
    pl: "Republika Zielonego Przylądka",
    ru: "Кабо-Верде",
    tr: "Cape Verde"
  },
  {
    path: "cambodia",
    de: "Kambodscha",
    en: "Cambodia",
    es: "Camboya",
    fr: "Cambodge",
    it: "Cambogia",
    pl: "Kambodża",
    ru: "Камбоджи",
    tr: "Kamboçya"
  },
  {
    path: "cameroon",
    de: "Kamerun",
    en: "Cameroon",
    es: "Camerún",
    fr: "Cameroun",
    it: "Camerun",
    pl: "Kamerun",
    ru: "Камеруна",
    tr: "Kamerun"
  },
  {
    path: "canada",
    de: "Kanada",
    en: "Canada",
    es: "Canadá",
    fr: "Canada",
    it: "Canada",
    pl: "Kanada",
    ru: "Канады",
    tr: "Kanada"
  },
  {
    path: "chad",
    de: "Tschad",
    en: "Chad",
    es: "Chad",
    fr: "Tchad",
    it: "Ciad",
    pl: "Czad",
    ru: "Чад",
    tr: "Çad"
  },
  {
    path: "chile",
    de: "Chile",
    en: "Chile",
    es: "Chile",
    fr: "Chili",
    it: "Cile",
    pl: "Chile",
    ru: "Чили",
    tr: "Şili"
  },
  {
    path: "china",
    de: "China",
    en: "China",
    es: "China",
    fr: "Chine",
    it: "Cina",
    pl: "Chiny",
    ru: "Китая",
    tr: "Çin"
  },
  {
    path: "colombia",
    de: "Kolumbien",
    en: "Colombia",
    es: "Colombia",
    fr: "Colombie",
    it: "Colombia",
    pl: "Kolumbia",
    ru: "Колумбии",
    tr: "Kolombiya"
  },
  {
    path: "congo_the_democratic_republic_of_the",
    de: "Demokratische Republik Kongo",
    en: "Congo, The Democratic Republic of the",
    es: "Congo, República Democrática del",
    fr: "Congo, République démocratique du",
    it: "Repubblica Democratica del Congo",
    pl: "Demokratyczna Republika Konga",
    ru: "Конго",
    tr: "Kongo Demokratik Cumhuriyeti"
  },
  {
    path: "costa_rica",
    de: "Costa Rica",
    en: "Costa Rica",
    es: "Costa Rica",
    fr: "Costa Rica",
    it: "Costa Rica",
    pl: "Kostaryka",
    ru: "Коста Рики",
    tr: "Kosta Rika"
  },
  {
    path: "cote_divoire",
    de: "Elfenbeinküste",
    en: "Côte d'Ivoire",
    es: "Costa de Marfil",
    fr: "Côte d'Ivoire",
    it: "Costa d'Avorio",
    pl: "Wybrzeże Kości Słoniowej",
    ru: "Хорватии",
    tr: "Fildişi Sahili"
  },
  {
    path: "croatia",
    de: "Kroatien",
    en: "Croatia",
    es: "Croacia",
    fr: "Croatie",
    it: "Croazia",
    pl: "Chorwacja",
    ru: "Кот-д’Ивуара",
    tr: "Hırvatistan"
  },
  {
    path: "curacao",
    de: "Curaçao",
    en: "Curaçao",
    es: "Curazao",
    fr: "Curaçao",
    it: "Curaçao",
    pl: "Curaçao",
    ru: "Кюрасао",
    tr: "Curaçao"
  },
  {
    path: "cyprus",
    de: "Zypern",
    en: "Cyprus",
    es: "Chipre",
    fr: "Chypre",
    it: "Cipro",
    pl: "Cypr",
    ru: "Кипра",
    tr: "Kıbrıs"
  },
  {
    path: "czech_republic",
    de: "Tschechische Republik",
    en: "Czech Republic",
    es: "República Checa",
    fr: "République tchèque",
    it: "Repubblica Ceca",
    pl: "Republika Czeska",
    ru: "Чехии",
    tr: "Çek Cumhuriyeti"
  },
  {
    path: "denmark",
    de: "Dänemark",
    en: "Denmark",
    es: "Dinamarca",
    fr: "Danemark",
    it: "Danimarca",
    pl: "Dania",
    ru: "Дании",
    tr: "Danimarka"
  },
  {
    path: "djibouti",
    de: "Dschibuti",
    en: "Djibouti",
    es: "Yibuti",
    fr: "Djibouti",
    it: "Gibuti",
    pl: "Dżibuti",
    ru: "Джибути",
    tr: "Cibuti"
  },
  {
    path: "dominican_republic",
    de: "Dominikanische Republik",
    en: "Dominican Republic",
    es: "República Dominicana",
    fr: "République dominicaine",
    it: "Repubblica Dominicana",
    pl: "Republika Dominikańska",
    ru: "Доминиканы",
    tr: "Dominik Cumhuriyeti"
  },
  {
    path: "ecuador",
    de: "Ecuador",
    en: "Ecuador",
    es: "Ecuador",
    fr: "Équateur",
    it: "Ecuador",
    pl: "Ekwador",
    ru: "Эквадора",
    tr: "Ekvador"
  },
  {
    path: "egypt",
    de: "Ägypten",
    en: "Egypt",
    es: "Egipto",
    fr: "Égypte",
    it: "Egitto",
    pl: "Egipt",
    ru: "Египта",
    tr: "Mısır"
  },
  {
    path: "el_salvador",
    de: "El Salvador",
    en: "El Salvador",
    es: "El Salvador",
    fr: "Salvador",
    it: "El Salvador",
    pl: "Salwador",
    ru: "Эль Сальвадора",
    tr: "El Salvador"
  },
  {
    path: "equatorial_guinea",
    de: "Äquatorialguinea",
    en: "Equatorial Guinea",
    es: "Guinea Ecuatorial",
    fr: "Guinée équatoriale",
    it: "Guinea Equatoriale",
    pl: "Gwinea Równikowa",
    ru: "Экваториальной Гвинеи",
    tr: "Ekvator Ginesi"
  },
  {
    path: "estonia",
    de: "Estland",
    en: "Estonia",
    es: "Estonia",
    fr: "Estonie",
    it: "Estonia",
    pl: "Estonia",
    ru: "Естонии",
    tr: "Estonya"
  },
  {
    path: "finland",
    de: "Finnland",
    en: "Finland",
    es: "Finlandia",
    fr: "Finlande",
    it: "Finlandia",
    pl: "Finlandia",
    ru: "Финляндии",
    tr: "Finlandiya"
  },
  {
    path: "france",
    de: "Frankreich",
    en: "France",
    es: "Francia",
    fr: "France",
    it: "Francia",
    pl: "Francja",
    ru: "Франции",
    tr: "Fransa"
  },
  {
    path: "gabon",
    de: "Gabun",
    en: "Gabon",
    es: "Gabón",
    fr: "Gabon",
    it: "Gabon",
    pl: "Gabon",
    ru: "Габона",
    tr: "Gabon"
  },
  {
    path: "georgia",
    de: "Georgien",
    en: "Georgia",
    es: "Georgia",
    fr: "Géorgie",
    it: "Georgia",
    pl: "Gruzja",
    ru: "Грузии",
    tr: "Gürcistan"
  },
  {
    path: "germany",
    de: "Deutschland",
    en: "Germany",
    es: "Alemania",
    fr: "Allemagne",
    it: "Germania",
    pl: "Niemcy",
    ru: "Германии",
    tr: "Almanya"
  },
  {
    path: "ghana",
    de: "Ghana",
    en: "Ghana",
    es: "Ghana",
    fr: "Ghana",
    it: "Ghana",
    pl: "Ghana",
    ru: "Ганы",
    tr: "Gana"
  },
  {
    path: "greece",
    de: "Griechenland",
    en: "Greece",
    es: "Grecia",
    fr: "Grèce",
    it: "Grecia",
    pl: "Grecja",
    ru: "Греции",
    tr: "Yunanistan"
  },
  {
    path: "guam",
    de: "Guam",
    en: "Guam",
    es: "Guam",
    fr: "Guam",
    it: "Guam",
    pl: "Guam",
    ru: "Гуам",
    tr: "Guam"
  },
  {
    path: "guinea",
    de: "Guinea",
    en: "Guinea",
    es: "Guinea",
    fr: "Guinée",
    it: "Guinea",
    pl: "Gwinea",
    ru: "Гвинеи",
    tr: "Gine"
  },
  {
    path: "guyana",
    de: "Guyana",
    en: "Guyana",
    es: "Guyana",
    fr: "Guyana",
    it: "Guyana",
    pl: "Gujana",
    ru: "Гайаны",
    tr: "Guyana"
  },
  {
    path: "haiti",
    de: "Haiti",
    en: "Haiti",
    es: "Haití",
    fr: "Haïti",
    it: "Haiti",
    pl: "Haiti",
    ru: "Гаити",
    tr: "Haiti"
  },
  {
    path: "honduras",
    de: "Honduras",
    en: "Honduras",
    es: "Honduras",
    fr: "Honduras",
    it: "Honduras",
    pl: "Honduras",
    ru: "Гондураса",
    tr: "Honduras"
  },
  {
    path: "hong_kong",
    de: "Hongkong",
    en: "Hong Kong",
    es: "Hong Kong",
    fr: "Hong Kong",
    it: "Hong Kong",
    pl: "Hongkong",
    ru: "Гонконга",
    tr: "Hong Kong"
  },
  {
    path: "hungary",
    de: "Ungarn",
    en: "Hungary",
    es: "Hungría",
    fr: "Hongrie",
    it: "Ungheria",
    pl: "Węgry",
    ru: "Венгрии",
    tr: "Macaristan"
  },
  {
    path: "india",
    de: "Indien",
    en: "India",
    es: "India",
    fr: "Inde",
    it: "India",
    pl: "Indie",
    ru: "Индии",
    tr: "Hindistan"
  },
  {
    path: "indonesia",
    de: "Indonesien",
    en: "Indonesia",
    es: "Indonesia",
    fr: "Indonésie",
    it: "Indonesia",
    pl: "Indonezja",
    ru: "Индонезии",
    tr: "Endonezya"
  },
  {
    path: "iran_islamic_republic_of",
    de: "Iran",
    en: "Iran, Islamic Republic of",
    es: "Irán, República Islámica de",
    fr: "Iran, République islamique d'",
    it: "Iran, Repubblica Islamica dell'",
    pl: "Iran, Islamska Republika",
    ru: "Ирана",
    tr: "İran İslam Cumhuriyeti"
  },
  {
    path: "iraq",
    de: "Irak",
    en: "Iraq",
    es: "Iraq",
    fr: "Irak",
    it: "Iraq",
    pl: "Irak",
    ru: "Ирака",
    tr: "Irak"
  },
  {
    path: "ireland",
    de: "Irland",
    en: "Ireland",
    es: "Irlanda",
    fr: "Irlande",
    it: "Irlanda",
    pl: "Irlandia",
    ru: "Ирландии",
    tr: "İrlanda"
  },
  {
    path: "isle_of_man",
    de: "Isle of Man",
    en: "Isle of Man",
    es: "Isla de Man",
    fr: "Île de Man",
    it: "Isola di Man",
    pl: "Wyspa Man",
    ru: "Острова Мэн",
    tr: "Man Adası"
  },
  {
    path: "israel",
    de: "Israel",
    en: "Israel",
    es: "Israel",
    fr: "Israël",
    it: "Israele",
    pl: "Izrael",
    ru: "Израиля",
    tr: "İsrail"
  },
  {
    path: "italy",
    de: "Italien",
    en: "Italy",
    es: "Italia",
    fr: "Italie",
    it: "Italia",
    pl: "Włochy",
    ru: "Италии",
    tr: "İtalya"
  },
  {
    path: "jamaica",
    de: "Jamaika",
    en: "Jamaica",
    es: "Jamaica",
    fr: "Jamaïque",
    it: "Giamaica",
    pl: "Jamajka",
    ru: "Ямайки",
    tr: "Jamaika"
  },
  {
    path: "japan",
    de: "Japan",
    en: "Japan",
    es: "Japón",
    fr: "Japon",
    it: "Giappone",
    pl: "Japonia",
    ru: "Японии",
    tr: "Japonya"
  },
  {
    path: "kazakhstan",
    de: "Kasachstan",
    en: "Kazakhstan",
    es: "Kazajistán",
    fr: "Kazakhstan",
    it: "Kazakistan",
    pl: "Kazachstan",
    ru: "Казахстана",
    tr: "Kazakistan"
  },
  {
    path: "kenya",
    de: "Kenia",
    en: "Kenya",
    es: "Kenia",
    fr: "Kenya",
    it: "Kenya",
    pl: "Kenia",
    ru: "Кении",
    tr: "Kenya"
  },
  {
    path: "korea_republic_of",
    de: "Südkorea",
    en: "Korea, Republic of",
    es: "Corea, República de",
    fr: "Corée, République de",
    it: "Corea del Sud",
    pl: "Korea Południowa",
    ru: "Кореи",
    tr: "Güney Kore"
  },
  {
    path: "kyrgyzstan",
    de: "Kirgisistan",
    en: "Kyrgyzstan",
    es: "Kirguistán",
    fr: "Kirghizistan",
    it: "Kirghizistan",
    pl: "Kirgistan",
    ru: "Киргизии",
    tr: "Kırgızistan"
  },
  {
    path: "lao_peoples_democratic_republic",
    de: "Laos",
    en: "Lao People's Democratic Republic",
    es: "Laos, República Democrática Popular de",
    fr: "Laos, République démocratique populaire de",
    it: "Laos, Repubblica Democratica Popolare del",
    pl: "Laos, Demokratyczna Republika Ludowa",

    ru: "Лаоса",
    tr: "Laos Halk Demokratik Cumhuriyeti"
  },
  {
    path: "latvia",
    de: "Lettland",
    en: "Latvia",
    es: "Letonia",
    fr: "Lettonie",
    it: "Lettonia",
    pl: "Łotwa",
    ru: "Латвии",
    tr: "Letonya"
  },
  {
    path: "lebanon",
    de: "Libanon",
    en: "Lebanon",
    es: "Líbano",
    fr: "Liban",
    it: "Libano",
    pl: "Liban",
    ru: "Ливана",
    tr: "Lübnan"
  },
  {
    path: "lesotho",
    de: "Lesotho",
    en: "Lesotho",
    es: "Lesotho",
    fr: "Lesotho",
    it: "Lesotho",
    pl: "Lesotho",
    ru: "Лесото",
    tr: "Lesotho"
  },
  {
    path: "liberia",
    de: "Liberia",
    en: "Liberia",
    es: "Liberia",
    fr: "Libéria",
    it: "Liberia",
    pl: "Liberia",
    ru: "Либерия",
    tr: "Liberya"
  },
  {
    path: "libya",
    de: "Libyen",
    en: "Libya",
    es: "Libia",
    fr: "Libye",
    it: "Libia",
    pl: "Libia",
    ru: "Ливии",
    tr: "Libya"
  },
  {
    path: "lithuania",
    de: "Litauen",
    en: "Lithuania",
    es: "Lituania",
    fr: "Lituanie",
    it: "Lituania",
    pl: "Litwa",
    ru: "Литвы",
    tr: "Litvanya"
  },
  {
    path: "luxembourg",
    de: "Luxemburg",
    en: "Luxembourg",
    es: "Luxemburgo",
    fr: "Luxembourg",
    it: "Lussemburgo",
    pl: "Luksemburg",
    ru: "Люксембурга",
    tr: "Lüksemburg"
  },
  {
    path: "macau",
    de: "Macau",
    en: "Macau",
    es: "Macao",
    fr: "Macao",
    it: "Macao",
    pl: "Makau",
    ru: "Макао",
    tr: "Makao"
  },
  {
    path: "macedonia",
    de: "Mazedonien",
    en: "Macedonia",
    es: "Macedonia",
    fr: "Macédoine",
    it: "Macedonia",
    pl: "Macedonia",
    ru: "Македонии",
    tr: "Makedonya"
  },
  {
    path: "madagascar",
    de: "Madagaskar",
    en: "Madagascar",
    es: "Madagascar",
    fr: "Madagascar",
    it: "Madagascar",
    pl: "Madagaskar",
    ru: "Мадагаскара",
    tr: "Madagaskar"
  },
  {
    path: "malawi",
    de: "Malawi",
    en: "Malawi",
    es: "Malawi",
    fr: "Malawi",
    it: "Malawi",
    pl: "Malawi",
    ru: "Малави",
    tr: "Malavi"
  },
  {
    path: "malaysia",
    de: "Malaysia",
    en: "Malaysia",
    es: "Malasia",
    fr: "Malaisie",
    it: "Malesia",
    pl: "Malezja",
    ru: "Малайзии",
    tr: "Malezya"
  },
  {
    path: "maldives",
    de: "Malediven",
    en: "Maldives",
    es: "Maldivas",
    fr: "Maldives",
    it: "Maldive",
    pl: "Malediwy",
    ru: "Мальдивы",
    tr: "Maldivler"
  },
  {
    path: "mali",
    de: "Mali",
    en: "Mali",
    es: "Malí",
    fr: "Mali",
    it: "Mali",
    pl: "Mali",
    ru: "Мали",
    tr: "Mali"
  },
  {
    path: "malta",
    de: "Malta",
    en: "Malta",
    es: "Malta",
    fr: "Malte",
    it: "Malta",
    pl: "Malta",
    ru: "Мальты",
    tr: "Malta"
  },
  {
    path: "mauritius",
    de: "Mauritius",
    en: "Mauritius",
    es: "Mauricio",
    fr: "Maurice",
    it: "Mauritius",
    pl: "Mauritius",
    ru: "Маврикий",
    tr: "Mauritius"
  },
  {
    path: "mexico",
    de: "Mexiko",
    en: "Mexico",
    es: "México",
    fr: "Mexique",
    it: "Messico",
    pl: "Meksyk",
    ru: "Мексики",
    tr: "Meksika"
  },
  {
    path: "moldova_republic_of",
    de: "Moldawien",
    en: "Moldova",
    es: "Moldavia",
    fr: "Moldavie",
    it: "Moldova",
    pl: "Mołdawia",
    ru: "Молдовы",
    tr: "Moldova"
  },
  {
    path: "mongolia",
    de: "Mongolei",
    en: "Mongolia",
    es: "Mongolia",
    fr: "Mongolie",
    it: "Mongolia",
    pl: "Mongolia",
    ru: "Монголии",
    tr: "Moğolistan"
  },
  {
    path: "montenegro",
    de: "Montenegro",
    en: "Montenegro",
    es: "Montenegro",
    fr: "Monténégro",
    it: "Montenegro",
    pl: "Czarnogóra",
    ru: "Черногории",
    tr: "Karadağ"
  },
  {
    path: "morocco",
    de: "Marokko",
    en: "Morocco",
    es: "Marruecos",
    fr: "Maroc",
    it: "Marocco",
    pl: "Maroko",
    ru: "Марокко",
    tr: "Fas"
  },
  {
    path: "myanmar",
    de: "Myanmar",
    en: "Myanmar",
    es: "Myanmar",
    fr: "Myanmar",
    it: "Myanmar",
    pl: "Mjanma",
    ru: "Мьянмы",
    tr: "Myanmar"
  },
  {
    path: "namibia",
    de: "Namibia",
    en: "Namibia",
    es: "Namibia",
    fr: "Namibie",
    it: "Namibia",
    pl: "Namibia",
    ru: "Намибии",
    tr: "Namibya"
  },
  {
    path: "nepal",
    de: "Nepal",
    en: "Nepal",
    es: "Nepal",
    fr: "Népal",
    it: "Nepal",
    pl: "Nepal",
    ru: "Непала",
    tr: "Nepal"
  },
  {
    path: "netherlands",
    de: "Niederlande",
    en: "Netherlands",
    es: "Países Bajos",
    fr: "Pays-Bas",
    it: "Paesi Bassi",
    pl: "Holandia",
    ru: "Нидерландов",
    tr: "Hollanda"
  },
  {
    path: "new_caledonia",
    de: "Neukaledonien",
    en: "New Caledonia",
    es: "Nueva Caledonia",
    fr: "Nouvelle-Calédonie",
    it: "Nuova Caledonia",
    pl: "Nowa Kaledonia",
    ru: "Новой Каледонии",
    tr: "Yeni Kaledonya"
  },
  {
    path: "new_zealand",
    de: "Neuseeland",
    en: "New Zealand",
    es: "Nueva Zelanda",
    fr: "Nouvelle-Zélande",
    it: "Nuova Zelanda",
    pl: "Nowa Zelandia",
    ru: "Новой Зеландии",
    tr: "Yeni Zelanda"
  },
  {
    path: "nicaragua",
    de: "Nicaragua",
    en: "Nicaragua",
    es: "Nicaragua",
    fr: "Nicaragua",
    it: "Nicaragua",
    pl: "Nikaragua",
    ru: "Никарагуа",
    tr: "Nikaragua"
  },
  {
    path: "niger",
    de: "Niger",
    en: "Niger",
    es: "Níger",
    fr: "Niger",
    it: "Niger",
    pl: "Niger",
    ru: "Нигера",
    tr: "Nijer"
  },
  {
    path: "nigeria",
    de: "Nigeria",
    en: "Nigeria",
    es: "Nigeria",
    fr: "Nigéria",
    it: "Nigeria",
    pl: "Nigeria",
    ru: "Нигерии",
    tr: "Nijerya"
  },
  {
    path: "norway",
    de: "Norwegen",
    en: "Norway",
    es: "Noruega",
    fr: "Norvège",
    it: "Norvegia",
    pl: "Norwegia",
    ru: "Норвегии",
    tr: "Norveç"
  },
  {
    path: "oman",
    de: "Oman",
    en: "Oman",
    es: "Omán",
    fr: "Oman",
    it: "Oman",
    pl: "Oman",
    ru: "Омана",
    tr: "Umman"
  },
  {
    path: "pakistan",
    de: "Pakistan",
    en: "Pakistan",
    es: "Pakistán",
    fr: "Pakistan",
    it: "Pakistan",
    pl: "Pakistan",
    ru: "Пакистана",
    tr: "Pakistan"
  },
  {
    path: "palestinian_territory",
    de: "Palästinensisches Gebiet",
    en: "Palestinian Territory",
    es: "Territorio Palestino",
    fr: "Territoire palestinien",
    it: "Territorio Palestinese",
    pl: "Terytorium Palestyńskie",
    ru: "Палестины",
    tr: "Filistin Toprakları"
  },
  {
    path: "panama",
    de: "Panama",
    en: "Panama",
    es: "Panamá",
    fr: "Panama",
    it: "Panama",
    pl: "Panama",
    ru: "Панамы",
    tr: "Panama"
  },
  {
    path: "papua-new-guinea",
    de: "Papua-Neuguinea",
    en: "Papua New Guinea",
    es: "Papúa Nueva Guinea",
    fr: "Papouasie-Nouvelle-Guinée",
    it: "Papua Nuova Guinea",
    pl: "Papua-Nowa Gwinea",
    ru: "Папуа-Новая Гвинея",
    tr: "Papua Yeni Gine"
  },
  {
    path: "paraguay",
    de: "Paraguay",
    en: "Paraguay",
    es: "Paraguay",
    fr: "Paraguay",
    it: "Paraguay",
    pl: "Paragwaj",
    ru: "Парагвая",
    tr: "Paraguay"
  },
  {
    path: "peru",
    de: "Peru",
    en: "Peru",
    es: "Perú",
    fr: "Pérou",
    it: "Perù",
    pl: "Peru",
    ru: "Перу",
    tr: "Peru"
  },
  {
    path: "philippines",
    de: "Philippinen",
    en: "Philippines",
    es: "Filipinas",
    fr: "Philippines",
    it: "Filippine",
    pl: "Filipiny",
    ru: "Филиппинов",
    tr: "Filipinler"
  },
  {
    path: "poland",
    de: "Polen",
    en: "Poland",
    es: "Polonia",
    fr: "Pologne",
    it: "Polonia",
    pl: "Polska",
    ru: "Польши",
    tr: "Polonya"
  },
  {
    path: "portugal",
    de: "Portugal",
    en: "Portugal",
    es: "Portugal",
    fr: "Portugal",
    it: "Portogallo",
    pl: "Portugalia",
    ru: "Португалии",
    tr: "Portekiz"
  },
  {
    path: "puerto_rico",
    de: "Puerto Rico",
    en: "Puerto Rico",
    es: "Puerto Rico",
    fr: "Porto Rico",
    it: "Porto Rico",
    pl: "Portoryko",
    ru: "Пуэрто-Рико",
    tr: "Porto Riko"
  },
  {
    path: "romania",
    de: "Rumänien",
    en: "Romania",
    es: "Rumanía",
    fr: "Roumanie",
    it: "Romania",
    pl: "Rumunia",
    ru: "Румынии",
    tr: "Romanya"
  },
  {
    path: "russian_federation",
    de: "Russische Föderation",
    en: "Russian Federation",
    es: "Federación Rusa",
    fr: "Fédération de Russie",
    it: "Federazione Russa",
    pl: "Federacja Rosyjska",
    ru: "России",
    tr: "Rusya Federasyonu"
  },
  {
    path: "rwanda",
    de: "Ruanda",
    en: "Rwanda",
    es: "Ruanda",
    fr: "Rwanda",
    it: "Ruanda",
    pl: "Rwanda",
    ru: "Руанды",
    tr: "Ruanda"
  },
  {
    path: "saint_martin",
    de: "Sankt Martin",
    en: "Saint Martin",
    es: "San Martín",
    fr: "Saint-Martin",
    it: "Saint Martin",
    pl: "Saint-Martin",
    ru: "Сен-Мартена",
    tr: "Saint Martin"
  },
  {
    path: "samoa",
    de: "Samoa",
    en: "Samoa",
    es: "Samoa",
    fr: "Samoa",
    it: "Samoa",
    pl: "Samoa",
    ru: "Самоа",
    tr: "Samoa"
  },
  {
    path: "saudi_arabia",
    de: "Saudi-Arabien",
    en: "Saudi Arabia",
    es: "Arabia Saudita",
    fr: "Arabie Saoudite",
    it: "Arabia Saudita",
    pl: "Arabia Saudyjska",
    ru: "Саудовской Аравии",
    tr: "Suudi Arabistan"
  },
  {
    path: "serbia",
    de: "Serbien",
    en: "Serbia",
    es: "Serbia",
    fr: "Serbie",
    it: "Serbia",
    pl: "Serbia",
    ru: "Сербии",
    tr: "Sırbistan"
  },
  {
    path: "seychelles",
    de: "Seychellen",
    en: "Seychelles",
    es: "Seychelles",
    fr: "Seychelles",
    it: "Seychelles",
    pl: "Seszele",
    ru: "Сейшел",
    tr: "Seyşeller"
  },
  {
    path: "sierra-leone",
    de: "Sierra Leone",
    en: "Sierra Leone",
    es: "Sierra Leona",
    fr: "Sierra Leone",
    it: "Sierra Leone",
    pl: "Sierra Leone",
    ru: "Сьерра-Леоне",
    tr: "Sierra Leone"
  },
  {
    path: "singapore",
    de: "Singapur",
    en: "Singapore",
    es: "Singapur",
    fr: "Singapour",
    it: "Singapore",
    pl: "Singapur",
    ru: "Сингапура",
    tr: "Singapur"
  },
  {
    path: "slovakia",
    de: "Slowakei",
    en: "Slovakia",
    es: "Eslovaquia",
    fr: "Slovaquie",
    it: "Slovacchia",
    pl: "Słowacja",
    ru: "Словакии",
    tr: "Slovakya"
  },
  {
    path: "slovenia",
    de: "Slowenien",
    en: "Slovenia",
    es: "Eslovenia",
    fr: "Slovénie",
    it: "Slovenia",
    pl: "Słowenia",
    ru: "Словении",
    tr: "Slovenya"
  },
  {
    path: "somalia",
    de: "Somalia",
    en: "Somalia",

    es: "Somalia",
    fr: "Somalie",
    it: "Somalia",
    pl: "Somalia",
    ru: "Сомали",
    tr: "Somali"
  },
  {
    path: "south_africa",
    de: "Südafrika",
    en: "South Africa",
    es: "Sudáfrica",
    fr: "Afrique du Sud",
    it: "Sud Africa",
    pl: "Republika Południowej Afryki",
    ru: "Южной Африки",
    tr: "Güney Afrika"
  },
  {
    path: "south-sudan",
    de: "Südsudan",
    en: "South Sudan",
    es: "Sudán del Sur",
    fr: "Soudan du Sud",
    it: "Sudan del Sud",
    pl: "Sudan Południowy",
    ru: "Южного Судана",
    tr: "Güney Sudan"
  },
  {
    path: "spain",
    de: "Spanien",
    en: "Spain",
    es: "España",
    fr: "Espagne",
    it: "Spagna",
    pl: "Hiszpania",
    ru: "Испании",
    tr: "İspanya"
  },
  {
    path: "sri-lanka",
    de: "Sri Lanka",
    en: "Sri Lanka",
    es: "Sri Lanka",
    fr: "Sri Lanka",
    it: "Sri Lanka",
    pl: "Sri Lanka",
    ru: "Шри Ланки",
    tr: "Sri Lanka"
  },
  {
    path: "sudan",
    de: "Sudan",
    en: "Sudan",
    es: "Sudán",
    fr: "Soudan",
    it: "Sudan",
    pl: "Sudan",
    ru: "Судана",
    tr: "Sudan"
  },
  {
    path: "swaziland",
    de: "Swasiland",
    en: "Swaziland",
    es: "Suazilandia",
    fr: "Swaziland",
    it: "Swaziland",
    pl: "Suazi",
    ru: "Свазиленда",
    tr: "Svaziland"
  },
  {
    path: "sweden",
    de: "Schweden",
    en: "Sweden",
    es: "Suecia",
    fr: "Suède",
    it: "Svezia",
    pl: "Szwecja",
    ru: "Швеции",
    tr: "İsveç"
  },
  {
    path: "switzerland",
    de: "Schweiz",
    en: "Switzerland",
    es: "Suiza",
    fr: "Suisse",
    it: "Svizzera",
    pl: "Szwajcaria",
    ru: "Швейцарии",
    tr: "İsviçre"
  },
  {
    path: "taiwan",
    de: "Taiwan",
    en: "Taiwan",
    es: "Taiwán",
    fr: "Taïwan",
    it: "Taiwan",
    pl: "Tajwan",
    ru: "Тайваня",
    tr: "Tayvan"
  },
  {
    path: "tajikistan",
    de: "Tadschikistan",
    en: "Tajikistan",
    es: "Tayikistán",
    fr: "Tadjikistan",
    it: "Tagikistan",
    pl: "Tadżykistan",
    ru: "Таджикистана",
    tr: "Tacikistan"
  },
  {
    path: "tanzania_united_republic_of",
    de: "Tansania",
    en: "Tanzania",
    es: "Tanzania",
    fr: "Tanzanie",
    it: "Tanzania",
    pl: "Tanzania",
    ru: "Танзании",
    tr: "Tanzanya"
  },
  {
    path: "thailand",
    de: "Thailand",
    en: "Thailand",
    es: "Tailandia",
    fr: "Thaïlande",
    it: "Thailandia",
    pl: "Tajlandia",
    ru: "Таиланда",
    tr: "Tayland"
  },
  {
    path: "timor-leste",
    de: "Osttimor",
    en: "Timor-Leste",
    es: "Timor Oriental",
    fr: "Timor oriental",
    it: "Timor Est",
    pl: "Timor Wschodni",
    ru: "Восточного Тимора",
    tr: "Doğu Timor"
  },
  {
    path: "togo",
    de: "Togo",
    en: "Togo",
    es: "Togo",
    fr: "Togo",
    it: "Togo",
    pl: "Togo",
    ru: "Того",
    tr: "Togo"
  },
  {
    path: "trinidad-and-tobago",
    de: "Trinidad und Tobago",
    en: "Trinidad and Tobago",
    es: "Trinidad y Tobago",
    fr: "Trinité-et-Tobago",
    it: "Trinidad e Tobago",
    pl: "Trynidad i Tobago",
    ru: "Тринидад и Табаго",
    tr: "Trinidad ve Tobago"
  },
  {
    path: "turkey",
    de: "Türkei",
    en: "Turkey",
    es: "Turquía",
    fr: "Turquie",
    it: "Turchia",
    pl: "Turcja",
    ru: "Турции",
    tr: "Türkiye"
  },
  {
    path: "uganda",
    de: "Uganda",
    en: "Uganda",
    es: "Uganda",
    fr: "Ouganda",
    it: "Uganda",
    pl: "Uganda",
    ru: "Уганды",
    tr: "Uganda"
  },
  {
    path: "ukraine",
    de: "Ukraine",
    en: "Ukraine",
    es: "Ucrania",
    fr: "Ukraine",
    it: "Ucraina",
    pl: "Ukraina",
    ru: "Украины",
    tr: "Ukrayna"
  },
  {
    path: "united_arab_emirates",
    de: "Vereinigte Arabische Emirate",
    en: "United Arab Emirates",
    es: "Emiratos Árabes Unidos",
    fr: "Émirats Arabes Unis",
    it: "Emirati Arabi Uniti",
    pl: "Zjednoczone Emiraty Arabskie",
    ru: "ОАЭ",
    tr: "Birleşik Arap Emirlikleri"
  },
  {
    path: "united_kingdom",
    de: "Vereinigtes Königreich",
    en: "United Kingdom",
    es: "Reino Unido",
    fr: "Royaume-Uni",
    it: "Regno Unito",
    pl: "Wielka Brytania",
    ru: "Великобритании",
    tr: "Birleşik Krallık"
  },
  {
    path: "united_states",
    de: "Vereinigte Staaten",
    en: "United States",
    es: "Estados Unidos",
    fr: "États-Unis",
    it: "Stati Uniti",
    pl: "Stany Zjednoczone",
    ru: "Америки",
    tr: "Amerika Birleşik Devletleri"
  },
  {
    path: "uruguay",
    de: "Uruguay",
    en: "Uruguay",
    es: "Uruguay",
    fr: "Uruguay",
    it: "Uruguay",
    pl: "Urugwaj",
    ru: "Уругвая",
    tr: "Uruguay"
  },
  {
    path: "uzbekistan",
    de: "Usbekistan",
    en: "Uzbekistan",
    es: "Uzbekistán",
    fr: "Ouzbékistan",
    it: "Uzbekistan",
    pl: "Uzbekistan",
    ru: "Узбекистана",
    tr: "Özbekistan"
  },
  {
    path: "venezuela",
    de: "Venezuela",
    en: "Venezuela",
    es: "Venezuela",
    fr: "Venezuela",
    it: "Venezuela",
    pl: "Wenezuela",
    ru: "Венесуэлы",
    tr: "Venezuela"
  },
  {
    path: "vietnam",
    de: "Vietnam",
    en: "Vietnam",
    es: "Vietnam",
    fr: "Viêt Nam",
    it: "Vietnam",
    pl: "Wietnam",
    ru: "Вьетнама",
    tr: "Vietnam"
  },
  {
    path: "virgin_islands_u-s",
    de: "Amerikanische Jungferninseln",
    en: "Virgin Islands (U.S.)",
    es: "Islas Vírgenes de los Estados Unidos",
    fr: "Îles Vierges des États-Unis",
    it: "Isole Vergini americane",
    pl: "Wyspy Dziewicze Stanów Zjednoczonych",
    ru: "Виргинских Островов",
    tr: "ABD Virjin Adaları"
  },
  {
    path: "yemen",
    de: "Jemen",
    en: "Yemen",
    es: "Yemen",
    fr: "Yémen",
    it: "Yemen",
    pl: "Jemen",
    ru: "Йемен",
    tr: "Yemen"
  },
  {
    path: "zambia",
    de: "Sambia",
    en: "Zambia",
    es: "Zambia",
    fr: "Zambie",
    it: "Zambia",
    pl: "Zambia",
    ru: "Замбии",
    tr: "Zambiya"
  },
  {
    path: "zimbabwe",
    de: "Simbabwe",
    en: "Zimbabwe",
    es: "Zimbabue",
    fr: "Zimbabwe",
    it: "Zimbabwe",
    pl: "Zimbabwe",
    ru: "Зимбабве",
    tr: "Zimbabve"
  }
];
