export const getScoreAnonymityColor = (score) => {
  switch (true) {
    case score >= 0 && score <= 20:
      return "rgba(255, 91, 91, 1)";
    case score > 20 && score <= 55:
      return "rgba(236, 222, 92, 1)";
    case score > 55 && score <= 75:
      return "rgba(255, 181, 38, 1)";
    default:
      return "rgba(58, 204, 121, 1)";
  }
};
