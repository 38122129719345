export const validateUrl = (url) => {
  let typeUrl = "";

  const urlRegex =
    /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;%=]*)?$/;

  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    typeUrl = "https://";
  }

  return { isValidUrl: urlRegex.test(url), typeUrl };
};
