export const routesPath = {
  claster: "klasterizaciya-zaprosov/",
  proxyChecker: "proxy-checker/",
  checkPorts: "skaner-portov/",
  pingIp: "ping-ip/",
  utm: "generator-utm/",
  siteSpeed: "speed/",
  ipv6Check: "ipv6-support/",
  spamBase: "ip-in-spam-databases/",
  trace: "trace/",
  filterProxy: "online-proxy-filter/",
  webRtcCheck: "web-rtc-check/",
  dnsCheck: "dns-check/",
  fingerprint: "fingerprint/",
  evercookie: "evercookie/",
  httpHeaders: "http-headers/",
  browserInfo: "browser-info/",
  myIp: "my-ip/",
  anonimityCheck: "anonymity-check/",
  export: "/:lang/export",
  filter: ":filter?",
  root: "/:lang"
};
