export const Pol = {
  header: {
    ariaLabelMain: "Wróć do strony głównej",
    altImgLogo: "Logo firmy FreeProxySale",
    other: "Więcej narzędzi",
    ariaLabelOther: "Szybkość rozbudowy dodatkowych pozycji menu",
    close: "Zamknąć"
  },
  footer: {
    logo: {
      alt: "Logo firmy Proxy-Sprzedaż"
    },
    rights: "Wszelkie prawa zastrzeżone",
    lang: {
      title: "Wybierz język",
      selectLanguage: [
        { value: "de", label: "Niemiecki" },
        { value: "en", label: "Angielski" },
        { value: "es", label: "Hiszpański" },
        { value: "fr", label: "Francuski" },
        { value: "it", label: "Włoski" },
        { value: "pl", label: "Polski" },
        { value: "ru", label: "Rosyjski" },
        { value: "tr", label: "Turecki" }
      ]
    },
    tools: "Instrumenty",
    company: "Firma"
  },
  navigation: {
    freeProxy: "Wolne proxy",
    personalProxy: "Osobisty pełnomocnik",
    proxyIpv6: "Pełnomocnik IPv6",
    articles: "Artykuły",
    contacts: "Łączność",
    proxyChecker: "Kontroler proxy",
    myIp: "Mój IP",
    checkPorts: "Skaner portów",
    pingIp: "Świst IP",
    anonimityCheck: "Kontrola anonimowości",
    utm: "UTM Budowniczy",
    speed: "Test prędkości proxy",
    ipv6Check: "IPv6 Walidator",
    spamBase: "IP Sprawdzenie czarnej listy",
    filterProxy: "Filtr listy proxy",
    traceIp: "IP Rysunek kalkowy",
    claster: "Grupowanie wniosków",
    webRtcChecker: "Kontrola WebRTC",
    dnsChecker: "Kontrola DNS",
    fingerPrint: "Skaner odcisków palców",
    evercookie: "Kontrola Evercookie",
    httpHeaders: "Przeglądanie nagłówków HTTP",
    browserInfo: "Informacje o przeglądarce",
    resedentialProxy: "Pełnomocnicy mieszkaniowi",
    ispProxy: "Pełnomocnicy ISP"
  },
  mainPage: {
    proxyTable: {
      sidebarPart: {
        title: "Konfiguracja proxy w przeglądarce:",
        countries: {
          placeholder: "Wybierz countriesConstant"
        },
        type: {
          title: "Typ:"
        },
        anonimity: {
          title: "Anonimowy:"
        },
        export: {
          title: "Export:",
          ip: "IP:Port",
          excel: "Excel",
          window: "W nowym oknie"
        },
        applyFilter: "Nałóż filtr",
        clearFilter: "Czysty filtr",

        infoLeft: "Bezpłatne proxy",
        infoRight:
          "wykluczają jakąkolwiek możliwość pracy z sieciami społecznościowymi, parsowania wyszukiwarek i pracy z prywatnym oprogramowaniem. Nie ponosimy odpowiedzialności za korzystanie z bezpłatnej listy proxy. Lista adresów IP jest dostarczana wyłącznie w celach informacyjnych.",
        buyProxy: "Kup osobiste proxy",
        personalProxyAdvantage: [
          "Pełnomocnictwa osobiste zapewnią Ci wysoką anonimowość i stabilność pracy"
        ]
      }
    },
    faq: "Prawie każdy użytkownik Internetu słyszał o tym, czym jest serwer proxy i dlaczego jest potrzebny. Stały się one szczególnie popularne, gdy pojawiła się ogromna potrzeba ominięcia blokowania popularnych zasobów internetowych - sieci społecznościowych, usług Yandex, hostingu wideo i tym podobnych Oczywiście większość jest zainteresowana darmowymi proxy.W sieci jest ich mnóstwo, ale nie zawsze jest oczywiste, jak je poprawnie wybrać i do czego się nadają oraz do jakich zadań lepiej kupić proxy."
  },
  anonimityCheck: {
    mainTitle: "Moja anonimowość",
    headText:
      "Korzystając z tej usługi, możesz sprawdzić, na ile jesteś anonimowy w sieci, na ile dane dostarczane przez Twój komputer/przeglądarkę są zgodne z danymi dostarczanymi przez Twój adres IP.",
    top: {
      ip: "Twój IP :",
      from: "Skąd jesteś:",
      camouflage: "Twoje przebranie:",
      anon: "Twoja anonimowość:",
      restartButton: "Uruchom ponownie skanowanie",
      waitButton: "Ładowanie",

      result: {
        title: "Twój wynik:",
        notAnonymity: "Brak anonimowości",
        badAnonymity: "Słaba anonimowość",
        lackAnonymity: "Niewystarczająca anonimowość",
        sufficientAnonymity: "Wystarczająca anonimowość",
        checking: "Sprawdzanie, proszę czekać",
        highAnonymity: "Wysoka anonimowość"
      }
    },
    table: {
      titleIpInfo: "Informacje IP",
      standardTimeZone: "Standardowa strefa czasowa",
      anonymityTitle: "Anonimowość i bezpieczeństwo",
      hour: "godziny",
      device: "Urządzenie",
      ip: "Mój IP",
      hideip: "Ukryć IP",
      host: "Host/Dostawca",
      country: "Kraj",
      city: "Miasto",
      index: "Indeks",
      coordinates: "Współrzędne",
      os: "OS",
      browser: "Przeglądarka",
      timezone: "Strefa czasowa",
      ipTime: "IP czas",
      systemTime: "Czas systemu",
      userAgent: "UserAgent",
      userAgentJs: "UserAgent JS",
      language: "Język",
      languageJs: "Język JS",
      screen: "Ekran",
      proxy: "Pełnomocnik",
      vpn: "VPN",
      tor: "Tor",
      anonimazer: {
        title: "Anonimizator",
        info: "Konieczne jest zamknięcie portów. Otwarte porty mogą znacząco zaszkodzić Twojemu urządzeniu lub już to zrobiły."
      },
      cookies: {
        info: "Wyłączenie może zapobiec śledzeniu przez sieci reklamowe i różne zasoby, co zwiększa prywatność. Zaleca się wyłączenie plików cookie lub ich regularne usuwanie."
      },
      blackList: {
        title: "Czarna lista",
        checks: "Czeki:",
        inBlackList: "Na czarnej liście:",
        buyProxy: "Kup czysty proxy"
      },
      flash: "Flash",
      java: "Java",
      activeX: {
        title: "ActiveX",
        info: "Element ActiveX posiada podatności na bezpieczeństwo i może być wykorzystywany do zainfekowania twojego komputera. Im więcej elementów ActiveX instalujesz, tym więcej stron może wykorzystać ich problemy, aby uszkodzić urządzenie."
      },
      webRtc: "WebRTC",
      webRtcIp: "WebRTC IPs",
      openPorts: {
        title: "Otwarte porty",
        links: "Skaner portów"
      },
      openPortsHTTP: {
        title: "Otwarte porty proxy HTTP",
        info: "Porty muszą być zamknięte. Otwarte porty mogą znacząco zaszkodzić lub już zaszkodziły Twojemu urządzeniu."
      },
      openPortsVPN: {
        title: "Otwarte porty VPN",
        info: "Konieczne jest zamknięcie portów. Otwarte porty mogą znacznie zaszkodzić lub już zaszkodziły twojemu urządzeniu."
      },
      openPortsWeb: "Otwórz porty proxy Web",
      doNotTrack: {
        title: "DoNotTrack",
        info: "Włączenie tej opcji dodaje pewną unikalność do cyfrowego odcisku palca przeglądarki.",
        link: "Włącz DNT"
      },
      dnsLeak: {
        title: "Wyciek DNS",
        info: "Trzecie strony mogą śledzić wszystkie twoje działania w sieci. Należy zmienić ustawienia DNS.",
        links: "Zmień DNS"
      },
      suspiciousHeadlines: "Podejrzane nagłówki",
      suspiciousPorts: "Podejrzane porty",
      twowayPing: "Dwukierunkowe pingowanie",
      replacementUserAgent: {
        title: "Zastąpienie UserAgent",
        info: "Na podstawie danych tworzących UserAgent z urządzenia, jeśli dane się różnią, oznacza to, że zachodzi zastąpienie."
      },
      yourHeadings: "Twoje nagłówki",
      showButton: {
        more: "Pokaż więcej",
        less: "Ukryj"
      }
    },
    faq: {
      text: []
    }
  },
  checkPorts: {
    analis: "Analiza w toku... Czekaj.",
    port: "Port",
    status: "Status",
    statusTrue: "dostępny",
    statusFalse: "niedostępne",
    description: "Opis",
    portStatus: "Stan portu na serwerze",
    scanType: "Typ skanu",
    mainTitle: "Sprawdzanie otwartych portów online",
    videoTitle: "Co to jest skaner portów i dlaczego?",
    headText:
      "Sprawdzenie otwartych portów za pomocą skanera pokazuje, które porty są otwarte na twoim lub cudzym komputerze/witrynie i co odpowiadają. Sprawdzenie odbywa się za pomocą skanera NMAP i pokazuje, jak otworzyć twój dostęp do komputera/strony dla świata zewnętrznego.",
    ipTitle: "Wyświetlacz:",
    ipElem: {
      placeholder: "Wpisz adres IP lub domenę",
      insertIp: "Wklej mój adres IP"
    },
    scanTypes: {
      title: "Rodzaje skanowanych portów:"
    },
    scanTitle: "Typ skanu:",
    startScan: "Rozpocznij skanowanie",
    resultCheck: "Wyniki testów:",
    resultCaption:
      "* Jeśli wynik to „Host nie działa”, oznacza to, że zapora lub router jest sprawdzany i blokuje adresy IP.",
    faq: {
      text: []
    }
  },
  claster: {
    mainTitle: "Narzędzie do grupowania słów kluczowych",
    checker: {
      regionTitle: "Sprawdzam region",
      selectPlaceholder: "Wybierz region",
      clasterTitle: "Próg klastrowania",
      groupBtn: "Grupa",
      queryTitle: "Upraszanie"
    },
    headText:
      "Dzięki tej usłudze masz dostęp do klastrowania zapytań online. Dzięki niemu możesz automatycznie grupować słowa kluczowe w oparciu o wyniki wyszukiwania Yandex i Google. Umiejętne zbudowanie struktury witryny zwiększy Twoje szanse na umieszczenie Twojej witryny na najwyższych pozycjach. usługa klastrowania zapytań online pomaga kategoryzować słowa kluczowe na stronach w taki sposób, aby uzyskać jak największy ruch SEO."
  },
  filterProxy: {
    mainTitle: "Filtr proxy",
    headText:
      "Filtrowanie proxy to proces wysyłania filtra proxy do głównego serwera w celu wybrania określonych rekomendacji.",
    filterOut: "Odfiltrować",
    save: "Zapisz do pliku"
  },
  ipv6: {
    mainTitle: "Sprawdzanie witryn na wsparcie IPv6",
    headText:
      "Wersja protokołu IPv6, która zapewnia maksymalną ochronę w sieci, wymaga użycia specyficznego dla witryny narzędzia wsparcia dla nowego protokołu proxy w celu wydajniejszego działania.",
    checker: {
      addressTitle: "Wpisz adres strony do weryfikacji",
      checkProxyBtn: "Sprawdź proxy",
      tip: "Test może chwilę potrwać",
      resultTitle: "Wyniki testów:",
      result: {
        success: "Gratulacje, ta strona obsługuje IPv6",
        error: "Niestety obecnie strona nie obsługuje protokołu IPv6"
      }
    },

    faq: {
      text: []
    }
  },
  myIp: {
    top: {
      title:
        "A to tylko niewielka część informacji, które można śledzić za pomocą twojego adresu IP:",
      ipv4: "Twój adres IPv4:",
      ipv6: "Twój adres IPv6:",
      changeIp: "Zmień swój adres IP",
      table: {
        check: "Sprawdzać",
        country: "Kraj",
        city: "Miasto",
        index: "Kod pocztowy",
        host: "Host",
        system: "System",
        browser: "Twoja przeglądarka",
        from: "Skąd jesteś",
        provider: "Dostawca",
        anon: "Anonimizator",
        proxy: "Pełnomocnik",
        protocol: "Protokół",
        imgAlt: {
          country: "Kraj",
          city: "Miasto",
          index: "Kod pocztowy",
          screen: "Monitor",
          os: "System operacyjny",
          browser: "Wersja przeglądarki",
          location: "Lokalizacja",
          server: "Serwer",
          anon: "Anonimizator",
          proxy: "Pełnomocnik",
          protocol: "Protokół",
          blacklist: "Czarna lista"
        }
      },
      map: {
        title: "Twoja lokalizacja na mapie!",
        hide: "Możesz ukryć swoją lokalizację",
        text: "Wystarczy skorzystać z naszego prostego, wygodnego i niezawodnego narzędzia. Ukryj dane osobowe, chroń ruch i usuń wszelkie ograniczenia ze swojego Internetu",
        hideIp: "Ukryj adres IP"
      },
      caution: {
        top: "Jak widać, dość szczegółowe informacje dostarcza IP.",
        bottom:
          "Po podłączeniu do Internetu komputerowi przypisywany jest numer o nazwie IP (adres protokołu internetowego). Należy zauważyć, że przypisanie nie jest arbitralne, ale adres IP, który jest dostępny dla dostawcy i jest bezpłatny. IP zmienia się po rozłączeniu, gdy ponowne łączenie. Te adresy IP są nazywane dynamicznymi. Jeśli chcesz, aby pozostały niezmienione, skontaktuj się z usługodawcą internetowym. Zazwyczaj niedynamiczny jest dostępny za opłatą. Nazywa się go również zewnętrznym. Często jest śledzony przez wyspecjalizowane programy online, określające towarzyszące informacje o właścicielu. Wewnętrzny adres IP lub lokalny nazywa się indywidualnym adresem komputera PC (laptopa) w sieci lokalnej."
      }
    },
    faq: {
      text: []
    }
  },
  pingIp: {
    mainTitle: "Sprawdź ping adresu IP lub serwisu",
    whatIsPing: "Co to jest ping IP i dlaczego?",
    headText: [
      "Kliknij przycisk „Wstaw mój adres IP” lub użyj adresu IP lub witryny, do której chcesz sprawdzić ping. Wybierz 3-5 pakietów testowych i kliknij przycisk „Ping IP”. czas = 'sekcja.",
      "Ping jest uważany za normalny, gdy mieści się w zakresie 40-110 ms. Od 110-210 ms zaczniesz doświadczać problemów w grach online, ale nie wpłynie to na twoje wizyty na stronach. Jeśli timeoutConstant przekroczy 110 ms, to jest powód, aby pomyśleć o jakości pracy Twojego dostawcy usług internetowych."
    ],
    checker: {
      showTitle: "Wyświetlacz:",
      showSubtitle:
        "Wprowadź prawidłowy adres IP (0.0.0.0) lub nazwę hosta (host.com)",
      ipInsert: "Wklej mój adres IP",
      addTitle: "Liczba opakowań:",
      selectPlaceholder: "Wybierz kraj",
      startScan: "Rozpocznij skanowanie",
      resultTitle: "Rozpocznij skanowanie:",
      resultCaption:
        "* Jeśli wynik to 'Host nie działa', oznacza to, że zapora lub router jest sprawdzany i blokuje adresy IP."
    },
    faq: {
      text: []
    }
  },
  proxyChecker: {
    mainTitle: "Proxy Checker",
    condition: "Status",
    ping: "Świst",
    type: "Typ",
    status: "Nie działa",
    workStatus: "Dostępny",
    head: {
      videoTitle: "Jak i dlaczego używać sprawdzania proxy?",
      headText:
        "Sprawdzanie proxy online, aby sprawdzić poprawność serwerów proxy. Analiza proxy lub grupy adresów pod kątem dostępności, kraju, szybkości, anonimowości, typu.",
      titleFirst: "Sprawdzanie proxy może:",
      listFirst: [
        "sprawdzanie IPv4, IPv6 pod kątem własności kraju;",
        "określenie rodzaju protokołu HTTPS, SOCKS5 oraz aktualnej prędkości;",
        "sprawdzenie anonimowości adresu;",
        "grupowe, szybkie wielowątkowe sprawdzanie proxy w 64 wątkach;",
        "możliwość wgrania listy i wgrania wyniku sprawdzenia;",
        "Wygodne wyświetlanie wyników przy użyciu filtra wyświetlania."
      ],
      titleSecond: "W jakim formacie należy dodać proxy do sprawdzania?",
      listSecond: [
        {
          content:
            "Jeśli masz <span>publiczne proxy</span> (bez loginu i hasła), następnie - IP: PORT"
        },
        {
          content:
            "Jeśli masz <span>prywatne proxy</span> (z autoryzacją przez login i hasło), a następnie - IP: PORT: UŻYTKOWNIK: PASS"
        }
      ],
      titleThird:
        "P.S.: Jeśli <a href='https://proxy-sale.com/' rel='noreferrer' target='_blank'> kupiłeś u nas proxy  </a>, to są one prywatne!"
    },
    faq: {
      text: []
    }
  },
  siteSpeed: {
    mainTitle: "Sprawdzić szybkość dostępu do strony przez proxy",
    head: {
      listFirst: [
        "Określanie szybkości transmisji danych przez proxy HTTP i SOCKS",
        "Możliwość bezpośredniego określenia szybkości ładowania stron",
        "Sprawdź dostęp do witryny"
      ],
      caution: "W jakim formacie należy przeprowadzić kontrolę?",
      subtitle:
        "Aby sprawdzić prędkość przez serwer proxy, musisz przekierować zasób, do którego potrzebujesz dostępu, oraz adres IP w formacie:",
      listSecond: [
        {
          text: "Proxy publiczne (bez loginu i hasła), następnie —",
          value: "IP:PORT"
        },
        {
          text: "Proxy prywatne (z autoryzacją loginu i hasła), a następnie —",
          value: "IP:PORT:USER:PASS"
        }
      ],
      post: "PS: Jeśli kupiłeś u nas proxy, to są one prywatne!"
    },
    checker: {
      adress: "Wpisz adres URL witryny, aby sprawdzić prędkość",
      proxy: "Adres proxy w formacie",
      format: "127.0.0.1:8080",
      subtitle: [
        "Aby przetestować szybkość transmisji danych przez proxy, wprowadź adres i port serwera proxy",
        "Aby przeprowadzić normalny test szybkości ładowania stron witryny, pozostaw to pole puste"
      ],
      type: {
        title: "Typ serwera proxy"
      },
      timeout: {
        title: "Koniec czasu:",
        placeholder: "Koniec czasu"
      },
      access: {
        title: "Sprawdź dostęp do strony",
        body: "Sprawdzanie dostępu i prędkości może zająć trochę czasu"
      },
      btn: "Sprawdź prędkość",
      result: {
        title: "Wyniki testów:",
        result: {
          titleFirst: "Sprawdź - szybkość dostępu do serwisu przez proxy",
          checkSite: "Sprawdzam witrynę",
          checkProxy: "Sprawdź proxy",
          timeout: "Koniec czasu",
          sec: "sec",
          amountOfDownloads: "Wyprodukowane próbne pliki do pobrania",
          titleSecond: "Sprawdzenie zakończone",
          averageSpeed: "Średnia prędkość pobierania",
          generalSpeed: "Całkowita średnia prędkość",
          speedValue: "Kb/sec",
          access: "Dostęp do witryny — tytuł z pobranej strony",
          emptyTitle: "Nie udało się uzyskać tytułu",
          isProxyUsing: {
            yes: "Korzystanie z proxy",
            no: "Bez proxy ładuj bezpośrednio"
          }
        }
      }
    },
    faq: {
      text: []
    },
    errors: {
      url: "Wejść na stronę url"
    }
  },
  spamBase: {
    mainTitle: "Obecność i sprawdzenie adresu IP na listach RBL",
    head: {
      text: "Jak wiadomo, spam może często powodować umieszczanie adresów IP na czarnej liście, co w zauważalny sposób zakłóca wszystkie operacje sieciowe. Aby tego uniknąć, powinieneś używać serwera sprawdzania IP w bazach spamu."
    },
    checker: {
      ip: {
        title: "Podaj poprawny adres IP",
        insert: "Wklej mój adres IP"
      },
      check: "Badanie",
      test: "Test może chwilę potrwać.",
      list: {
        checkedAll: "Czeki:",
        checked: "Wyrejestrowany:",
        blackList: "Na czarnej liście:"
      },
      result: {
        listed: "Katalogowany",
        notListed: "Nie wymienione"
      }
    },
    faq: {
      text: []
    }
  },
  trace: {
    mainTitle: "IP rysunek kalkowy",
    head: {
      text: "Dzięki usłudze na tej stronie masz dostęp do bezpłatnego i niemal natychmiastowego śladu adresu IP lub strony. Pomoże Ci przeprowadzić diagnostykę sieci, która określa prędkość wysyłania żądań przez serwery w Internecie, gdy otworzyć zasób sieciowy w przeglądarce określić przyczyny ewentualnych problemów związanych z powolnym ładowaniem strony."
    },
    checker: {
      ip: "Wprowadź poprawny adres IP (0.0.0.0) lub nazwę hosta (domain.ru)",
      route: {
        title: "Pokaż trasę paczki według kraju",
        placeholder: "Wybierz przedmiot"
      },
      btn: "Namierzać IP",
      result: "Wyniki testów:"
    }
  },
  webRtcCheck: {
    mainTitle: "Kontrola WebRTC",
    head: {
      text: "Sprawdzanie wycieków za pomocą WebRTC. Twoje dane mogą być zagrożone, nawet jeśli masz połączenie z VPN."
    },
    top: {
      myIp: "Twój adres IP:",
      from: "Skąd jesteś:",
      state: "Status:",
      warning: "Potencjalne zagrożenie"
    },
    info: {
      top: "Jak widać dość szczegółowych informacji dostarcza IP.",
      bottom:
        "Sprawdziliśmy, czy w Twojej przeglądarce jest aktywowany WebRTC i czy masz prawdziwy adres IP. Jak się okazuje, istnieje ryzyko potencjalnego wycieku, WebRTC jest włączone i może ujawnić Twój prawdziwy adres IP.",
      disableBottom:
        "Sprawdziliśmy, czy w Twojej przeglądarce jest aktywowany WebRTC i czy masz prawdziwy adres IP. Jak się okazuje, Twój prawdziwy adres IP pozostaje nieznany, a WebRTC nie jest aktywowany w Twojej przeglądarce."
    },
    resetButton: "Uruchom ponownie test szczelności WebRTC",
    status: {
      type: "Typ",
      state: "Status",
      topText: "Włączono WebRTC",
      bottomText: "Groźba ujawnienia Twojego prawdziwego adresu IP!",
      warning: "Zagrożenie wyciekiem",
      checking: "Sprawdź, proszę czekać"
    }
  },
  dnsCheck: {
    top: {
      myIp: "Twój adres IP:",
      from: "Skąd jesteś:",
      state: "Status:",
      warning: "Potencjalne zagrożenie"
    },
    info: {
      top: "Jak widzisz, podczas sprawdzania twojego adresu IP wykryto wyciek DNS.",
      bottom:
        "Przeskanowaliśmy twoje IP i wykryliśmy wyciek poprzez DNS. Twoje żądania zostały ujawnione, a połączenie nie jest bezpieczne. Oznacza to, że administrator Twojego DNS może śledzić informacje o odwiedzanych przez Ciebie stronach internetowych i programach.",
      disableBottom:
        "Przeskanowaliśmy twoje IP i nie wykryliśmy wycieku poprzez DNS. Twoje żądania nie zostały ujawnione, a połączenie jest bezpieczne. Oznacza to, że administrator Twojego DNS nie może śledzić informacji o odwiedzanych przez Ciebie stronach internetowych i programach."
    },
    resetButton: "Uruchom ponownie test szczelności DNS",
    status: {
      type: "Typ",
      state: "Status",
      topText: "Włączono DNS",
      bottomText: "Zagrożenie ujawnienia twoich zapytań!",
      warning: "Zagrożenie wyciekiem",
      checking: "Sprawdź, proszę czekać"
    }
  },
  fingerPrintScanner: {
    mainTitle: "Skaner linii papilarnych przeglądarki",
    head: {
      text: "Odciski palców przeglądarki stanowią unikalną migawkę ustawień Twojego komputera, a specjalne mechanizmy witryny internetowej mogą Cię śledzić."
    },
    top: {
      myIp: "Twój adres IP:",
      fingerprint: "Odcisk palca przeglądarki:"
    },
    table: {
      location: "Lokalizacja",
      provider: "Dostawca",
      host: "Gospodarz",
      os: "SO",
      browser: "Przeglądarka",
      plugin: "Podłącz:",
      status: "Status:",
      isOn: "włączony",
      isOff: "wyłączony",
      device: "Urządzenie",
      browserLanguage: "Język przeglądarki",
      screenSize: "Rozmiar ekranu",
      timeZone: "Strefa czasowa"
    },
    imgAlt: {
      location: "Lokalizacja",
      provider: "Dostawca",
      host: "Gospodarz",
      os: "system operacyjny",
      browser: "Przeglądarka",
      language: "Język przeglądarki",
      size: "Rozmiar ekranu",
      timeZone: "Strefa czasowa"
    },
    bottom: {
      method: "Metoda J.S"
    }
  },
  createEvercookie: {
    mainTitle: "Kontrola Evercookie",
    head: {
      text: "Kliknij przycisk, aby utworzyć evercookie. Twoja anonimowość nie zostanie naruszona: plik cookie to losowa liczba z zakresu od 1 do 1000, której używamy do testowania plików evercookies. Nie śledzimy, jak z niego korzystasz."
    },
    top: {
      button: "Utwórz Evercookie"
    }
  },
  httpHeaders: {
    requestHeaders: "Nagłówki żądań",
    responseHeaders: "Nagłówki odpowiedzi",
    submitButton: "Złożyć wniosek"
  },
  browserInfo: {
    mainTitle: "Informacje z Twojej przeglądarki",
    head: {
      text:
        "Za pomocą przeglądarki różne witryny i usługi mają możliwość śledzenia użytkownika. Jest to konieczne na przykład w celu zidentyfikowania osoby, nawet jeśli zmieniła ona swój adres IP. Systemy reklamowe wykorzystują również możliwości śledzenia, aby poznać zainteresowania użytkownika.\n" +
        "Nasza usługa pomoże Ci określić, które metody śledzenia są włączone w Twojej przeglądarce."
    },
    table: {
      type: "Typ",
      discovered: "Odkryty",
      cookieMeaning: "Znaczenie pliku cookie",
      parameter: "Parametr",
      value: "Wartość",
      resolution: "Rozdzielczość ekranu:",
      desktop: "Pulpit",
      colorDepth: "Głębia koloru",
      bit: "fragment"
    },
    button: {
      createCookie: "Utwórz pliki cookie",
      userTracking: "Śledzenie użytkownika"
    }
  },
  utm: {
    mainTitle: "Generator tagów online UTM",
    checker: {
      linkUtm: "Tutaj pojawi się link z tagiem UTM",
      copy: "Kopiuj",
      shortUrl: "Krótki URL",
      traffic: [
        { text: "Google ADS", source: "google" },
        { text: "Yandex.Direct", source: "yandex" },
        { text: "VK.com", source: "vk" },
        { text: "Facebook", source: "facebook" },
        { text: "Instagram", source: "instagram" },
        { text: "Target.Mail.ru", source: "targetmailru" },
        { reset: true, text: "Reset" }
      ]
    },
    faq: {
      text: []
    }
  },
  pageHead: {
    ip: "Twój IP",
    changeIp: "Zmień IP",
    anon: "Twoja anonimowość",
    moreAnon: "Dowiedz się więcej o anonimowości"
  },
  checker: {
    country: "Wybierz kraj:",
    add: "Dodaj jako pierwszy:",
    show: {
      title: "Wyświetlacz"
    },
    displayType: "Wyświetlacz",
    checkProxy: "Sprawdź proxy",
    fileName: "Załaduj z .txt lub .csv",
    typeProxy: {
      title: "Wpisz proxy:",
      placeholder: "Tutaj wpisz swój tekst"
    },
    info: {
      text: "Pozbądź się ograniczeń i niedogodności, ryzyka zbanowania lub trwałej zmiany adresu IP. Osobiste proxy do pracy z sieciami społecznościowymi, Avito, Key Collector i innymi celami.",
      buy: "Kup ochronę"
    },
    results: {
      title: "Wyniki testów",
      checked: "Sprawdzony",
      working: "Pracujący",
      notWorking: "Nie działa",
      divider: "z",
      btn: "Kup działający"
    },
    download: {
      title: "Pobierz tylko serwer proxy pracy",
      txt: "sciągnij .txt",
      csv: "stół w .csv"
    }
  },
  common: {
    faqTitle: "Odpowiadamy na Twoje pytania",
    yes: "Tak",
    no: "Nie",
    notDefined: "Nie określono",
    copy: "Skopiowane!",
    select: {
      placeholderCountry: "Wybierz kraj"
    },
    buyAnonProxy: "Kup anonimowość",
    buyWorkingProxy: "Kup działający"
  },
  table: {
    top: {
      workProxy: "Pełnomocnicy pracujący",
      anon: "Anonimowy",
      socks: "Socks 4/5",
      http: "HTTP/HTTPS",
      proxyInBase: "Pełnomocnik w bazie danych"
    },
    tableColumns: {
      copy: "Skopiuj do schowka",
      country: "Kraj",
      type: "Typ",
      anon: {
        title: "Anonimowość",
        speed: "Prędkość",
        s: "s",
        ms: "ms",
        m: "m",
        h: "h",
        types: {
          anon: "Anonimowość",
          proz: "Przezroczysty",
          elite: "Elita",
          notFounded: "Nie założony"
        }
      },
      check: {
        title: "Badanie",
        tip: "Podpowiedź",
        buy: "Kup działający",
        reload: "Ponowne uruchomienie",
        ago: "mins. ago",
        status: {
          work: "Pracujący",
          notWorked: "Nie działa"
        }
      },
      condition: {
        title: "Schorzenie"
      },
      login: "Zaloguj sie",
      password: "Hasło",
      pagination: {
        rowsPerPage: "Proxy dla strony",
        separatorText: "z"
      },
      requests: "Upraszanie",
      requestsCluster: "Żądania dotyczące klastrów",
      competitors: "Zawodnicy"
    },
    noDataComponent: "Brak proxy"
  },
  errorTranslation: {
    somethingWrong: "Coś poszło nie tak, spróbuj ponownie",
    largeProxyList:
      "Liczba pełnomocników do weryfikacji nie powinna przekraczać 100",
    emptyProxy: "Brakujące proxy do eksportu",
    apiError: "Coś poszło nie tak!",
    wrongFile: "Nieprawidłowy typ pliku. Dozwolone tylko .txt lub .csv",
    nonReadableFile: "Nie można odczytać pliku!",
    emptyProxyList: "Wpisz proxy!",
    emptyClusterQuery: "Wpisz prośby!",
    clusterQueryMinValue: "Wpisz nie mniej niż 2 prośby!",
    incorrectIpOrDomain: "Nieprawidłowy adres IP lub domena! ",
    incorrectIp: "Nieprawidłowy adres IP! ",
    proxyFilterEmpty: "Wpisz proxy!",
    proxyIsEmpty: "Ten kraj nie ma proxy!",
    proxyFilterIncorrect: "Nieprawidłowy serwer proxy!",
    emptyIpOrDomain: "Wpisz poprawny adres IP lub domenę!",
    emptySiteUrl: "Wpisz adres URL witryny!",
    emptyUserAgent: "Wybierz agenta użytkownika!",
    invalidUrl: "Nieprawidłowy format URL!",
    timeError: "Spróbuj ponownie za kilka minut!",
    IncorrectUrlFormat: "Nieprawidłowy format adresu URL!"
  },
  anonimityTypes: [
    {
      value: "an-anonim.txt",
      label: "Anonimowy",
      defaultChecked: false,
      route: "anonimnyj"
    },
    {
      value: "el-elit.txt",
      label: "Elita",
      defaultChecked: false,
      route: "elite"
    },
    {
      value: "pr-proz.txt",
      label: "Przezroczysty",
      defaultChecked: false,
      route: "transparent"
    }
  ],
  browsers: [
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/chrome.svg",
      value: "Google Chrome"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/safari.svg",
      value: "Safari"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/opera.svg",
      value: "Opera"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/firefox.svg",
      value: "Firefox"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/yandex.svg",
      value: "Yandex"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/android.svg",
      value: "Android"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/explorer.svg",
      value: "Internet Explorer"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/browser1.svg",
      value: "Browser"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      value: "+",
      last: true
    }
  ],
  checkerProxyTypes: [
    {
      value: "HTTP,HTTPS",
      label: "HTTP/HTTPS",
      defaultChecked: true
    },
    {
      value: "SOCKS,SOCKS5",
      label: "SOCKS 4/5",
      defaultChecked: true
    },
    {
      value: "NO",
      label: "Nie pracujący",
      defaultChecked: true
    }
  ],
  checkPortsTypes: [
    {
      value: "POPULAR",
      label: "Popularne porty",
      defaultChecked: true
    },
    {
      value: "SERVER",
      label: "Porty serwerowe",
      defaultChecked: false
    },
    {
      value: "GAME",
      label: "Porty gier",
      defaultChecked: false
    },
    {
      value: "APPLICATION",
      label: "Porty aplikacji",
      defaultChecked: false
    },
    {
      value: "P2P",
      label: "Porty P2P",
      defaultChecked: false
    }
  ],
  utmInputs: [
    {
      label: "Wstęp url",
      name: "url",
      placeholder: "Tutaj wklej swój link lub stronę docelową"
    },
    {
      label: "UTM-źródło*",
      name: "source",
      placeholder: "Źródło ruchu. Na przykład: google"
    },
    {
      label: "UTM-średni*",
      name: "medium",
      placeholder: "Rodzaj ruchu. Na przykład: cpc, e-mail, baner"
    },
    {
      label: "UTM-kampania*",
      name: "campaign",
      placeholder: "Firma reklamowa, na przykład: wyszukiwarka google"
    },
    {
      label: "UTM-zawartość",
      name: "content",
      placeholder: "Na przykład: cpc, e-mail, baner"
    },
    {
      label: "UTM-termin",
      name: "term",
      placeholder: "Słowo kluczowe. Na przykład: makro (słowo kluczowe)"
    }
  ],
  yesNo: [
    { label: "Tak", value: true },
    { label: "Nie", value: false }
  ],
  timeout: [
    { label: "10 sek", value: 10 },
    { label: "15 sek", value: 15 },
    { label: "30 sek", value: 30 },
    { label: "60 sek", value: 60 }
  ],
  countries: [
    {
      dataBaseValue: "Andorra",
      value: "Andora",
      label: "Andora",
      imgSrc: "/img/flags/ad.svg"
    },
    {
      dataBaseValue: "UAE",
      value: "ZEA",
      label: "ZEA",
      imgSrc: "/img/flags/ae.svg"
    },
    {
      dataBaseValue: "Afghanistan",
      value: "Afganistan",
      label: "Afganistan",
      imgSrc: "/img/flags/af.svg"
    },
    {
      dataBaseValue: "Antigua and Barbuda",
      value: "Antigua i Barbuda",
      label: "Antigua i Barbuda",
      imgSrc: "/img/flags/ag.svg"
    },
    {
      dataBaseValue: "Anguilla",
      value: "Anguilla",
      label: "Anguilla",
      imgSrc: "/img/flags/ai.svg"
    },
    {
      dataBaseValue: "Albania",
      value: "Albania",
      label: "Albania",
      imgSrc: "/img/flags/al.svg"
    },
    {
      dataBaseValue: "Armenia",
      value: "Armenia",
      label: "Armenia",
      imgSrc: "/img/flags/am.svg"
    },
    {
      dataBaseValue: "Antilles",
      value: "Antyle",
      label: "Antyle",
      imgSrc: "/img/flags/an.svg"
    },
    {
      dataBaseValue: "Angola",
      value: "Angola",
      label: "Angola",
      imgSrc: "/img/flags/ao.svg"
    },
    {
      dataBaseValue: "Antarctic",
      value: "Antarktyda",
      label: "Antarktyda",
      imgSrc: "/img/flags/aq.svg"
    },
    {
      dataBaseValue: "Argentina",
      value: "Argentyna",
      label: "Argentyna",
      imgSrc: "/img/flags/ar.svg"
    },
    {
      dataBaseValue: "American Samoa",
      value: "Samoa Amerykańskie",
      label: "Samoa Amerykańskie",
      imgSrc: "/img/flags/as.svg"
    },
    {
      dataBaseValue: "Austria",
      value: "Austria",
      label: "Austria",
      imgSrc: "/img/flags/at.svg"
    },
    {
      dataBaseValue: "Australia",
      value: "Australia",
      label: "Australia",
      imgSrc: "/img/flags/au.svg"
    },
    {
      dataBaseValue: "Aruba",
      value: "Aruba",
      label: "Aruba",
      imgSrc: "/img/flags/aw.svg"
    },
    {
      dataBaseValue: "Åland Islands",
      value: "Wyspy Alandzkie",
      label: "Wyspy Alandzkie",
      imgSrc: "/img/flags/ax.svg"
    },
    {
      dataBaseValue: "Azerbaijan",
      value: "Azerbejdżan",
      label: "Azerbejdżan",
      imgSrc: "/img/flags/az.svg"
    },
    {
      dataBaseValue: "Bosnia and Herzegovina",
      value: "Bośnia i Hercegowina",
      label: "Bośnia i Hercegowina",
      imgSrc: "/img/flags/ba.svg"
    },
    {
      dataBaseValue: "Barbados",
      value: "Barbados",
      label: "Barbados",
      imgSrc: "/img/flags/bb.svg"
    },
    {
      dataBaseValue: "Bangladesh",
      value: "Bangladesz",
      label: "Bangladesz",
      imgSrc: "/img/flags/bd.svg"
    },
    {
      dataBaseValue: "Belgium",
      value: "Belgia",
      label: "Belgia",
      imgSrc: "/img/flags/be.svg"
    },
    {
      dataBaseValue: "Burkina Faso",
      value: "Burkina Faso",
      label: "Burkina Faso",
      imgSrc: "/img/flags/bf.svg"
    },
    {
      dataBaseValue: "Bulgaria",
      value: "Bułgaria",
      label: "Bułgaria",
      imgSrc: "/img/flags/bg.svg"
    },
    {
      dataBaseValue: "Bahrain",
      value: "Bahrajn",
      label: "Bahrajn",
      imgSrc: "/img/flags/bh.svg"
    },
    {
      dataBaseValue: "Burundi",
      value: "Burundi",
      label: "Burundi",
      imgSrc: "/img/flags/bi.svg"
    },
    {
      dataBaseValue: "Benin",
      value: "Benin",
      label: "Benin",
      imgSrc: "/img/flags/bj.svg"
    },
    {
      dataBaseValue: "Saint Barthélemy",
      value: "Saint Barthelemy",
      label: "Saint Barthelemy",
      imgSrc: "/img/flags/bl.svg"
    },
    {
      dataBaseValue: "Bermuda",
      value: "Bermudy",
      label: "Bermudy",
      imgSrc: "/img/flags/bm.svg"
    },
    {
      dataBaseValue: "Brunei",
      value: "Brunei",
      label: "Brunei",
      imgSrc: "/img/flags/bn.svg"
    },
    {
      dataBaseValue: "Bolivia",
      value: "Boliwia",
      label: "Boliwia",
      imgSrc: "/img/flags/bo.svg"
    },
    {
      dataBaseValue: "Bonaire",
      value: "Bonaire",
      label: "Bonaire",
      imgSrc: "/img/flags/bq.svg"
    },
    {
      dataBaseValue: "Brazil",
      value: "Brazylia",
      label: "Brazylia",
      imgSrc: "/img/flags/br.svg"
    },
    {
      dataBaseValue: "Bahamas",
      value: "Bahamy",
      label: "Bahamy",
      imgSrc: "/img/flags/bs.svg"
    },
    {
      dataBaseValue: "Butane",
      value: "Butan",
      label: "Butan",
      imgSrc: "/img/flags/bt.svg"
    },
    {
      dataBaseValue: "Bouvet Island",
      value: "Wyspa Bouveta",
      label: "Wyspa Bouveta",
      imgSrc: "/img/flags/bv.svg"
    },
    {
      dataBaseValue: "Botswana",
      value: "Botswana",
      label: "Botswana",
      imgSrc: "/img/flags/bw.svg"
    },
    {
      dataBaseValue: "Belarus",
      value: "Białoruś",
      label: "Białoruś",
      imgSrc: "/img/flags/by.svg"
    },
    {
      dataBaseValue: "Belize",
      value: "Belize",
      label: "Belize",
      imgSrc: "/img/flags/bz.svg"
    },
    {
      dataBaseValue: "Canada",
      value: "Kanada",
      label: "Kanada",
      imgSrc: "/img/flags/ca.svg"
    },
    {
      dataBaseValue: "Coconut islands",
      value: "Wyspy kokosowe",
      label: "Wyspy kokosowe",
      imgSrc: "/img/flags/cc.svg"
    },
    {
      dataBaseValue: "DR Congo",
      value: "DR Kongo",
      label: "DR Kongo",
      imgSrc: "/img/flags/cd.svg"
    },
    {
      dataBaseValue: "Central African Republic",
      value: "Republika Środkowoafrykańska",
      label: "Republika Środkowoafrykańska",
      imgSrc: "/img/flags/cf.svg"
    },
    {
      dataBaseValue: "Congo",
      value: "Kongo",
      label: "Kongo",
      imgSrc: "/img/flags/cg.svg"
    },
    {
      dataBaseValue: "Switzerland",
      value: "Szwajcaria",
      label: "Szwajcaria",
      imgSrc: "/img/flags/ch.svg"
    },
    {
      dataBaseValue: "Ivory Coast",
      value: "Wybrzeże Kości Słoniowej",
      label: "Wybrzeże Kości Słoniowej",
      imgSrc: "/img/flags/ci.svg"
    },
    {
      dataBaseValue: "Cook Islands",
      value: "Wyspy Cooka",
      label: "Wyspy Cooka",
      imgSrc: "/img/flags/ck.svg"
    },
    {
      dataBaseValue: "Chile",
      value: "Chile",
      label: "Chile",
      imgSrc: "/img/flags/cl.svg"
    },
    {
      dataBaseValue: "Cameroon",
      value: "Kamerun",
      label: "Kamerun",
      imgSrc: "/img/flags/cm.svg"
    },
    {
      dataBaseValue: "China",
      value: "Chiny",
      label: "Chiny",
      imgSrc: "/img/flags/cn.svg"
    },
    {
      dataBaseValue: "Colombia",
      value: "Kolumbia",
      label: "Kolumbia",
      imgSrc: "/img/flags/co.svg"
    },
    {
      dataBaseValue: "Costa Rica",
      value: "Kostaryka",
      label: "Kostaryka",
      imgSrc: "/img/flags/cr.svg"
    },
    {
      dataBaseValue: "Cuba",
      value: "Kuba",
      label: "Kuba",
      imgSrc: "/img/flags/cu.svg"
    },
    {
      dataBaseValue: "Cape Verde",
      value: "Wyspy Zielonego Przylądka",
      label: "Wyspy Zielonego Przylądka",
      imgSrc: "/img/flags/cv.svg"
    },
    {
      dataBaseValue: "Curacao",
      value: "Curaçao",
      label: "Curaçao",
      imgSrc: "/img/flags/cw.svg"
    },
    {
      dataBaseValue: "Christmas Island",
      value: "Wyspa Bożego Narodzenia",
      label: "Wyspa Bożego Narodzenia",
      imgSrc: "/img/flags/cx.svg"
    },
    {
      dataBaseValue: "Cyprus",
      value: "Cypr",
      label: "Cypr",
      imgSrc: "/img/flags/cy.svg"
    },
    {
      dataBaseValue: "Czech Republic",
      value: "Republika Czeska",
      label: "Republika Czeska",
      imgSrc: "/img/flags/cz.svg"
    },
    {
      dataBaseValue: "Germany",
      value: "Niemcy",
      label: "Niemcy",
      imgSrc: "/img/flags/de.svg"
    },
    {
      dataBaseValue: "Djibouti",
      value: "Dżibuti",
      label: "Dżibuti",
      imgSrc: "/img/flags/dj.svg"
    },
    {
      dataBaseValue: "Denmark",
      value: "Dania",
      label: "Dania",
      imgSrc: "/img/flags/dk.svg"
    },
    {
      dataBaseValue: "Dominica",
      value: "Dominika",
      label: "Dominika",
      imgSrc: "/img/flags/dm.svg"
    },
    {
      dataBaseValue: "Dominican Republic",
      value: "Republika Dominikany",
      label: "Republika Dominikany",
      imgSrc: "/img/flags/do.svg"
    },
    {
      dataBaseValue: "Algeria",
      value: "Algeria",
      label: "Algeria",
      imgSrc: "/img/flags/dz.svg"
    },
    {
      dataBaseValue: "Ecuador",
      value: "Ekwador",
      label: "Ekwador",
      imgSrc: "/img/flags/ec.svg"
    },
    {
      dataBaseValue: "Estonia",
      value: "Estonia",
      label: "Estonia",
      imgSrc: "/img/flags/ee.svg"
    },
    {
      dataBaseValue: "Egypt",
      value: "Egipt",
      label: "Egipt",
      imgSrc: "/img/flags/eg.svg"
    },
    {
      dataBaseValue: "West Sahara",
      value: "Sahara Zachodnia",
      label: "Sahara Zachodnia",
      imgSrc: "/img/flags/eh.svg"
    },
    {
      dataBaseValue: "Eritrea",
      value: "Erytrea",
      label: "Erytrea",
      imgSrc: "/img/flags/er.svg"
    },
    {
      dataBaseValue: "Spain",
      value: "Hiszpania",
      label: "Hiszpania",
      imgSrc: "/img/flags/es.svg"
    },
    {
      dataBaseValue: "Ethiopia",
      value: "Etiopia",
      label: "Etiopia",
      imgSrc: "/img/flags/et.svg"
    },
    {
      dataBaseValue: "Europe",
      value: "Europa",
      label: "Europa",
      imgSrc: "/img/flags/eu.svg"
    },
    {
      dataBaseValue: "Finland",
      value: "Finlandia",
      label: "Finlandia",
      imgSrc: "/img/flags/fi.svg"
    },
    {
      dataBaseValue: "Netherlands",
      value: "Holandia",
      label: "Holandia",
      imgSrc: "/img/flags/nl.svg"
    },
    {
      dataBaseValue: "Fiji",
      value: "Fidżi",
      label: "Fidżi",
      imgSrc: "/img/flags/fj.svg"
    },
    {
      dataBaseValue: "Falkland Islands",
      value: "Falklandy",
      label: "Falklandy",
      imgSrc: "/img/flags/fk.svg"
    },
    {
      dataBaseValue: "Federated States of Micronesia",
      value: "Sfederowane Stany Mikronezji",
      label: "Sfederowane Stany Mikronezji",
      imgSrc: "/img/flags/fm.svg"
    },
    {
      dataBaseValue: "Faroe Islands",
      value: "Wyspy Owcze",
      label: "Wyspy Owcze",
      imgSrc: "/img/flags/fo.svg"
    },
    {
      dataBaseValue: "France",
      value: "Francja",
      label: "Francja",
      imgSrc: "/img/flags/fr.svg"
    },
    {
      dataBaseValue: "Georgia",
      value: "Gruzja",
      label: "Gruzja",
      imgSrc: "/img/flags/ge.svg"
    },
    // {
    //   dataBaseValue: "United Kingdom",
    //   value: "Zjednoczone Królestwo",
    //   label: "Zjednoczone Królestwo",
    //   imgSrc: "/img/flags/en.svg"
    // },
    {
      dataBaseValue: "Northern Ireland",
      value: "Irlandia Północna",
      label: "Irlandia Północna",
      imgSrc: "/img/flags/nir.svg"
    },
    {
      dataBaseValue: "Scotland",
      value: "Szkocja",
      label: "Szkocja",
      imgSrc: "/img/flags/sct.svg"
    },
    {
      dataBaseValue: "Wales",
      value: "Walia",
      label: "Walia",
      imgSrc: "/img/flags/wls.svg"
    },
    {
      dataBaseValue: "Grenada",
      value: "Grenada",
      label: "Grenada",
      imgSrc: "/img/flags/gd.svg"
    },
    {
      dataBaseValue: "French Guiana",
      value: "Gujana Francuska",
      label: "Gujana Francuska",
      imgSrc: "/img/flags/gf.svg"
    },
    {
      dataBaseValue: "Guernsey",
      value: "Guernsey",
      label: "Guernsey",
      imgSrc: "/img/flags/gg.svg"
    },
    {
      dataBaseValue: "Ghana",
      value: "Ghana",
      label: "Ghana",
      imgSrc: "/img/flags/gh.svg"
    },
    {
      dataBaseValue: "Gibraltar",
      value: "Gibraltar",
      label: "Gibraltar",
      imgSrc: "/img/flags/gi.svg"
    },
    {
      dataBaseValue: "Greenland",
      value: "Grenlandia",
      label: "Grenlandia",
      imgSrc: "/img/flags/gl.svg"
    },
    {
      dataBaseValue: "Gambia",
      value: "Gambia",
      label: "Gambia",
      imgSrc: "/img/flags/gm.svg"
    },
    {
      dataBaseValue: "Guinea",
      value: "Gwinea",
      label: "Gwinea",
      imgSrc: "/img/flags/gn.svg"
    },
    {
      dataBaseValue: "Guadeloupe",
      value: "Gwadelupa",
      label: "Gwadelupa",
      imgSrc: "/img/flags/gp.svg"
    },
    {
      dataBaseValue: "Equatorial Guinea",
      value: "Gwinea Równikowa",
      label: "Gwinea Równikowa",
      imgSrc: "/img/flags/gq.svg"
    },
    {
      dataBaseValue: "Greece",
      value: "Grecja",
      label: "Grecja",
      imgSrc: "/img/flags/gr.svg"
    },
    {
      dataBaseValue: "South Georgia and the South Sandwich Islands",
      value: "Georgia Południowa i Sandwich Południowy",
      label: "Georgia Południowa i Sandwich Południowy",
      imgSrc: "/img/flags/gs.svg"
    },
    {
      dataBaseValue: "Guatemala",
      value: "Gwatemala",
      label: "Gwatemala",
      imgSrc: "/img/flags/gt.svg"
    },
    {
      dataBaseValue: "Guam",
      value: "Guam",
      label: "Guam",
      imgSrc: "/img/flags/gu.svg"
    },
    {
      dataBaseValue: "Guinea-Bissau",
      value: "Gwinea Bissau",
      label: "Gwinea Bissau",
      imgSrc: "/img/flags/gw.svg"
    },
    {
      dataBaseValue: "Guyana",
      value: "Gujana",
      label: "Gujana",
      imgSrc: "/img/flags/gy.svg"
    },
    {
      dataBaseValue: "Hong Kong",
      value: "Hong Kong",
      label: "Hong Kong",
      imgSrc: "/img/flags/hk.svg"
    },
    {
      dataBaseValue: "Honduras",
      value: "Honduras",
      label: "Honduras",
      imgSrc: "/img/flags/hn.svg"
    },
    {
      dataBaseValue: "Croatia",
      value: "Chorwacja",
      label: "Chorwacja",
      imgSrc: "/img/flags/hr.svg"
    },
    {
      dataBaseValue: "Haiti",
      value: "Haiti",
      label: "Haiti",
      imgSrc: "/img/flags/ht.svg"
    },
    {
      dataBaseValue: "Hungary",
      value: "Węgry",
      label: "Węgry",
      imgSrc: "/img/flags/hu.svg"
    },
    {
      dataBaseValue: "Indonesia",
      value: "Indonezja",
      label: "Indonezja",
      imgSrc: "/img/flags/id.svg"
    },
    {
      dataBaseValue: "Ireland",
      value: "Irlandia",
      label: "Irlandia",
      imgSrc: "/img/flags/ie.svg"
    },
    {
      dataBaseValue: "Israel",
      value: "Izrael",
      label: "Izrael",
      imgSrc: "/img/flags/il.svg"
    },
    {
      dataBaseValue: "Isle of Man",
      value: "Wyspa Man",
      label: "Wyspa Man",
      imgSrc: "/img/flags/im.svg"
    },
    {
      dataBaseValue: "India",
      value: "Indie",
      label: "Indie",
      imgSrc: "/img/flags/in.svg"
    },
    {
      dataBaseValue: "British Indian Ocean Territory",
      value: "Brytyjskie Terytorium Oceanu Indyjskiego",
      label: "Brytyjskie Terytorium Oceanu Indyjskiego",
      imgSrc: "/img/flags/io.svg"
    },
    {
      dataBaseValue: "Iraq",
      value: "Irak",
      label: "Irak",
      imgSrc: "/img/flags/iq.svg"
    },
    {
      dataBaseValue: "Iran",
      value: "Iran",
      label: "Iran",
      imgSrc: "/img/flags/ir.svg"
    },
    {
      dataBaseValue: "Italy",
      value: "Włochy",
      label: "Włochy",
      imgSrc: "/img/flags/it.svg"
    },
    {
      dataBaseValue: "Jersey",
      value: "Golf",
      label: "Golf",
      imgSrc: "/img/flags/je.svg"
    },
    {
      dataBaseValue: "Jamaica",
      value: "Jamajka",
      label: "Jamajka",
      imgSrc: "/img/flags/jm.svg"
    },
    {
      dataBaseValue: "Jordan",
      value: "Jordania",
      label: "Jordania",
      imgSrc: "/img/flags/jo.svg"
    },
    {
      dataBaseValue: "Japan",
      value: "Japonia",
      label: "Japonia",
      imgSrc: "/img/flags/jp.svg"
    },
    {
      dataBaseValue: "Kenya",
      value: "Kenia",
      label: "Kenia",
      imgSrc: "/img/flags/ke.svg"
    },
    {
      dataBaseValue: "Kyrgyzstan",
      value: "Kirgistan",
      label: "Kirgistan",
      imgSrc: "/img/flags/kg.svg"
    },
    {
      dataBaseValue: "Cambodia",
      value: "Kambodża",
      label: "Kambodża",
      imgSrc: "/img/flags/kh.svg"
    },
    {
      dataBaseValue: "Kiribati",
      value: "Kiribati",
      label: "Kiribati",
      imgSrc: "/img/flags/ki.svg"
    },
    {
      dataBaseValue: "Comoros",
      value: "Komory",
      label: "Komory",
      imgSrc: "/img/flags/km.svg"
    },
    {
      dataBaseValue: "Saint Kitts and Nevis",
      value: "Saint Kitts i Nevis",
      label: "Saint Kitts i Nevis",
      imgSrc: "/img/flags/kn.svg"
    },
    {
      dataBaseValue: "Democratic People's Republic of Korea",
      value: "Koreańska Republika Ludowo-Demokratyczna",
      label: "Koreańska Republika Ludowo-Demokratyczna",
      imgSrc: "/img/flags/kp.svg"
    },
    {
      dataBaseValue: "South Korea",
      value: "Korea Południowa",
      label: "Korea Południowa",
      imgSrc: "/img/flags/kr.svg"
    },
    {
      dataBaseValue: "Kuwait",
      value: "Kuwejt",
      label: "Kuwejt",
      imgSrc: "/img/flags/kw.svg"
    },
    {
      dataBaseValue: "Cayman Islands",
      value: "Kajmany",
      label: "Kajmany",
      imgSrc: "/img/flags/ky.svg"
    },
    {
      dataBaseValue: "Kazakhstan",
      value: "Kazachstan",
      label: "Kazachstan",
      imgSrc: "/img/flags/kz.svg"
    },
    {
      dataBaseValue: "Laos",
      value: "Laos",
      label: "Laos",
      imgSrc: "/img/flags/la.svg"
    },
    {
      dataBaseValue: "Lebanon",
      value: "Liban",
      label: "Liban",
      imgSrc: "/img/flags/lb.svg"
    },
    {
      dataBaseValue: "Saint Lucia",
      value: "święta Lucia",
      label: "święta Lucia",
      imgSrc: "/img/flags/lc.svg"
    },
    {
      dataBaseValue: "Liechtenstein",
      value: "Liechtenstein",
      label: "Liechtenstein",
      imgSrc: "/img/flags/li.svg"
    },
    {
      dataBaseValue: "Sri Lanka",
      value: "Sri Lanka",
      label: "Sri Lanka",
      imgSrc: "/img/flags/lk.svg"
    },
    {
      dataBaseValue: "Liberia",
      value: "Liberia",
      label: "Liberia",
      imgSrc: "/img/flags/lr.svg"
    },
    {
      dataBaseValue: "Lesotho",
      value: "Lesoto",
      label: "Lesoto",
      imgSrc: "/img/flags/ls.svg"
    },
    {
      dataBaseValue: "Lithuania",
      value: "Litwa",
      label: "Litwa",
      imgSrc: "/img/flags/lt.svg"
    },
    {
      dataBaseValue: "Luxembourg",
      value: "Luksemburg",
      label: "Luksemburg",
      imgSrc: "/img/flags/lu.svg"
    },
    {
      dataBaseValue: "Latvia",
      value: "Łotwa",
      label: "Łotwa",
      imgSrc: "/img/flags/lv.svg"
    },
    {
      dataBaseValue: "Libya",
      value: "Libia",
      label: "Libia",
      imgSrc: "/img/flags/ly.svg"
    },
    {
      dataBaseValue: "Morocco",
      value: "Maroko",
      label: "Maroko",
      imgSrc: "/img/flags/ma.svg"
    },
    {
      dataBaseValue: "Monaco",
      value: "Monako",
      label: "Monako",
      imgSrc: "/img/flags/mc.svg"
    },
    {
      dataBaseValue: "Moldova",
      value: "Moldova",
      label: "Moldova",
      imgSrc: "/img/flags/md.svg"
    },
    {
      dataBaseValue: "Montenegro",
      value: "Czarnogóra",
      label: "Czarnogóra",
      imgSrc: "/img/flags/me.svg"
    },
    {
      dataBaseValue: "Saint Martin",
      value: "święty Marcin",
      label: "święty Marcin",
      imgSrc: "/img/flags/mf.svg"
    },
    {
      dataBaseValue: "Madagascar",
      value: "Madagaskar",
      label: "Madagaskar",
      imgSrc: "/img/flags/mg.svg"
    },
    {
      dataBaseValue: "Marshall Islands",
      value: "Wyspy Marshalla",
      label: "Wyspy Marshalla",
      imgSrc: "/img/flags/mh.svg"
    },
    {
      dataBaseValue: "Macedonia",
      value: "Macedonia",
      label: "Macedonia",
      imgSrc: "/img/flags/mk.svg"
    },
    {
      dataBaseValue: "Mali",
      value: "Mali",
      label: "Mali",
      imgSrc: "/img/flags/ml.svg"
    },
    {
      dataBaseValue: "Myanmar",
      value: "Myanmar",
      label: "Myanmar",
      imgSrc: "/img/flags/mm.svg"
    },
    {
      dataBaseValue: "Mongolia",
      value: "Mongolia",
      label: "Mongolia",
      imgSrc: "/img/flags/mn.svg"
    },
    {
      dataBaseValue: "Macau",
      value: "Makau",
      label: "Makau",
      imgSrc: "/img/flags/mo.svg"
    },
    {
      dataBaseValue: "Northern Mariana Islands",
      value: "Mariany Północne",
      label: "Mariany Północne",
      imgSrc: "/img/flags/mp.svg"
    },
    {
      dataBaseValue: "Martinique",
      value: "Martynika",
      label: "Martynika",
      imgSrc: "/img/flags/mq.svg"
    },
    {
      dataBaseValue: "Mauritius",
      value: "Mauritania",
      label: "Mauritania",
      imgSrc: "/img/flags/mr.svg"
    },
    {
      dataBaseValue: "Montserrat",
      value: "Montserrat",
      label: "Montserrat",
      imgSrc: "/img/flags/ms.svg"
    },
    {
      dataBaseValue: "Malta",
      value: "Malta",
      label: "Malta",
      imgSrc: "/img/flags/mt.svg"
    },
    {
      dataBaseValue: "O. Mauritius",
      value: "O. Mauritius",
      label: "O. Mauritius",
      imgSrc: "/img/flags/mu.svg"
    },
    {
      dataBaseValue: "Maldives",
      value: "Malediwy",
      label: "Malediwy",
      imgSrc: "/img/flags/mv.svg"
    },
    {
      dataBaseValue: "Malawi",
      value: "Malawi",
      label: "Malawi",
      imgSrc: "/img/flags/mw.svg"
    },
    {
      dataBaseValue: "Mexico",
      value: "Meksyk",
      label: "Meksyk",
      imgSrc: "/img/flags/mx.svg"
    },
    {
      dataBaseValue: "Malaysia",
      value: "Malezja",
      label: "Malezja",
      imgSrc: "/img/flags/my.svg"
    },
    {
      dataBaseValue: "Mozambique",
      value: "Mozambik",
      label: "Mozambik",
      imgSrc: "/img/flags/mz.svg"
    },
    {
      dataBaseValue: "Namibia",
      value: "Namibia",
      label: "Namibia",
      imgSrc: "/img/flags/na.svg"
    },
    {
      dataBaseValue: "New Caledonia",
      value: "Nowa Kaledonia",
      label: "Nowa Kaledonia",
      imgSrc: "/img/flags/nc.svg"
    },
    {
      dataBaseValue: "Niger",
      value: "Niger",
      label: "Niger",
      imgSrc: "/img/flags/ne.svg"
    },
    {
      dataBaseValue: "Norfolk Island",
      value: "Wyspa Norfolk",
      label: "Wyspa Norfolk",
      imgSrc: "/img/flags/nf.svg"
    },
    {
      dataBaseValue: "Nigeria",
      value: "Nigeria",
      label: "Nigeria",
      imgSrc: "/img/flags/ng.svg"
    },
    {
      dataBaseValue: "Nicaragua",
      value: "Nikaragua",
      label: "Nikaragua",
      imgSrc: "/img/flags/ni.svg"
    },
    {
      dataBaseValue: "Norway",
      value: "Norwegia",
      label: "Norwegia",
      imgSrc: "/img/flags/no.svg"
    },
    {
      dataBaseValue: "Nepal",
      value: "Nepal",
      label: "Nepal",
      imgSrc: "/img/flags/np.svg"
    },
    {
      dataBaseValue: "Nauru",
      value: "Nauru",
      label: "Nauru",
      imgSrc: "/img/flags/nr.svg"
    },
    {
      dataBaseValue: "Niue",
      value: "Niue",
      label: "Niue",
      imgSrc: "/img/flags/nu.svg"
    },
    {
      dataBaseValue: "New Zealand",
      value: "Nowa Zelandia",
      label: "Nowa Zelandia",
      imgSrc: "/img/flags/nz.svg"
    },
    {
      dataBaseValue: "Oman",
      value: "Oman",
      label: "Oman",
      imgSrc: "/img/flags/om.svg"
    },
    {
      dataBaseValue: "Panama",
      value: "Panama",
      label: "Panama",
      imgSrc: "/img/flags/pa.svg"
    },
    {
      dataBaseValue: "Peru",
      value: "Peru",
      label: "Peru",
      imgSrc: "/img/flags/pe.svg"
    },
    {
      dataBaseValue: "French polynesia",
      value: "Polinezja Francuska",
      label: "Polinezja Francuska",
      imgSrc: "/img/flags/pf.svg"
    },
    {
      dataBaseValue: "Papua New Guinea",
      value: "Papua Nowa Gwinea",
      label: "Papua Nowa Gwinea",
      imgSrc: "/img/flags/pg.svg"
    },
    {
      dataBaseValue: "Philippines",
      value: "Filipiny",
      label: "Filipiny",
      imgSrc: "/img/flags/ph.svg"
    },
    {
      dataBaseValue: "Pakistan",
      value: "Pakistan",
      label: "Pakistan",
      imgSrc: "/img/flags/pk.svg"
    },
    {
      dataBaseValue: "Poland",
      value: "Polska",
      label: "Polska",
      imgSrc: "/img/flags/pl.svg"
    },
    {
      dataBaseValue: "Saint Pierre and Miquelon",
      value: "Saint-Pierre i Miquelon",
      label: "Saint-Pierre i Miquelon",
      imgSrc: "/img/flags/pm.svg"
    },
    {
      dataBaseValue: "Pitcairn Islands",
      value: "Wyspy Pitcairn",
      label: "Wyspy Pitcairn",
      imgSrc: "/img/flags/pn.svg"
    },
    {
      dataBaseValue: "Puerto Rico",
      value: "Portoryko",
      label: "Portoryko",
      imgSrc: "/img/flags/pr.svg"
    },
    {
      dataBaseValue: "Palestine",
      value: "Palestyna",
      label: "Palestyna",
      imgSrc: "/img/flags/ps.svg"
    },
    {
      dataBaseValue: "Portugal",
      value: "Portugalia",
      label: "Portugalia",
      imgSrc: "/img/flags/pt.svg"
    },
    {
      dataBaseValue: "Paraguay",
      value: "Paragwaj",
      label: "Paragwaj",
      imgSrc: "/img/flags/py.svg"
    },
    {
      dataBaseValue: "Qatar",
      value: "Katar",
      label: "Katar",
      imgSrc: "/img/flags/qa.svg"
    },
    {
      dataBaseValue: "Reunion",
      value: "Zjazd",
      label: "Zjazd",
      imgSrc: "/img/flags/re.svg"
    },
    {
      dataBaseValue: "Romania",
      value: "Rumunia",
      label: "Rumunia",
      imgSrc: "/img/flags/ro.svg"
    },
    {
      dataBaseValue: "Serbia",
      value: "Serbia",
      label: "Serbia",
      imgSrc: "/img/flags/rs.svg"
    },
    {
      dataBaseValue: "Russia",
      value: "Federacja Rosyjska",
      label: "Federacja Rosyjska",
      imgSrc: "/img/flags/ru.svg"
    },
    {
      dataBaseValue: "Rwanda",
      value: "Rwanda",
      label: "Rwanda",
      imgSrc: "/img/flags/rw.svg"
    },
    {
      dataBaseValue: "South Africa",
      value: "Afryka Południowa",
      label: "Afryka Południowa",
      imgSrc: "/img/flags/sa.svg"
    },
    {
      dataBaseValue: "Solomon Islands",
      value: "Wyspy Salomona",
      label: "Wyspy Salomona",
      imgSrc: "/img/flags/sb.svg"
    },
    {
      dataBaseValue: "Seychelles",
      value: "Seszele",
      label: "Seszele",
      imgSrc: "/img/flags/sc.svg"
    },
    {
      dataBaseValue: "Sudan",
      value: "Sudan",
      label: "Sudan",
      imgSrc: "/img/flags/sd.svg"
    },
    {
      dataBaseValue: "Sweden",
      value: "Szwecja",
      label: "Szwecja",
      imgSrc: "/img/flags/se.svg"
    },
    {
      dataBaseValue: "Singapore",
      value: "Singapur",
      label: "Singapur",
      imgSrc: "/img/flags/sg.svg"
    },
    {
      dataBaseValue: "O. St. Helena",
      value: "O. St. Helena",
      label: "O. St. Helena",
      imgSrc: "/img/flags/sh.svg"
    },
    {
      dataBaseValue: "Slovenia",
      value: "Słowenia",
      label: "Słowenia",
      imgSrc: "/img/flags/si.svg"
    },
    {
      dataBaseValue: "Svalbard",
      value: "Svalbard",
      label: "Svalbard",
      imgSrc: "/img/flags/sj.svg"
    },
    {
      dataBaseValue: "Slovakia",
      value: "Słowacja",
      label: "Słowacja",
      imgSrc: "/img/flags/sk.svg"
    },
    {
      dataBaseValue: "Sierra Leone",
      value: "Sierra Leone",
      label: "Sierra Leone",
      imgSrc: "/img/flags/sl.svg"
    },
    {
      dataBaseValue: "San Marino",
      value: "San Marino",
      label: "San Marino",
      imgSrc: "/img/flags/sm.svg"
    },
    {
      dataBaseValue: "Senegal",
      value: "Senegal",
      label: "Senegal",
      imgSrc: "/img/flags/sn.svg"
    },
    {
      dataBaseValue: "Somalia",
      value: "Somali",
      label: "Somali",
      imgSrc: "/img/flags/so.svg"
    },
    {
      dataBaseValue: "Suriname",
      value: "Surinam",
      label: "Surinam",
      imgSrc: "/img/flags/sr.svg"
    },
    {
      dataBaseValue: "South Sudan",
      value: "Południowy Sudan",
      label: "Południowy Sudan",
      imgSrc: "/img/flags/ss.svg"
    },
    {
      dataBaseValue: "Sao Tome and Principe",
      value: "Wyspy Świętego Tomasza i Książęca",
      label: "Wyspy Świętego Tomasza i Książęca",
      imgSrc: "/img/flags/st.svg"
    },
    {
      dataBaseValue: "Salvador",
      value: "Salvador",
      label: "Salvador",
      imgSrc: "/img/flags/sv.svg"
    },
    {
      dataBaseValue: "Sint Maarten",
      value: "Sint Maarten",
      label: "Sint Maarten",
      imgSrc: "/img/flags/sx.svg"
    },
    {
      dataBaseValue: "Syria",
      value: "Syria",
      label: "Syria",
      imgSrc: "/img/flags/sy.svg"
    },
    {
      dataBaseValue: "Eswatini",
      value: "Eswatini",
      label: "Eswatini",
      imgSrc: "/img/flags/sz.svg"
    },
    {
      dataBaseValue: "Turks and Caicos Islands",
      value: "Wyspy Turks i Caicos",
      label: "Wyspy Turks i Caicos",
      imgSrc: "/img/flags/tc.svg"
    },
    {
      dataBaseValue: "Chad",
      value: "Czad",
      label: "Czad",
      imgSrc: "/img/flags/td.svg"
    },
    {
      dataBaseValue: "Togo",
      value: "Iść",
      label: "Iść",
      imgSrc: "/img/flags/tg.svg"
    },
    {
      dataBaseValue: "Thailand",
      value: "Tajlandia",
      label: "Tajlandia",
      imgSrc: "/img/flags/th.svg"
    },
    {
      dataBaseValue: "Tajikistan",
      value: "Tadżykistan",
      label: "Tadżykistan",
      imgSrc: "/img/flags/tj.svg"
    },
    {
      dataBaseValue: "Tokelau",
      value: "Tokelau",
      label: "Tokelau",
      imgSrc: "/img/flags/tk.svg"
    },
    {
      dataBaseValue: "East Timor",
      value: "Wschodni Timor",
      label: "Wschodni Timor",
      imgSrc: "/img/flags/tl.svg"
    },
    {
      dataBaseValue: "Turkmenistan",
      value: "Turkmenia",
      label: "Turkmenia",
      imgSrc: "/img/flags/tm.svg"
    },
    {
      dataBaseValue: "Tunisia",
      value: "Tunezja",
      label: "Tunezja",
      imgSrc: "/img/flags/tn.svg"
    },
    {
      dataBaseValue: "Turkey",
      value: "Indyk",
      label: "Indyk",
      imgSrc: "/img/flags/tr.svg"
    },
    {
      dataBaseValue: "Trinidad and Tobago",
      value: "Trynidad i Tobago",
      label: "Trynidad i Tobago",
      imgSrc: "/img/flags/tt.svg"
    },
    {
      dataBaseValue: "Tuvalu",
      value: "Tuvalu",
      label: "Tuvalu",
      imgSrc: "/img/flags/tv.svg"
    },
    {
      dataBaseValue: "Taiwan",
      value: "Tajwan",
      label: "Tajwan",
      imgSrc: "/img/flags/tw.svg"
    },
    {
      dataBaseValue: "Tanzania",
      value: "Tanzania",
      label: "Tanzania",
      imgSrc: "/img/flags/tz.svg"
    },
    {
      dataBaseValue: "Ukraine",
      value: "Ukraina",
      label: "Ukraina",
      imgSrc: "/img/flags/ua.svg"
    },
    {
      dataBaseValue: "Uganda",
      value: "Uganda",
      label: "Uganda",
      imgSrc: "/img/flags/ug.svg"
    },
    {
      dataBaseValue: "Minor Outlying Islands (USA)",
      value: "Drobne Dalekie Wyspy (USA)",
      label: "Drobne Dalekie Wyspy (USA)",
      imgSrc: "/img/flags/um.svg"
    },
    {
      dataBaseValue: "United States",
      value: "Stany Zjednoczone",
      label: "Stany Zjednoczone",
      imgSrc: "/img/flags/us.svg"
    },
    {
      dataBaseValue: "United Kingdom",
      value: "Zjednoczone Królestwo",
      label: "Zjednoczone Królestwo",
      imgSrc: "/img/flags/uk.svg"
    },
    {
      dataBaseValue: "Uruguay",
      value: "Urugwaj",
      label: "Urugwaj",
      imgSrc: "/img/flags/uy.svg"
    },
    {
      dataBaseValue: "Uzbekistan",
      value: "Uzbekistan",
      label: "Uzbekistan",
      imgSrc: "/img/flags/uz.svg"
    },
    {
      dataBaseValue: "Vatican City State",
      value: "Państwo Watykańskie",
      label: "Państwo Watykańskie",
      imgSrc: "/img/flags/va.svg"
    },
    {
      dataBaseValue: "Saint Vincent and the Grenadines",
      value: "Saint Vincent i Grenadyny",
      label: "Saint Vincent i Grenadyny",
      imgSrc: "/img/flags/vc.svg"
    },
    {
      dataBaseValue: "Venezuela",
      value: "Wenezuela",
      label: "Wenezuela",
      imgSrc: "/img/flags/ve.svg"
    },
    {
      dataBaseValue: "British Virgin Islands",
      value: "Brytyjskie Wyspy Dziewicze",
      label: "Brytyjskie Wyspy Dziewicze",
      imgSrc: "/img/flags/vg.svg"
    },
    {
      dataBaseValue: "US Virgin Islands",
      value: "Wyspy Dziewicze Stanów Zjednoczonych",
      label: "Wyspy Dziewicze Stanów Zjednoczonych",
      imgSrc: "/img/flags/vi.svg"
    },
    {
      dataBaseValue: "Vietnam",
      value: "Wietnam",
      label: "Wietnam",
      imgSrc: "/img/flags/vn.svg"
    },
    {
      dataBaseValue: "Vanuatu",
      value: "Vanuatu",
      label: "Vanuatu",
      imgSrc: "/img/flags/vu.svg"
    },
    {
      dataBaseValue: "Wallis and Futuna Islands",
      value: "Wyspy Wallis i Futuna",
      label: "Wyspy Wallis i Futuna",
      imgSrc: "/img/flags/wf.svg"
    },
    {
      dataBaseValue: "Samoa",
      value: "Samoa",
      label: "Samoa",
      imgSrc: "/img/flags/ws.svg"
    },
    {
      dataBaseValue: "Kosovo",
      value: "Kosowo",
      label: "Kosowo",
      imgSrc: "/img/flags/xk.svg"
    },
    {
      dataBaseValue: "Yemen",
      value: "Jemen",
      label: "Jemen",
      imgSrc: "/img/flags/ye.svg"
    },
    {
      dataBaseValue: "Mayotte",
      value: "Majotta",
      label: "Majotta",
      imgSrc: "/img/flags/yt.svg"
    },
    {
      dataBaseValue: "South Africa",
      value: "Afryka Południowa",
      label: "Afryka Południowa",
      imgSrc: "/img/flags/za.svg"
    },
    {
      dataBaseValue: "Zambia",
      value: "Zambia",
      label: "Zambia",
      imgSrc: "/img/flags/zm.svg"
    },
    {
      dataBaseValue: "Zimbabwe",
      value: "Zimbabwe",
      label: "Zimbabwe",
      imgSrc: "/img/flags/zw.svg"
    }
  ],
  filterProxyItems: [
    {
      id: 1,
      value: "Format IP:PORT",
      regExp: /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(\s{0,}|:)\d{1,5}/,
      divider: [":"]
    },
    // {
    //   id: 2,
    //   value: "Format KeyCollector",
    //   regExp:
    //     /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(\s{0,}|:)\d{1,5}(\s{0,}|@)\S+(\s{0,}|:)\S+/,
    //   divider: [":", "@"]
    // },
    {
      id: 3,
      value: "Format IP:PORT@Login:Password",
      regExp:
        /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(\s{0,}|:)\d{1,5}(\s{0,}|@)\S+(\s{0,}|:)\S+/,
      divider: [":", "@"]
    },
    {
      id: 4,
      value: "Format IP.PORT:Login:Password",
      regExp:
        /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(\s{0,}|:)\d{1,5}(\s{0,}|:)\S+(\s{0,}|:)\S+/,
      divider: [":"]
    }
  ],
  quantity: [
    { label: "10 szt.", value: 10 },
    { label: "15 szt.", value: 15 },
    { label: "20 szt.", value: 20 },
    { label: "50 szt.", value: 50 }
  ],
  regions: [
    { value: "MOSKWA", label: "Moskwa" },
    { value: "KIJÓW", label: "Kijów" },
    { value: "ŚW. PETERSBURG", label: "St. Petersburg" }
  ]
};
