export const getWebRTC = (setIps) => {
  const myPeerConnection =
    window.RTCPeerConnection ||
    window.mozRTCPeerConnection ||
    window.webkitRTCPeerConnection;

  if (!myPeerConnection) {
    return;
  }

  const configuration = {
    iceServers: [{ urls: "stun:stun.l.google.com:19302" }]
  };

  const pc = new myPeerConnection(configuration),
    // eslint-disable-next-line no-empty-function
    noop = () => {},
    localIPs = {},
    ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g;

  const ipIterate = (ip) => {
    if (ip !== "0.0.0.0" && !localIPs[ip]) {
      setIps([{ ip, type: "IPv4", state: true }]);
    }
    localIPs[ip] = true;
  };

  pc.createDataChannel("");

  pc.createOffer((sdp) => {
    sdp.sdp.split("\n").forEach((line) => {
      if (line.indexOf("candidate") < 0) return;
      line.match(ipRegex).forEach(ipIterate);
    });
    pc.setLocalDescription(sdp, noop, noop);
  }, noop);

  pc.onicecandidate = (ice) => {
    if (
      !ice ||
      !ice.candidate ||
      !ice.candidate.candidate ||
      !ice.candidate.candidate.match(ipRegex)
    ) {
      return;
    }

    ice.candidate.candidate.match(ipRegex).forEach(ipIterate);
  };
};
