import { Suspense, startTransition, useEffect } from "react";

import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { toast } from "react-toastify";

import { Footer, Header, MetaDate } from "../components/common";
import ToastNotificationContainer from "../components/common/ToastNotificationContainer";
import { Loader } from "../components/ui";
import {
  useDispatchedActions,
  usePageContent,
  useRedirect,
  useTranslation
} from "../hooks";
import { ApiService } from "../services/ApiService";
import { IndexWrapper, Main } from "../styles";

export const IndexLayout = () => {
  useRedirect();

  // Getting page content from the server
  usePageContent();

  const t = useTranslation();

  const { client, anonimityScore, anonimityInfo, blacklist, openPorts } =
    useSelector((state) => state.client);

  // Dispatch
  const {
    getClientInfo,
    getAnonimityScore,
    getAnonimityInfo,
    saveAnonimityScore,
    getBlackList
  } = useDispatchedActions();

  useEffect(() => {
    (async () => {
      await ApiService.getClientIpv();

      if (!client.isDataLoaded) {
        startTransition(() => getClientInfo());
      }

      if (!anonimityInfo.isDataLoaded) {
        startTransition(() => getAnonimityInfo());

        Cookies.set("SESSION-ID", "0nZPKC22ie90H", { path: "/" });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const userIp = anonimityInfo?.data?.ip;

    if (userIp) {
      startTransition(() => getBlackList(userIp));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anonimityInfo?.data?.ip]);

  // checking ip: if ip from localstorage !== ip from server - get anonimity score from sever
  useEffect(() => {
    if (client.isDataLoaded) {
      const localData = JSON.parse(
        window.localStorage.getItem("localClientInfo")
      );
      if (localData?.ip !== client.data.ip) {
        getAnonimityScore();
      } else if (localData && localData.ip === client.data.ip) {
        saveAnonimityScore(localData.score);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client.isDataLoaded]);

  // Handling error
  useEffect(() => {
    if (
      client.isFetchError ||
      anonimityScore.isFetchError ||
      anonimityInfo.isFetchError ||
      blacklist.isFetchError ||
      openPorts.isFetchError
    ) {
      toast.error(t.errorTranslation.apiError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    client.isFetchError,
    anonimityScore.isFetchError,
    anonimityInfo.isFetchError,
    blacklist.isFetchError,
    openPorts.isFetchError
  ]);

  return (
    <>
      <MetaDate />

      <IndexWrapper>
        <Header />

        <Main>
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </Main>

        <Footer />
      </IndexWrapper>
      <ToastNotificationContainer />
    </>
  );
};
