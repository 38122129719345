export const It = {
  header: {
    ariaLabelMain: "Vai alla pagina principale",
    altImgLogo: "Logo della compagnia FreeProxySale",
    other: "Altri strumenti",
    ariaLabelOther: "Tasso di espansione di voci di menu aggiuntive",
    close: "Vicino"
  },
  footer: {
    logo: {
      alt: "Proxy-Sale logo della compagnia"
    },
    rights: "Tutti i diritti riservati",
    lang: {
      title: "Scegli la lingua",
      selectLanguage: [
        { value: "de", label: "Tedesco" },
        { value: "en", label: "Inglese" },
        { value: "es", label: "Spagnolo" },
        { value: "fr", label: "Francese" },
        { value: "it", label: "Italiano" },
        { value: "pl", label: "Polacco" },
        { value: "ru", label: "Russo" },
        { value: "tr", label: "Turco" }
      ]
    },
    tools: "Strumenti",
    company: "Azienda"
  },
  navigation: {
    freeProxy: "Free proxy",
    personalProxy: "Procura personale",
    proxyIpv6: "Procuratore IPv6",
    articles: "Articoli",
    contacts: "Contatti",
    proxyChecker: "Controllo proxy",
    myIp: "Il mio IP",
    checkPorts: "Scanner per porte",
    pingIp: "Ping IP",
    anonimityCheck: "Controllo dell'anonimato",
    utm: "UTM Costruttore",
    speed: "Prova di velocità proxy",
    ipv6Check: "Validatore IPv6",
    spamBase: "IP Controllo lista nera",
    filterProxy: "Filtro elenco proxy",
    traceIp: "IP Tracciamento",
    claster: "Raggruppamento delle richieste",
    webRtcChecker: "Controllo WebRTC",
    dnsChecker: "Controllo DNS",
    fingerPrint: "Scanner delle impronte digitali",
    evercookie: "Controllo Evercookie",
    httpHeaders: "Visualizzazione delle intestazioni HTTP",
    browserInfo: "Informazioni sul browser",
    resedentialProxy: "Procure residenziali",
    ispProxy: "Procura dell'ISP"
  },
  mainPage: {
    proxyTable: {
      sidebarPart: {
        title: "Configurazione di un proxy nel browser:",
        countries: {
          placeholder: "Selezionare countriesConstant"
        },
        type: {
          title: "Tipo di:"
        },
        anonimity: {
          title: "Anonimo:"
        },
        export: {
          title: "Export:",
          ip: "IP:Port",
          excel: "Excel",
          window: "In una nuova finestra"
        },
        applyFilter: "Applica il filtro",
        clearFilter: "Filtro pulito",
        infoLeft: "I proxy",
        infoRight:
          "gratuiti escludono qualsiasi possibilità di lavorare con i social network, analizzare i motori di ricerca e lavorare con software privati. Non siamo responsabili per l'uso dell'elenco proxy gratuito. L'elenco IP viene fornito solo a scopo informativo.",
        buyProxy: "Acquista proxy personali",
        personalProxyAdvantage: [
          "I proxy personali ti forniranno un elevato anonimato e stabilità del lavoro"
        ]
      }
    },
    faq: "Quasi tutti gli utenti di Internet hanno sentito parlare di cos'è un server proxy e perché è necessario. Sono diventati particolarmente popolari quando c'era un'enorme necessità di aggirare il blocco delle risorse Web popolari: social network, servizi Yandex, hosting video e simili Certo, la maggior parte è interessata ai proxy gratuiti, ce ne sono molti in rete, ma non è sempre ovvio come sceglierli correttamente e a cosa sono adatti, e per quali compiti è meglio acquistare un proxy. "
  },
  anonimityCheck: {
    mainTitle: "Il mio anonimato",
    headText:
      "Utilizzando questo servizio puoi verificare quanto sei anonimo sulla rete, quanto i dati forniti dal tuo computer/browser corrispondono ai dati forniti dal tuo indirizzo IP.",
    top: {
      ip: "Il tuo IP :",
      from: "Di dove sei:",
      camouflage: "Il tuo travestimento:",
      anon: "Il tuo anonimato:",
      restartButton: "Riavvia la scansione",
      waitButton: "Caricamento",

      result: {
        title: "Il tuo risultato:",
        notAnonymity: "Nessuna anonimato",
        badAnonymity: "Anonimato scarso",
        lackAnonymity: "Mancanza di anonimato",
        sufficientAnonymity: "Anonimato sufficiente",
        checking: "Verifica, per favore attendi",
        highAnonymity: "Alto anonimato"
      }
    },
    table: {
      titleIpInfo: "Informazioni IP",
      standardTimeZone: "Fuso orario standard",
      anonymityTitle: "Anonimato e sicurezza",
      hour: "ore",
      device: "Dispositivo",
      ip: "Il mio IP",
      hideip: "Nascondere IP",
      host: "Ospite/Fornitore",
      country: "Paese",
      city: "Città",
      index: "Indice",
      coordinates: "Coordinate",
      os: "OS",
      browser: "Browser",
      timezone: "Fuso orario",
      ipTime: "IP tempo",
      systemTime: "Tempo di sistema",
      userAgent: "UserAgent",
      userAgentJs: "UserAgent JS",
      language: "Lingua",
      languageJs: "Lingua JS",
      screen: "Schermo",
      proxy: "Procuratore",
      vpn: "VPN",
      tor: "Tor",
      anonimazer: {
        title: "Anonimizzatore",
        info: "È necessario chiudere le porte. Le porte aperte possono causare danni significativi al tuo dispositivo o potrebbero già averne causati."
      },
      cookies: {
        info: "Disabilitare può prevenire il tracciamento da parte delle reti pubblicitarie e di varie risorse, aumentando la privacy. Si consiglia di disabilitare i cookie o cancellarli periodicamente."
      },
      blackList: {
        title: "Lista nera",
        checks: "Controlli:",
        inBlackList: "Nella lista nera:",
        buyProxy: "Acquista proxy puro"
      },
      flash: "Flash",
      java: "Java",
      activeX: {
        title: "ActiveX",
        info: "Il controllo ActiveX presenta vulnerabilità di sicurezza e può essere utilizzato per infettare il tuo computer. Più controlli ActiveX installi, più i siti possono sfruttare i loro problemi per danneggiare il tuo dispositivo."
      },
      webRtc: "WebRTC",
      webRtcIp: "WebRTC IPs",
      openPorts: {
        title: "Porte aperte",
        links: "Scanner di porte"
      },
      openPortsHTTP: {
        title: "Porte aperte del proxy HTTP",
        info: "Le porte devono essere chiuse. Le porte aperte possono arrecare danni significativi o hanno già arrecato danni al tuo dispositivo."
      },
      openPortsVPN: {
        title: "Porte VPN aperte",
        info: "È necessario chiudere le porte. Le porte aperte possono arrecare danni significativi o hanno già arrecato danni al tuo dispositivo."
      },
      openPortsWeb: "Apri le porte proxy Web",
      doNotTrack: {
        title: "DoNotTrack",
        info: "Attivando questa opzione si aggiunge un certo grado di unicità all'impronta digitale del browser.",
        link: "Attiva DNT"
      },
      dnsLeak: {
        title: "Perdita DNS",
        info: "Terze parti possono tracciare tutte le tue azioni sulla rete. È necessario modificare le impostazioni DNS.",
        links: "Modifica DNS"
      },
      suspiciousHeadlines: "Titoli sospetti",
      suspiciousPorts: "Porti sospetti",
      twowayPing: "Ping bidirezionale",
      replacementUserAgent: {
        title: "Sostituzione dell'UserAgent",
        info: "Basato sui dati che formano l'UserAgent del dispositivo, se i dati differiscono, significa che è in corso una sostituzione."
      },
      yourHeadings: "Le tue intestazioni",
      showButton: {
        more: "Mostra di più",
        less: "Nascondi"
      }
    },
    faq: {
      text: []
    }
  },
  checkPorts: {
    analis: "Analisi in corso... Aspetta.",
    port: "Porta",
    status: "Stato",
    statusTrue: "disponibile",
    statusFalse: "non disponibile",
    description: "Descrizione",
    portStatus: "Stato della porta sul server",
    scanType: "Tipo di scansione",
    mainTitle: "Controllo delle porte aperte online",
    videoTitle: "Cos'è un port scanner e perché?",
    headText:
      "Il controllo delle porte aperte utilizzando uno scanner mostra quali porte sono aperte sul tuo computer/sito o su qualcun altro e cosa rispondono. Il controllo viene eseguito tramite lo scanner NMAP e mostra come aprire il tuo accesso al PC/sito per il mondo esterno.",
    ipTitle: "Display:",
    ipElem: {
      placeholder: "Immettere l'indirizzo IP o il dominio",
      insertIp: "Incolla il mio indirizzo IP"
    },
    scanTypes: {
      title: "Tipi di porte scansionate:"
    },
    scanTitle: "Tipo di scansione:",
    startScan: "Inizia scansione",
    resultCheck: "Risultati del test:",
    resultCaption:
      "* Se il risultato è 'Host non attivo', il firewall o il router vengono controllati e bloccano gli indirizzi IP.",
    faq: {
      text: []
    }
  },
  claster: {
    mainTitle: "Strumento di raggruppamento delle parole chiave",
    checker: {
      regionTitle: "Controllo della regione",
      selectPlaceholder: "Scegli la regione",
      clasterTitle: "Soglia di raggruppamento",
      groupBtn: "Gruppo",
      queryTitle: "Richieste"
    },
    headText:
      "Grazie a questo servizio, il clustering di query online è disponibile per te. Con esso, puoi raggruppare automaticamente le parole chiave in base ai risultati di ricerca di Yandex e Google. La costruzione competente della struttura del sito aumenterà le tue possibilità di portare il tuo sito nelle prime posizioni. Il nostro il servizio di clustering di query online ti aiuta a classificare le tue parole chiave nelle tue pagine in modo da ottenere il massimo traffico SEO."
  },
  filterProxy: {
    mainTitle: "Filtro proxy",
    headText:
      "Il filtro proxy è il processo di invio di un filtro proxy al server principale per selezionare consigli specifici.",
    filterOut: "Filtrare",
    save: "Salva su file"
  },
  ipv6: {
    mainTitle: "Verifica dei siti per il supporto IPv6",
    headText:
      "La versione del protocollo IPv6, che fornisce la massima protezione sulla rete, richiede l'uso di uno strumento di supporto specifico del sito per il nuovo protocollo proxy per un funzionamento più efficiente.",
    checker: {
      addressTitle: "Inserisci l'indirizzo del sito per la verifica",
      checkProxyBtn: "Controlla proxy",
      tip: "Il test potrebbe richiedere del tempo",
      resultTitle: "Risultati del test:",
      result: {
        success: "Congratulazioni, questo sito supporta IPv6",
        error: "Sfortunatamente, il sito non supporta IPv6 al momento"
      }
    },

    faq: {
      text: []
    }
  },
  myIp: {
    top: {
      title:
        "E questa è solo una piccola parte delle informazioni che possono essere tracciate dal tuo indirizzo IP:",
      ipv4: "Il tuo indirizzo IPv4:",
      ipv6: "Il tuo indirizzo IPv6:",
      changeIp: "Cambia il tuo indirizzo IP",
      table: {
        check: "Controllo",
        country: "Paese",
        city: "Città",
        index: "Codice postale",
        host: "Host",
        system: "Sistema",
        browser: "Il tuo browser",
        from: "Di dove sei",
        provider: "Fornitore",
        anon: "Anonimizzatore",
        proxy: "Procuratore",
        protocol: "Protocollo",
        imgAlt: {
          country: "Paese",
          city: "Città",
          index: "Codice postale",
          screen: "Tenere sotto controllo",
          os: "Sistema operativo",
          browser: "Versione browser",
          location: "Posizione",
          server: "Server",
          anon: "Anonimizzatore",
          proxy: "Procuratore",
          protocol: "Protocollo",
          blacklist: "Lista nera"
        }
      },
      map: {
        title: "Your location on the map!",
        hide: "You can hide your location",
        text: "It is enough to use our simple, convenient and reliable tool. Hide personal data, protect traffic and remove all limits from your Internet",
        hideIp: "Hide IP address"
      },
      caution: {
        top: "As you can see, quite detailed information is provided by IP.",
        bottom:
          "When connected to the Internet, the computer is assigned a number called IP (Internet Protocol Address). It should be noted that the assignment is not arbitrary, but the IP that is available to the provider and is free. IP changes after disconnecting when reconnecting. These IPs are called dynamic. If you want it to be unchanged, contact your ISP. Usually, non-dynamic is available for a fee. It is also called external. It is often tracked by specialized online programs, determining the accompanying information about the owner. Internal IP or local is called the individual address of a PC (laptop) in a local network."
      }
    },
    faq: {
      text: []
    }
  },
  pingIp: {
    mainTitle: "Controllare ping indirizzo IP o sito",
    whatIsPing: "Cos'è il ping IP e perché?",
    headText: [
      "Fai clic sul pulsante 'Inserisci il mio indirizzo IP' o utilizza l'IP o il sito su cui desideri controllare il ping. Seleziona 3-5 pacchetti di prova e fai clic sul pulsante 'Ping IP'. Vedrai il valore desiderato nel ' tempo = 'sezione.",
      "Il ping è considerato normale quando è compreso nell'intervallo 40-110 ms. Da 110-210 ms, inizierai a riscontrare problemi nei giochi online, ma ciò difficilmente influirà sulle tue visite ai siti. Se il timeoutConstant supera i 110 ms, allora questo è un motivo per pensare alla qualità del lavoro del tuo ISP."
    ],
    checker: {
      showTitle: "Schermo:",
      showSubtitle:
        "Inserisci un indirizzo IP valido (0.0.0.0) o un nome host (host.com)",
      ipInsert: "Incolla il mio indirizzo IP",
      addTitle: "Numero di pacchi:",
      selectPlaceholder: "Scegli un paese",
      startScan: "Inizia scansione",
      resultTitle: "Inizia scansione:",
      resultCaption:
        "* Se il risultato è 'Lhost non è attivo, il firewall o il router vengono controllati e bloccano gli indirizzi IP."
    },
    faq: {
      text: []
    }
  },
  proxyChecker: {
    mainTitle: "Controllore proxy",
    condition: "Stato",
    ping: "Ping",
    type: "Tipo",
    status: "Non funziona",
    workStatus: "Disponibile",
    head: {
      videoTitle: "Come e perché utilizzare un proxy checker?",
      headText:
        "Controllo proxy online per verificare la validità dei server proxy. Analisi di un proxy o di un gruppo di indirizzi per disponibilità, paese, velocità, anonimato, tipo.",
      titleFirst: "Il controllore proxy può:",
      listFirst: [
        "verifica IPv4, IPv6 per la proprietà del paese;",
        "determinazione del tipo di protocollo HTTPS, SOCKS5 e velocità attuale;",
        "verifica dell'anonimato dell'indirizzo;",
        "controllo proxy multithread di gruppo ad alta velocità in 64 thread;",
        "la possibilità di caricare un elenco e caricare l'esito del controllo;",
        "Comoda visualizzazione dei risultati utilizzando un filtro di visualizzazione."
      ],
      titleSecond:
        "In quale formato dovrebbe essere aggiunto un proxy al controllo?",
      listSecond: [
        {
          content:
            "Se hai <span>proxy pubblici</span> (senza login e password), quindi - IP: PORTA"
        },
        {
          content:
            "Se hai <span>proxy privati</span> (con autorizzazione tramite login e password), quindi - IP: PORTA: UTENTE: PASS"
        }
      ],
      titleThird:
        "P.S.: Se <a href='https://proxy-sale.com/' rel='noreferrer' target='_blank'> hai acquistato un proxy  </a> con noi, allora si mettono in privato!"
    },
    faq: {
      text: []
    }
  },
  siteSpeed: {
    mainTitle: "Controllare la velocità di accesso al sito Web tramite proxy",
    head: {
      listFirst: [
        "Determinazione della velocità di trasmissione dei dati tramite proxy HTTP e SOCKS",
        "Possibilità di determinare direttamente la velocità di caricamento delle pagine",
        "Controlla l'accesso al sito"
      ],
      caution: "In quale formato deve essere eseguito il controllo?",
      subtitle:
        "Per controllare la velocità tramite un proxy, devi guidare una risorsa a cui devi accedere e un indirizzo IP nel formato:",
      listSecond: [
        {
          text: "Proxy pubblici (senza login e password), quindi —",
          value: "IP:PORT"
        },
        {
          text: "Proxy privati (con autorizzazione login e password), quindi —",
          value: "IP:PORT:USER:PASS"
        }
      ],
      post: "P.S.: se hai acquistato un proxy con noi, si mettono in privato!"
    },
    checker: {
      adress: "Inserisci l'URL del sito per verificare la velocità",
      proxy: "Indirizzo proxy nel formato",
      format: "127.0.0.1:8080",
      subtitle: [
        "Per testare la velocità di trasmissione dei dati tramite un proxy, inserire l'indirizzo e la porta del server proxy",
        "Per un normale test della velocità di caricamento delle pagine del sito, lascia vuoto questo campo."
      ],
      type: {
        title: "Tipo di server proxy"
      },
      timeout: {
        title: "Tempo scaduto:",
        placeholder: "Tempo scaduto"
      },
      access: {
        title: "Controlla l'accesso al sito",
        body: "I controlli di accesso e velocità potrebbero richiedere del tempo"
      },
      btn: "Controlla la velocità",
      result: {
        title: "Risultati del test:",
        result: {
          titleFirst: "Verifica - la velocità di accesso al sito tramite proxy",
          checkSite: "Controllo del sito",
          checkProxy: "Controlla proxy",
          timeout: "Tempo scaduto",
          sec: "sec",
          amountOfDownloads: "Download di prova prodotti",
          titleSecond: "Verifica completata",
          averageSpeed: "Velocità di download media",
          generalSpeed: "Velocità media totale",
          speedValue: "Kb/sec",
          access: "Accesso al sito - Titolo dalla pagina scaricata",
          emptyTitle: "Impossibile ottenere il titolo",
          isProxyUsing: {
            yes: "Usando il proxy",
            no: "Senza un proxy, carica direttamente"
          }
        }
      }
    },
    faq: {
      text: []
    },
    errors: {
      url: "Inserisci l'URL del sito"
    }
  },
  spamBase: {
    mainTitle: "Presenza IP e verifica nei database di spam",
    head: {
      text: "Come sapete, lo spam può spesso causare l'inserimento dell'IP nella lista nera, il che interrompe notevolmente tutte le operazioni di rete. Per evitare ciò, è necessario utilizzare il server di controllo IP nei database di spam."
    },
    checker: {
      ip: {
        title: "Si prega di inserire l'indirizzo IP corretto",
        insert: "Incolla il mio indirizzo IP"
      },
      check: "Visita medica",
      test: "Il test potrebbe richiedere del tempo.",
      list: {
        checkedAll: "Controlli:",
        checked: "Controllato:",
        blackList: "Nella lista nera:"
      },
      result: {
        listed: "Elencato",
        notListed: "Non elencato"
      }
    },
    faq: {
      text: []
    }
  },
  trace: {
    mainTitle: "Tracciamento IP",
    head: {
      text: "Grazie al servizio di questa pagina, hai accesso a una traccia gratuita e quasi istantanea dell'indirizzo IP o del sito. Ti aiuterà a eseguire la diagnostica di rete, che determina la velocità di invio delle richieste tramite i server su Internet quando aprire una risorsa Web in un browser determinare le cause di possibili problemi associati al caricamento lento del sito. "
    },
    checker: {
      ip: "Immettere l'indirizzo IP corretto (0.0.0.0) o il nome host (domain.ru)",
      route: {
        title: "Mostra il percorso del pacchetto per paese",
        placeholder: "Seleziona un oggetto"
      },
      btn: "Traccia IP",
      result: "Risultati del test:"
    }
  },
  webRtcCheck: {
    mainTitle: "Controllo WebRTC",
    head: {
      text: "Controllo delle perdite tramite WebRTC. I tuoi dati potrebbero essere a rischio anche se sei connesso a una VPN."
    },
    top: {
      myIp: "Il tuo indirizzo IP:",
      from: "Di dove sei:",
      state: "Stato:",
      warning: "Potenziale minaccia"
    },
    info: {
      top: "Come puoi vedere, informazioni abbastanza dettagliate sono fornite da IP.",
      bottom:
        "Abbiamo verificato se WebRTC è attivato nel tuo browser e se disponi di un indirizzo IP reale. A quanto pare, esiste il rischio di una potenziale perdita, WebRTC è abilitato e potrebbe rivelare il tuo vero indirizzo IP.",
      disableBottom:
        "Abbiamo verificato se WebRTC è attivato nel tuo browser e se disponi di un indirizzo IP reale. A quanto pare, il tuo vero indirizzo IP rimane sconosciuto e WebRTC non è attivato nel tuo browser."
    },
    resetButton: "Riavviare il test di tenuta WebRTC",
    status: {
      type: "Tipo",
      state: "Stato",
      topText: "WebRTC abilitato",
      bottomText: "Minaccia di rivelare il tuo vero indirizzo IP!",
      warning: "Minaccia di fuga",
      checking: "Controlla, attendi"
    }
  },
  dnsCheck: {
    top: {
      myIp: "Il tuo indirizzo IP:",
      from: "Di dove sei:",
      state: "Stato:",
      warning: "Potenziale minaccia"
    },
    info: {
      top: "Come puoi vedere, durante la verifica del tuo IP è stata rilevata una perdita di DNS.",
      bottom:
        "Abbiamo esaminato il tuo IP e abbiamo rilevato una perdita tramite DNS. Le tue richieste sono state rivelate e la connessione non è sicura. Ciò significa che l'amministratore del tuo DNS può tracciare le informazioni sui siti web e i programmi che utilizzi.",
      disableBottom:
        "Abbiamo esaminato il tuo IP e non abbiamo rilevato alcuna perdita tramite DNS. Le tue richieste non sono state rivelate e la connessione è sicura. Ciò significa che l'amministratore del tuo DNS non può tracciare le informazioni sui siti web e i programmi che utilizzi."
    },
    resetButton: "Riavviare il test di tenuta DNS",
    status: {
      type: "Tipo",
      state: "Stato",
      topText: "DNS abilitato",
      bottomText: "Minaccia di rivelare le tue richieste!",
      warning: "Minaccia di fuga",
      checking: "Controlla, attendi"
    }
  },
  fingerPrintScanner: {
    mainTitle: "Scanner per impronte digitali del browser",
    head: {
      text: "Le impronte digitali del browser sono un'istantanea unica delle impostazioni del tuo computer e speciali meccanismi del sito Web possono tracciarti."
    },
    top: {
      myIp: "Il tuo indirizzo IP:",
      fingerprint: "Impronta digitale del browser:"
    },
    table: {
      location: "Posizione",
      provider: "Fornitore",
      host: "Ospite",
      os: "SO",
      browser: "Navigatore",
      plugin: "Collegare:",
      status: "Stato:",
      isOn: "abilitato",
      isOff: "spento",
      device: "Dispositivo",
      browserLanguage: "Lingua del browser",
      screenSize: "Dimensione dello schermo",
      timeZone: "Fuso orario"
    },
    imgAlt: {
      location: "Posizione",
      provider: "Fornitore",
      host: "Ospite",
      os: "sistema operativo",
      browser: "Navigatore",
      language: "Lingua del browser",
      size: "Dimensione dello schermo",
      timeZone: "Fuso orario"
    },
    bottom: {
      method: "Metodo JS"
    }
  },
  createEvercookie: {
    mainTitle: "Controllo Evercookie",
    head: {
      text: "Fare clic sul pulsante per creare un evercookie. Il tuo anonimato non sarà compromesso: un cookie è un numero casuale compreso tra 1 e 1000 che utilizziamo per testare ogni cookie. Non monitoriamo il tuo utilizzo."
    },
    top: {
      button: "Crea Evercookie"
    }
  },
  httpHeaders: {
    requestHeaders: "Richiedi intestazioni",
    responseHeaders: "Intestazioni di risposta",
    submitButton: "Inviare la richiesta"
  },
  browserInfo: {
    mainTitle: "Informazioni dal tuo browser",
    head: {
      text:
        "Utilizzando il browser, vari siti e servizi hanno la capacità di tracciare l'utente. Ciò è necessario, ad esempio, per identificare una persona anche se ha cambiato il suo indirizzo IP. I sistemi pubblicitari utilizzano anche funzionalità di tracciamento per conoscere gli interessi dell’utente.\n" +
        "Il nostro servizio ti aiuterà a determinare quali metodi di tracciamento sono abilitati nel tuo browser."
    },
    table: {
      type: "Tipo",
      discovered: "Scoperto",
      cookieMeaning: "Significato dei biscotti",
      parameter: "Parametro",
      value: "Valore",
      resolution: "Risoluzione dello schermo:",
      desktop: "Desktop",
      colorDepth: "Profondità di colore",
      bit: "morso"
    },
    button: {
      createCookie: "Crea cookie",
      userTracking: "Tracciamento degli utenti"
    }
  },
  utm: {
    mainTitle: "Il generatore di tag UTM online",
    checker: {
      linkUtm: "Il collegamento con il tag UTM apparirà qui",
      copy: "Copia",
      shortUrl: "Breve URL",
      traffic: [
        { text: "Google ADS", source: "google" },
        { text: "Yandex.Direct", source: "yandex" },
        { text: "VK.com", source: "vk" },
        { text: "Facebook", source: "facebook" },
        { text: "Instagram", source: "instagram" },
        { text: "Target.Mail.ru", source: "targetmailru" },
        { reset: true, text: "Reset" }
      ]
    },
    faq: {
      text: []
    }
  },
  pageHead: {
    ip: "Il tuo IP",
    changeIp: "Modificare IP",
    anon: "Il tuo anonimato",
    moreAnon: "Ulteriori informazioni sull'anonimato"
  },
  checker: {
    country: "Scegli un paese:",
    add: "Aggiungi prima:",
    show: {
      title: "Schermo"
    },
    displayType: "Schermo",
    checkProxy: "Controlla proxy",
    fileName: "Carica da .txt o .csv",
    typeProxy: {
      title: "Inserisci proxy:",
      placeholder: "Scrivi il tuo testo qui"
    },
    info: {
      text: "Risolvi le restrizioni e gli inconvenienti, il rischio di essere bannato o di modificare in modo permanente il tuo indirizzo IP. Proxy personali per lavorare con i social network, Avito, Key Collector e altri scopi.",
      buy: "Acquista protezione"
    },
    results: {
      title: "Risultati del test",
      checked: "Controllato",
      working: "Lavorando",
      notWorking: "Non funziona",
      divider: "di",
      btn: "Acquista funzionante"
    },
    download: {
      title: "Scarica solo proxy di lavoro",
      txt: "scarica .txt",
      csv: "tavolo dentro .csv"
    }
  },
  common: {
    faqTitle: "Rispondiamo alle tue domande",
    yes: "Sì",
    no: "No",
    notDefined: "Non definito",
    copy: "Copiato!",
    select: {
      placeholderCountry: "Scegli il paese"
    },
    buyAnonProxy: "Acquista l'anonimato",
    buyWorkingProxy: "Acquista funzionante"
  },
  table: {
    top: {
      workProxy: "Proxy di lavoro",
      anon: "Anonymous",
      socks: "Socks 4/5",
      http: "HTTP/HTTPS",
      proxyInBase: "Proxy nel database"
    },
    tableColumns: {
      copy: "Copia negli appunti",
      country: "Paese",
      type: "Tipo di",
      anon: {
        title: "Anonimato",
        speed: "Velocità",
        s: "s",
        ms: "ms",
        m: "m",
        h: "h",
        types: {
          anon: "Anonimato",
          proz: "Trasparente",
          elite: "Elite",
          notFounded: "Non fondato"
        }
      },
      check: {
        title: "Visita medica",
        tip: "Richiesta",
        buy: "Acquista funzionante",
        reload: "Riavviare",
        ago: "mins. ago",
        status: {
          work: "Lavorando",
          notWorked: "Non funziona"
        }
      },
      condition: {
        title: "Condizione"
      },
      login: "Login",
      password: "Parola d'ordine",
      pagination: {
        rowsPerPage: "Proxy per pagina",
        separatorText: "di"
      },
      requests: "Richieste",
      requestsCluster: "Richieste su cluster",
      competitors: "Concorrenti"
    },
    noDataComponent: "Non ci sono proxy"
  },
  errorTranslation: {
    somethingWrong: "Qualcosa è andato storto, riprova",
    largeProxyList:
      "Il numero di deleghe per la verifica non deve superare 100",
    emptyProxy: "Proxy mancanti per l'esportazione",
    apiError: "Qualcosa è andato storto!",
    wrongFile: "Tipo di file non valido. Consentito solo .txt o .csv",
    nonReadableFile: "Impossibile leggere il file!",
    emptyProxyList: "Inserisci proxy!",
    emptyClusterQuery: "Inserisci le richieste!",
    clusterQueryMinValue: "Inserisci non meno di 2 richieste!",
    incorrectIpOrDomain: "IP o dominio errato! ",
    incorrectIp: "Errato IP! ",
    proxyFilterEmpty: "Inserisci proxy!",
    proxyIsEmpty: "Questo paese non ha proxy!",
    proxyFilterIncorrect: "Proxy errato!",
    emptyIpOrDomain: "Immettere l'indirizzo IP o il dominio corretto!",
    emptySiteUrl: "Inserisci l'URL del sito!",
    emptyUserAgent: "Seleziona un agente utente!",
    invalidUrl: "Formato URL non valido!",
    timeError: "Riprova tra un paio di minuti!",
    IncorrectUrlFormat: "Formato URL errato!"
  },
  anonimityTypes: [
    {
      value: "an-anonim.txt",
      label: "Anonimo",
      defaultChecked: false,
      route: "anonimnyj"
    },
    {
      value: "el-elit.txt",
      label: "Elite",
      defaultChecked: false,
      route: "elite"
    },
    {
      value: "pr-proz.txt",
      label: "Transparent",
      defaultChecked: false,
      route: "transparent"
    }
  ],
  browsers: [
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/chrome.svg",
      value: "Google Chrome"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/safari.svg",
      value: "Safari"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/opera.svg",
      value: "Opera"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/firefox.svg",
      value: "Firefox"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/yandex.svg",
      value: "Yandex"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/android.svg",
      value: "Android"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/explorer.svg",
      value: "Internet Explorer"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/browser1.svg",
      value: "Browser"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      value: "+",
      last: true
    }
  ],
  checkerProxyTypes: [
    {
      value: "HTTP,HTTPS",
      label: "HTTP/HTTPS",
      defaultChecked: true
    },
    {
      value: "SOCKS4,SOCKS5",
      label: "SOCKS 4/5",
      defaultChecked: true
    },
    {
      value: "NO",
      label: "Non funzionante",
      defaultChecked: true
    }
  ],
  checkPortsTypes: [
    {
      value: "POPULAR",
      label: "Porte popolari",
      defaultChecked: true
    },
    {
      value: "SERVER",
      label: "Porte del server",
      defaultChecked: false
    },
    {
      value: "GAME",
      label: "Porte del gioco",
      defaultChecked: false
    },
    {
      value: "APPLICATION",
      label: "Porte delle applicazioni",
      defaultChecked: false
    },
    {
      value: "P2P",
      label: "Porte P2P",
      defaultChecked: false
    }
  ],
  utmInputs: [
    {
      label: "Pagina di destinazione url",
      name: "url",
      placeholder: "Incolla qui il tuo link o pagina di destinazione"
    },
    {
      label: "UTM-fonte*",
      name: "source",
      placeholder: "Sorgente di traffico. Ad esempio: google"
    },
    {
      label: "UTM-medio*",
      name: "medium",
      placeholder: "Tipo di traffico. Ad esempio: cpc, email, banner"
    },
    {
      label: "UTM-campagna*",
      name: "campaign",
      placeholder: "Società pubblicitaria, ad esempio: google-poisk"
    },
    {
      label: "UTM-contenuto",
      name: "content",
      placeholder: "Ad esempio: cpc, email, banner"
    },
    {
      label: "UTM-termine",
      name: "term",
      placeholder: "Parola chiave. Ad esempio: macro (parola chiave)"
    }
  ],
  yesNo: [
    { label: "Sì", value: true },
    { label: "No", value: false }
  ],
  timeout: [
    { label: "10 sec", value: 10 },
    { label: "15 sec", value: 15 },
    { label: "30 sec", value: 30 },
    { label: "60 sec", value: 60 }
  ],
  countries: [
    {
      dataBaseValue: "Andorra",
      value: "Andorra",
      label: "Andorra",
      imgSrc: "/img/flags/ad.svg"
    },
    {
      dataBaseValue: "UAE",
      value: "Emirati Arabi Uniti",
      label: "Emirati Arabi Uniti",
      imgSrc: "/img/flags/ae.svg"
    },
    {
      dataBaseValue: "Afghanistan",
      value: "Afghanistan",
      label: "Afghanistan",
      imgSrc: "/img/flags/af.svg"
    },
    {
      dataBaseValue: "Antigua and Barbuda",
      value: "Antigua e Barbuda",
      label: "Antigua e Barbuda",
      imgSrc: "/img/flags/ag.svg"
    },
    {
      dataBaseValue: "Anguilla",
      value: "Anguilla",
      label: "Anguilla",
      imgSrc: "/img/flags/ai.svg"
    },
    {
      dataBaseValue: "Albania",
      value: "Albania",
      label: "Albania",
      imgSrc: "/img/flags/al.svg"
    },
    {
      dataBaseValue: "Armenia",
      value: "Armenia",
      label: "Armenia",
      imgSrc: "/img/flags/am.svg"
    },
    {
      dataBaseValue: "Antilles",
      value: "Antille",
      label: "Antille",
      imgSrc: "/img/flags/an.svg"
    },
    {
      dataBaseValue: "Angola",
      value: "Angola",
      label: "Angola",
      imgSrc: "/img/flags/ao.svg"
    },
    {
      dataBaseValue: "Antarctic",
      value: "Antartico",
      label: "Antartico",
      imgSrc: "/img/flags/aq.svg"
    },
    {
      dataBaseValue: "Argentina",
      value: "Argentina",
      label: "Argentina",
      imgSrc: "/img/flags/ar.svg"
    },
    {
      dataBaseValue: "American Samoa",
      value: "Samoa americane",
      label: "Samoa americane",
      imgSrc: "/img/flags/as.svg"
    },
    {
      dataBaseValue: "Austria",
      value: "Austria",
      label: "Austria",
      imgSrc: "/img/flags/at.svg"
    },
    {
      dataBaseValue: "Australia",
      value: "Australia",
      label: "Australia",
      imgSrc: "/img/flags/au.svg"
    },
    {
      dataBaseValue: "Aruba",
      value: "Aruba",
      label: "Aruba",
      imgSrc: "/img/flags/aw.svg"
    },
    {
      dataBaseValue: "Åland Islands",
      value: "Isole Aland",
      label: "Isole Aland",
      imgSrc: "/img/flags/ax.svg"
    },
    {
      dataBaseValue: "Azerbaijan",
      value: "Azerbaigian",
      label: "Azerbaigian",
      imgSrc: "/img/flags/az.svg"
    },
    {
      dataBaseValue: "Bosnia and Herzegovina",
      value: "Bosnia Erzegovina",
      label: "Bosnia Erzegovina",
      imgSrc: "/img/flags/ba.svg"
    },
    {
      dataBaseValue: "Barbados",
      value: "Barbados",
      label: "Barbados",
      imgSrc: "/img/flags/bb.svg"
    },
    {
      dataBaseValue: "Bangladesh",
      value: "Bangladesh",
      label: "Bangladesh",
      imgSrc: "/img/flags/bd.svg"
    },
    {
      dataBaseValue: "Belgium",
      value: "Belgio",
      label: "Belgio",
      imgSrc: "/img/flags/be.svg"
    },
    {
      dataBaseValue: "Burkina Faso",
      value: "Burkina Faso",
      label: "Burkina Faso",
      imgSrc: "/img/flags/bf.svg"
    },
    {
      dataBaseValue: "Bulgaria",
      value: "Bulgaria",
      label: "Bulgaria",
      imgSrc: "/img/flags/bg.svg"
    },
    {
      dataBaseValue: "Bahrain",
      value: "Bahrein",
      label: "Bahrein",
      imgSrc: "/img/flags/bh.svg"
    },
    {
      dataBaseValue: "Burundi",
      value: "Burundi",
      label: "Burundi",
      imgSrc: "/img/flags/bi.svg"
    },
    {
      dataBaseValue: "Benin",
      value: "Benin",
      label: "Benin",
      imgSrc: "/img/flags/bj.svg"
    },
    {
      dataBaseValue: "Saint Barthélemy",
      value: "San Bartolomeo",
      label: "San Bartolomeo",
      imgSrc: "/img/flags/bl.svg"
    },
    {
      dataBaseValue: "Bermuda",
      value: "Bermuda",
      label: "Bermuda",
      imgSrc: "/img/flags/bm.svg"
    },
    {
      dataBaseValue: "Brunei",
      value: "Brunei",
      label: "Brunei",
      imgSrc: "/img/flags/bn.svg"
    },
    {
      dataBaseValue: "Bolivia",
      value: "Bolivia",
      label: "Bolivia",
      imgSrc: "/img/flags/bo.svg"
    },
    {
      dataBaseValue: "Bonaire",
      value: "Bonaire",
      label: "Bonaire",
      imgSrc: "/img/flags/bq.svg"
    },
    {
      dataBaseValue: "Brazil",
      value: "Brasile",
      label: "Brasile",
      imgSrc: "/img/flags/br.svg"
    },
    {
      dataBaseValue: "Bahamas",
      value: "Bahamas",
      label: "Bahamas",
      imgSrc: "/img/flags/bs.svg"
    },
    {
      dataBaseValue: "Butane",
      value: "Butano",
      label: "Butano",
      imgSrc: "/img/flags/bt.svg"
    },
    {
      dataBaseValue: "Bouvet Island",
      value: "Isola Bouvet",
      label: "Isola Bouvet",
      imgSrc: "/img/flags/bv.svg"
    },
    {
      dataBaseValue: "Botswana",
      value: "Botswana",
      label: "Botswana",
      imgSrc: "/img/flags/bw.svg"
    },
    {
      dataBaseValue: "Belarus",
      value: "Bielorussia",
      label: "Bielorussia",
      imgSrc: "/img/flags/by.svg"
    },
    {
      dataBaseValue: "Belize",
      value: "Belize",
      label: "Belize",
      imgSrc: "/img/flags/bz.svg"
    },
    {
      dataBaseValue: "Canada",
      value: "Canada",
      label: "Canada",
      imgSrc: "/img/flags/ca.svg"
    },
    {
      dataBaseValue: "Coconut islands",
      value: "Isole di cocco",
      label: "Isole di cocco",
      imgSrc: "/img/flags/cc.svg"
    },
    {
      dataBaseValue: "DR Congo",
      value: "DR Congo",
      label: "DR Congo",
      imgSrc: "/img/flags/cd.svg"
    },
    {
      dataBaseValue: "Central African Republic",
      value: "Repubblica Centrafricana",
      label: "Repubblica Centrafricana",
      imgSrc: "/img/flags/cf.svg"
    },
    {
      dataBaseValue: "Congo",
      value: "Congo",
      label: "Congo",
      imgSrc: "/img/flags/cg.svg"
    },
    {
      dataBaseValue: "Switzerland",
      value: "Svizzero",
      label: "Svizzero",
      imgSrc: "/img/flags/ch.svg"
    },
    {
      dataBaseValue: "Ivory Coast",
      value: "Costa d'Avorio",
      label: "Costa d'Avorio",
      imgSrc: "/img/flags/ci.svg"
    },
    {
      dataBaseValue: "Cook Islands",
      value: "Isole Cook",
      label: "Isole Cook",
      imgSrc: "/img/flags/ck.svg"
    },
    {
      dataBaseValue: "Chile",
      value: "Chile",
      label: "Chile",
      imgSrc: "/img/flags/cl.svg"
    },
    {
      dataBaseValue: "Cameroon",
      value: "Camerun",
      label: "Camerun",
      imgSrc: "/img/flags/cm.svg"
    },
    {
      dataBaseValue: "China",
      value: "Cina",
      label: "Cina",
      imgSrc: "/img/flags/cn.svg"
    },
    {
      dataBaseValue: "Colombia",
      value: "Colombia",
      label: "Colombia",
      imgSrc: "/img/flags/co.svg"
    },
    {
      dataBaseValue: "Costa Rica",
      value: "Costa Rica",
      label: "Costa Rica",
      imgSrc: "/img/flags/cr.svg"
    },
    {
      dataBaseValue: "Cuba",
      value: "Cuba",
      label: "Cuba",
      imgSrc: "/img/flags/cu.svg"
    },
    {
      dataBaseValue: "Cape Verde",
      value: "Capo Verde",
      label: "Capo Verde",
      imgSrc: "/img/flags/cv.svg"
    },
    {
      dataBaseValue: "Curacao",
      value: "Curacao",
      label: "Curacao",
      imgSrc: "/img/flags/cw.svg"
    },
    {
      dataBaseValue: "Christmas Island",
      value: "Isola di Natale",
      label: "Isola di Natale",
      imgSrc: "/img/flags/cx.svg"
    },
    {
      dataBaseValue: "Cyprus",
      value: "Cipro",
      label: "Cipro",
      imgSrc: "/img/flags/cy.svg"
    },
    {
      dataBaseValue: "Czech Republic",
      value: "Repubblica Ceca",
      label: "Repubblica Ceca",
      imgSrc: "/img/flags/cz.svg"
    },
    {
      dataBaseValue: "Germany",
      value: "Germania",
      label: "Germania",
      imgSrc: "/img/flags/de.svg"
    },
    {
      dataBaseValue: "Djibouti",
      value: "Gibuti",
      label: "Gibuti",
      imgSrc: "/img/flags/dj.svg"
    },
    {
      dataBaseValue: "Denmark",
      value: "Danimarca",
      label: "Danimarca",
      imgSrc: "/img/flags/dk.svg"
    },
    {
      dataBaseValue: "Dominica",
      value: "Dominico",
      label: "Dominico",
      imgSrc: "/img/flags/dm.svg"
    },
    {
      dataBaseValue: "Dominican Republic",
      value: "Repubblica Dominicana",
      label: "Repubblica Dominicana",
      imgSrc: "/img/flags/do.svg"
    },
    {
      dataBaseValue: "Algeria",
      value: "Algeria",
      label: "Algeria",
      imgSrc: "/img/flags/dz.svg"
    },
    {
      dataBaseValue: "Ecuador",
      value: "Ecuador",
      label: "Ecuador",
      imgSrc: "/img/flags/ec.svg"
    },
    {
      dataBaseValue: "Estonia",
      value: "Estonia",
      label: "Estonia",
      imgSrc: "/img/flags/ee.svg"
    },
    {
      dataBaseValue: "Egypt",
      value: "Egitto",
      label: "Egitto",
      imgSrc: "/img/flags/eg.svg"
    },
    {
      dataBaseValue: "West Sahara",
      value: "Sahara occidentale",
      label: "Sahara occidentale",
      imgSrc: "/img/flags/eh.svg"
    },
    {
      dataBaseValue: "Eritrea",
      value: "Eritreo",
      label: "Eritreo",
      imgSrc: "/img/flags/er.svg"
    },
    {
      dataBaseValue: "Spain",
      value: "Spagna",
      label: "Spagna",
      imgSrc: "/img/flags/es.svg"
    },
    {
      dataBaseValue: "Ethiopia",
      value: "Etiopia",
      label: "Etiopia",
      imgSrc: "/img/flags/et.svg"
    },
    {
      dataBaseValue: "Europe",
      value: "Europa",
      label: "Europa",
      imgSrc: "/img/flags/eu.svg"
    },
    {
      dataBaseValue: "Finland",
      value: "Finlandia",
      label: "Finlandia",
      imgSrc: "/img/flags/fi.svg"
    },
    {
      dataBaseValue: "Netherlands",
      value: "Olanda",
      label: "Olanda",
      imgSrc: "/img/flags/nl.svg"
    },
    {
      dataBaseValue: "Fiji",
      value: "Figi",
      label: "Figi",
      imgSrc: "/img/flags/fj.svg"
    },
    {
      dataBaseValue: "Falkland Islands",
      value: "Isole Falkland",
      label: "Isole Falkland",
      imgSrc: "/img/flags/fk.svg"
    },
    {
      dataBaseValue: "Federated States of Micronesia",
      value: "Stati Federati di Micronesia",
      label: "Stati Federati di Micronesia",
      imgSrc: "/img/flags/fm.svg"
    },
    {
      dataBaseValue: "Faroe Islands",
      value: "Isole Faroe",
      label: "Isole Faroe",
      imgSrc: "/img/flags/fo.svg"
    },
    {
      dataBaseValue: "France",
      value: "Francia",
      label: "Francia",
      imgSrc: "/img/flags/fr.svg"
    },
    {
      dataBaseValue: "Georgia",
      value: "Georgia",
      label: "Georgia",
      imgSrc: "/img/flags/ge.svg"
    },
    // {
    //   dataBaseValue: "United Kingdom",
    //   value: "Regno Unito",
    //   label: "Regno Unito",
    //   imgSrc: "/img/flags/en.svg"
    // },
    {
      dataBaseValue: "Northern Ireland",
      value: "Irlanda del Nord",
      label: "Irlanda del Nord",
      imgSrc: "/img/flags/nir.svg"
    },
    {
      dataBaseValue: "Scotland",
      value: "Scozia",
      label: "Scozia",
      imgSrc: "/img/flags/sct.svg"
    },
    {
      dataBaseValue: "Wales",
      value: "Galles",
      label: "Galles",
      imgSrc: "/img/flags/wls.svg"
    },
    {
      dataBaseValue: "Grenada",
      value: "Granata",
      label: "Granata",
      imgSrc: "/img/flags/gd.svg"
    },
    {
      dataBaseValue: "French Guiana",
      value: "Guiana francese",
      label: "Guiana francese",
      imgSrc: "/img/flags/gf.svg"
    },
    {
      dataBaseValue: "Guernsey",
      value: "Guernsey",
      label: "Guernsey",
      imgSrc: "/img/flags/gg.svg"
    },
    {
      dataBaseValue: "Ghana",
      value: "Ghana",
      label: "Ghana",
      imgSrc: "/img/flags/gh.svg"
    },
    {
      dataBaseValue: "Gibraltar",
      value: "Gibilterra",
      label: "Gibilterra",
      imgSrc: "/img/flags/gi.svg"
    },
    {
      dataBaseValue: "Greenland",
      value: "Groenlandia",
      label: "Groenlandia",
      imgSrc: "/img/flags/gl.svg"
    },
    {
      dataBaseValue: "Gambia",
      value: "Gambia",
      label: "Gambia",
      imgSrc: "/img/flags/gm.svg"
    },
    {
      dataBaseValue: "Guinea",
      value: "Guinea",
      label: "Guinea",
      imgSrc: "/img/flags/gn.svg"
    },
    {
      dataBaseValue: "Guadeloupe",
      value: "Guadalupa",
      label: "Guadalupa",
      imgSrc: "/img/flags/gp.svg"
    },
    {
      dataBaseValue: "Equatorial Guinea",
      value: "Guinea Equatoriale",
      label: "Guinea Equatoriale",
      imgSrc: "/img/flags/gq.svg"
    },
    {
      dataBaseValue: "Greece",
      value: "Grecia",
      label: "Grecia",
      imgSrc: "/img/flags/gr.svg"
    },
    {
      dataBaseValue: "South Georgia and the South Sandwich Islands",
      value: "Georgia del Sud e Isole Sandwich meridionali",
      label: "Georgia del Sud e Isole Sandwich meridionali",
      imgSrc: "/img/flags/gs.svg"
    },
    {
      dataBaseValue: "Guatemala",
      value: "Guatemala",
      label: "Guatemala",
      imgSrc: "/img/flags/gt.svg"
    },
    {
      dataBaseValue: "Guam",
      value: "Guam",
      label: "Guam",
      imgSrc: "/img/flags/gu.svg"
    },
    {
      dataBaseValue: "Guinea-Bissau",
      value: "Guinea-Bissau",
      label: "Guinea-Bissau",
      imgSrc: "/img/flags/gw.svg"
    },
    {
      dataBaseValue: "Guyana",
      value: "Guyana",
      label: "Guyana",
      imgSrc: "/img/flags/gy.svg"
    },
    {
      dataBaseValue: "Hong Kong",
      value: "Hong Kong",
      label: "Hong Kong",
      imgSrc: "/img/flags/hk.svg"
    },
    {
      dataBaseValue: "Honduras",
      value: "Honduras",
      label: "Honduras",
      imgSrc: "/img/flags/hn.svg"
    },
    {
      dataBaseValue: "Croatia",
      value: "Croazia",
      label: "Croazia",
      imgSrc: "/img/flags/hr.svg"
    },
    {
      dataBaseValue: "Haiti",
      value: "Haiti",
      label: "Haiti",
      imgSrc: "/img/flags/ht.svg"
    },
    {
      dataBaseValue: "Hungary",
      value: "Ungheria",
      label: "Ungheria",
      imgSrc: "/img/flags/hu.svg"
    },
    {
      dataBaseValue: "Indonesia",
      value: "Indonesia",
      label: "Indonesia",
      imgSrc: "/img/flags/id.svg"
    },
    {
      dataBaseValue: "Ireland",
      value: "Irlanda",
      label: "Irlanda",
      imgSrc: "/img/flags/ie.svg"
    },
    {
      dataBaseValue: "Israel",
      value: "Israele",
      label: "Israele",
      imgSrc: "/img/flags/il.svg"
    },
    {
      dataBaseValue: "Isle of Man",
      value: "Isola di Man",
      label: "Isola di Man",
      imgSrc: "/img/flags/im.svg"
    },
    {
      dataBaseValue: "India",
      value: "India",
      label: "India",
      imgSrc: "/img/flags/in.svg"
    },
    {
      dataBaseValue: "British Indian Ocean Territory",
      value: "Territorio britannico dell'Oceano Indiano",
      label: "Territorio britannico dell'Oceano Indiano",
      imgSrc: "/img/flags/io.svg"
    },
    {
      dataBaseValue: "Iraq",
      value: "Iraq",
      label: "Iraq",
      imgSrc: "/img/flags/iq.svg"
    },
    {
      dataBaseValue: "Iran",
      value: "Iran",
      label: "Iran",
      imgSrc: "/img/flags/ir.svg"
    },
    {
      dataBaseValue: "Italy",
      value: "Italia",
      label: "Italia",
      imgSrc: "/img/flags/it.svg"
    },
    {
      dataBaseValue: "Jersey",
      value: "Maglia",
      label: "Maglia",
      imgSrc: "/img/flags/je.svg"
    },
    {
      dataBaseValue: "Jamaica",
      value: "Giamaica",
      label: "Giamaica",
      imgSrc: "/img/flags/jm.svg"
    },
    {
      dataBaseValue: "Jordan",
      value: "Giordania",
      label: "Giordania",
      imgSrc: "/img/flags/jo.svg"
    },
    {
      dataBaseValue: "Japan",
      value: "Giappone",
      label: "Giappone",
      imgSrc: "/img/flags/jp.svg"
    },
    {
      dataBaseValue: "Kenya",
      value: "Kenia",
      label: "Kenia",
      imgSrc: "/img/flags/ke.svg"
    },
    {
      dataBaseValue: "Kyrgyzstan",
      value: "Kirghizistan",
      label: "Kirghizistan",
      imgSrc: "/img/flags/kg.svg"
    },
    {
      dataBaseValue: "Cambodia",
      value: "Cambogia",
      label: "Cambogia",
      imgSrc: "/img/flags/kh.svg"
    },
    {
      dataBaseValue: "Kiribati",
      value: "Kiribati",
      label: "Kiribati",
      imgSrc: "/img/flags/ki.svg"
    },
    {
      dataBaseValue: "Comoros",
      value: "Comore",
      label: "Comore",
      imgSrc: "/img/flags/km.svg"
    },
    {
      dataBaseValue: "Saint Kitts and Nevis",
      value: "Saint Kitts e Nevis",
      label: "Saint Kitts e Nevis",
      imgSrc: "/img/flags/kn.svg"
    },
    {
      dataBaseValue: "Democratic People's Republic of Korea",
      value: "Repubblica Democratica Popolare di Corea",
      label: "Repubblica Democratica Popolare di Corea",
      imgSrc: "/img/flags/kp.svg"
    },
    {
      dataBaseValue: "South Korea",
      value: "Corea del Sud",
      label: "Corea del Sud",
      imgSrc: "/img/flags/kr.svg"
    },
    {
      dataBaseValue: "Kuwait",
      value: "Kuwait",
      label: "Kuwait",
      imgSrc: "/img/flags/kw.svg"
    },
    {
      dataBaseValue: "Cayman Islands",
      value: "Isole Cayman",
      label: "Isole Cayman",
      imgSrc: "/img/flags/ky.svg"
    },
    {
      dataBaseValue: "Kazakhstan",
      value: "Kazakistan",
      label: "Kazakistan",
      imgSrc: "/img/flags/kz.svg"
    },
    {
      dataBaseValue: "Laos",
      value: "Laos",
      label: "Laos",
      imgSrc: "/img/flags/la.svg"
    },
    {
      dataBaseValue: "Lebanon",
      value: "Libano",
      label: "Libano",
      imgSrc: "/img/flags/lb.svg"
    },
    {
      dataBaseValue: "Saint Lucia",
      value: "Santa Lucia",
      label: "Santa Lucia",
      imgSrc: "/img/flags/lc.svg"
    },
    {
      dataBaseValue: "Liechtenstein",
      value: "Liechtenstein",
      label: "Liechtenstein",
      imgSrc: "/img/flags/li.svg"
    },
    {
      dataBaseValue: "Sri Lanka",
      value: "Sri Lanka",
      label: "Sri Lanka",
      imgSrc: "/img/flags/lk.svg"
    },
    {
      dataBaseValue: "Liberia",
      value: "Liberia",
      label: "Liberia",
      imgSrc: "/img/flags/lr.svg"
    },
    {
      dataBaseValue: "Lesotho",
      value: "Lesoto",
      label: "Lesoto",
      imgSrc: "/img/flags/ls.svg"
    },
    {
      dataBaseValue: "Lithuania",
      value: "Lituania",
      label: "Lituania",
      imgSrc: "/img/flags/lt.svg"
    },
    {
      dataBaseValue: "Luxembourg",
      value: "Lussemburgo",
      label: "Lussemburgo",
      imgSrc: "/img/flags/lu.svg"
    },
    {
      dataBaseValue: "Latvia",
      value: "Lettonia",
      label: "Lettonia",
      imgSrc: "/img/flags/lv.svg"
    },
    {
      dataBaseValue: "Libya",
      value: "Libia",
      label: "Libia",
      imgSrc: "/img/flags/ly.svg"
    },
    {
      dataBaseValue: "Morocco",
      value: "Marocco",
      label: "Marocco",
      imgSrc: "/img/flags/ma.svg"
    },
    {
      dataBaseValue: "Monaco",
      value: "Monaco",
      label: "Monaco",
      imgSrc: "/img/flags/mc.svg"
    },
    {
      dataBaseValue: "Moldova",
      value: "Moldavia",
      label: "Moldavia",
      imgSrc: "/img/flags/md.svg"
    },
    {
      dataBaseValue: "Montenegro",
      value: "Montenegro",
      label: "Montenegro",
      imgSrc: "/img/flags/me.svg"
    },
    {
      dataBaseValue: "Saint Martin",
      value: "San Martino",
      label: "San Martino",
      imgSrc: "/img/flags/mf.svg"
    },
    {
      dataBaseValue: "Madagascar",
      value: "Madagascar",
      label: "Madagascar",
      imgSrc: "/img/flags/mg.svg"
    },
    {
      dataBaseValue: "Marshall Islands",
      value: "Isole Marshall",
      label: "Isole Marshall",
      imgSrc: "/img/flags/mh.svg"
    },
    {
      dataBaseValue: "Macedonia",
      value: "Macedonia",
      label: "Macedonia",
      imgSrc: "/img/flags/mk.svg"
    },
    {
      dataBaseValue: "Mali",
      value: "Mali",
      label: "Mali",
      imgSrc: "/img/flags/ml.svg"
    },
    {
      dataBaseValue: "Myanmar",
      value: "Birmania",
      label: "Birmania",
      imgSrc: "/img/flags/mm.svg"
    },
    {
      dataBaseValue: "Mongolia",
      value: "Mongolia",
      label: "Mongolia",
      imgSrc: "/img/flags/mn.svg"
    },
    {
      dataBaseValue: "Macau",
      value: "Macao",
      label: "Macao",
      imgSrc: "/img/flags/mo.svg"
    },
    {
      dataBaseValue: "Northern Mariana Islands",
      value: "Isole Marianne settentrionali",
      label: "Isole Marianne settentrionali",
      imgSrc: "/img/flags/mp.svg"
    },
    {
      dataBaseValue: "Martinique",
      value: "Martinica",
      label: "Martinica",
      imgSrc: "/img/flags/mq.svg"
    },
    {
      dataBaseValue: "Mauritius",
      value: "Maurizio",
      label: "Maurizio",
      imgSrc: "/img/flags/mr.svg"
    },
    {
      dataBaseValue: "Montserrat",
      value: "Montserrat",
      label: "Montserrat",
      imgSrc: "/img/flags/ms.svg"
    },
    {
      dataBaseValue: "Malta",
      value: "Malta",
      label: "Malta",
      imgSrc: "/img/flags/mt.svg"
    },
    {
      dataBaseValue: "O. Mauritius",
      value: "O. Mauritius",
      label: "O. Mauritius",
      imgSrc: "/img/flags/mu.svg"
    },
    {
      dataBaseValue: "Maldives",
      value: "Maldive",
      label: "Maldive",
      imgSrc: "/img/flags/mv.svg"
    },
    {
      dataBaseValue: "Malawi",
      value: "Malawi",
      label: "Malawi",
      imgSrc: "/img/flags/mw.svg"
    },
    {
      dataBaseValue: "Mexico",
      value: "Messico",
      label: "Messico",
      imgSrc: "/img/flags/mx.svg"
    },
    {
      dataBaseValue: "Malaysia",
      value: "Malaysia",
      label: "Malaysia",
      imgSrc: "/img/flags/my.svg"
    },
    {
      dataBaseValue: "Mozambique",
      value: "Mozambico",
      label: "Mozambico",
      imgSrc: "/img/flags/mz.svg"
    },
    {
      dataBaseValue: "Namibia",
      value: "Namibia",
      label: "Namibia",
      imgSrc: "/img/flags/na.svg"
    },
    {
      dataBaseValue: "New Caledonia",
      value: "Nuova Caledonia",
      label: "Nuova Caledonia",
      imgSrc: "/img/flags/nc.svg"
    },
    {
      dataBaseValue: "Niger",
      value: "Niger",
      label: "Niger",
      imgSrc: "/img/flags/ne.svg"
    },
    {
      dataBaseValue: "Norfolk Island",
      value: "Isola Norfolk",
      label: "Isola Norfolk",
      imgSrc: "/img/flags/nf.svg"
    },
    {
      dataBaseValue: "Nigeria",
      value: "Nigeria",
      label: "Nigeria",
      imgSrc: "/img/flags/ng.svg"
    },
    {
      dataBaseValue: "Nicaragua",
      value: "Nicaragua",
      label: "Nicaragua",
      imgSrc: "/img/flags/ni.svg"
    },
    {
      dataBaseValue: "Norway",
      value: "Norvegia",
      label: "Norvegia",
      imgSrc: "/img/flags/no.svg"
    },
    {
      dataBaseValue: "Nepal",
      value: "Nepal",
      label: "Nepal",
      imgSrc: "/img/flags/np.svg"
    },
    {
      dataBaseValue: "Nauru",
      value: "Nauru",
      label: "Nauru",
      imgSrc: "/img/flags/nr.svg"
    },
    {
      dataBaseValue: "Niue",
      value: "Niue",
      label: "Niue",
      imgSrc: "/img/flags/nu.svg"
    },
    {
      dataBaseValue: "New Zealand",
      value: "Nuova Zelanda",
      label: "Nuova Zelanda",
      imgSrc: "/img/flags/nz.svg"
    },
    {
      dataBaseValue: "Oman",
      value: "Oman",
      label: "Oman",
      imgSrc: "/img/flags/om.svg"
    },
    {
      dataBaseValue: "Panama",
      value: "Panama",
      label: "Panama",
      imgSrc: "/img/flags/pa.svg"
    },
    {
      dataBaseValue: "Peru",
      value: "Perù",
      label: "Perù",
      imgSrc: "/img/flags/pe.svg"
    },
    {
      dataBaseValue: "French polynesia",
      value: "Polinesia francese",
      label: "Polinesia francese",
      imgSrc: "/img/flags/pf.svg"
    },
    {
      dataBaseValue: "Papua New Guinea",
      value: "Papua Nuova Guinea",
      label: "Papua Nuova Guinea",
      imgSrc: "/img/flags/pg.svg"
    },
    {
      dataBaseValue: "Philippines",
      value: "Filippini",
      label: "Filippini",
      imgSrc: "/img/flags/ph.svg"
    },
    {
      dataBaseValue: "Pakistan",
      value: "Pakistan",
      label: "Pakistan",
      imgSrc: "/img/flags/pk.svg"
    },
    {
      dataBaseValue: "Poland",
      value: "Polonia",
      label: "Polonia",
      imgSrc: "/img/flags/pl.svg"
    },
    {
      dataBaseValue: "Saint Pierre and Miquelon",
      value: "Saint Pierre e Miquelon",
      label: "Saint Pierre e Miquelon",
      imgSrc: "/img/flags/pm.svg"
    },
    {
      dataBaseValue: "Pitcairn Islands",
      value: "Isole Pitcairn",
      label: "Isole Pitcairn",
      imgSrc: "/img/flags/pn.svg"
    },
    {
      dataBaseValue: "Puerto Rico",
      value: "Portorico",
      label: "Portorico",
      imgSrc: "/img/flags/pr.svg"
    },
    {
      dataBaseValue: "Palestine",
      value: "Palestina",
      label: "Palestina",
      imgSrc: "/img/flags/ps.svg"
    },
    {
      dataBaseValue: "Portugal",
      value: "Portogallo",
      label: "Portogallo",
      imgSrc: "/img/flags/pt.svg"
    },
    {
      dataBaseValue: "Paraguay",
      value: "Paraguay",
      label: "Paraguay",
      imgSrc: "/img/flags/py.svg"
    },
    {
      dataBaseValue: "Qatar",
      value: "Qatar",
      label: "Qatar",
      imgSrc: "/img/flags/qa.svg"
    },
    {
      dataBaseValue: "Reunion",
      value: "Riunione",
      label: "Riunione",
      imgSrc: "/img/flags/re.svg"
    },
    {
      dataBaseValue: "Romania",
      value: "Romania",
      label: "Romania",
      imgSrc: "/img/flags/ro.svg"
    },
    {
      dataBaseValue: "Serbia",
      value: "Serbia",
      label: "Serbia",
      imgSrc: "/img/flags/rs.svg"
    },
    {
      dataBaseValue: "Russia",
      value: "Federazione Russa",
      label: "Federazione Russa",
      imgSrc: "/img/flags/ru.svg"
    },
    {
      dataBaseValue: "Rwanda",
      value: "Ruanda",
      label: "Ruanda",
      imgSrc: "/img/flags/rw.svg"
    },
    {
      dataBaseValue: "South Africa",
      value: "Sud Africa",
      label: "Sud Africa",
      imgSrc: "/img/flags/sa.svg"
    },
    {
      dataBaseValue: "Solomon Islands",
      value: "Isole Salomone",
      label: "Isole Salomone",
      imgSrc: "/img/flags/sb.svg"
    },
    {
      dataBaseValue: "Seychelles",
      value: "Seychelles",
      label: "Seychelles",
      imgSrc: "/img/flags/sc.svg"
    },
    {
      dataBaseValue: "Sudan",
      value: "Sudan",
      label: "Sudan",
      imgSrc: "/img/flags/sd.svg"
    },
    {
      dataBaseValue: "Sweden",
      value: "Svezia",
      label: "Svezia",
      imgSrc: "/img/flags/se.svg"
    },
    {
      dataBaseValue: "Singapore",
      value: "Singapore",
      label: "Singapore",
      imgSrc: "/img/flags/sg.svg"
    },
    {
      dataBaseValue: "O. St. Helena",
      value: "O. St. Helena",
      label: "O. St. Helena",
      imgSrc: "/img/flags/sh.svg"
    },
    {
      dataBaseValue: "Slovenia",
      value: "Slovenia",
      label: "Slovenia",
      imgSrc: "/img/flags/si.svg"
    },
    {
      dataBaseValue: "Svalbard",
      value: "Svalbard",
      label: "Svalbard",
      imgSrc: "/img/flags/sj.svg"
    },
    {
      dataBaseValue: "Slovakia",
      value: "Slovacchia",
      label: "Slovacchia",
      imgSrc: "/img/flags/sk.svg"
    },
    {
      dataBaseValue: "Sierra Leone",
      value: "Sierra Leone",
      label: "Sierra Leone",
      imgSrc: "/img/flags/sl.svg"
    },
    {
      dataBaseValue: "San Marino",
      value: "San Marino",
      label: "San Marino",
      imgSrc: "/img/flags/sm.svg"
    },
    {
      dataBaseValue: "Senegal",
      value: "Senegal",
      label: "Senegal",
      imgSrc: "/img/flags/sn.svg"
    },
    {
      dataBaseValue: "Somalia",
      value: "Somalia",
      label: "Somalia",
      imgSrc: "/img/flags/so.svg"
    },
    {
      dataBaseValue: "Suriname",
      value: "Suriname",
      label: "Suriname",
      imgSrc: "/img/flags/sr.svg"
    },
    {
      dataBaseValue: "South Sudan",
      value: "Sudan del Sud",
      label: "Sudan del Sud",
      imgSrc: "/img/flags/ss.svg"
    },
    {
      dataBaseValue: "Sao Tome and Principe",
      value: "Sao Tomé e Principe",
      label: "Sao Tomé e Principe",
      imgSrc: "/img/flags/st.svg"
    },
    {
      dataBaseValue: "Salvador",
      value: "Salvatore",
      label: "Salvatore",
      imgSrc: "/img/flags/sv.svg"
    },
    {
      dataBaseValue: "Sint Maarten",
      value: "Sint Maarten",
      label: "Sint Maarten",
      imgSrc: "/img/flags/sx.svg"
    },
    {
      dataBaseValue: "Syria",
      value: "Siria",
      label: "Siria",
      imgSrc: "/img/flags/sy.svg"
    },
    {
      dataBaseValue: "Eswatini",
      value: "Eswatini",
      label: "Eswatini",
      imgSrc: "/img/flags/sz.svg"
    },
    {
      dataBaseValue: "Turks and Caicos Islands",
      value: "Isole Turks e Caicos",
      label: "Isole Turks e Caicos",
      imgSrc: "/img/flags/tc.svg"
    },
    {
      dataBaseValue: "Chad",
      value: "Chad",
      label: "Chad",
      imgSrc: "/img/flags/td.svg"
    },
    {
      dataBaseValue: "Togo",
      value: "Andare",
      label: "Andare",
      imgSrc: "/img/flags/tg.svg"
    },
    {
      dataBaseValue: "Thailand",
      value: "Tailandia",
      label: "Tailandia",
      imgSrc: "/img/flags/th.svg"
    },
    {
      dataBaseValue: "Tajikistan",
      value: "Tagikistan",
      label: "Tagikistan",
      imgSrc: "/img/flags/tj.svg"
    },
    {
      dataBaseValue: "Tokelau",
      value: "Tokelau",
      label: "Tokelau",
      imgSrc: "/img/flags/tk.svg"
    },
    {
      dataBaseValue: "East Timor",
      value: "Timor Est",
      label: "Timor Est",
      imgSrc: "/img/flags/tl.svg"
    },
    {
      dataBaseValue: "Turkmenistan",
      value: "Turkmenistan",
      label: "Turkmenistan",
      imgSrc: "/img/flags/tm.svg"
    },
    {
      dataBaseValue: "Tunisia",
      value: "Tunisia",
      label: "Tunisia",
      imgSrc: "/img/flags/tn.svg"
    },
    {
      dataBaseValue: "Turkey",
      value: "Tacchino",
      label: "Tacchino",
      imgSrc: "/img/flags/tr.svg"
    },
    {
      dataBaseValue: "Trinidad and Tobago",
      value: "Trinidad e Tobago",
      label: "Trinidad e Tobago",
      imgSrc: "/img/flags/tt.svg"
    },
    {
      dataBaseValue: "Tuvalu",
      value: "Tuvalù",
      label: "Tuvalù",
      imgSrc: "/img/flags/tv.svg"
    },
    {
      dataBaseValue: "Taiwan",
      value: "Taiwan",
      label: "Taiwan",
      imgSrc: "/img/flags/tw.svg"
    },
    {
      dataBaseValue: "Tanzania",
      value: "Tanzania",
      label: "Tanzania",
      imgSrc: "/img/flags/tz.svg"
    },
    {
      dataBaseValue: "Ukraine",
      value: "Ucraino",
      label: "Ucraino",
      imgSrc: "/img/flags/ua.svg"
    },
    {
      dataBaseValue: "Uganda",
      value: "Uganda",
      label: "Uganda",
      imgSrc: "/img/flags/ug.svg"
    },
    {
      dataBaseValue: "Minor Outlying Islands (USA)",
      value: "Isole minori esterne (USA)",
      label: "Isole minori esterne (USA)",
      imgSrc: "/img/flags/um.svg"
    },
    {
      dataBaseValue: "United States",
      value: "Stati Uniti d'America",
      label: "Stati Uniti d'America",
      imgSrc: "/img/flags/us.svg"
    },
    {
      dataBaseValue: "United Kingdom",
      value: "Regno Unito",
      label: "Regno Unito",
      imgSrc: "/img/flags/uk.svg"
    },
    {
      dataBaseValue: "Uruguay",
      value: "Uruguay",
      label: "Uruguay",
      imgSrc: "/img/flags/uy.svg"
    },
    {
      dataBaseValue: "Uzbekistan",
      value: "Uzbekistan",
      label: "Uzbekistan",
      imgSrc: "/img/flags/uz.svg"
    },
    {
      dataBaseValue: "Vatican City State",
      value: "Stato della Città del Vaticano",
      label: "Stato della Città del Vaticano",
      imgSrc: "/img/flags/va.svg"
    },
    {
      dataBaseValue: "Saint Vincent and the Grenadines",
      value: "Saint Vincent e Grenadine",
      label: "Saint Vincent e Grenadine",
      imgSrc: "/img/flags/vc.svg"
    },
    {
      dataBaseValue: "Venezuela",
      value: "Venezuela",
      label: "Venezuela",
      imgSrc: "/img/flags/ve.svg"
    },
    {
      dataBaseValue: "British Virgin Islands",
      value: "Isole Vergini Britanniche",
      label: "Isole Vergini Britanniche",
      imgSrc: "/img/flags/vg.svg"
    },
    {
      dataBaseValue: "US Virgin Islands",
      value: "Isole Vergini americane",
      label: "Isole Vergini americane",
      imgSrc: "/img/flags/vi.svg"
    },
    {
      dataBaseValue: "Vietnam",
      value: "Vietnam",
      label: "Vietnam",
      imgSrc: "/img/flags/vn.svg"
    },
    {
      dataBaseValue: "Vanuatu",
      value: "Vanuatu",
      label: "Vanuatu",
      imgSrc: "/img/flags/vu.svg"
    },
    {
      dataBaseValue: "Wallis and Futuna Islands",
      value: "Isole Wallis e Futuna",
      label: "Isole Wallis e Futuna",
      imgSrc: "/img/flags/wf.svg"
    },
    {
      dataBaseValue: "Samoa",
      value: "Samoa",
      label: "Samoa",
      imgSrc: "/img/flags/ws.svg"
    },
    {
      dataBaseValue: "Kosovo",
      value: "Kosovo",
      label: "Kosovo",
      imgSrc: "/img/flags/xk.svg"
    },
    {
      dataBaseValue: "Yemen",
      value: "Yemen",
      label: "Yemen",
      imgSrc: "/img/flags/ye.svg"
    },
    {
      dataBaseValue: "Mayotte",
      value: "Mayotte",
      label: "Mayotte",
      imgSrc: "/img/flags/yt.svg"
    },
    {
      dataBaseValue: "South Africa",
      value: "Sud Africa",
      label: "Sud Africa",
      imgSrc: "/img/flags/za.svg"
    },
    {
      dataBaseValue: "Zambia",
      value: "Zambia",
      label: "Zambia",
      imgSrc: "/img/flags/zm.svg"
    },
    {
      dataBaseValue: "Zimbabwe",
      value: "Zimbabwe",
      label: "Zimbabwe",
      imgSrc: "/img/flags/zw.svg"
    }
  ],
  filterProxyItems: [
    {
      id: 1,
      value: "Format IP:PORT",
      regExp: /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(\s{0,}|:)\d{1,5}/,
      divider: [":"]
    },
    // {
    //   id: 2,
    //   value: "Format KeyCollector",
    //   regExp:
    //     /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(\s{0,}|:)\d{1,5}(\s{0,}|@)\S+(\s{0,}|:)\S+/,
    //   divider: [":", "@"]
    // },
    {
      id: 3,
      value: "Format IP:PORT@Login:Password",
      regExp:
        /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(\s{0,}|:)\d{1,5}(\s{0,}|@)\S+(\s{0,}|:)\S+/,
      divider: [":", "@"]
    },
    {
      id: 4,
      value: "Format IP.PORT:Login:Password",
      regExp:
        /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(\s{0,}|:)\d{1,5}(\s{0,}|:)\S+(\s{0,}|:)\S+/,
      divider: [":"]
    }
  ],
  quantity: [
    { label: "10 pz.", value: 10 },
    { label: "15 pz.", value: 15 },
    { label: "20 pz.", value: 20 },
    { label: "50 pz.", value: 50 }
  ],
  regions: [
    { value: "MOSCA", label: "Mosca" },
    { value: "KIEV", label: "Kiev" },
    { value: "ST. PIETROBURGO", label: "San Pietroburgo" }
  ]
};
