import { Link, useParams } from "react-router-dom";

import { useTranslation } from "../../../../../../../hooks";

const HeaderInstrumentsItem = ({ item, onClick }) => {
  const { lang } = useParams();
  const t = useTranslation();

  return (
    <li className="header__mobile-subitem" onClick={onClick}>
      <Link to={`/${lang ? lang : "ru"}/${item.route}/`}>
        <img
          src={item.imgSrc}
          loading="lazy"
          width="16"
          height="16"
          alt={t.navigation[item.translation]}
          aria-hidden="true"
        />
        {t.navigation[item.translation]}
      </Link>
    </li>
  );
};

export default HeaderInstrumentsItem;
