import moment from "moment/moment";

import { ApiService } from "../../services/ApiService";

import { getDntActive } from "./getDntActive";

export const anonimityService = async (webRtc = true) => {
  try {
    const dateJs = moment().format();
    const userAgentJs = navigator.userAgent;
    const languageJs = navigator.language;
    const windowSizes = {
      width: window.screen.width,
      height: window.screen.height
    };
    const flash =
      typeof navigator.plugins !== "undefined" &&
      typeof navigator.plugins["Shockwave Flash"] === "object";
    const java = navigator.javaEnabled();

    const activeX = typeof window.ActiveXObject !== "undefined";

    const doNotTrack = getDntActive();

    const mobile = navigator.userAgentData?.mobile;

    const isVBScript =
      !!navigator.userAgent.match(/Trident/) ||
      !!navigator.userAgent.match(/MSIE/);

    const { screen } = window;
    const dpi = window.devicePixelRatio;

    const { data } = await ApiService.getAnonimityInfo(
      dateJs,
      userAgentJs,
      languageJs,
      windowSizes,
      flash,
      java,
      activeX,
      webRtc,
      doNotTrack,
      mobile,
      isVBScript,
      screen.colorDepth,
      dpi
    );

    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("An error occurred:", error);
  }
};
