import { createSlice } from "@reduxjs/toolkit";

import { translationInitialState } from "./Translation.initialState";
import { getAllTranslation, saveTranslation } from "./Translation.operations";
import {
  getAllTranslationPendingReducer,
  getAllTranslationRejectedReducer,
  getAllTranslationSuccessReducer,
  saveTranslationSuccessReducer
} from "./Translation.reducer";

const translationSlice = createSlice({
  name: "translations",
  initialState: translationInitialState,
  extraReducers: (builder) =>
    builder
      .addCase(saveTranslation, saveTranslationSuccessReducer)
      .addCase(getAllTranslation.fulfilled, getAllTranslationSuccessReducer)
      .addCase(getAllTranslation.pending, getAllTranslationPendingReducer)
      .addCase(getAllTranslation.rejected, getAllTranslationRejectedReducer)
});

export const TranslationReducerActions = {
  getAllTranslation,
  saveTranslation
};

export default translationSlice.reducer;
