import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import { ApiService } from "../../../services/ApiService";

export const getAllTranslation = createAsyncThunk(
  "translation/getAllTranslation",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.getAllTranslation();

      return data;
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);

export const saveTranslation = createAction("translation/saveTranslation");
