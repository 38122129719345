export const proxyServerTypesConstant = {
  options: [
    {
      value: "HTTP",
      label: "HTTP | HTTPS",
      defaultChecked: true
    },
    {
      value: "SOCKS",
      label: "SOCKS 4/5",
      defaultChecked: false
    }
  ],
  name: "proxyType"
};
