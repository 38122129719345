import { startTransition, useEffect } from "react";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useDispatchedActions } from "./useDispatchedActions";
import { useMatchRoute } from "./useMatchRoute";

export const usePageContent = () => {
  const { lang, filter } = useParams();
  const route = useMatchRoute();
  const { getPageContent } = useDispatchedActions();
  const { store } = useSelector((state) => state.content.content);
  const { translation } = useSelector((state) => state.translation);

  useEffect(() => {
    if (!lang || !Object.keys(translation).includes(lang)) {
      return;
    }

    // get content for filters
    if (filter && !store?.[filter]?.hasOwnProperty(lang)) {
      startTransition(() => getPageContent({ locale: lang, tag: filter }));
    }

    if (!store?.[route.tag]?.hasOwnProperty(lang)) {
      startTransition(() => getPageContent({ locale: lang, tag: route.tag }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route, lang, filter]);
};
