import { ClientReducerActions } from "./ClientReducer/Client.slice";
import { ContentReducerActions } from "./ContentReducer/Content.slice";
import { DnsReducerActions } from "./DnsReducer/Dns.slice";
import { HeadersReducerAction } from "./HeadersReducer/Headers.slice";
import { ProxyReducerActions } from "./ProxyReducer/Proxy.slice";
import { TranslationReducerActions } from "./TranslationReducer/Translation.slice";
import { WebRtcReducerActions } from "./WebRtcReducer/WebRtc.slice";

export const AllActions = {
  ...TranslationReducerActions,
  ...ClientReducerActions,
  ...ProxyReducerActions,
  ...ContentReducerActions,
  ...WebRtcReducerActions,
  ...DnsReducerActions,
  ...HeadersReducerAction
};
