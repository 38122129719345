import { forwardRef, useEffect, useState } from "react";

import { useTranslation } from "../../../hooks";

export const CheckerTextarea = forwardRef(({ proxyList }, ref) => {
  const [value, setValue] = useState([]);
  const t = useTranslation();

  const onInputHandler = (e) => {
    setValue(e.target.value.split("\n"));
  };

  useEffect(() => {
    if (proxyList.length > 0) {
      const ipAddresses = proxyList.map((item) => item.ip || item);

      setValue(ipAddresses);
    } else {
      setValue([]);
    }
  }, [proxyList]);

  return (
    <div className="checker-main__right">
      <div className="checker__title">{t.checker.typeProxy.title}</div>
      <div className="checker__right-textarea checker-main__right-textarea">
        <textarea
          aria-label={t.checker.typeProxy.title}
          ref={ref}
          onInput={onInputHandler}
          value={value?.join("\n") || ""}
        ></textarea>
      </div>
    </div>
  );
});
