import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { useDispatchedActions, useTranslation } from "../../../../../hooks";
import {
  mobileCompanyNavigation,
  mobileInstrumentsNavigation
} from "../../../../../utils/constants";
import { LangDropdown } from "../HeaderTop/components/LangDropdown/LangDropdown";

import HeaderCompanyItem from "./components/HeaderCompanyItem";
import HeaderInstrumentsItem from "./components/HeaderInstrumentsItem";

import "./HeaderMobile.scss";

export const HeaderMobile = () => {
  // **Props
  const { lang } = useParams();

  const t = useTranslation();
  const { burgerFlag } = useSelector((state) => state.content.content);
  // Dispatch
  const { toggleBurger } = useDispatchedActions();

  const toggleAccordionHandle = (e) => {
    const accordion = e.currentTarget;
    const mobileSubList = accordion.nextElementSibling;

    accordion.classList.toggle("active");

    if (mobileSubList.style.maxHeight) {
      mobileSubList.style.maxHeight = null;
    } else {
      mobileSubList.style.maxHeight = `${mobileSubList.scrollHeight}px`;
    }
  };

  const linkClickHandler = () => {
    toggleBurger(!burgerFlag);
  };

  return (
    <>
      <div
        className={`backdrop ${burgerFlag ? "active" : ""}`}
        onClick={linkClickHandler}
      ></div>
      <div className="menu-wraper">
        <div className={`header__mobile ${burgerFlag ? "active" : ""}`}>
          <div className="navWraper">
            <nav className="header__mobile-nav">
              {burgerFlag && (
                <button className="close_btn" onClick={linkClickHandler}>
                  <img
                    src="/img/ui/close.svg"
                    width="12"
                    height="12"
                    alt={t.header.close}
                  />
                </button>
              )}
              <ul className="header__mobile-list">
                <li className="header__mobile-item">
                  <button
                    className="header__mobile-accordion"
                    type="button"
                    onClick={toggleAccordionHandle}
                  >
                    {t.footer.company}
                    <img
                      src={"/img/ui/arrow.svg"}
                      width="12"
                      height="12"
                      loading="lazy"
                      alt="open menu"
                      className={"arrow"}
                    />
                  </button>

                  <ul className="header__mobile-sublist">
                    <li
                      className="header__mobile-subitem"
                      onClick={linkClickHandler}
                    >
                      <Link to={`/${lang}/`}>{t?.navigation?.freeProxy}</Link>
                    </li>
                    {mobileCompanyNavigation.map(({ href, text }) => (
                      <HeaderCompanyItem
                        key={text}
                        href={href}
                        text={text}
                        onClick={linkClickHandler}
                      />
                    ))}
                  </ul>
                </li>

                <li className="header__mobile-item">
                  <button
                    className="header__mobile-accordion"
                    type="button"
                    onClick={toggleAccordionHandle}
                  >
                    {t.footer.tools}
                    <img
                      src={"/img/ui/arrow.svg"}
                      width="12"
                      height="12"
                      loading="lazy"
                      alt="open menu"
                      className={"arrow"}
                    />
                  </button>

                  <ul className="header__mobile-sublist">
                    {mobileInstrumentsNavigation.map((item) => (
                      <HeaderInstrumentsItem
                        key={item.route}
                        item={item}
                        onClick={linkClickHandler}
                      />
                    ))}
                  </ul>
                </li>
              </ul>
              <div className="languageWraper">
                <LangDropdown />
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};
