import { Link, useParams } from "react-router-dom";

import { useProgress, useTranslation } from "../../../hooks";
import { useClientSelector } from "../../../store/selectors/useClientSelector";
import { getColor } from "../../../utils/helpers";

import {
  Data,
  Loader,
  LoaderWraper,
  PreloadText,
  Progres,
  ProgressWraper,
  StyledLink,
  Wraper
} from "./ProgressBar.styled";

const ProgressBar = ({ score }) => {
  const t = useTranslation();
  const { lang } = useParams();
  const { anonymityInfo } = useClientSelector();
  const isLoaded = anonymityInfo?.isDataLoaded;

  const { currentProgress } = useProgress(score, isLoaded);

  return (
    <Wraper>
      <ProgressWraper setColor={getColor(currentProgress)}>
        <Progres
          progress={currentProgress}
          setColor={getColor(currentProgress)}
        >
          <StyledLink to={`/${lang ? lang : "ru"}/anonymity-check/`}>
            <LoaderWraper>
              <PreloadText>{t.pageHead.anon}:</PreloadText>
              {currentProgress ? <Data>{currentProgress}%</Data> : <Loader />}
            </LoaderWraper>
          </StyledLink>
        </Progres>
      </ProgressWraper>
      <Link
        to={`/${lang ? lang : "ru"}/anonymity-check/`}
        className="page-head__data-link"
      >
        {t.pageHead.moreAnon}
      </Link>
    </Wraper>
  );
};
export default ProgressBar;
