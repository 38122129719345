export const Ru = {
  header: {
    ariaLabelMain: "Перейти на главную страницу",
    altImgLogo: "Логотип компании FreeProxySale",
    other: "Другое",
    ariaLabelOther: "Показатель раскрытия дополнительных пунктов меню",
    close: "Закрыть"
  },
  footer: {
    logo: {
      alt: "Логотип компании Proxy-Sale"
    },
    rights: "Все права защищены",
    lang: {
      title: "Выберите язык",
      selectLanguage: [
        { value: "de", label: "Немецкий" },
        { value: "en", label: "Английский" },
        { value: "es", label: "Испанский" },
        { value: "fr", label: "Французский" },
        { value: "it", label: "Итальянский" },
        { value: "pl", label: "Польский" },
        { value: "ru", label: "Русский" },
        { value: "tr", label: "Турецкий" }
      ]
    },
    tools: "Инструменты",
    company: "Компания"
  },
  navigation: {
    freeProxy: "Бесплатные прокси",
    personalProxy: "Персональные прокси",
    proxyIpv6: "Прокси IPv6",
    articles: "Статьи",
    contacts: "Контакты",
    proxyChecker: "Прокси-чекер",
    myIp: "Мой IP",
    checkPorts: "Сканер портов",
    pingIp: "Пинг IP",
    anonimityCheck: "Моя анонимность",
    utm: "Генератор UTM-меток",
    speed: "Скорость IP",
    ipv6Check: "Поддержка IPv6",
    spamBase: "IP в спам базах",
    filterProxy: "Прокси фильтр",
    traceIp: "Трассировка IP",
    claster: "Кластеризация запросов",
    webRtcChecker: "Проверка WebRTC",
    dnsChecker: "Проверка DNS",
    fingerPrint: "Сканер фингерпринта",
    evercookie: "Проверка Evercookie",
    httpHeaders: "Просмотр HTTP заголовков",
    browserInfo: "Информация о браузере",
    resedentialProxy: "Резидентские прокси",
    ispProxy: "ISP прокси"
  },
  mainPage: {
    proxyTable: {
      sidebarPart: {
        title: "Настройка прокси в браузере:",
        countries: {
          placeholder: "Выберите страны"
        },
        type: {
          title: "Тип:"
        },
        anonimity: {
          title: "Анонимных:"
        },
        export: {
          title: "Экспорт:",
          ip: "IP:Port",
          excel: "Excel",
          window: "В новом окне"
        },
        applyFilter: "Применить фильтр",
        clearFilter: "Очистить фильтр",
        infoLeft: "Free прокси листы",
        infoRight:
          "исключают любую возможность выполнения любых задач требуемых анонимность и стабильность.",
        buyProxy: "Купить персональные прокси",
        personalProxyAdvantage: [
          "Персональные прокси предоставят высокую анонимность, скорость и стабильность."
        ]
      }
    },
    faq: "О том, что такое прокси-сервер и для чего он нужен слышал практически каждый интернет-пользователь. Они стали особенно популярны, когда возникла массовая потребность в обходе блокировок популярных веб-ресурсов — социальных сетей, сервисов Яндекса, видеохостингов и тому подобное. Разумеется, большинство интересуют бесплатные прокси. В сети их предостаточно, однако не всегда очевидно, как их правильно выбрать и для чего они подойдут, а для каких задач лучше купить прокси."
  },
  anonimityCheck: {
    mainTitle: "Моя анонимность",
    headText:
      "С помощью данного сервиса вы можете проверить на сколько вы анонимны в сети, на сколько данные, предоставляемые вашим компьютером/браузером, совпадают с данными, предоставляемым вашим ip-адресом.",
    top: {
      ip: "Ваш IP :",
      from: "Откуда Вы:",
      camouflage: "Ваша маскировка:",
      anon: "Ваша анонимность:",
      restartButton: "Перезапустить проверку",
      waitButton: "Загрузка",

      result: {
        title: "Ваш результат:",
        notAnonymity: "Отсутствие анонимности",
        badAnonymity: "Плохая анонимность",
        lackAnonymity: "Недостаточная анонимность",
        sufficientAnonymity: "Достаточная анонимность",
        checking: "Проверка, подождите",
        highAnonymity: "Высокая анонимность"
      }
    },
    table: {
      titleIpInfo: "IP информация",
      device: "Устройство",
      anonymityTitle: "Анонимность и безопасность",
      standardTimeZone: "Часовой пояс",
      hour: "часа",
      ip: "Мой IP",
      hideip: "Скрыть IP",
      host: "Хост/Провайдер",
      country: "Страна",
      city: "Город",
      index: "Индекс",
      coordinates: "Координаты",
      os: "ОС",
      browser: "Браузер",
      timezone: "Временная зона",
      ipTime: "Время IP",
      systemTime: "Системное время",
      userAgent: "UserAgent",
      userAgentJs: "UserAgent JS",
      language: "Язык",
      languageJs: "Язык JS",
      screen: "Экран",
      proxy: "Прокси",
      vpn: "VPN",
      tor: "Tor",
      anonimazer: {
        title: "Анонимайзер",
        info: "Необходимо закрыть порты. Открытые порты могут значительно навредить или уже навредили вашему устройству."
      },
      cookies: {
        info: "Отключение может предотвратить отслеживание активности рекламными сетями и различными ресурсами, что способствует большей конфиденциальности. Рекомендуется отключить cookies или периодически очищать"
      },
      blackList: {
        title: "Черный список",
        checks: "Проверок:",
        inBlackList: "В черном списке:",
        buyProxy: "Купить чистый прокси"
      },
      flash: "Flash",
      java: "Java",
      activeX: {
        title: "ActiveX",
        info: "Элемент управления ActiveX имеет уязвимости в системе безопасности и может быть использован для заражения вашего компьютера. Чем больше элементов управления ActiveX вы устанавливаете, тем больше сайтов могут воспользоваться своими проблемами, чтобы повредить устройство"
      },
      webRtc: "WebRTC",
      webRtcIp: "WebRTC IPs",
      openPorts: {
        title: "Открытые порты",
        links: "Сканер портов"
      },

      openPortsHTTP: {
        title: "Открытые порты HTTP proxy",
        info: "Необходимо закрыть порты. Открытые порты могут значительно навредить или уже навредили вашему устройству"
      },
      openPortsVPN: {
        title: "Открытые порты VPN",
        info: "Необходимо закрыть порты. Открытые порты могут значительно навредить или уже навредили вашему устройству"
      },
      openPortsWeb: "Открытые порты Web proxy",
      doNotTrack: {
        title: "DoNotTrack",
        info: "Включение этой опции добавляет некоторую уникальность к цифровому отпечатку браузера",
        link: "Включить DNT"
      },
      dnsLeak: {
        title: "Утечка DNS",
        info: "Третьи лица могут отслеживать все ваши действия в сети. Необходимо изменить DNS устройства",
        links: "Изменить DNS"
      },
      suspiciousHeadlines: "Подозрительные заголовки",
      suspiciousPorts: "Подозрительные порты",
      twowayPing: "Двухсторонний пинг",
      replacementUserAgent: {
        title: "Подмена userAgent",
        info: "Исходим из данных формирующих юзерагент от устройства, при различии данных - значит идет подмена"
      },
      yourHeadings: "Ваши заголовки",
      showButton: {
        more: "Смотреть больше",
        less: "Спрятать"
      }
    },
    faq: {
      text: []
    }
  },
  checkPorts: {
    analis: "Идет анализ... Подождите.",
    port: "Порт",
    status: "Статус",
    statusTrue: "доступен",
    statusFalse: "недоступен",
    description: "Описание",
    portStatus: "Статус портов на сервере",
    scanType: "Тип сканирования",
    mainTitle: "Проверка открытых портов онлайн",
    videoTitle: "Что такое сканер портов и зачем?",
    ipTitle: "Отображать:",
    headText:
      "Проверка открытых портов с помощью сканера показывает какие порты открыты на вашем или чужом компьютере/сайте и за что они отвечают. Проверка производится через сканер nmap и показывает, насколько открыт ваш доступ к пк/сайту для внешнего мира.",
    ipElem: {
      placeholder: "Введите IP-адрес или домен",
      insertIp: "Вставить мой IP-адрес"
    },
    scanTypes: {
      title: "Тип сканируемых портов:"
    },
    scanTitle: "Тип сканирования:",
    startScan: "Начать сканирование",
    resultCheck: "Результаты проверки:",
    resultCaption:
      "* Если результат - «Хост не работает», значит, выполняется проверка брандмауэра или маршрутизатора и блокирующие IP-адреса.",
    faq: {
      text: []
    }
  },
  claster: {
    mainTitle: "Кластеризация запросов онлайн",
    checker: {
      regionTitle: "Регион проверки",
      selectPlaceholder: "Выберите регион",
      clasterTitle: "Порог кластеризации",
      groupBtn: "Сгруппировать",
      queryTitle: "Запросы"
    },
    headText:
      "Благодаря этому сервису вам доступна кластеризация запросов онлайн. С ним вы сможете автоматически сгруппировать ключевые слова на основе результатов поисковой выдачи Яндекса и Google. Грамотное построение структуры сайта повысит ваши шансы на вывод сайта в топовые позиции. Наш онлайн-сервис для кластеризации запросов поможет вам распределить ключевые слова по страницам таким образом, чтобы получить наибольшее количество SEO-трафика."
  },
  filterProxy: {
    mainTitle: "Фильтр прокси",
    headText:
      "Фильтрация прокси представляет собой процесс отправления прокси-фильтра к главному серверу для отбора определенных рекомендаций.",
    filterOut: "Filter out",
    save: "Save to file"
  },
  ipv6: {
    mainTitle: "Проверка IPv6",
    headText:
      "Версия протокола IPv6, который обеспечивает максимальную защиту в сети, для более эффективной работы требует использования инструмента поддержки конкретным сайтом нового протокола прокси.",
    checker: {
      addressTitle: "Введите адрес сайта для проверки",
      checkProxyBtn: "Проверить прокси",
      tip: "Тест может занять некоторое время",
      resultTitle: "Результаты проверки:",
      result: {
        success: "Поздравляем, этот сайт поддерживает IPv6",
        error: "К сожалению, сайт не поддерживает IPv6 в данный момент"
      }
    },
    faq: {
      text: []
    }
  },
  myIp: {
    top: {
      title:
        "И это лишь малая часть информации, которую можно отследить по вашему IP-адресу:",
      ipv4: "Ваш IPv4 адрес:",
      ipv6: "Ваш IPv6 адрес:",
      changeIp: "Сменить свой IP-адрес",
      table: {
        country: "Страна",
        city: "Город",
        index: "Почтовый индекс",
        host: "Хост",
        system: "Система",
        browser: "Ваш браузер",
        from: "Откуда Вы",
        provider: "Провайдер",
        anon: "Анонимайзер",
        proxy: "Прокси",
        protocol: "Протокол",
        check: "Проверить",
        imgAlt: {
          country: "Страна",
          city: "Город",
          index: "Почтовый индекс",
          screen: "Монитор",
          os: "Операционная система",
          browser: "Версия браузера",
          location: "Расположение",
          server: "Сервер",
          anon: "Анонимайзер",
          proxy: "Прокси",
          protocol: "Протокол",
          blacklist: "Черный список"
        }
      },
      map: {
        title: "Ваше расположение на карте!",
        hide: "Вы можете скрыть свое местоположение",
        text: "Достаточно воспользоваться нашим простым, удобным и надежным инструментом. Скройте личные данные, защитите трафик и снимите все лимиты со своего интернета",
        hideIp: "Скрыть IP-адрес"
      },
      caution: {
        top: "Как видите, по IP предоставляются довольно подробная информация.",
        bottom:
          "При подключении к интернету, компьютеру присваивают номер, именуемый IP (Internet Protocol Address). Следует заметить, что присваивают не произвольно, а тот айпи, который доступен провайдеру и свободен. IP изменяется после отключения при повторном подключении. Тaкие IP называются динамическими. Если хотите, чтобы был неизменный, обратитесь к своему провайдеру. Обычно, не динамический предоставляется за отдельную плату. Его еще называют внешним. Часто отслеживается специализированными онлайн программами, определяя сопутствующую инфу о владельце. Внутренним IP или локальным называют индивидуальный адрес ПК (ноутбука) в локальной сети."
      }
    },
    faq: {
      text: []
    }
  },
  pingIp: {
    mainTitle: "Проверить пинг IP адреса или сайта",
    whatIsPing: "Что такое пинг IP и зачем?",
    headText: [
      "Нажмите на кнопку «Вставить мой IP-адрес» или используйте тот IP или сайт к которому необходимо проверить пинг. Выберите 3–5 тестовых пакетов и нажмите кнопку «Пинг IP». Вы увидите нужное значение в разделе «time=».",
      "Пинг считается нормальным, когда находится в пределах 40-110 мс. От 110-210 мс вы начнете испытывать проблемы в онлайн-играх, но на посещении сайтов это практически не отразится. Если таймаут превышает 110 мс, то это повод задуматься о качестве работы вашего провайдера."
    ],
    checker: {
      showTitle: "Отображать:",
      showSubtitle:
        "Введите действительный IP-адрес (0.0.0.0) или имя хоста (host.com)",
      ipInsert: "Вставить мой IP-адрес",
      addTitle: "Количество пакетов:",
      selectPlaceholder: "Выберите страну",
      startScan: "Начать сканирование",
      resultTitle: "Результаты проверки:",
      resultCaption:
        "* Если результат - «Хост не работает», значит, выполняется проверка брандмауэра или маршрутизатора и блокирующие IP-адреса."
    },
    faq: {
      text: []
    }
  },
  proxyChecker: {
    mainTitle: "Прокси чекер - проверка прокси",
    condition: "Статус",
    type: "Тип",
    ping: "Пинг",
    status: "Не рабочий",
    workStatus: "Доступный",
    head: {
      videoTitle: "Как и зачем использовать прокси-чекер?",
      headText:
        "Бесплатный прокси чекер онлайн для проверки валидности прокси-серверов. С помощью прокси-чекера вам доступен целый ряд опций для проверки прокси: доступность, страна, скорость, тип анонимности, тип протокола. Прокси-чекер доступен в бесплатном варианте с отсутствием ограничения на количество проверок IP адресов на доступность.",
      titleFirst: "Прокси чекер умеет:",
      listFirst: [
        "проверка IPv4, IPv6 на принадлежность страны;",
        "определние типа протокола HTTP/S, SOCKS5 и текущей скорости;",
        "проверка анонимности адреса;",
        "групповая, скоростная многопоточная проверка прокси в 64 потока;",
        "возможность закачать списком и выгрузить результат проверки;",
        "удобный вывод результатов с помощью фильтра отображения."
      ],
      titleSecond: "В каком формате добавлять прокси в чекер?",
      listSecond: [
        {
          content:
            "Если у вас <span>публичные прокси</span> (без логина и пароля), то — IP:PORT"
        },
        {
          content:
            "Если у вас <span>приватные прокси</span> (с авторизацией по логину и паролю), то — IP:PORT:USER:PASS"
        }
      ],
      titleThird:
        "P.S.: Если вы <a href='https://proxy-sale.com/' rel='noreferrer' target='_blank'> купили прокси</a> у нас, то они приватные!"
    },
    faq: {
      text: []
    }
  },
  siteSpeed: {
    mainTitle: "Проверить скорость доступа сайта через прокси",
    head: {
      listFirst: [
        "Определение скорости передачи данных через HTTP и SOCKS прокси",
        "Возможность определения скороcти загрузки страниц напрямую",
        "Проверьте доступ к сайту"
      ],
      caution: "В каком формате делать проверку?",
      subtitle:
        "Для проверки скорости через прокси необходимо вбить ресурс к которому необходим доступ и IP адрес в формате:",
      listSecond: [
        {
          text: "Публичные прокси (без логина и пароля), то —",
          value: "IP:PORT"
        },

        {
          text: "Приватные прокси (с авторизацией по логину и паролю), то — ",
          value: "IP:PORT:USER:PASS "
        }
      ],
      post: "P.S.: Если вы купили прокси у нас, то они приватные!"
    },
    checker: {
      adress: "Введите адрес сайта для проверки скорости",
      proxy: "Адрес прокси в формате",
      format: "127.0.0.1:8080",
      subtitle: [
        "Для теста скорости передачи данных через прокси введите адрес и порт прокси сервера",
        "Для обычного теста скорости загрузки страниц сайта оставьте это поле пустым"
      ],
      type: {
        title: "Тип прокси сервера"
      },
      timeout: {
        title: "Таймаут:",
        placeholder: "Таймаут"
      },
      access: {
        title: "Проверить доступ к сайту",
        body: "Проверка доступа и скорости может занять некоторое время"
      },
      btn: "Проверить скорость",
      result: {
        title: "Результаты проверки:",
        result: {
          titleFirst: "Проверить - скорость доступа к сайту через прокси",
          checkSite: "Проверка сайта",
          checkProxy: "Проверка прокси",
          timeout: "Таймаут",
          sec: "сек",
          amountOfDownloads: "Тестовых загрузок произведено",
          titleSecond: "Проверка завершена",
          averageSpeed: "Средняя скорость загрузки",
          generalSpeed: "Общая средняя скорость",
          speedValue: "Кбайт/сек",
          access: "Доступ к сайту - заголовок (Title) с загруженной страницы",
          emptyTitle: "Не удалось получить Title",
          isProxyUsing: {
            yes: "С использованием прокси",
            no: "Без прокси, загрузка напрямую"
          }
        }
      }
    },
    faq: {
      text: []
    },
    errors: {
      url: "Введите адрес сайта"
    }
  },
  spamBase: {
    mainTitle: "Наличие и проверка IP в спам базах",
    head: {
      text: "Как известно, спам часто может стать причиной попадания IP в «черный список», что заметно нарушает всю работу в сети. Во избежaние этого следует пользоваться сервером проверки Айпи в спам-базах."
    },
    checker: {
      ip: {
        title: "Введите правильный IP-адрес",
        insert: "Вставить мой IP-адрес"
      },
      check: "Проверка",
      test: "Тест может занять некоторое время",
      list: {
        checkedAll: "Проверок:",
        checked: "Проверено:",
        blackList: "В черном списке:"
      },
      result: {
        listed: "Числится",
        notListed: "Не числится"
      }
    },
    faq: {
      text: []
    }
  },
  trace: {
    mainTitle: "Трассировка IP",
    head: {
      text: "Благодаря сервису, который размещен на этой странице, вам доступна бесплатная и практически моментальная трассировка IP адреса или сайта. Она пoможет вам выполнить сетевую диагностику, определяющую скорость послания запросов через серверы в интернете при открытии веб-ресурса в браузере. С помощью трассировки вы сумеете определить причины возможных проблем, связанных с медленной загрузкой сайта."
    },
    checker: {
      ip: "Введите корректный IP адрес (0.0.0.0) или имя хоста (домен.ру)",
      route: {
        title: "Показать маршрут пакета по странам",
        placeholder: "Выберите пункт"
      },
      btn: "Трассировать IP",
      result: "Результаты проверки:"
    }
  },
  utm: {
    mainTitle: "Онлайн генератор UTM меток",
    checker: {
      linkUtm: "Ссылка с UTM-меткой появиться здесь",
      copy: "Скопировать",
      shortUrl: "Короткий URL",
      traffic: [
        { text: "Google ADS", source: "google" },
        { text: "Яндекс.Директ", source: "yandex" },
        { text: "VK.com", source: "vk" },
        { text: "Facebook", source: "facebook" },
        { text: "Instagram", source: "instagram" },
        { text: "Target.Mail.ru", source: "targetmailru" },
        { reset: true, text: "Сброс" }
      ]
    },
    faq: {
      text: []
    }
  },
  webRtcCheck: {
    mainTitle: "Проверка WebRTC",
    head: {
      text: "Проверка на утечку через WebRTC. Ваши данные могут быть под угрозой, даже если вы подключены к VPN."
    },
    top: {
      myIp: "Ваш IP адрес:",
      from: "Откуда Вы:",
      state: "Статус:",
      warning: "Потенциальная угроза"
    },
    info: {
      top: "Как видите, по IP предоставляются довольно подробная информация.",
      bottom:
        "Мы проверили, активирован ли в вашем браузере WebRTC и нет ли у вас реального IP-адреса. Как выяснилось, присутствует риск потенциальной утечки, WebRTC включен и может раскрыть ваш реальный IP-адрес.",
      disableBottom:
        "Мы проверили, активирован ли в вашем браузере WebRTC и нет ли у вас реального IP-адреса. Как выяснилось, Ваш реальный IP-адрес остается неизвестным, и WebRTC неактивирован в вашем браузере."
    },
    resetButton: "Перезапустить тест на утечку WebRTC",
    status: {
      type: "Тип",
      state: "Статус",
      topText: "WebRTC включен",
      bottomText: "Угроза раскрыть ваш реальный IP-адрес!",
      warning: "Угроза утечки",
      checking: "Проверка, подождите"
    }
  },
  dnsCheck: {
    top: {
      myIp: "Ваш IP адрес:",
      from: "Откуда Вы:",
      state: "Статус:",
      warning: "Потенциальная угроза"
    },
    info: {
      top: "Как видите, при проверке вашего IP обнаружена утечка DNS",
      bottom:
        "Мы просканировали ваш IP и обнаружили утечку через DNS, ваши запросы раскрыты и соединение не является безопасным. Это означает, что администратор ваших DNS может отслеживать информацию о используемых вами сайтов и программ.",
      disableBottom:
        "Мы просканировали ваш IP и не обнаружили утечку через DNS, ваши запросы не раскрыты и соединение является безопасным. Это означает, что администратор ваших DNS не может отслеживать информацию о используемых вами сайтов и программ."
    },
    resetButton: "Перезапустить тест на утечку DNS",
    status: {
      type: "Тип",
      state: "Статус",
      topText: "DNS включен",
      bottomText: "Угроза раскрыть ваши запросы!",
      warning: "Угроза утечки",
      checking: "Проверка, подождите"
    }
  },
  fingerPrintScanner: {
    mainTitle: "Сканер Fingerprint браузера",
    head: {
      text: "Отпечатки браузера представляют собой уникальный слепок с настроек вашего компьютера, а специальные механизмы сайтов могут проводить слежение за вами."
    },
    top: {
      myIp: "Ваш IP адрес:",
      fingerprint: "Отпечаток браузера (Browser Fingerprint):"
    },
    table: {
      location: "Локация",
      provider: "Провайдер",
      host: "Хост",
      os: "ОС",
      browser: "Браузер",
      plugin: "Плагин:",
      status: "Статус:",
      isOn: "включено",
      isOff: "выключено",
      device: "Устройство",
      browserLanguage: "Язык браузера",
      screenSize: "Размер экрана",
      timeZone: "Часовой пояс"
    },
    imgAlt: {
      location: "Локация",
      provider: "Провайдер",
      host: "Хост",
      os: "Операционная система",
      browser: "Браузер",
      language: "Язык браузера",
      size: "Размер экрана",
      timeZone: "Часовой пояс"
    },
    bottom: {
      method: "метод JS"
    }
  },
  createEvercookie: {
    mainTitle: "Проверка Evercookie",
    head: {
      text: "Нажмите кнопку, чтобы создать evercookie. Ваша анонимность не будет нарушена: cookie - это случайное число от 1 до 1000, которое мы используем для тестирования evercookies. Мы не отслеживаем вас с его помощью."
    },
    top: {
      button: "Создать Evercookie"
    }
  },
  httpHeaders: {
    requestHeaders: "Заголовки запросов",
    responseHeaders: "Заголовки ответов",
    submitButton: "Отправить запрос"
  },
  browserInfo: {
    mainTitle: "Информация от вашего браузера",
    head: {
      text:
        "С помощью браузера различные сайты и сервисы имеют возможность отслеживать пользователя. Это нужно, например, для того чтобы определить человека даже если он изменил свой IP адрес. Также возможность отслеживания используют рекламные системы, для того чтобы знать интересы пользователя.\n" +
        "Наш сервис поможет определить, какие методы слежения включены в вашем браузере."
    },
    table: {
      type: "Тип",
      discovered: "Обнаружена",
      cookieMeaning: "Значение куки",
      parameter: "Параметр",
      value: "Значение",
      resolution: "Разрешение:",
      desktop: "Рабочий стол",
      colorDepth: "Глубина цвета",
      bit: "бит"
    },
    button: {
      createCookie: "Создать куки",
      userTracking: "Отслеживание пользователей"
    }
  },
  pageHead: {
    ip: "Ваш IP",
    changeIp: "Изменить IP",
    anon: "Ваша анонимность",
    moreAnon: "Более подробно о анонимности"
  },
  checker: {
    country: "Выберите страну:",
    add: "Добавить первые:",
    show: {
      title: "Отображать"
    },
    displayType: "Отображать",
    checkProxy: "Проверить прокси",
    fileName: "Загрузить из .txt или .csv",
    typeProxy: {
      title: "Введите прокси:",
      placeholder: "Наберайте текст здесь"
    },
    info: {
      text: "Исключите любую возможность потерять доступ к необходимому ресурсу или быть заблокированным. Персональные прокси для любых ваших задач.",
      buy: "Купить прокси"
    },
    results: {
      title: "Результаты проверки",
      checked: "Проверено",
      working: "Рабочий",
      notWorking: "Не рабочий",
      divider: "из",
      btn: "Купить рабочий"
    },
    download: {
      title: "Скачать только рабочие прокси",
      txt: "скачать в .txt",
      csv: "таблицей в .csv"
    }
  },
  common: {
    faqTitle: "Отвечаем на ваши вопросы",
    yes: "Да",
    no: "Нет",
    notDefined: "Не определено",
    copy: "Скопировано!",
    select: {
      placeholderCountry: "Выберите страну"
    },
    buyAnonProxy: "Купить анонимное",
    buyWorkingProxy: "Купить рабочий"
  },
  table: {
    top: {
      workProxy: "Рабочих прокси",
      anon: "Анонимных",
      socks: "Socks 4/5",
      http: "HTTP/HTTPS",
      proxyInBase: "Прокси в базе"
    },
    tableColumns: {
      copy: "Скопировать в буфер",
      country: "Страна",
      type: "Тип",
      anon: {
        title: "Анонимность",
        speed: "Скорость",
        s: "с",
        ms: "мс",
        m: "м",
        h: "ч",
        types: {
          anon: "Анонимный",
          proz: "Прозрачный",
          elite: "Элитный",
          notFounded: "Не найдено"
        }
      },
      check: {
        title: "Проверка",
        tip: "Подсказка",
        buy: "Купить рабочий",
        reload: "Перезагрузить",
        ago: "мин. назад",
        status: {
          work: "Рабочий",
          notWorked: "Не рабочий"
        }
      },
      condition: {
        title: "Состояние"
      },
      login: "Логин",
      password: "Пароль",
      pagination: {
        rowsPerPage: "Прокси на страницу",
        separatorText: "из"
      },
      requests: "Запросы",
      requestsCluster: "Запросов в кластере",
      competitors: "Конкуренты"
    },
    noDataComponent: "Нет прокси"
  },
  errorTranslation: {
    somethingWrong: "Что-то пошло не так, попробуйте еще раз",
    largeProxyList: "Количество прокси для проверки должно быть не более 100",
    emptyProxy: "Отсутствуют прокси для экспорта",
    apiError: "Что-то пошло не так!",
    wrongFile: "Некорректный тип файла. Разрешается только .txt или .csv",
    nonReadableFile: "Невозможно прочитать файл!",
    emptyProxyList: "Введите прокси!",
    emptyClusterQuery: "Введите запросы!",
    clusterQueryMinValue: "Введите минимум 2 запроса!",
    incorrectIpOrDomain: "Некорректный IP или Домен!",
    incorrectIp: "Некорректный IP! ",
    proxyFilterEmpty: "Введите прокси!",
    proxyIsEmpty: "В этой стране отсутствуют прокси",
    proxyFilterIncorrect: "Некорректные прокси!",
    emptyIpOrDomain: "Введите корректный Ip адрес или домен!",
    emptySiteUrl: "Введите URL-адрес сайта!",
    emptyUserAgent: "Выберите user agent!",
    invalidUrl: "Недопустимий формат URL!",
    timeError: "Попробуйте через пару минут!",
    IncorrectUrlFormat: "Неправильный формат URL!"
  },
  anonimityTypes: [
    {
      value: "an-anonim.txt",
      label: "Анонимный",
      defaultChecked: false,
      route: "anonimnyj"
    },
    {
      value: "el-elit.txt",
      label: "Элитный",
      defaultChecked: false,
      route: "elite"
    },
    {
      value: "pr-proz.txt",
      label: "Прозрачный",
      defaultChecked: false,
      route: "transparent"
    }
  ],
  browsers: [
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/chrome.svg",
      value: "Гугл Хром"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/safari.svg",
      value: "Сафари"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/opera.svg",
      value: "Опера"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/firefox.svg",
      value: "Файер Фокс"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/yandex.svg",
      value: "Яндекс"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/android.svg",
      value: "Андроид"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/explorer.svg",
      value: "Интернет Експлорер"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/browser1.svg",
      value: "Браузер"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      value: "+",
      last: true
    }
  ],
  checkerProxyTypes: [
    {
      value: "HTTP,HTTPS",
      label: "HTTP/HTTPS",
      defaultChecked: true
    },
    {
      value: "SOCKS4,SOCKS5",
      label: "SOCKS 4/5",
      defaultChecked: true
    },
    {
      value: "NO",
      label: "Нерабочие",
      defaultChecked: true
    }
  ],
  checkPortsTypes: [
    {
      value: "POPULAR",
      label: "Популярные порты",
      defaultChecked: true
    },
    {
      value: "SERVER",
      label: "Серверные порты",
      defaultChecked: false
    },
    {
      value: "GAME",
      label: "Игровые порты",
      defaultChecked: false
    },
    {
      value: "APPLICATION",
      label: "Порты приложения",
      defaultChecked: false
    },
    {
      value: "P2P",
      label: "P2P порты",
      defaultChecked: false
    }
  ],
  utmInputs: [
    {
      label: "URL целевой страницы",
      name: "url",
      placeholder: "Вставьте сюда ссылку или целевую страницу"
    },
    {
      label: "UTM-source*",
      name: "source",
      placeholder: "Источник трафика. Например: google"
    },
    {
      label: "UTM-medium*",
      name: "medium",
      placeholder: "Тип трафика. Например: cpc, email, banner"
    },
    {
      label: "UTM-campaign*",
      name: "campaign",
      placeholder: "Рекламная компания, Например: google-poisk"
    },
    {
      label: "UTM-content",
      name: "content",
      placeholder: "Например: cpc, email, banner"
    },
    {
      label: "UTM-term",
      name: "term",
      placeholder: "Ключевое слово. Например: макрос (keyword)"
    }
  ],
  yesNo: [
    { label: "Да", value: true },
    { label: "Нет", value: false }
  ],
  timeout: [
    { label: "10 сек", value: 10 },
    { label: "15 сек", value: 15 },
    { label: "30 сек", value: 30 },
    { label: "60 сек", value: 60 }
  ],
  countries: [
    {
      dataBaseValue: "Andorra",
      value: "Андорра",
      label: "Андорра",
      imgSrc: "/img/flags/ad.svg"
    },
    {
      dataBaseValue: "UAE",
      value: "ОАЕ",
      label: "ОАЕ",
      imgSrc: "/img/flags/ae.svg"
    },
    {
      dataBaseValue: "Afghanistan",
      value: "Афганистан",
      label: "Афганистан",
      imgSrc: "/img/flags/af.svg"
    },
    {
      dataBaseValue: "Antigua and Barbuda",
      value: "Антигуа и Барбуда",
      label: "Антигуа и Барбуда",
      imgSrc: "/img/flags/ag.svg"
    },
    {
      dataBaseValue: "Anguilla",
      value: "Ангилья",
      label: "Ангилья",
      imgSrc: "/img/flags/ai.svg"
    },
    {
      dataBaseValue: "Albania",
      value: "Албания",
      label: "Албания",
      imgSrc: "/img/flags/al.svg"
    },
    {
      dataBaseValue: "Armenia",
      value: "Армения",
      label: "Армения",
      imgSrc: "/img/flags/am.svg"
    },
    {
      dataBaseValue: "Antilles",
      value: "Антильские острова",
      label: "Антильские острова",
      imgSrc: "/img/flags/an.svg"
    },
    {
      dataBaseValue: "Angola",
      value: "Ангола",
      label: "Ангола",
      imgSrc: "/img/flags/ao.svg"
    },
    {
      dataBaseValue: "Antarctic",
      value: "Антарктика",
      label: "Антарктика",
      imgSrc: "/img/flags/aq.svg"
    },
    {
      dataBaseValue: "Argentina",
      value: "Аргентина",
      label: "Аргентина",
      imgSrc: "/img/flags/ar.svg"
    },
    {
      dataBaseValue: "American Samoa",
      value: "Американское Самоа",
      label: "Американское Самоа",
      imgSrc: "/img/flags/as.svg"
    },
    {
      dataBaseValue: "Austria",
      value: "Австрия",
      label: "Австрия",
      imgSrc: "/img/flags/at.svg"
    },
    {
      dataBaseValue: "Australia",
      value: "Австралия",
      label: "Австралия",
      imgSrc: "/img/flags/au.svg"
    },
    {
      dataBaseValue: "Aruba",
      value: "Аруба",
      label: "Аруба",
      imgSrc: "/img/flags/aw.svg"
    },
    {
      dataBaseValue: "Åland Islands",
      value: "Аландские острова",
      label: "Аландские острова",
      imgSrc: "/img/flags/ax.svg"
    },
    {
      dataBaseValue: "Azerbaijan",
      value: "Азербайджан",
      label: "Азербайджан",
      imgSrc: "/img/flags/az.svg"
    },
    {
      dataBaseValue: "Bosnia and Herzegovina",
      value: "Босния и Герцеговина",
      label: "Босния и Герцеговина",
      imgSrc: "/img/flags/ba.svg"
    },
    {
      dataBaseValue: "Barbados",
      value: "Барбадос",
      label: "Барбадос",
      imgSrc: "/img/flags/bb.svg"
    },
    {
      dataBaseValue: "Bangladesh",
      value: "Бангладеш",
      label: "Бангладеш",
      imgSrc: "/img/flags/bd.svg"
    },
    {
      dataBaseValue: "Belgium",
      value: "Бельгия",
      label: "Бельгия",
      imgSrc: "/img/flags/be.svg"
    },
    {
      dataBaseValue: "Burkina Faso",
      value: "Буркина-Фасо",
      label: "Буркина-Фасо",
      imgSrc: "/img/flags/bf.svg"
    },
    {
      dataBaseValue: "Bulgaria",
      value: "Болгария",
      label: "Болгария",
      imgSrc: "/img/flags/bg.svg"
    },
    {
      dataBaseValue: "Bahrain",
      value: "Бахрейн",
      label: "Бахрейн",
      imgSrc: "/img/flags/bh.svg"
    },
    {
      dataBaseValue: "Burundi",
      value: "Бурунди",
      label: "Бурунди",
      imgSrc: "/img/flags/bi.svg"
    },
    {
      dataBaseValue: "Benin",
      value: "Бенин",
      label: "Бенин",
      imgSrc: "/img/flags/bj.svg"
    },
    {
      dataBaseValue: "Saint Barthélemy",
      value: "Сен-Бартелеми",
      label: "Сен-Бартелеми",
      imgSrc: "/img/flags/bl.svg"
    },
    {
      dataBaseValue: "Bermuda",
      value: "Бермуды",
      label: "Бермуды",
      imgSrc: "/img/flags/bm.svg"
    },
    {
      dataBaseValue: "Brunei",
      value: "Бруней",
      label: "Бруней",
      imgSrc: "/img/flags/bn.svg"
    },
    {
      dataBaseValue: "Bolivia",
      value: "Боливия",
      label: "Боливия",
      imgSrc: "/img/flags/bo.svg"
    },
    {
      dataBaseValue: "Bonaire",
      value: "Бонэйр",
      label: "Бонэйр",
      imgSrc: "/img/flags/bq.svg"
    },
    {
      dataBaseValue: "Brazil",
      value: "Бразилия",
      label: "Бразилия",
      imgSrc: "/img/flags/br.svg"
    },
    {
      dataBaseValue: "Bahamas",
      value: "Багамы",
      label: "Багамы",
      imgSrc: "/img/flags/bs.svg"
    },
    {
      dataBaseValue: "Butane",
      value: "Бутан",
      label: "Бутан",
      imgSrc: "/img/flags/bt.svg"
    },
    {
      dataBaseValue: "Bouvet Island",
      value: "Остров Буве",
      label: "Остров Буве",
      imgSrc: "/img/flags/bv.svg"
    },
    {
      dataBaseValue: "Botswana",
      value: "Ботсвана",
      label: "Ботсвана",
      imgSrc: "/img/flags/bw.svg"
    },
    {
      dataBaseValue: "Belarus",
      value: "Беларусь",
      label: "Беларусь",
      imgSrc: "/img/flags/by.svg"
    },
    {
      dataBaseValue: "Belize",
      value: "Белиз",
      label: "Белиз",
      imgSrc: "/img/flags/bz.svg"
    },
    {
      dataBaseValue: "Canada",
      value: "Канада",
      label: "Канада",
      imgSrc: "/img/flags/ca.svg"
    },
    {
      dataBaseValue: "Coconut islands",
      value: "Кокосовые острова",
      label: "Кокосовые острова",
      imgSrc: "/img/flags/cc.svg"
    },
    {
      dataBaseValue: "DR Congo",
      value: "ДР Конго",
      label: "ДР Конго",
      imgSrc: "/img/flags/cd.svg"
    },
    {
      dataBaseValue: "Central African Republic",
      value: "Центральноафриканская Республика",
      label: "Центральноафриканская Республика",
      imgSrc: "/img/flags/cf.svg"
    },
    {
      dataBaseValue: "Congo",
      value: "Конго",
      label: "Конго",
      imgSrc: "/img/flags/cg.svg"
    },
    {
      dataBaseValue: "Switzerland",
      value: "Швейцария",
      label: "Швейцария",
      imgSrc: "/img/flags/ch.svg"
    },
    {
      dataBaseValue: "Ivory Coast",
      value: "Кот-д'Ивуар",
      label: "Кот-д'Ивуар",
      imgSrc: "/img/flags/ci.svg"
    },
    {
      dataBaseValue: "Cook Islands",
      value: "Острова Кука",
      label: "Острова Кука",
      imgSrc: "/img/flags/ck.svg"
    },
    {
      dataBaseValue: "Chile",
      value: "Чили",
      label: "Чили",
      imgSrc: "/img/flags/cl.svg"
    },
    {
      dataBaseValue: "Cameroon",
      value: "Камерун",
      label: "Камерун",
      imgSrc: "/img/flags/cm.svg"
    },
    {
      dataBaseValue: "China",
      value: "Китай",
      label: "Китай",
      imgSrc: "/img/flags/cn.svg"
    },
    {
      dataBaseValue: "Colombia",
      value: "Колумбия",
      label: "Колумбия",
      imgSrc: "/img/flags/co.svg"
    },
    {
      dataBaseValue: "Costa Rica",
      value: "Коста-Рика",
      label: "Коста-Рика",
      imgSrc: "/img/flags/cr.svg"
    },
    {
      dataBaseValue: "Cuba",
      value: "Куба",
      label: "Куба",
      imgSrc: "/img/flags/cu.svg"
    },
    {
      dataBaseValue: "Cape Verde",
      value: "Кабо-Верде",
      label: "Кабо-Верде",
      imgSrc: "/img/flags/cv.svg"
    },
    {
      dataBaseValue: "Curacao",
      value: "Кюрасао",
      label: "Кюрасао",
      imgSrc: "/img/flags/cw.svg"
    },
    {
      dataBaseValue: "Christmas Island",
      value: "Остров Рождества",
      label: "Остров Рождества",
      imgSrc: "/img/flags/cx.svg"
    },
    {
      dataBaseValue: "Cyprus",
      value: "Кипр",
      label: "Кипр",
      imgSrc: "/img/flags/cy.svg"
    },
    {
      dataBaseValue: "Czech Republic",
      value: "Чехия",
      label: "Чехия",
      imgSrc: "/img/flags/cz.svg"
    },
    {
      dataBaseValue: "Germany",
      value: "Германия",
      label: "Германия",
      imgSrc: "/img/flags/de.svg"
    },
    {
      dataBaseValue: "Djibouti",
      value: "Джибути",
      label: "Джибути",
      imgSrc: "/img/flags/dj.svg"
    },
    {
      dataBaseValue: "Denmark",
      value: "Дания",
      label: "Дания",
      imgSrc: "/img/flags/dk.svg"
    },
    {
      dataBaseValue: "Dominica",
      value: "Доминика",
      label: "Доминика",
      imgSrc: "/img/flags/dm.svg"
    },
    {
      dataBaseValue: "Dominican Republic",
      value: "Доминиканская Республика",
      label: "Доминиканская Республика",
      imgSrc: "/img/flags/do.svg"
    },
    {
      dataBaseValue: "Algeria",
      value: "Алжир",
      label: "Алжир",
      imgSrc: "/img/flags/dz.svg"
    },
    {
      dataBaseValue: "Ecuador",
      value: "Эквадор",
      label: "Эквадор",
      imgSrc: "/img/flags/ec.svg"
    },
    {
      dataBaseValue: "Estonia",
      value: "Эстония",
      label: "Эстония",
      imgSrc: "/img/flags/ee.svg"
    },
    {
      dataBaseValue: "Egypt",
      value: "Египет",
      label: "Египет",
      imgSrc: "/img/flags/eg.svg"
    },
    {
      dataBaseValue: "West Sahara",
      value: "Западная Сахара",
      label: "Западная Сахара",
      imgSrc: "/img/flags/eh.svg"
    },
    {
      dataBaseValue: "Eritrea",
      value: "Эритрея",
      label: "Эритрея",
      imgSrc: "/img/flags/er.svg"
    },
    {
      dataBaseValue: "Spain",
      value: "Испания",
      label: "Испания",
      imgSrc: "/img/flags/es.svg"
    },
    {
      dataBaseValue: "Ethiopia",
      value: "Эфиопия",
      label: "Эфиопия",
      imgSrc: "/img/flags/et.svg"
    },
    {
      dataBaseValue: "Europe",
      value: "Европа",
      label: "Европа",
      imgSrc: "/img/flags/eu.svg"
    },
    {
      dataBaseValue: "Finland",
      value: "Финляндия",
      label: "Финляндия",
      imgSrc: "/img/flags/fi.svg"
    },
    {
      dataBaseValue: "Netherlands",
      value: "Нидерланды",
      label: "Нидерланды",
      imgSrc: "/img/flags/nl.svg"
    },
    {
      dataBaseValue: "Fiji",
      value: "Фиджи",
      label: "Фиджи",
      imgSrc: "/img/flags/fj.svg"
    },
    {
      dataBaseValue: "Falkland Islands",
      value: "Фолклендские острова",
      label: "Фолклендские острова",
      imgSrc: "/img/flags/fk.svg"
    },
    {
      dataBaseValue: "Federated States of Micronesia",
      value: "Федеративные Штаты Микронезии",
      label: "Федеративные Штаты Микронезии",
      imgSrc: "/img/flags/fm.svg"
    },
    {
      dataBaseValue: "Faroe Islands",
      value: "Фарерские о-ва",
      label: "Фарерские о-ва",
      imgSrc: "/img/flags/fo.svg"
    },
    {
      dataBaseValue: "France",
      value: "Франция",
      label: "Франция",
      imgSrc: "/img/flags/fr.svg"
    },
    {
      dataBaseValue: "Georgia",
      value: "Грузия",
      label: "Грузия",
      imgSrc: "/img/flags/ge.svg"
    },
    {
      dataBaseValue: "Northern Ireland",
      value: "Северная Ирландия",
      label: "Северная Ирландия",
      imgSrc: "/img/flags/nir.svg"
    },
    {
      dataBaseValue: "Scotland",
      value: "Шотландия",
      label: "Шотландия",
      imgSrc: "/img/flags/sct.svg"
    },
    {
      dataBaseValue: "Wales",
      value: "Уэльс",
      label: "Уэльс",
      imgSrc: "/img/flags/wls.svg"
    },
    {
      dataBaseValue: "Grenada",
      value: "Гренада",
      label: "Гренада",
      imgSrc: "/img/flags/gd.svg"
    },
    {
      dataBaseValue: "French Guiana",
      value: "Французская Гвиана",
      label: "Французская Гвиана",
      imgSrc: "/img/flags/gf.svg"
    },
    {
      dataBaseValue: "Guernsey",
      value: "Гернси",
      label: "Гернси",
      imgSrc: "/img/flags/gg.svg"
    },
    {
      dataBaseValue: "Ghana",
      value: "Гана",
      label: "Гана",
      imgSrc: "/img/flags/gh.svg"
    },
    {
      dataBaseValue: "Gibraltar",
      value: "Гибралтар",
      label: "Гибралтар",
      imgSrc: "/img/flags/gi.svg"
    },
    {
      dataBaseValue: "Greenland",
      value: "Гренландия",
      label: "Гренландия",
      imgSrc: "/img/flags/gl.svg"
    },
    {
      dataBaseValue: "Gambia",
      value: "Гамбия",
      label: "Гамбия",
      imgSrc: "/img/flags/gm.svg"
    },
    {
      dataBaseValue: "Guinea",
      value: "Гвинея",
      label: "Гвинея",
      imgSrc: "/img/flags/gn.svg"
    },
    {
      dataBaseValue: "Guadeloupe",
      value: "Гваделупа",
      label: "Гваделупа",
      imgSrc: "/img/flags/gp.svg"
    },
    {
      dataBaseValue: "Equatorial Guinea",
      value: "Экваториальная Гвинея",
      label: "Экваториальная Гвинея",
      imgSrc: "/img/flags/gq.svg"
    },
    {
      dataBaseValue: "Greece",
      value: "Греция",
      label: "Греция",
      imgSrc: "/img/flags/gr.svg"
    },
    {
      dataBaseValue: "South Georgia and the South Sandwich Islands",
      value: "Южная Георгия и Южные Сандвичевы острова",
      label: "Южная Георгия и Южные Сандвичевы острова",
      imgSrc: "/img/flags/gs.svg"
    },
    {
      dataBaseValue: "Guatemala",
      value: "Гватемала",
      label: "Гватемала",
      imgSrc: "/img/flags/gt.svg"
    },
    {
      dataBaseValue: "Guam",
      value: "Гуам",
      label: "Гуам",
      imgSrc: "/img/flags/gu.svg"
    },
    {
      dataBaseValue: "Guinea-Bissau",
      value: "Гвинея-Бисау",
      label: "Гвинея-Бисау",
      imgSrc: "/img/flags/gw.svg"
    },
    {
      dataBaseValue: "Guyana",
      value: "Гайана",
      label: "Гайана",
      imgSrc: "/img/flags/gy.svg"
    },
    {
      dataBaseValue: "Hong Kong",
      value: "Гонконг",
      label: "Гонконг",
      imgSrc: "/img/flags/hk.svg"
    },
    {
      dataBaseValue: "Honduras",
      value: "Гондурас",
      label: "Гондурас",
      imgSrc: "/img/flags/hn.svg"
    },
    {
      dataBaseValue: "Croatia",
      value: "Хорватия",
      label: "Хорватия",
      imgSrc: "/img/flags/hr.svg"
    },
    {
      dataBaseValue: "Haiti",
      value: "Гаити",
      label: "Гаити",
      imgSrc: "/img/flags/ht.svg"
    },
    {
      dataBaseValue: "Hungary",
      value: "Венгрия",
      label: "Венгрия",
      imgSrc: "/img/flags/hu.svg"
    },
    {
      dataBaseValue: "Indonesia",
      value: "Индонезия",
      label: "Индонезия",
      imgSrc: "/img/flags/id.svg"
    },
    {
      dataBaseValue: "Ireland",
      value: "Ирландия",
      label: "Ирландия",
      imgSrc: "/img/flags/ie.svg"
    },
    {
      dataBaseValue: "Israel",
      value: "Израиль",
      label: "Израиль",
      imgSrc: "/img/flags/il.svg"
    },
    {
      dataBaseValue: "Isle of Man",
      value: "о-в Мэн",
      label: "о-в Мэн",
      imgSrc: "/img/flags/im.svg"
    },
    {
      dataBaseValue: "India",
      value: "Индия",
      label: "Индия",
      imgSrc: "/img/flags/in.svg"
    },
    {
      dataBaseValue: "British Indian Ocean Territory",
      value: "Британская территория в Индийском океане",
      label: "Британская территория в Индийском океане",
      imgSrc: "/img/flags/io.svg"
    },
    {
      dataBaseValue: "Iraq",
      value: "Ирак",
      label: "Ирак",
      imgSrc: "/img/flags/iq.svg"
    },
    {
      dataBaseValue: "Iran",
      value: "Иран",
      label: "Иран",
      imgSrc: "/img/flags/ir.svg"
    },
    {
      dataBaseValue: "Italy",
      value: "Италия",
      label: "Италия",
      imgSrc: "/img/flags/it.svg"
    },
    {
      dataBaseValue: "Jersey",
      value: "Джерси",
      label: "Джерси",
      imgSrc: "/img/flags/je.svg"
    },
    {
      dataBaseValue: "Jamaica",
      value: "Ямайка",
      label: "Ямайка",
      imgSrc: "/img/flags/jm.svg"
    },
    {
      dataBaseValue: "Jordan",
      value: "Иордания",
      label: "Иордания",
      imgSrc: "/img/flags/jo.svg"
    },
    {
      dataBaseValue: "Japan",
      value: "Япония",
      label: "Япония",
      imgSrc: "/img/flags/jp.svg"
    },
    {
      dataBaseValue: "Kenya",
      value: "Кения",
      label: "Кения",
      imgSrc: "/img/flags/ke.svg"
    },
    {
      dataBaseValue: "Kyrgyzstan",
      value: "Киргизия",
      label: "Киргизия",
      imgSrc: "/img/flags/kg.svg"
    },
    {
      dataBaseValue: "Cambodia",
      value: "Камбоджа",
      label: "Камбоджа",
      imgSrc: "/img/flags/kh.svg"
    },
    {
      dataBaseValue: "Kiribati",
      value: "Кирибати",
      label: "Кирибати",
      imgSrc: "/img/flags/ki.svg"
    },
    {
      dataBaseValue: "Comoros",
      value: "Коморы",
      label: "Коморы",
      imgSrc: "/img/flags/km.svg"
    },
    {
      dataBaseValue: "Saint Kitts and Nevis",
      value: "Сент-Китс и Невис",
      label: "Сент-Китс и Невис",
      imgSrc: "/img/flags/kn.svg"
    },
    {
      dataBaseValue: "Democratic People's Republic of Korea",
      value: "Корейская Народно-Демократическая Республика",
      label: "Корейская Народно-Демократическая Республика",
      imgSrc: "/img/flags/kp.svg"
    },
    {
      dataBaseValue: "South Korea",
      value: "Южная Корея",
      label: "Южная Корея",
      imgSrc: "/img/flags/kr.svg"
    },
    {
      dataBaseValue: "Kuwait",
      value: "Кувейт",
      label: "Кувейт",
      imgSrc: "/img/flags/kw.svg"
    },
    {
      dataBaseValue: "Cayman Islands",
      value: "Острова Кайман",
      label: "Острова Кайман",
      imgSrc: "/img/flags/ky.svg"
    },
    {
      dataBaseValue: "Kazakhstan",
      value: "Казахстан",
      label: "Казахстан",
      imgSrc: "/img/flags/kz.svg"
    },
    {
      dataBaseValue: "Laos",
      value: "Лаос",
      label: "Лаос",
      imgSrc: "/img/flags/la.svg"
    },
    {
      dataBaseValue: "Lebanon",
      value: "Ливан",
      label: "Ливан",
      imgSrc: "/img/flags/lb.svg"
    },
    {
      dataBaseValue: "Saint Lucia",
      value: "Сент-Люсия",
      label: "Сент-Люсия",
      imgSrc: "/img/flags/lc.svg"
    },
    {
      dataBaseValue: "Liechtenstein",
      value: "Лихтенштейн",
      label: "Лихтенштейн",
      imgSrc: "/img/flags/li.svg"
    },
    {
      dataBaseValue: "Sri Lanka",
      value: "Шри-Ланка",
      label: "Шри-Ланка",
      imgSrc: "/img/flags/lk.svg"
    },
    {
      dataBaseValue: "Liberia",
      value: "Либерия",
      label: "Либерия",
      imgSrc: "/img/flags/lr.svg"
    },
    {
      dataBaseValue: "Lesotho",
      value: "Лесото",
      label: "Лесото",
      imgSrc: "/img/flags/ls.svg"
    },
    {
      dataBaseValue: "Lithuania",
      value: "Литва",
      label: "Литва",
      imgSrc: "/img/flags/lt.svg"
    },
    {
      dataBaseValue: "Luxembourg",
      value: "Люксембург",
      label: "Люксембург",
      imgSrc: "/img/flags/lu.svg"
    },
    {
      dataBaseValue: "Latvia",
      value: "Латвия",
      label: "Латвия",
      imgSrc: "/img/flags/lv.svg"
    },
    {
      dataBaseValue: "Libya",
      value: "Ливия",
      label: "Ливия",
      imgSrc: "/img/flags/ly.svg"
    },
    {
      dataBaseValue: "Morocco",
      value: "Марокко",
      label: "Марокко",
      imgSrc: "/img/flags/ma.svg"
    },
    {
      dataBaseValue: "Monaco",
      value: "Монако",
      label: "Монако",
      imgSrc: "/img/flags/mc.svg"
    },
    {
      dataBaseValue: "Moldova",
      value: "Молдавия",
      label: "Молдавия",
      imgSrc: "/img/flags/md.svg"
    },
    {
      dataBaseValue: "Montenegro",
      value: "Черногория",
      label: "Черногория",
      imgSrc: "/img/flags/me.svg"
    },
    {
      dataBaseValue: "Saint Martin",
      value: "Сен-Мартен",
      label: "Сен-Мартен",
      imgSrc: "/img/flags/mf.svg"
    },
    {
      dataBaseValue: "Madagascar",
      value: "Мадагаскар",
      label: "Мадагаскар",
      imgSrc: "/img/flags/mg.svg"
    },
    {
      dataBaseValue: "Marshall Islands",
      value: "Маршалловы о-ва",
      label: "Маршалловы о-ва",
      imgSrc: "/img/flags/mh.svg"
    },
    {
      dataBaseValue: "Macedonia",
      value: "Македония",
      label: "Македония",
      imgSrc: "/img/flags/mk.svg"
    },
    {
      dataBaseValue: "Mali",
      value: "Мали",
      label: "Мали",
      imgSrc: "/img/flags/ml.svg"
    },
    {
      dataBaseValue: "Myanmar",
      value: "Мьянма",
      label: "Мьянма",
      imgSrc: "/img/flags/mm.svg"
    },
    {
      dataBaseValue: "Mongolia",
      value: "Монголия",
      label: "Монголия",
      imgSrc: "/img/flags/mn.svg"
    },
    {
      dataBaseValue: "Macau",
      value: "Макао",
      label: "Макао",
      imgSrc: "/img/flags/mo.svg"
    },
    {
      dataBaseValue: "Northern Mariana Islands",
      value: "Северные Марианские о-ва",
      label: "Северные Марианские о-ва",
      imgSrc: "/img/flags/mp.svg"
    },
    {
      dataBaseValue: "Martinique",
      value: "Мартиника",
      label: "Мартиника",
      imgSrc: "/img/flags/mq.svg"
    },
    {
      dataBaseValue: "Mauritius",
      value: "Мавритания",
      label: "Мавритания",
      imgSrc: "/img/flags/mr.svg"
    },
    {
      dataBaseValue: "Montserrat",
      value: "Монтсеррат",
      label: "Монтсеррат",
      imgSrc: "/img/flags/ms.svg"
    },
    {
      dataBaseValue: "Malta",
      value: "Мальта",
      label: "Мальта",
      imgSrc: "/img/flags/mt.svg"
    },
    {
      dataBaseValue: "O. Mauritius",
      value: "о. Маврикий",
      label: "о. Маврикий",
      imgSrc: "/img/flags/mu.svg"
    },
    {
      dataBaseValue: "Maldives",
      value: "Мальдивы",
      label: "Мальдивы",
      imgSrc: "/img/flags/mv.svg"
    },
    {
      dataBaseValue: "Malawi",
      value: "Малави",
      label: "Малави",
      imgSrc: "/img/flags/mw.svg"
    },
    {
      dataBaseValue: "Mexico",
      value: "Мексика",
      label: "Мексика",
      imgSrc: "/img/flags/mx.svg"
    },
    {
      dataBaseValue: "Malaysia",
      value: "Малайзия",
      label: "Малайзия",
      imgSrc: "/img/flags/my.svg"
    },
    {
      dataBaseValue: "Mozambique",
      value: "Мозамбик",
      label: "Мозамбик",
      imgSrc: "/img/flags/mz.svg"
    },
    {
      dataBaseValue: "Namibia",
      value: "Намибия",
      label: "Намибия",
      imgSrc: "/img/flags/na.svg"
    },
    {
      dataBaseValue: "New Caledonia",
      value: "Новая Каледония",
      label: "Новая Каледония",
      imgSrc: "/img/flags/nc.svg"
    },
    {
      dataBaseValue: "Niger",
      value: "Нигер",
      label: "Нигер",
      imgSrc: "/img/flags/ne.svg"
    },
    {
      dataBaseValue: "Norfolk Island",
      value: "Остров Норфолк",
      label: "Остров Норфолк",
      imgSrc: "/img/flags/nf.svg"
    },
    {
      dataBaseValue: "Nigeria",
      value: "Нигерия",
      label: "Нигерия",
      imgSrc: "/img/flags/ng.svg"
    },
    {
      dataBaseValue: "Nicaragua",
      value: "Никарагуа",
      label: "Никарагуа",
      imgSrc: "/img/flags/ni.svg"
    },
    {
      dataBaseValue: "Norway",
      value: "Норвегия",
      label: "Норвегия",
      imgSrc: "/img/flags/no.svg"
    },
    {
      dataBaseValue: "Nepal",
      value: "Непал",
      label: "Непал",
      imgSrc: "/img/flags/np.svg"
    },
    {
      dataBaseValue: "Nauru",
      value: "Науру",
      label: "Науру",
      imgSrc: "/img/flags/nr.svg"
    },
    {
      dataBaseValue: "Niue",
      value: "Ниуэ",
      label: "Ниуэ",
      imgSrc: "/img/flags/nu.svg"
    },
    {
      dataBaseValue: "New Zealand",
      value: "Новая Зеландия",
      label: "Новая Зеландия",
      imgSrc: "/img/flags/nz.svg"
    },
    {
      dataBaseValue: "Oman",
      value: "Оман",
      label: "Оман",
      imgSrc: "/img/flags/om.svg"
    },
    {
      dataBaseValue: "Panama",
      value: "Панама",
      label: "Панама",
      imgSrc: "/img/flags/pa.svg"
    },
    {
      dataBaseValue: "Peru",
      value: "Перу",
      label: "Перу",
      imgSrc: "/img/flags/pe.svg"
    },
    {
      dataBaseValue: "French polynesia",
      value: "Французская Полинезия",
      label: "Французская Полинезия",
      imgSrc: "/img/flags/pf.svg"
    },
    {
      dataBaseValue: "Papua New Guinea",
      value: "Папуа — Новая Гвинея",
      label: "Папуа — Новая Гвинея",
      imgSrc: "/img/flags/pg.svg"
    },
    {
      dataBaseValue: "Philippines",
      value: "Филиппины",
      label: "Филиппины",
      imgSrc: "/img/flags/ph.svg"
    },
    {
      dataBaseValue: "Pakistan",
      value: "Пакистан",
      label: "Пакистан",
      imgSrc: "/img/flags/pk.svg"
    },
    {
      dataBaseValue: "Poland",
      value: "Польша",
      label: "Польша",
      imgSrc: "/img/flags/pl.svg"
    },
    {
      dataBaseValue: "Saint Pierre and Miquelon",
      value: "Сен-Пьер и Микелон",
      label: "Сен-Пьер и Микелон",
      imgSrc: "/img/flags/pm.svg"
    },
    {
      dataBaseValue: "Pitcairn Islands",
      value: "о-ва Питкэрн",
      label: "о-ва Питкэрн",
      imgSrc: "/img/flags/pn.svg"
    },
    {
      dataBaseValue: "Puerto Rico",
      value: "Пуэрто-Рико",
      label: "Пуэрто-Рико",
      imgSrc: "/img/flags/pr.svg"
    },
    {
      dataBaseValue: "Palestine",
      value: "Палестина",
      label: "Палестина",
      imgSrc: "/img/flags/ps.svg"
    },
    {
      dataBaseValue: "Portugal",
      value: "Португалия",
      label: "Португалия",
      imgSrc: "/img/flags/pt.svg"
    },
    {
      dataBaseValue: "Paraguay",
      value: "Парагвай",
      label: "Парагвай",
      imgSrc: "/img/flags/py.svg"
    },
    {
      dataBaseValue: "Qatar",
      value: "Катар",
      label: "Катар",
      imgSrc: "/img/flags/qa.svg"
    },
    {
      dataBaseValue: "Reunion",
      value: "Реюньон",
      label: "Реюньон",
      imgSrc: "/img/flags/re.svg"
    },
    {
      dataBaseValue: "Romania",
      value: "Румыния",
      label: "Румыния",
      imgSrc: "/img/flags/ro.svg"
    },
    {
      dataBaseValue: "Serbia",
      value: "Сербия",
      label: "Сербия",
      imgSrc: "/img/flags/rs.svg"
    },
    {
      dataBaseValue: "Russia",
      value: "Россия",
      label: "Россия",
      imgSrc: "/img/flags/ru.svg"
    },
    {
      dataBaseValue: "Rwanda",
      value: "Руанда",
      label: "Руанда",
      imgSrc: "/img/flags/rw.svg"
    },
    {
      dataBaseValue: "South Africa",
      value: "Южная Африка",
      label: "Южная Африка",
      imgSrc: "/img/flags/sa.svg"
    },
    {
      dataBaseValue: "Solomon Islands",
      value: "Соломоновы о-ва",
      label: "Соломоновы о-ва",
      imgSrc: "/img/flags/sb.svg"
    },
    {
      dataBaseValue: "Seychelles",
      value: "Сейшелы",
      label: "Сейшелы",
      imgSrc: "/img/flags/sc.svg"
    },
    {
      dataBaseValue: "Sudan",
      value: "Судан",
      label: "Судан",
      imgSrc: "/img/flags/sd.svg"
    },
    {
      dataBaseValue: "Sweden",
      value: "Швеция",
      label: "Швеция",
      imgSrc: "/img/flags/se.svg"
    },
    {
      dataBaseValue: "Singapore",
      value: "Сингапур",
      label: "Сингапур",
      imgSrc: "/img/flags/sg.svg"
    },
    {
      dataBaseValue: "O. St. Helena",
      value: "о. Св. Елены",
      label: "о. Св. Елены",
      imgSrc: "/img/flags/sh.svg"
    },
    {
      dataBaseValue: "Slovenia",
      value: "Словения",
      label: "Словения",
      imgSrc: "/img/flags/si.svg"
    },
    {
      dataBaseValue: "Svalbard",
      value: "Шпицберген",
      label: "Шпицберген",
      imgSrc: "/img/flags/sj.svg"
    },
    {
      dataBaseValue: "Slovakia",
      value: "Словакия",
      label: "Словакия",
      imgSrc: "/img/flags/sk.svg"
    },
    {
      dataBaseValue: "Sierra Leone",
      value: "Сьерра-Леоне",
      label: "Сьерра-Леоне",
      imgSrc: "/img/flags/sl.svg"
    },
    {
      dataBaseValue: "San Marino",
      value: "Сан-Марино",
      label: "Сан-Марино",
      imgSrc: "/img/flags/sm.svg"
    },
    {
      dataBaseValue: "Senegal",
      value: "Сенегал",
      label: "Сенегал",
      imgSrc: "/img/flags/sn.svg"
    },
    {
      dataBaseValue: "Somalia",
      value: "Сомали",
      label: "Сомали",
      imgSrc: "/img/flags/so.svg"
    },
    {
      dataBaseValue: "Suriname",
      value: "Суринам",
      label: "Суринам",
      imgSrc: "/img/flags/sr.svg"
    },
    {
      dataBaseValue: "South Sudan",
      value: "Южный Судан",
      label: "Южный Судан",
      imgSrc: "/img/flags/ss.svg"
    },
    {
      dataBaseValue: "Sao Tome and Principe",
      value: "Сан-Томе и Принсипи",
      label: "Сан-Томе и Принсипи",
      imgSrc: "/img/flags/st.svg"
    },
    {
      dataBaseValue: "Salvador",
      value: "Сальвадор",
      label: "Сальвадор",
      imgSrc: "/img/flags/sv.svg"
    },
    {
      dataBaseValue: "Sint Maarten",
      value: "Синт-Мартен",
      label: "Синт-Мартен",
      imgSrc: "/img/flags/sx.svg"
    },
    {
      dataBaseValue: "Syria",
      value: "Сирия",
      label: "Сирия",
      imgSrc: "/img/flags/sy.svg"
    },
    {
      dataBaseValue: "Eswatini",
      value: "Эсватини",
      label: "Эсватини",
      imgSrc: "/img/flags/sz.svg"
    },
    {
      dataBaseValue: "Turks and Caicos Islands",
      value: "Острова Теркс и Кайкос",
      label: "Острова Теркс и Кайкос",
      imgSrc: "/img/flags/tc.svg"
    },
    {
      dataBaseValue: "Chad",
      value: "Чад",
      label: "Чад",
      imgSrc: "/img/flags/td.svg"
    },
    {
      dataBaseValue: "Togo",
      value: "Того",
      label: "Того",
      imgSrc: "/img/flags/tg.svg"
    },
    {
      dataBaseValue: "Thailand",
      value: "Таиланд",
      label: "Таиланд",
      imgSrc: "/img/flags/th.svg"
    },
    {
      dataBaseValue: "Tajikistan",
      value: "Таджикистан",
      label: "Таджикистан",
      imgSrc: "/img/flags/tj.svg"
    },
    {
      dataBaseValue: "Tokelau",
      value: "Токелау",
      label: "Токелау",
      imgSrc: "/img/flags/tk.svg"
    },
    {
      dataBaseValue: "East Timor",
      value: "Восточный Тимор",
      label: "Восточный Тимор",
      imgSrc: "/img/flags/tl.svg"
    },
    {
      dataBaseValue: "Turkmenistan",
      value: "Туркменистан",
      label: "Туркменистан",
      imgSrc: "/img/flags/tm.svg"
    },
    {
      dataBaseValue: "Tunisia",
      value: "Тунис",
      label: "Тунис",
      imgSrc: "/img/flags/tn.svg"
    },
    {
      dataBaseValue: "Turkey",
      value: "Турция",
      label: "Турция",
      imgSrc: "/img/flags/tr.svg"
    },
    {
      dataBaseValue: "Trinidad and Tobago",
      value: "Тринидад и Тобаго",
      label: "Тринидад и Тобаго",
      imgSrc: "/img/flags/tt.svg"
    },
    {
      dataBaseValue: "Tuvalu",
      value: "Тувалу",
      label: "Тувалу",
      imgSrc: "/img/flags/tv.svg"
    },
    {
      dataBaseValue: "Taiwan",
      value: "Тайвань",
      label: "Тайвань",
      imgSrc: "/img/flags/tw.svg"
    },
    {
      dataBaseValue: "Tanzania",
      value: "Танзания",
      label: "Танзания",
      imgSrc: "/img/flags/tz.svg"
    },
    {
      dataBaseValue: "Ukraine",
      value: "Украина",
      label: "Украина",
      imgSrc: "/img/flags/ua.svg"
    },
    {
      dataBaseValue: "Uganda",
      value: "Уганда",
      label: "Уганда",
      imgSrc: "/img/flags/ug.svg"
    },
    {
      dataBaseValue: "Minor Outlying Islands (USA)",
      value: "Внешние малые о-ва (США)",
      label: "Внешние малые о-ва (США)",
      imgSrc: "/img/flags/um.svg"
    },
    {
      dataBaseValue: "United States",
      value: "Соединенные Штаты Америки",
      label: "Соединенные Штаты Америки",
      imgSrc: "/img/flags/us.svg"
    },
    {
      dataBaseValue: "United Kingdom",
      value: "Великобритания",
      label: "Великобритания",
      imgSrc: "/img/flags/uk.svg"
    },
    {
      dataBaseValue: "Uruguay",
      value: "Уругвай",
      label: "Уругвай",
      imgSrc: "/img/flags/uy.svg"
    },
    {
      dataBaseValue: "Uzbekistan",
      value: "Узбекистан",
      label: "Узбекистан",
      imgSrc: "/img/flags/uz.svg"
    },
    {
      dataBaseValue: "Vatican City State",
      value: "Государство-город Ватикан",
      label: "Государство-город Ватикан",
      imgSrc: "/img/flags/va.svg"
    },
    {
      dataBaseValue: "Saint Vincent and the Grenadines",
      value: "Сент-Винсент и Гренадины",
      label: "Сент-Винсент и Гренадины",
      imgSrc: "/img/flags/vc.svg"
    },
    {
      dataBaseValue: "Venezuela",
      value: "Венесуэла",
      label: "Венесуэла",
      imgSrc: "/img/flags/ve.svg"
    },
    {
      dataBaseValue: "British Virgin Islands",
      value: "Британские Виргинские острова",
      label: "Британские Виргинские острова",
      imgSrc: "/img/flags/vg.svg"
    },
    {
      dataBaseValue: "US Virgin Islands",
      value: "Американские Виргинские острова",
      label: "Американские Виргинские острова",
      imgSrc: "/img/flags/vi.svg"
    },
    {
      dataBaseValue: "Vietnam",
      value: "Вьетнам",
      label: "Вьетнам",
      imgSrc: "/img/flags/vn.svg"
    },
    {
      dataBaseValue: "Vanuatu",
      value: "Вануату",
      label: "Вануату",
      imgSrc: "/img/flags/vu.svg"
    },
    {
      dataBaseValue: "Wallis and Futuna Islands",
      value: "о-ва Уоллис и Футуна",
      label: "о-ва Уоллис и Футуна",
      imgSrc: "/img/flags/wf.svg"
    },
    {
      dataBaseValue: "Samoa",
      value: "Самоа",
      label: "Самоа",
      imgSrc: "/img/flags/ws.svg"
    },
    {
      dataBaseValue: "Kosovo",
      value: "Косово",
      label: "Косово",
      imgSrc: "/img/flags/xk.svg"
    },
    {
      dataBaseValue: "Yemen",
      value: "Йемен",
      label: "Йемен",
      imgSrc: "/img/flags/ye.svg"
    },
    {
      dataBaseValue: "Mayotte",
      value: "Майотта",
      label: "Майотта",
      imgSrc: "/img/flags/yt.svg"
    },
    {
      dataBaseValue: "South Africa",
      value: "Южная Африка",
      label: "Южная Африка",
      imgSrc: "/img/flags/za.svg"
    },
    {
      dataBaseValue: "Zambia",
      value: "Замбия",
      label: "Замбия",
      imgSrc: "/img/flags/zm.svg"
    },
    {
      dataBaseValue: "Zimbabwe",
      value: "Зимбабве",
      label: "Зимбабве",
      imgSrc: "/img/flags/zw.svg"
    }
  ],
  filterProxyItems: [
    {
      id: 1,
      value: "Формат IP:PORT",
      regExp: /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(\s{0,}|:)\d{1,5}/,
      divider: [":"]
    },
    {
      id: 3,
      value: "Формат IP:PORT@Login:Password",
      regExp:
        /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(\s{0,}|:)\d{1,5}(\s{0,}|@)\S+(\s{0,}|:)\S+/,
      divider: [":", "@"]
    },
    {
      id: 4,
      value: "Формат IP.PORT:Login:Password",
      regExp:
        /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(\s{0,}|:)\d{1,5}(\s{0,}|:)\S+(\s{0,}|:)\S+/,
      divider: [":"]
    }
  ],
  quantity: [
    { label: "10 шт.", value: 10 },
    { label: "15 шт.", value: 15 },
    { label: "20 шт.", value: 20 },
    { label: "50 шт.", value: 50 }
  ],
  regions: [
    { value: "MOSCOW", label: "Москва" },
    { value: "KYIV", label: "Киев" },
    { value: "ST. PETERSBURG", label: "Санкт-Петербург" }
  ]
};
