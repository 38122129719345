export const getTextareaData = (textareaRef) =>
  Array.from(
    new Set(
      textareaRef.current.value
        .split(/[\n\s,;]/g)
        .filter(
          (ip) =>
            /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}:\d{1,5}:.+:.+$/g.test(ip) ||
            /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}:\d{1,5}$/g.test(ip)
        )
    )
  );
