import { useEffect, useState } from "react";

import { useClientSelector } from "../store/selectors/useClientSelector";
import { useTranslationSelector } from "../store/selectors/useTranslationSelector";
import {
  defineCorrectLocationHelper,
  findCountryFlagHelper
} from "../utils/helpers";

import { useTranslation } from "./useTranslation";

export const useCountryInfo = () => {
  const [countryInfo, setCountryInfo] = useState({
    imgSrc: "",
    location: ""
  });

  const t = useTranslation();
  const { anonymityInfo } = useClientSelector();
  const { countries } = useTranslationSelector();

  useEffect(() => {
    setCountryInfo({
      imgSrc: findCountryFlagHelper(anonymityInfo?.data?.country, countries),
      location: defineCorrectLocationHelper(
        anonymityInfo?.data,
        t.common.notDefined
      )
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anonymityInfo?.data]);

  return { countryInfo };
};
