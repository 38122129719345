import { useEffect, useRef, useState } from "react";

import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { checkBrowserLangInLangsArrayHelper } from "../../../../../../../utils/helpers/";
import Icon from "../../../../../../ui/Icon";

import LangItem from "./components/LangItem";

export const LangDropdown = () => {
  const [isLangListVisible, setIsLangListVisible] = useState(false);

  const { lang } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const langDropdownRef = useRef(null);

  const { langs } = useSelector((state) => state.translation);

  const closeLangList = (e) => {
    if (
      langDropdownRef.current &&
      !langDropdownRef.current.contains(e.target)
    ) {
      setIsLangListVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeLangList);

    return () => {
      document.removeEventListener("click", closeLangList);
    };
  }, [isLangListVisible]);

  const currentLang = checkBrowserLangInLangsArrayHelper(langs.data, lang);

  const languageHandler = (code) => {
    Cookies.set("lang", code.toLowerCase(), { expires: 7 });

    navigate(`/${code.toLowerCase()}/${pathname.split("/")[2]}`);

    setIsLangListVisible(false);
  };

  const toggleLangList = () => {
    setIsLangListVisible((prev) => !prev);
  };

  return (
    <div className="header-top__lang" ref={langDropdownRef}>
      <div
        className={`header-top__lang-current ${
          isLangListVisible ? "rotated" : ""
        }`}
        style={{ "--arrow": "url(/img/ui/arrow.svg)" }}
        onClick={toggleLangList}
      >
        <Icon
          alt={currentLang?.toUpperCase()}
          height="24"
          width="24"
          loading="lazy"
          src={`/img/flags/${currentLang}.svg`}
        />
        <span>{currentLang?.toUpperCase()}</span>
      </div>
      <ul
        className={`header-top__lang-list ${
          isLangListVisible
            ? "header-top__lang-list-visible"
            : "header-top__lang-list-hidden"
        }`}
      >
        {langs.data.map(({ code, internationalName }) => (
          <LangItem
            key={code}
            code={code}
            internationalName={internationalName}
            languageHandler={languageHandler}
          />
        ))}
      </ul>
    </div>
  );
};
