import { setClientIpHeader, setClientIpv6Header } from "../utils/helpers";
import { axiosPath } from "../utils/helpers/axiosPath";
import { instance, ipvInstance } from "../utils/helpers/instance";

export class ApiService {
  static nginxGetClientIp() {
    return instance.get(axiosPath("/api/nginx/client/ip"));
  }

  static async getClientIpv() {
    try {
      const { data } = await instance.get(axiosPath("/api/detect/ip"));

      if (data["cf-pseudo-ipv4"] === "") {
        setClientIpHeader(data["cf-connecting-ip"]);
      } else {
        setClientIpv6Header(data["cf-connecting-ip"]);

        const result = await ipvInstance.get("detect/ipv4");

        if (result.status === 200) {
          setClientIpHeader(result.data["remote_addr"]);
        } else {
          setClientIpHeader(data["cf-pseudo-ipv4"]);
        }
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  static getClientIpInfo() {
    return instance.get(axiosPath("/api/client/ip"));
  }

  static getAnonimityScore(
    dateJs,
    userAgentJs,
    languageJs,
    window,
    flash = true,
    java = true,
    activeX = true,
    webRtc = true
  ) {
    return instance.post(axiosPath("/api/client/anonymity/anonymity/scan"), {
      dateJs,
      userAgentJs,
      languageJs,
      window,
      flash,
      java,
      activeX,
      webRtc
    });
  }

  static getAnonimityInfo(
    dateJs,
    userAgentJs,
    languageJs,
    window,
    flash = true,
    java = true,
    activeX = true,
    webRtc = true,
    doNotTrack,
    mobile,
    isVBScript,
    color,
    dpi
  ) {
    return instance.post(axiosPath("/api/client/anonymity/"), {
      dateJs,
      userAgentJs,
      languageJs,
      window,
      flash,
      java,
      activeX,
      webRtc,
      doNotTrack,
      mobile,
      isVBScript,
      color,
      dpi
    });
  }

  static getOpenPorts() {
    return instance.get(axiosPath("/api/client/anonymity/scan/ports"));
  }

  static getAllProxies() {
    return instance.get(axiosPath("/api/front/main/proxy"));
  }

  static getProxiesByPage(params) {
    return instance.post(
      axiosPath("/api/front/main/pagination/filtration"),
      params
    );
  }

  static allProxiesStatistic() {
    return instance.get(axiosPath("/api/front/main/proxy/statistic"));
  }

  static checkProxyById(id) {
    return instance.get(axiosPath("/api/front/main/proxy/one"), {
      params: { id }
    });
  }

  static getListOfProxies(country, count) {
    return instance.post(axiosPath("/api/front/main/proxy/list"), {
      country,
      count
    });
  }

  static checkListOfProxies(proxyList) {
    return instance.post(axiosPath("/api/front/main/proxy/checker"), {
      proxyList
    });
  }

  static getResultOfProxyCheck(proxyId) {
    return instance.get(axiosPath("/api/front/main/proxy/checker/result"), {
      params: { proxyId }
    });
  }

  static checkProxyList(proxyList) {
    return instance.post(axiosPath("/api/front/main/proxy/v2/checker"), {
      proxyList
    });
  }

  static getResultOfProxyCheckList(proxyList) {
    return instance.post(axiosPath("/api/front/main/proxy/checker/v2/result"), {
      proxyList
    });
  }

  static getAllProxiesForExport(
    captchaKey = "",
    { countries, proxyProtocols, proxyTypes }
  ) {
    return instance.post(axiosPath("/api/front/main/proxy/export"), {
      captchaKey,
      countries,
      proxyProtocols,
      proxyTypes
    });
  }

  static getCaptchaInfo() {
    return instance.get(axiosPath("/api/front/main/captcha/info"));
  }

  static checkOpenPorts(ipOrDomain, portType, scanType) {
    return instance.post(axiosPath("/api/client/port/scan"), {
      ipOrDomain,
      portType,
      scanType
    });
  }

  static getPingId(ipOrDomain, number) {
    return instance.post(axiosPath("/api/client/ip/ping"), {
      ipOrDomain,
      number
    });
  }

  static getPingResult(id, numberOfPing) {
    return instance.post(axiosPath("/api/client/ip/ping/result"), {
      id,
      numberOfPing
    });
  }

  static getUtmUrl(longUrl) {
    return instance.get(axiosPath("/api/client/utm"), {
      params: {
        longUrl
      }
    });
  }

  static getSiteSpeed(formValues) {
    return instance.post(axiosPath("/api/client/speedtest"), formValues);
  }

  static checkIPv6(domain) {
    return instance.get(axiosPath("/api/client/ipv6"), { params: { domain } });
  }

  static checkIpInSpamBase(ip) {
    return instance.get(axiosPath("/api/client/check/blacklist"), {
      params: {
        ip
      }
    });
  }

  static checkTraceroute(target, isShowCountry) {
    return instance.get(axiosPath("/api/client/ip/traceroute"), {
      params: {
        target,
        isShowCountry
      }
    });
  }

  static getClusteredInfo(queryList, clusterSize, region) {
    return instance.post(axiosPath("/api/client/clustering"), {
      queryList,
      clusterSize,
      region
    });
  }

  static getAllTranslation() {
    return instance.get(axiosPath("/api/languageCode/all"));
  }

  static getPageContent(locale, tag) {
    return instance.get(axiosPath("/api/page/content"), {
      params: { locale, tag }
    });
  }
  //
  // static getProperty(name) {
  //   return instance.get(axiosPath("/api/admin/property/getValueByName"), {
  //     params: { name }
  //   });
  // }

  static getAllCountryTags() {
    return instance.get(axiosPath("/api/country/tag/all"));
  }

  static getCountryByTag(countryTag) {
    return instance.get(
      axiosPath(`/api/meta/county/tag?countryTag=${countryTag}`)
    );
  }

  static getDnsLeakIp() {
    return instance.get(axiosPath("/api/client/dns/leak"), {});
  }

  static getHeaders(url, userAgent, httpVersion, method) {
    return instance.post(axiosPath("/api/client/header/request"), {
      url,
      userAgent,
      httpVersion,
      method
    });
  }

  static getSiteHeaders() {
    return instance.get(axiosPath("/"));
  }

  static getCacheSSR(contentId) {
    return instance.get(axiosPath(`/ssr/cache/${contentId}`));
  }
}
