import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import { ApiService } from "../../../services/ApiService";

export const allProxiesStatistic = createAsyncThunk(
  "proxy/allProxiesStatistic",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiService.allProxiesStatistic();

      if (response && response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);

export const checkProxyById = createAsyncThunk(
  "proxy/checkProxyById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ApiService.checkProxyById(id);

      if (response && response.status !== 200) {
        throw response;
      }

      return {
        id,
        response: response.data
      };
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);

export const getAllProxiesForExport = createAsyncThunk(
  "proxy/getAllProxiesForExport",
  async ({ captchaKey, filterObj }, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.getAllProxiesForExport(
        captchaKey,
        filterObj
      );

      return data;
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);

export const getProxyTableData = createAsyncThunk(
  "proxy/getProxyTableData",
  async (filterParams, { rejectWithValue }) => {
    try {
      const response = await ApiService.getProxiesByPage(filterParams);

      if (response && response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);

export const removeProxy = createAction("proxy/removeProxy");
export const changeFilterOptions = createAction("proxy/changeFilterOptions");
export const clearFilterOptions = createAction("proxy/clearFilterOptions");
export const saveProxyTableData = createAction("proxy/saveProxyTableData");
export const saveProxiesStatistic = createAction("proxy/saveProxiesStatistic");
export const setPrevFilterOptions = createAction("proxy/setPrevFilterOptions");
export const setIsFirstFilter = createAction("proxy/setIsFirstFilter");
export const clearProxiesForExport = createAction(
  "proxy/clearProxiesForExport"
);
