import { createSlice } from "@reduxjs/toolkit";

import { dnsInitialState } from "./Dns.initialState";
import { getDnsData } from "./Dns.operations";
import {
  getDnsFulfilledReducer,
  getDnsPendingReducer,
  getDnsRejectReducer
} from "./Dns.reducer";

const dnsSlice = createSlice({
  name: "dns",
  initialState: dnsInitialState,
  extraReducers: (builder) =>
    builder
      .addCase(getDnsData.pending, getDnsPendingReducer)
      .addCase(getDnsData.fulfilled, getDnsFulfilledReducer)
      .addCase(getDnsData.rejected, getDnsRejectReducer)
});

export const DnsReducerActions = { getDnsData };

export default dnsSlice.reducer;
