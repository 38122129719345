import styled from "@emotion/styled";

export const Tooltip = styled("div")`
  position: absolute;
  top: -14px;
  left: 210px;
`;

export const TooltipText = styled("span")`
  padding: 7px 16px;
  font-size: 16px;
  border-radius: 4px;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  color: #131430;
`;

export const CountryName = styled("p")`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
`;

export const CheckMark = styled("div")`
  position: absolute;
  bottom: -14px;
  left: 20px;
  width: 0;
  height: 0;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  border-bottom: 10px solid #fff;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
  transform: rotate(180deg);
`;
