import { Suspense } from "react";

import { createRoot, hydrateRoot } from "react-dom/client";
// eslint-disable-next-line import/namespace
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from "react-router-dom";

import { App } from "./App";
import { getRouter } from "./components/common";
import { ApiService } from "./services/ApiService";
import { setupStore } from "./store/store";

import "./index.scss";

const getApp = (initialState) => {
  const store = setupStore(initialState);
  const routes = createRoutesFromElements(getRouter());
  const router = createBrowserRouter(routes);

  return (
    <Provider store={store}>
      <HelmetProvider>
        <Suspense fallback={null}>
          <App
            routerProvider={RouterProvider}
            routerProps={{
              router
            }}
          />
        </Suspense>
      </HelmetProvider>
    </Provider>
  );
};

const hydrateApp = (initialState = {}) => {
  const app = getApp(initialState);
  const rootElement = document.getElementById("root");

  if (rootElement.hasChildNodes()) {
    hydrateRoot(rootElement, app);
  } else {
    createRoot(rootElement).render(app);
  }
};

const initialState = async () => {
  try {
    const contentId = document.querySelector(
      "meta[name='ssr-content-id']"
    ).content;

    if (contentId) {
      const { data } = await ApiService.getCacheSSR(contentId);

      if (data.state) {
        return hydrateApp(data.state);
      }
    }

    return hydrateApp(window.__PRELOADED_STATE__);
  } catch (e) {
    return hydrateApp(window.__PRELOADED_STATE__);
  } finally {
    delete window.__PRELOADED_STATE__;
    document.getElementById("preloaded-state")?.remove();
  }
};

initialState();
