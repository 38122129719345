import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import { ApiService } from "../../../services/ApiService";

export const getCaptchaInfo = createAsyncThunk(
  "content/getCaptchaInfo",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiService.getCaptchaInfo();

      if (response && response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);

export const getPageContent = createAsyncThunk(
  "content/getPageContent",
  async (contentData, { rejectWithValue }) => {
    try {
      const { locale, tag } = contentData;
      if (!locale || !tag) {
        return;
      }
      const response = await ApiService.getPageContent(
        locale.toUpperCase(),
        tag
      );
      if (response && response.status !== 200) {
        throw response;
      }
      const { data } = response;
      return { data, locale, tag };
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);

export const getAllCountryTags = createAsyncThunk(
  "content/getAllCountryTags",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiService.getAllCountryTags();

      if (response && response.status !== 200) {
        throw response;
      }
      const { data } = response;

      return data;
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);

export const getCountryInfo = createAsyncThunk(
  "content/getCountryInfo",
  async (countryTag, { rejectWithValue }) => {
    try {
      const response = await ApiService.getCountryByTag(countryTag);

      if (response && response.status !== 200) {
        throw response;
      }
      const { data } = response;

      return data;
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);

export const toggleBurger = createAction("content/togleBurger");
export const serverSeoSave = createAction("content/serverSeoSave");
export const serverCountrySave = createAction("content/serverCountrySave");
export const serverCountryTagsSave = createAction(
  "content/serverCountryTagsSave"
);
