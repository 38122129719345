export const pathNames = [
  "ping-ip",
  "generator-utm",
  "",
  "proxy-checker",
  "skaner-portov",
  "speed",
  "ipv6-support",
  "ip-in-spam-databases",
  "trace",
  "online-proxy-filter",
  "my-ip",
  "anonymity-check",
  "export",
  "klasterizaciya-zaprosov",
  "web-rtc-check",
  "fingerprint",
  "evercookie",
  "http-headers",
  "browser-info",
  "dns-check",
  "not-found"
];

export const filters = ["https", "http", "socks4", "anonimnyj"];
export const countries = [
  "united_states",
  "china",
  "taiwan",
  "ecuador",
  "colombia",
  "hong_kong",
  "indonesia",
  "india",
  "poland",
  "russian_federation",
  "france",
  "nepal",
  "netherlands",
  "brazil",
  "germany",
  "uzbekistan",
  "bosnia_and_herzegovina",
  "mexico",
  "singapore",
  "ukraine",
  "hungary",
  "vietnam",
  "czech_republic",
  "korea_republic_of",
  "kenya",
  "malaysia",
  "iran,_islamic_republic_of",
  "iran,_islamic_republic_of",
  "south_africa",
  "bulgaria",
  "japan",
  "greece",
  "canada",
  "thailand",
  "dominican_republic",
  "egypt",
  "bangladesh",
  "moldova_republic_of",
  "afganistan",
  "korea,_republic_of"
];
export const pathNamesOther = [
  "generator-utm",
  "speed",
  "ipv6-support",
  "ip-in-spam-databases",
  "online-proxy-filter",
  "trace",
  "web-rtc-check",
  "fingerprint",
  "evercookie",
  "http-headers",
  "browser-info",
  "dns-check"
];
