import { createAsyncThunk } from "@reduxjs/toolkit";

import { ApiService } from "../../../services/ApiService";

export const getDnsData = createAsyncThunk(
  "dns/getDns",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.getDnsLeakIp();

      return data;
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);
