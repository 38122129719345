const Icon = ({
  src,
  width = "48",
  height = "48",
  loading = "lazy",
  alt = ""
}) => (
  <img src={src} width={width} height={height} loading={loading} alt={alt} />
);

export default Icon;
