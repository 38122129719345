import { createSlice } from "@reduxjs/toolkit";

import { headersInitialState } from "./Headers.initialState";
import { getRequestHeaders } from "./Headers.operations";
import {
  getHeadersPendingReducer,
  getHeadersRejectReducer,
  getRequestHeadersFulfilledReducer
} from "./Headers.reducer";

const headersSlice = createSlice({
  name: "httpHeaders",
  initialState: headersInitialState,
  extraReducers: (builder) =>
    builder
      .addCase(getRequestHeaders.pending, getHeadersPendingReducer)
      .addCase(getRequestHeaders.fulfilled, getRequestHeadersFulfilledReducer)
      .addCase(getRequestHeaders.rejected, getHeadersRejectReducer)
});

export const HeadersReducerAction = {
  getRequestHeaders
};

export default headersSlice.reducer;
