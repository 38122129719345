import { forwardRef } from "react";

import { Container } from "../../../../ui";

import { Navigation } from "./components/Navigation/Navigation";

import "./HeaderBottom.scss";

export const HeaderBottom = forwardRef((props, ref) => {
  // **Props
  const { isHeaderFixed } = props;

  return (
    <div className="header-bottom--wrapper">
      <div
        ref={ref}
        className={
          isHeaderFixed ? "header-bottom header-bottom--fixed" : "header-bottom"
        }
      >
        <Container>
          <Navigation />
        </Container>
      </div>
    </div>
  );
});
