import { memo } from "react";

import { Link } from "react-router-dom";

const NotDropdownItem = ({
  route,
  lang,
  imgSrc,
  t,
  translation,

  isActive
}) => (
  <li className={`header-bottom__nav-item ${isActive ? "active" : ""}`}>
    <Link to={`/${lang ? lang : "ru"}/${route}/`}>
      <img
        src={imgSrc}
        loading="lazy"
        width="16"
        height="16"
        alt={t.navigation[translation]}
        aria-hidden="true"
      />
      {t.navigation[translation]}
    </Link>
  </li>
);

export default memo(NotDropdownItem);
