import styled from "@emotion/styled";

export const Wraper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  margin-bottom: 40px;
  border: 2px solid rgba(19, 20, 48, 0.65);
  border-radius: 4px;
  background-color: #131430;
  color: #fff;
  padding: 18px 30px 18px 30px;

  @media screen and (min-width: 925px) {
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    gap: 0;
    padding: 18px 60px 28px 40px;
  }
`;

export const Text = styled("p")`
  font-weight: 600;
  font-size: 20px;
  line-height: 1.35;
  margin-bottom: 12px;
`;

export const Ip = styled("p")`
  font-weight: 700;
  font-size: 32px;
  line-height: 1.37;
`;

export const LocationWraper = styled("div")`
  display: flex;
  align-items: center;
`;

export const Location = styled("p")`
  font-weight: 600;
  font-size: 20px;
  line-height: 1.35;
`;

export const Img = styled("img")`
  margin-right: 14px;
`;

export const PotentialWarning = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 260px;
  height: 48px;
  border-radius: 111px;
  background-color: ${({ warning }) => (warning ? "#dc674e" : "#38b000")};

  @media screen and (min-width: 500px) {
    width: 370px;
  }
`;

export const CheckingWarning = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 48px;
  border-radius: 111px;
  background-color: #fff;
  color: #000;

  @media screen and (min-width: 600px) {
    width: 370px;
  }
`;

export const WarningText = styled("p")`
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
`;
