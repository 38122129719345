export const getCaptchaInfoFulfilledReducer = (state, action) => {
  state.captcha.isDataLoaded = true;
  state.captcha.data = action.payload;
  state.captcha.isLoading = false;
};

export const getCaptchaInfoPendingReducer = (state) => {
  state.captcha.isLoading = true;
};

export const getCaptchaInfoRejectedReducer = (state, action) => {
  state.captcha.isDataLoaded = false;
  state.captcha.isFetchError = action.payload || true;
  state.captcha.isLoading = false;
};

export const getPageContentFulfilledReducer = (state, action) => {
  state.content.isDataLoaded = true;
  state.content.store = {
    ...state.content.store,
    [action.payload.tag]: {
      ...state.content.store[action.payload.tag],
      [action.payload.locale]: action.payload.data
    }
  };
  state.content.isLoading = false;
};

export const getPageContentPendingReducer = (state) => {
  state.content.isLoading = true;
};

export const getPageContentRejectedReducer = (state, action) => {
  state.content.isDataLoaded = false;
  state.content.isFetchError = action.payload || true;
  state.content.isLoading = false;
};

export const getAllCountryTagsFulfilledReducer = (state, action) => {
  state.countryTags.isDataLoaded = true;
  state.countryTags.data = action.payload;
};

export const getCountryInfoFulfilledReducer = (state, action) => {
  state.country.isDataLoaded = true;
  state.country.data = action.payload;
};

export const toggleBurgerReducer = (state, action) => {
  const { body } = document;
  if (action.payload) {
    state.content.burgerFlag = true;
    body.parentNode.style.overflow = "hidden";
    body.classList.add("lock");
  } else {
    state.content.burgerFlag = false;
    body.parentNode.style.overflow = "";
    body.classList.remove("lock");
  }
};

export const serverSeoSaveReducer = (state, action) => {
  state.content.isDataLoaded = true;
  state.content.store = {
    [action.payload.tag]: {
      [action.payload.locale]: action.payload.data
    }
  };
  state.content.isLoading = false;
};

export const serverCountrySaveReducer = (state, action) => {
  state.country.isDataLoaded = true;
  state.country.data = action.payload;
};

export const serverCountryTagsSaveReducer = (state, action) => {
  state.countryTags.isDataLoaded = true;
  state.countryTags.data = action.payload;
};
