import { Container } from "../../ui";

import { FooterLeft } from "./components/FooterLeft/FooterLeft";
import { Navigation } from "./components/Navigation/Navigation";

// Styles
import "./Footer.scss";

export const Footer = () => (
  <footer className="footer">
    <Container>
      <div className="footer__wrapper">
        <FooterLeft />
        <Navigation />
      </div>
    </Container>
  </footer>
);
