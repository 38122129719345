import { useEffect, useState } from "react";

import { compareIp } from "../utils/helpers";

import { useDispatchedActions } from "./useDispatchedActions";

export const useWebRtcStatus = (ips, storedIp, setIps) => {
  const [webRtcStatus, setWebRtcStatus] = useState(false);

  const { setWebRtc } = useDispatchedActions();

  useEffect(() => {
    if (ips && ips.length > 0) {
      setWebRtcStatus(true);
      setWebRtc();

      const compareIps = compareIp(ips[0].ip, storedIp);

      if (!compareIps && !ips.find((item) => item.ip === storedIp)) {
        setIps((prevState) => [
          ...prevState,
          { ip: storedIp, type: "IPv4", state: true }
        ]);
      }
    } else {
      setWebRtcStatus(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ips, storedIp]);

  return webRtcStatus;
};
