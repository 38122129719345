import { createSlice } from "@reduxjs/toolkit";

import { proxyInitialState } from "./Proxy.initialState";
import {
  allProxiesStatistic,
  changeFilterOptions,
  checkProxyById,
  clearFilterOptions,
  clearProxiesForExport,
  getAllProxiesForExport,
  getProxyTableData,
  removeProxy,
  saveProxiesStatistic,
  saveProxyTableData,
  setIsFirstFilter,
  setPrevFilterOptions
} from "./Proxy.operations";
import {
  allProxiesStatisticFulfilledReducer,
  allProxiesStatisticPendingReducer,
  allProxiesStatisticRejectedReducer,
  changeFilterOptionsReducer,
  checkProxyByIdFulfilledReducer,
  checkProxyByIdPendingReducer,
  checkProxyByIdRejectedReducer,
  clearFilterOptionsReducer,
  clearProxiesForExportReducer,
  getAllProxiesForExportFulfilledReducer,
  getAllProxiesForExportPendingReducer,
  getAllProxiesForExportRejectedReducer,
  getProxyTableDataFulfilledReducer,
  getProxyTableDataPendingReducer,
  getProxyTableDataRejectReducer,
  removeProxyReducer,
  saveProxiesStatisticReducer,
  saveProxyTableDataReducer,
  setIsFirstFilterReducer,
  setPrevFilterOptionsReducer
} from "./Proxy.reducer";

const proxySlice = createSlice({
  name: "proxy",
  initialState: proxyInitialState,

  extraReducers: (builder) =>
    builder
      .addCase(removeProxy, removeProxyReducer)
      .addCase(changeFilterOptions, changeFilterOptionsReducer)
      .addCase(clearFilterOptions, clearFilterOptionsReducer)
      .addCase(saveProxyTableData, saveProxyTableDataReducer)
      .addCase(saveProxiesStatistic, saveProxiesStatisticReducer)
      .addCase(setPrevFilterOptions, setPrevFilterOptionsReducer)
      .addCase(setIsFirstFilter, setIsFirstFilterReducer)
      .addCase(clearProxiesForExport, clearProxiesForExportReducer)

      .addCase(getProxyTableData.fulfilled, getProxyTableDataFulfilledReducer)
      .addCase(getProxyTableData.pending, getProxyTableDataPendingReducer)
      .addCase(getProxyTableData.rejected, getProxyTableDataRejectReducer)

      .addCase(
        allProxiesStatistic.fulfilled,
        allProxiesStatisticFulfilledReducer
      )
      .addCase(allProxiesStatistic.pending, allProxiesStatisticPendingReducer)
      .addCase(allProxiesStatistic.rejected, allProxiesStatisticRejectedReducer)

      .addCase(checkProxyById.fulfilled, checkProxyByIdFulfilledReducer)
      .addCase(checkProxyById.pending, checkProxyByIdPendingReducer)
      .addCase(checkProxyById.rejected, checkProxyByIdRejectedReducer)

      .addCase(
        getAllProxiesForExport.fulfilled,
        getAllProxiesForExportFulfilledReducer
      )
      .addCase(
        getAllProxiesForExport.pending,
        getAllProxiesForExportPendingReducer
      )
      .addCase(
        getAllProxiesForExport.rejected,
        getAllProxiesForExportRejectedReducer
      )
});

export const ProxyReducerActions = {
  getProxyTableData,
  allProxiesStatistic,
  checkProxyById,
  getAllProxiesForExport,
  removeProxy,
  changeFilterOptions,
  clearFilterOptions,
  saveProxyTableData,
  saveProxiesStatistic,
  setPrevFilterOptions,
  setIsFirstFilter,
  clearProxiesForExport
};
export default proxySlice.reducer;
