export const getBrowserVersion = (version) => {
  const versionArray = version.split(".");

  if (
    versionArray.length > 2 &&
    versionArray[versionArray.length - 1] === "null"
  ) {
    versionArray[versionArray.length - 1] = "0";
  }

  return versionArray.join(".");
};
