import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { MetaDate } from "../../components/common";
import { Container } from "../../components/ui";
import { useDispatchedActions } from "../../hooks";
import { collectServerSideState } from "../../utils/helpers/collectServerSideState";

const fetchProxiesForExport = () => {
  const proxiesForExport = JSON.parse(localStorage.getItem("proxiesForExport"));
  return proxiesForExport || [];
};

const ExportProxyPage = () => {
  const [exportProxy, setExportProxy] = useState([]);

  const { lang } = useParams();
  const { getPageContent } = useDispatchedActions();

  useEffect(() => {
    const getSeoData = () => {
      const TAG = "export";
      getPageContent({ locale: lang, tag: TAG });
    };

    getSeoData();

    const proxiesForExport = fetchProxiesForExport();
    setExportProxy(proxiesForExport);

    window.addEventListener("beforeunload", () => {
      localStorage.removeItem("proxiesForExport");
    });

    return () => {
      window.removeEventListener("beforeunload", () => {
        localStorage.removeItem("proxiesForExport");
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MetaDate />
      <Container>
        <div
          style={{
            wordWrap: "break-word",
            whiteSpace: "pre-wrap",
            padding: "10px"
          }}
        >
          {!exportProxy.length ? "No data" : exportProxy.join("\n")}
        </div>
      </Container>
    </>
  );
};

export default ExportProxyPage;

ExportProxyPage.getServerSideState = async (req, store, params) => {
  await collectServerSideState(params, store);
};
