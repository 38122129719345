import "./Button.scss";

export const Button = (props) => {
  // **Props
  const {
    btn,
    color,
    plain,
    children,
    style,
    disabled = false,
    ...rest
  } = props;

  const getClassNames = () => {
    let classes = "";

    if (btn) {
      classes += " section__btn";
    }

    if (color === "light") {
      classes += " section__btn--light";
    } else if (color === "dark") {
      classes += " section__btn--dark";
    }

    if (plain) {
      classes += " section__btn--plain";
    }

    return classes;
  };

  return (
    <button
      style={style}
      className={getClassNames()}
      {...rest}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
