import { redirect } from "react-router-dom";

import { ApiService } from "../services/ApiService";
import { filters } from "../utils/constants";

export const AppRoutes = [
  {
    path: ":lang/klasterizaciya-zaprosov/",
    tag: "claster",
    title: "Keyword grouping tool",
    description: "Keyword grouping tool",
    component: () => import("../pages/ClasterPage/ClasterPage")
  },
  {
    path: ":lang/proxy-checker/",
    tag: "proxy-checker",
    title: "Proxy checker page",
    description: "Proxy checker page",
    component: () => import("../pages/ProxyCheckerPage/ProxyCheckerPage")
  },
  {
    path: ":lang/skaner-portov/",
    tag: "check-ports",
    title: "Check ports page",
    description: "Check ports page",
    component: () => import("../pages/CheckPortPage/CheckPortPage")
  },
  {
    path: ":lang/ping-ip/",
    tag: "ping-ip",
    title: "Ping ip page",
    description: "Ping ip page",
    component: () => import("../pages/PingIpPage/PingIpPage")
  },
  {
    path: ":lang/generator-utm/",
    tag: "utm",
    title: "Utm page",
    description: "Utm page",
    component: () => import("../pages/UtmPage/UtmPage")
  },
  {
    path: ":lang/speed/",
    tag: "site-speed",
    title: "Site speed page",
    description: "Site speed page",
    component: () => import("../pages/SiteSpeedPage/SiteSpeedPage")
  },
  {
    path: ":lang/ipv6-support/",
    tag: "ipv6-check",
    title: "Ipv6 check page",
    description: "Ipv6 check page",
    component: () => import("../pages/Ipv6CheckPage/Ipv6CheckPage")
  },
  {
    path: ":lang/ip-in-spam-databases/",
    tag: "spam-base",
    title: "Spam base page",
    description: "Spam base page",
    component: () => import("../pages/SpamBasePage/SpamBasePage")
  },
  {
    path: ":lang/trace/",
    tag: "trace",
    title: "Trace page",
    description: "Trace page",
    component: () => import("../pages/TracePage/TracePage")
  },
  {
    path: ":lang/online-proxy-filter/",
    tag: "filter-proxy",
    title: "Filter proxy page",
    description: "Filter proxy page",
    component: () => import("../pages/FilterProxyPage/FilterProxyPage")
  },
  {
    path: ":lang/web-rtc-check/",
    tag: "web-rtc-check",
    title: "WebRTC check page",
    description: "WebRTC check page",
    component: () => import("../pages/WebRtcCheckPage/WebRtcCheckPage")
  },
  {
    path: ":lang/dns-check/",
    tag: "dns-check",
    title: "Dns check page",
    description: "Dns check page",
    component: () => import("../pages/DnsCheckPage/DnsCheckPage")
  },
  {
    path: ":lang/fingerprint/",
    tag: "fingerprint",
    title: "Fingerprint scanner page",
    description: "Fingerprint scanner page",
    component: () =>
      import("../pages/FingerPrintScannerPage/FingerPrintScannerPage")
  },
  {
    path: ":lang/evercookie/",
    tag: "evercookie",
    title: "Evercookie check page",
    description: "Evercookie check page",
    component: () => import("../pages/EvercookiePage/EvercookiePage")
  },
  {
    path: ":lang/http-headers/",
    tag: "http-headers",
    title: "Viewing HTTP headers page",
    description: "Viewing HTTP headers page",
    component: () => import("../pages/HttpHeadersPage/HttpHeadersPage")
  },
  {
    path: ":lang/browser-info/",
    tag: "browser-info",
    title: "Information from your browser page",
    description: "Information from your browser page",
    component: () => import("../pages/BrowserInfoPage/BrowserInfoPage")
  },
  {
    path: ":lang/my-ip/",
    tag: "my-ip",
    title: "My ip page",
    description: "My ip page",
    component: () => import("../pages/MyIpPage/MyIpPage")
  },
  {
    path: ":lang/anonymity-check/",
    tag: "anonimity-check",
    title: "Anonimity check page",
    description: "Anonimity check page",
    component: () => import("../pages/AnonimityCheckPage/AnonimityCheckPage")
  },
  {
    path: ":lang/:filter?",
    tag: "home",
    title: "Home page",
    description: "Home page",
    component: () => import("../pages/MainPage/MainPage"),
    loader: async (arg) => {
      const { params } = arg;

      if (params.filter) {
        const response = await ApiService.getAllCountryTags();

        if (response.status === 200) {
          const countryTags = response.data.map(({ countryTag }) => countryTag);
          const filterParams = [...filters, ...countryTags];
          const isFilterExists = filterParams?.indexOf(params.filter) !== -1;

          if (!isFilterExists) {
            return redirect(`/${params.lang}/not-found`);
          }
        }
      }

      return null;
    }
  }
];
