import { useCallback, useEffect, useRef, useState } from "react";

import { HeaderBottom } from "./components/HeaderBottom/HeaderBottom";
import { HeaderMobile } from "./components/HeaderMobile/HeaderMobile";
import { HeaderTop } from "./components/HeaderTop/HeaderTop";

import "./Header.scss";

export const Header = () => {
  const [isHeaderFixed, setisHeaderFixed] = useState(false);

  // Refs
  const headerTopRef = useRef();
  const headerBottomRef = useRef();

  const getStyles = () => {
    const styles = {};

    if (isHeaderFixed) {
      const margin = headerBottomRef?.current?.offsetHeight;

      styles.marginBottom = `${margin}px`;
    }
    return styles;
  };

  const scrollHandler = useCallback(
    () => {
      if (window.pageYOffset > headerTopRef?.current?.offsetHeight) {
        setisHeaderFixed(true);
      } else {
        setisHeaderFixed(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [headerTopRef?.current?.offsetHeight, setisHeaderFixed]
  );

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, [scrollHandler]);

  return (
    <header className="header" style={getStyles()}>
      <HeaderTop ref={headerTopRef} />
      <HeaderBottom ref={headerBottomRef} isHeaderFixed={isHeaderFixed} />

      <HeaderMobile />
    </header>
  );
};
