import styled from "@emotion/styled";

export const HeaderTopLayout = styled.div`
  border-bottom: 1px solid #ececec;
  padding-top: 9px;
  padding-bottom: 9px;

  @media (max-width: 1200px) {
    padding-top: 16px;
    padding-bottom: 16px;

    //.logotype {
    //    order: 1;
    //    margin-right: auto;
    //    margin-left: auto;
    //}
  }
`;
