import { ApiService } from "../../services/ApiService";
import { AllActions } from "../../store/reducers/AllActions";

export const collectServerSideState = async (params, store) => {
  let meta;

  // Client query
  // const { data: client } = await ApiService.getClientIpInfo();

  // Translation query
  const { data: langs } = await ApiService.getAllTranslation();

  // Dispatching to state
  store.dispatch(AllActions.saveTranslation(langs));

  // store.dispatch(AllActions.saveClientInfo(client));

  // save countryTags to state
  if (params.countryTags) {
    store.dispatch(AllActions.serverCountryTagsSave(params.countryTags));
  }

  // Seo query
  if (params.country) {
    const res = await ApiService.getCountryByTag(params.country);
    meta = res.data;

    store.dispatch(AllActions.serverCountrySave(res.data));
  } else {
    const res = await ApiService.getPageContent(
      params.locale.toUpperCase(),
      params.tag
    );
    meta = res.data;

    store.dispatch(
      AllActions.serverSeoSave({
        data: meta,
        locale: params.locale,
        tag: params.tag
      })
    );
  }
};
