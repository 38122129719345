export const findCountryFlagHelper = (countryName, countries) => {
  if (countryName) {
    const result = countries?.find(
      (country) => country.dataBaseValue === countryName
    );

    if (result) {
      return result.imgSrc;
    }
  }

  return "";
};
