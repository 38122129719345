export const Tr = {
  header: {
    ariaLabelMain: "Ana sayfaya git",
    altImgLogo: "Şirketin logosu FreeProxySale",
    other: "Başka",
    ariaLabelOther: "Ek menü öğelerinin genişleme oranı",
    close: "Kapalı"
  },
  footer: {
    logo: {
      alt: "Şirketin logosu Proxy-Sale"
    },
    rights: "Tüm hakları Saklıdır",
    lang: {
      title: "Dil seçiniz",
      selectLanguage: [
        { value: "de", label: "Almanca" },
        { value: "en", label: "İngilizce" },
        { value: "es", label: "İspanyolca" },
        { value: "fr", label: "Fransızca" },
        { value: "it", label: "İtalyanca" },
        { value: "pl", label: "Lehçe" },
        { value: "ru", label: "Rusça" },
        { value: "tr", label: "Türkçe" }
      ]
    },
    tools: "Aletler",
    company: "Şirket"
  },
  navigation: {
    freeProxy: "Ücretsiz proxy'ler",
    personalProxy: "Kişisel proxy'ler",
    proxyIpv6: "Vekil IPv6",
    articles: "Nesne",
    contacts: "Kişiler",
    proxyChecker: "Proxy denetleyicisi",
    myIp: "Benim IP",
    checkPorts: "Bağlantı Noktası Tarayıcı",
    pingIp: "Ping atmak IP",
    anonimityCheck: "Anonimliğim",
    utm: "UTM etiket oluşturucu",
    speed: "Hız IP",
    ipv6Check: "Destek IPv6",
    spamBase: "IP spam veritabanlarında",
    filterProxy: "Proxy filtresi",
    traceIp: "Izleme IP",
    claster: "Sorgu Kümeleme",
    webRtcChecker: "WebRTC kontrolü",
    dnsChecker: "DNS kontrolü",
    fingerPrint: "Parmak izi tarayıcısı",
    evercookie: "Evercookie kontrolü",
    httpHeaders: "HTTP başlıklarını görüntüleme",
    browserInfo: "Tarayıcı bilgileri",
    resedentialProxy: "Konut vekilleri",
    ispProxy: "İSS proxy'si"
  },
  mainPage: {
    proxyTable: {
      sidebarPart: {
        title: "Tarayıcı proxy ayarları:",
        countries: {
          placeholder: "Ülkeleri seçin"
        },
        type: {
          title: "Bir çeşit:"
        },
        anonimity: {
          title: "Anonim:"
        },
        export: {
          title: "İhracat:",
          ip: "IP:Port",
          excel: "Excel",
          window: "Yeni bir pencerede"
        },
        applyFilter: "Filtre uygula",
        clearFilter: "Temiz filtre",

        infoLeft: "Ücretsiz proxy'ler,",
        infoRight:
          "sosyal ağlarla çalışma, arama motorlarını ayrıştırma ve özel yazılımlarla çalışma olasılığını dışlar. Ücretsiz proxy listesinin kullanımından sorumlu değiliz. IP listesi tarafından sağlanmaktadır.яется в ознакомительных целях.",
        buyProxy: "Kişisel proxy satın alın",
        personalProxyAdvantage: [
          "Kişisel proxy'ler size yüksek anonimlik ve istikrar sağlayacaktır"
        ]
      }
    },
    faq: "Neredeyse her İnternet kullanıcısı bir proxy sunucusunun ne olduğunu ve neden gerekli olduğunu duydu. Özellikle popüler web kaynaklarının (sosyal ağlar, Yandex hizmetleri, video barındırma vb.) elbette. , çoğu ücretsiz proxy'lerle ilgileniyor. Ağda birçoğu var, ancak bunların nasıl doğru seçileceği ve ne için uygun oldukları ve hangi görevler için bir proxy satın almanın daha iyi olduğu her zaman açık değildir."
  },
  anonimityCheck: {
    mainTitle: "Benim anonimliğim",
    headText:
      "Bu hizmeti kullanarak ağda ne kadar anonim olduğunuzu, bilgisayarınız/tarayıcınız tarafından sağlanan verilerin IP adresinizin sağladığı verilerle ne kadar eşleştiğini kontrol edebilirsiniz.",
    top: {
      ip: "IP'niz :",
      from: "Nerelisin:",
      camouflage: "Kılık değiştir:",
      anon: "Anonimliğiniz:",
      restartButton: "Taramayı yeniden başlat",
      waitButton: "Yükleniyor",

      result: {
        title: "Sonucunuz:",
        notAnonymity: "Anonimlik yok",
        badAnonymity: "Kötü anonimlik",
        lackAnonymity: "Yetersiz anonimlik",
        sufficientAnonymity: "Yeterli anonimlik",
        checking: "Kontrol, lütfen bekleyin",
        highAnonymity: "Yüksek anonimlik"
      }
    },
    table: {
      titleIpInfo: "IP bilgisi",
      standardTimeZone: "Standart Saat Dilimi",
      anonymityTitle: "Anonimlik ve güvenlik",
      hour: "saat",
      device: "Cihaz",
      ip: "Benim IP",
      hideip: "Saklamak IP",
      host: "Sunucu/Sağlayıcı",
      country: "Ülke",
      city: "Şehir",
      index: "Dizin",
      coordinates: "Koordinatlar",
      os: "Işletim sistemi",
      browser: "Tarayıcı",
      timezone: "Saat dilimi",
      ipTime: "Zaman IP",
      systemTime: "Sistem zamanı",
      userAgent: "UserAgent",
      userAgentJs: "UserAgent JS",
      language: "Dil",
      languageJs: "Dil JS",
      screen: "Ekran",
      proxy: "Vekil",
      vpn: "VPN",
      tor: "Tor",
      anonimazer: {
        title: "Anonimizatör",
        info: "Portları kapatmak gereklidir. Açık portlar cihazınıza ciddi zarar verebilir veya zaten zarar vermiş olabilir."
      },
      cookies: {
        info: "Devre dışı bırakma, reklam ağları ve çeşitli kaynaklar tarafından izlemeyi önleyebilir, daha fazla gizlilik sağlar. Çerezleri devre dışı bırakmanız veya periyodik olarak temizlemeniz önerilir."
      },
      blackList: {
        title: "Kara liste",
        checks: "Çekler:",
        inBlackList: "Kara listeye alındı:",
        buyProxy: "Temiz bir proxy satın alın"
      },
      flash: "Flash",
      java: "Java",
      activeX: {
        title: "ActiveX",
        info: "ActiveX denetimi güvenlik açıklarına sahiptir ve bilgisayarınızı enfekte etmek için kullanılabilir. Ne kadar çok ActiveX denetimi yüklerseniz, sitelerin cihazınıza zarar vermek için sorunlarını kullanma olasılığı o kadar artar."
      },
      webRtc: "WebRTC",
      webRtcIp: "WebRTC IPs",
      openPorts: {
        title: "Açık bağlantı noktaları",
        links: "Port tarayıcı"
      },
      openPortsHTTP: {
        title: "Açık HTTP proxy bağlantı noktaları",
        info: "Bağlantı noktaları kapatılmalıdır. Açık bağlantı noktaları cihazınıza ciddi zarar verebilir veya zaten zarar vermiş olabilir."
      },
      openPortsVPN: {
        title: "Açık VPN Bağlantı Noktaları",
        info: "Bağlantı noktalarını kapatmak gereklidir. Açık bağlantı noktaları cihazınıza ciddi zarar verebilir veya zaten zarar vermiş olabilir."
      },
      openPortsWeb: "Web proxy bağlantı noktalarını açın",
      doNotTrack: {
        title: "DoNotTrack",
        info: "Bu seçeneği etkinleştirmek tarayıcının dijital parmak izine bazı benzersizlikler ekler.",
        link: "DNT'yi Etkinleştir"
      },
      dnsLeak: {
        title: "DNS Sızıntısı",
        info: "Üçüncü taraflar ağdaki tüm eylemlerinizi takip edebilir. DNS ayarlarının değiştirilmesi gerekmektedir.",
        links: "DNS'yi Değiştir"
      },
      suspiciousHeadlines: "Şüpheli başlıklar",
      suspiciousPorts: "Şüpheli bağlantı noktaları",
      twowayPing: "İki yönlü ping",
      replacementUserAgent: {
        title: "Kullanıcı Ajanı Değiştirme",
        info: "Cihazdan UserAgent oluşturan verilere dayanarak, veriler farklı ise, bu bir değiştirme işlemi gerçekleştiğini gösterir."
      },
      yourHeadings: "Başlıklarınız",
      showButton: {
        more: "Daha fazla göster",
        less: "Gizle"
      }
    },
    faq: {
      text: []
    }
  },
  checkPorts: {
    analis: "Analiz devam ediyor... Bekle.",
    port: "Port",
    status: "Durum",
    statusTrue: "mevcut",
    statusFalse: "kullanım dışı",
    description: "Tanım",
    portStatus: "Sunucudaki bağlantı noktası durumu",
    scanType: "Tarama tipi",
    mainTitle: "Açık bağlantı noktalarını çevrimiçi kontrol edin",
    videoTitle: "Port tarayıcı nedir ve neden?",
    ipTitle: "Görüntüle:",
    headText:
      "Açık portları tarayıcı ile kontrol etmek, sizin veya bir başkasının bilgisayarında/sitesinde hangi portların açık olduğunu ve bunların nelerden sorumlu olduğunu gösterir. Kontrol nmap tarayıcı üzerinden yapılır ve PC/siteye erişiminizin dış dünyaya ne kadar açık olduğunu gösterir. ",
    ipElem: {
      placeholder: "IP adresini veya etki alanını girin",
      insertIp: "IP adresimi yapıştır"
    },
    scanTypes: {
      title: "Taranan bağlantı noktası türleri:"
    },
    scanTitle: "Tarama tipi:",
    startScan: "Taramayı Başlat",
    resultCheck: "Test sonuçları:",
    resultCaption:
      "* Sonuç 'Ana Bilgisayar Kapatıldı' ise, bir güvenlik duvarı veya yönlendirici denetimi devam ediyor ve IP adreslerini engelliyor.",
    faq: {
      text: []
    }
  },
  claster: {
    mainTitle: "Çevrimiçi sorgu kümeleme",
    checker: {
      regionTitle: "Bölge kontrol ediliyor",
      selectPlaceholder: "Bölge seçin",
      clasterTitle: "Kümeleme eşiği",
      groupBtn: "Grup",
      queryTitle: "İstekler"
    },
    headText:
      "Bu hizmet sayesinde online sorgu kümeleme hizmetinize sunulmuştur. Bu sayede anahtar kelimeleri Yandex ve Google arama sonuçlarına göre otomatik olarak gruplayabilirsiniz. Yetkili site yapısı siteyi üst sıralara taşıma şansınızı artıracaktır. sorgu kümeleme hizmeti, anahtar kelimeleri sayfalar arasında en fazla SEO trafiğini alacak şekilde dağıtmanıza yardımcı olur."
  },
  filterProxy: {
    mainTitle: "Proxy filtresi",
    headText:
      "Proxy filtreleme, belirli önerileri seçmek için ana sunucuya bir proxy filtresi gönderme işlemidir.",
    filterOut: "Filter out",
    save: "Save to file"
  },
  ipv6: {
    mainTitle: "IPv6’nın desteklenip desteklenmediği konusunda sitelerin testi",
    headText:
      "Ağ üzerinde en fazla güvenliği sağlayan IPv6 protokolünün sürümü, daha verimli çalışması için yeni proxy protokolünü desteklemek için siteye özel bir aracın kullanılmasını gerektiriyor.",
    checker: {
      addressTitle: "Kontrol etmek için web sitesi adresini girin",
      checkProxyBtn: "Proxy'yi kontrol et",
      tip: "Test biraz zaman alabilir",
      resultTitle: "Test sonuçları:",
      result: {
        success: "Tebrikler, bu site destekliyor IPv6",
        error: "Üzgünüz, site şu anda IPv6'yı desteklemiyor"
      }
    },
    faq: {
      text: []
    }
  },
  myIp: {
    top: {
      title:
        "Ve bu, IP adresiniz tarafından izlenebilecek bilgilerin sadece küçük bir kısmı:",
      ipv4: "IPv4 adresiniz:",
      ipv6: "IPv6 adresiniz:",
      changeIp: "IP adresinizi değiştirin",
      table: {
        check: "Kontrol etmek",
        country: "Ülke",
        city: "Şehir",
        index: "Posta kodu",
        host: "Ev sahibi",
        system: "Sistem",
        browser: "Senin tarayıcın",
        from: "Nerelisin",
        provider: "Sağlayıcı",
        anon: "Anonimleştirici",
        proxy: "Vekil",
        protocol: "Protokol",
        imgAlt: {
          country: "Ülke",
          city: "Şehir",
          index: "Posta kodu",
          screen: "Monitör",
          os: "İşletim sistemi",
          browser: "Tarayıcı Sürümü",
          location: "Konum",
          server: "Sunucu",
          anon: "Anonimleştirici",
          proxy: "Vekil",
          protocol: "Protokol",
          blacklist: "Kara liste"
        }
      },
      map: {
        title: "Haritadaki konumunuz!",
        hide: "Konumunuzu gizleyebilirsiniz",
        text: "Basit, kullanışlı ve güvenilir aracımızı kullanmanız yeterlidir. Kişisel verileri gizleyin, trafiği koruyun ve İnternet'inizdeki tüm sınırları kaldırın",
        hideIp: "IP adresini gizle"
      },
      caution: {
        top: "Görüldüğü gibi IP konusunda oldukça detaylı bilgiler verilmektedir.",
        bottom:
          "İnternet'e bağlandığında, bir bilgisayara IP (İnternet Protokol Adresi) adı verilen bir numara atanır. Rastgele değil, sağlayıcıya açık ve ücretsiz olan IP atandığına dikkat edilmelidir. Bağlantı kesildiğinde IP değişir. yeniden bağlanıyor.Bu tür IP'lere dinamik denir.Eğer değişmemesini istiyorsanız, ISS'nize başvurun.Genellikle, dinamik olmayan bir ücret karşılığında sağlanır.Harici olarak da adlandırılır.Genellikle özel çevrimiçi programlar tarafından izlenir, ilgili sahibi hakkında bilgi. Dahili IP veya yerel, yerel ağlardaki bir PC'nin (dizüstü bilgisayarın) bireysel adresi olarak adlandırılır."
      }
    },
    faq: {
      text: []
    }
  },
  pingIp: {
    mainTitle: "Bir IP adresine veya web sitesine ping atmak",
    whatIsPing: "IP ping nedir ve neden?",
    headText: [
      "IP adresimi ekle butonuna tıklayın veya ping yapmak istediğiniz IP veya siteyi kullanın. 3-5 test paketi seçin ve 'Ping IP' butonuna tıklayın. 'Zaman' bölümünde istediğiniz değeri göreceksiniz. = bölüm.",
      "Ping 40-110 ms aralığında olduğunda normal kabul edilir. 110-210 ms'den itibaren online oyunlarda sorun yaşamaya başlarsınız ancak bunun site ziyaretlerine çok az etkisi olacaktır. Zaman aşımı 110 ms'yi geçerse o zaman bu, sağlayıcınızın yaptığı işin kalitesi hakkında düşünmek için bir nedendir."
    ],
    checker: {
      showTitle: "Görüntüle:",
      showSubtitle:
        "Geçerli bir IP adresi (0.0.0.0) veya ana bilgisayar adı (host.com) girin",
      ipInsert: "IP adresimi yapıştır",
      addTitle: "Paketlerin sayısı:",
      selectPlaceholder: "Bir ülke seç",
      startScan: "Taramayı Başlat",
      resultTitle: "Test sonuçları:",
      resultCaption:
        "* Sonuç 'Host Down' ise, bir güvenlik duvarı veya yönlendirici kontrolü yapılıyor ve IP adreslerini engelliyor."
    },
    faq: {
      text: []
    }
  },
  proxyChecker: {
    mainTitle: "Proxy denetleyicisi",
    condition: "Durum",
    ping: "Ping",
    type: "Tip",
    status: "Çalışmıyor",
    workStatus: "Mevcut",
    head: {
      videoTitle: "Proxy denetleyicisi nasıl ve neden kullanılır?",
      headText:
        "Proxy sunucularının geçerliliğini kontrol etmek için çevrimiçi proxy denetleyicisi. Kullanılabilirlik, ülke, hız, anonimlik, tür için bir proxy veya bir adres grubunun analizi.",
      titleFirst: "Proxy denetleyicisi:",
      listFirst: [
        "ülke sahipliği için IPv4, IPv6 kontrol ediliyor;",
        "HTTP/S'yi, SOCKS5 protokol türünü ve mevcut hızı tanımlama;",
        "adres anonimlik kontrolü;",
        "64 iş parçacığında grup, yüksek hızlı çok iş parçacıklı proxy denetimi;",
        "Listeyi yükleme ve kontrolün sonucunu kaldırma yeteneği;",
        "görüntü filtresini kullanarak sonuçların uygun çıktısı."
      ],
      titleSecond: "Denetleyiciye hangi biçimde proxy eklenecek?",
      listSecond: [
        {
          content:
            "Eğer varsa <span>genel vekiller</span> (kullanıcı adı ve şifre olmadan) — IP:PORT"
        },
        {
          content:
            "Eğer varsa <span>özel proxyler</span> (giriş yaparak yetkilendirme ile ve parola), ardından — IP:PORT:USER:PASS"
        }
      ],
      titleThird:
        "P.S.: Eğer sen <a href='https://proxy-sale.com/' rel='noreferrer' target='_blank'> bir proxy satın aldı </a>bizimle, onlar özeldir!"
    },
    faq: {
      text: []
    }
  },
  siteSpeed: {
    mainTitle: "Proxy üzerinden site erişim hızını kontrol edin",
    head: {
      listFirst: [
        "HTTP ve SOCKS proxy'leri üzerinden veri aktarım hızının belirlenmesi",
        "Sayfa yükleme hızını doğrudan belirleme yeteneği",
        "Site erişimini kontrol edin"
      ],
      caution: "Kontrol edilecek format?",
      subtitle:
        "Bir proxy üzerinden hızı kontrol etmek için, erişmeniz gereken kaynağı ve biçimdeki IP adresini kullanmanız gerekir:",
      listSecond: [
        {
          text: "Genel proxyler (oturum açma ve parola olmadan), ardından —",
          value: "IP:PORT"
        },
        {
          text: "Özel proxyler (oturum açma ve şifre ile yetkilendirme ile), ardından —",
          value: "IP:PORT:USER:PASS"
        }
      ],
      post: "P.S.: Eğer sen bir proxy satın aldı bizimle, onlar özeldir!"
    },
    checker: {
      adress: "Hız testi için web sitesi adresini girin",
      proxy: "Biçimdeki proxy adresi",
      format: "127.0.0.1:8080",
      subtitle: [
        "Proxy üzerinden veri aktarım hızını test etmek için proxy sunucusunun adresini ve portunu girin",
        "Normal bir sayfa yükleme hızı testi için bu alanı boş bırakın"
      ],
      type: {
        title: "Proxy türü"
      },
      timeout: {
        title: "Zaman aşımı:",
        placeholder: "Zaman aşımı"
      },
      access: {
        title: "Site erişimini kontrol edin",
        body: "Erişim ve hız kontrolü biraz zaman alabilir"
      },
      btn: "Hızı Kontrol Et",
      result: {
        title: "Test sonuçları:",
        result: {
          titleFirst: "Kontrol - bir proxy aracılığıyla siteye erişim hızı",
          checkSite: "Site kontrolü",
          checkProxy: "Proxy kontrolü",
          timeout: "Zaman aşımı",
          sec: "saniye",
          amountOfDownloads: "Üretilen test indirmeleri",
          titleSecond: "Doğrulama tamamlandı",
          averageSpeed: "Ortalama indirme hızı",
          generalSpeed: "Genel ortalama hız",
          speedValue: "KB/sn",
          access: "Siteye erişim - yüklenen sayfadan başlık (Başlık)",
          emptyTitle: "Başlık alınamadı",
          isProxyUsing: {
            yes: "Proxy kullanma",
            no: "Proxy yok, doğrudan indirin"
          }
        }
      }
    },
    faq: {
      text: []
    },
    errors: {
      url: "Web sitesi adresini girin"
    }
  },
  spamBase: {
    mainTitle: "IP’nin mevcüt olması ve spam veritabanlarında control edilmesi",
    head: {
      text: "Bildiğiniz gibi, istenmeyen e-postalar genellikle IP'nin kara listeye alınmasına neden olabilir ve bu da tüm ağ işlemlerini belirgin şekilde kesintiye uğratır. Bunu önlemek için spam veritabanlarında IP kontrol sunucusunu kullanmalısınız."
    },
    checker: {
      ip: {
        title: "Doğru IP adresini girin",
        insert: "IP adresimi yapıştır"
      },
      check: "Muayene",
      test: "Test biraz zaman alabilir",
      list: {
        checkedAll: "Çekler:",
        checked: "Kontrol:",
        blackList: "Kara listeye alındı:"
      },
      result: {
        listed: "Listelenmiş",
        notListed: "Listelenmemiş"
      }
    },
    faq: {
      text: []
    }
  },
  trace: {
    mainTitle: "IP izleme",
    head: {
      text: "Bu sayfada barındırılan hizmet sayesinde, bir IP adresinin veya bir sitenin ücretsiz ve neredeyse anında izlenmesine erişiminiz var. bir tarayıcıda bir web kaynağı açarsınız. İzlemeyi kullanarak, sitenin yavaş yüklenmesiyle ilgili olası sorunların nedenlerini belirleyebileceksiniz."
    },
    checker: {
      ip: "Geçerli bir IP adresi (0.0.0.0) veya ana bilgisayar adı (domain.ru) girin",
      route: {
        title: "Ülkeye göre paket rotasını göster",
        placeholder: "Bir öğe seçin"
      },
      btn: "Izleme IP'si",
      result: "Test sonuçları:"
    }
  },
  webRtcCheck: {
    mainTitle: "WebRTC kontrolü",
    head: {
      text: "WebRTC aracılığıyla sızıntı kontrolü yapılıyor. Bir VPN'e bağlı olsanız bile verileriniz risk altında olabilir."
    },
    top: {
      myIp: "IP adresiniz:",
      from: "Nerelisin:",
      state: "Durum:",
      warning: "Potansiyel bir tehdit"
    },
    info: {
      top: "Gördüğünüz gibi oldukça detaylı bilgiler IP tarafından sağlanıyor.",
      bottom:
        "Tarayıcınızda WebRTC'nin etkin olup olmadığını ve gerçek bir IP adresiniz olup olmadığını kontrol ettik. Görünüşe göre potansiyel bir sızıntı riski var, WebRTC etkin ve gerçek IP adresinizi açığa çıkarabilir.",
      disableBottom:
        "Tarayıcınızda WebRTC'nin etkin olup olmadığını ve gerçek bir IP adresiniz olup olmadığını kontrol ettik. Görünen o ki, gerçek IP adresiniz bilinmiyor ve tarayıcınızda WebRTC etkinleştirilmiyor."
    },
    resetButton: "WebRTC sızıntı testini yeniden başlatın",
    status: {
      type: "Tip",
      state: "Durum",
      topText: "WebRTC etkin",
      bottomText: "Gerçek IP adresinizi ifşa etme tehdidi!",
      warning: "Sızıntı tehdidi",
      checking: "Kontrol edin, lütfen bekleyin"
    }
  },
  dnsCheck: {
    top: {
      myIp: "IP adresiniz:",
      from: "Nerelisin:",
      state: "Durum:",
      warning: "Potansiyel bir tehdit"
    },
    info: {
      top: "Gördüğünüz gibi, IP'nizin kontrol edilmesi sırasında DNS sızıntısı tespit edildi.",
      bottom:
        "IP'nizi taradık ve DNS üzerinden bir sızıntı tespit ettik. İstekleriniz ifşa edildi ve bağlantı güvenli değil. Bu, DNS yöneticinizin kullandığınız web siteleri ve programlar hakkında bilgi takip edebileceği anlamına gelir.",
      disableBottom:
        "IP'nizi taradık ve DNS üzerinden herhangi bir sızıntı tespit etmedik. İstekleriniz ifşa edilmedi ve bağlantı güvenlidir. Bu, DNS yöneticinizin kullandığınız web siteleri ve programlar hakkında bilgi takip edemeyeceği anlamına gelir."
    },
    resetButton: "DNS sızıntı testini yeniden başlatın",
    status: {
      type: "Tip",
      state: "Durum",
      topText: "DNS etkin",
      bottomText: "İsteklerinizi açığa çıkarma tehdidi!",
      warning: "Sızıntı tehdidi",
      checking: "Kontrol edin, lütfen bekleyin"
    }
  },
  fingerPrintScanner: {
    mainTitle: "Tarayıcı Parmak İzi Tarayıcı",
    head: {
      text: "Tarayıcı parmak izleri, bilgisayarınızın ayarlarının benzersiz bir anlık görüntüsüdür ve özel web sitesi mekanizmaları sizi izleyebilir."
    },
    top: {
      myIp: "IP adresiniz:",
      fingerprint: "Tarayıcı Parmak İzi:"
    },
    table: {
      location: "Konum",
      provider: "Sağlayıcı",
      host: "Ev sahibi",
      os: "Işletim sistemi",
      browser: "Tarayıcı",
      plugin: "Eklenti:",
      status: "Durum:",
      isOn: "etkinleştirilmiş",
      isOff: "kapalı",
      device: "Cihaz",
      browserLanguage: "Tarayıcı dili",
      screenSize: "Ekran boyutu",
      timeZone: "Saat dilimi"
    },
    imgAlt: {
      location: "Konum",
      provider: "Sağlayıcı",
      host: "Ev sahibi",
      os: "işletim sistemi",
      browser: "Tarayıcı",
      language: "Tarayıcı dili",
      size: "Ekran boyutu",
      timeZone: "Saat dilimi"
    },
    bottom: {
      method: "JS yöntemi"
    }
  },
  createEvercookie: {
    mainTitle: "Evercookie kontrolü",
    head: {
      text: "Bir evercookie oluşturmak için düğmeye tıklayın. Anonimliğinizden ödün verilmeyecektir: Çerez, evercookies'i test etmek için kullandığımız 1 ile 1000 arasında rastgele bir sayıdır. Bunu kullandığınızı takip etmiyoruz."
    },
    top: {
      button: "Evercookie'yi oluştur"
    }
  },
  httpHeaders: {
    requestHeaders: "Başlıkları Talep Et",
    responseHeaders: "Yanıt Başlıkları",
    submitButton: "İstek gönderin"
  },
  browserInfo: {
    mainTitle: "Tarayıcınızdan gelen bilgiler",
    head: {
      text:
        "Tarayıcıyı kullanarak çeşitli siteler ve hizmetler kullanıcıyı takip etme olanağına sahiptir. Bu, örneğin bir kişinin IP adresini değiştirmiş olsa bile kimliğinin belirlenmesi için gereklidir. Reklam sistemleri ayrıca kullanıcının ilgi alanlarını bilmek için izleme yeteneklerini de kullanır.\n" +
        "Hizmetimiz, tarayıcınızda hangi izleme yöntemlerinin etkinleştirildiğini belirlemenize yardımcı olacaktır."
    },
    table: {
      type: "Tip",
      discovered: "Keşfetti",
      cookieMeaning: "Çerez anlamı",
      parameter: "Parametre",
      value: "Değer",
      resolution: "Ekran çözünürlüğü:",
      desktop: "Masaüstü",
      colorDepth: "Renk derinliği",
      bit: "biraz"
    },
    button: {
      createCookie: "Çerez oluştur",
      userTracking: "Kullanıcı takibi"
    }
  },
  utm: {
    mainTitle: "Çevrimiçi UTM etiket oluşturucu",
    checker: {
      linkUtm: "UTM etiketli bağlantı burada görünecek",
      copy: "Kopyala",
      shortUrl: "Kısa URL",
      traffic: [
        { text: "Google ADS", source: "google" },
        { text: "Яндекс.Директ", source: "yandex" },
        { text: "VK.com", source: "vk" },
        { text: "Facebook", source: "facebook" },
        { text: "Instagram", source: "instagram" },
        { text: "Target.Mail.ru", source: "targetmailru" },
        { reset: true, text: "Sıfırla" }
      ]
    },
    faq: {
      text: []
    }
  },
  pageHead: {
    ip: "Senin IP",
    changeIp: "Değiştirmek IP",
    anon: "Anonimliğiniz",
    moreAnon: "Anonimlik hakkında daha fazla bilgi edinin"
  },
  checker: {
    country: "Bir ülke seç:",
    add: "Önce ekle:",
    show: {
      title: "Görüntüle"
    },
    displayType: "Görüntüle",
    checkProxy: "Proxy'yi kontrol et",
    fileName: ".txt veya .csv'den yükleyin",
    typeProxy: {
      title: "Proxy girin:",
      placeholder: "Metni buraya yazın"
    },
    info: {
      text: "Kendinizi kısıtlamalardan ve rahatsızlıklardan, yasaklanma veya IP adresinizi sürekli değiştirme riskinden kurtarın. Sosyal ağlar, Avito, Anahtar Toplayıcı ve diğer amaçlarla çalışmak için kişisel vekiller.",
      buy: "Koruma satın al"
    },
    results: {
      title: "Test sonuçları",
      checked: "Kontrol",
      working: "Çalışan",
      notWorking: "Calışmıyor",
      divider: "itibaren",
      btn: "Bir işçi satın al"
    },
    download: {
      title: "Yalnızca çalışan proxy'leri indirin",
      txt: ".txt olarak indir",
      csv: ".csv'deki tablo"
    }
  },
  common: {
    faqTitle: "Sorularınızı cevaplıyoruz",
    yes: "Evet",
    no: "Değil",
    notDefined: "Tanımsız",
    copy: "Kopyalandı!",
    select: {
      placeholderCountry: "Bir ülke seç"
    },
    buyAnonProxy: "Anonim satın al",
    buyWorkingProxy: "Bir işçi satın al"
  },
  table: {
    top: {
      workProxy: "Çalışan proxy'ler",
      anon: "Anonim",
      socks: "Socks 4/5",
      http: "HTTP/HTTPS",
      proxyInBase: "Veritabanındaki proxy'ler"
    },
    tableColumns: {
      copy: "Panoya kopyala",
      country: "Ülke",
      type: "Bir çeşit",
      anon: {
        title: "Anonimlik",
        speed: "Hız",
        s: "с",
        ms: "ms",
        m: "m",
        h: "h",
        types: {
          anon: "Anonim",
          proz: "Şeffaf",
          elite: "Seçkinler",
          notFounded: "Bulunamadı"
        }
      },
      check: {
        title: "Muayene",
        tip: "İpucu",
        buy: "Bir işçi satın al",
        reload: "Tekrar yükle",
        ago: "dakika önce",
        status: {
          work: "Çalışan",
          notWorked: "Çalışmıyor"
        }
      },
      condition: {
        title: "Durum"
      },
      login: "Giriş yapmak",
      password: "Şifre",
      pagination: {
        rowsPerPage: "Sayfa başına proxy",
        separatorText: "itibaren"
      },
      requests: "İstekler",
      requestsCluster: "Bir kümedeki istekler",
      competitors: "Yarışmacılar"
    },
    noDataComponent: "Vekalet yok"
  },
  errorTranslation: {
    somethingWrong: "Bir şeyler ters gitti, tekrar deneyin",
    largeProxyList: "Doğrulama için proxy sayısı 100'ü geçmemelidir",
    emptyProxy: "Dışa aktarma için eksik proxy'ler",
    apiError: "Bir şeyler yanlış gitti!",
    wrongFile: "Geçersiz dosya türü. Yalnızca .txt veya .csv'ye izin verilir",
    nonReadableFile: "Dosya okunamıyor!",
    emptyProxyList: "Proxy girin!",
    emptyClusterQuery: "İstekleri Girin!",
    clusterQueryMinValue: "En az 2 sorgu girin!",
    incorrectIpOrDomain: "Yanlış IP veya Etki Alanı!",
    incorrectIp: "Yanlış IP! ",
    proxyFilterEmpty: "Proxy girin!",
    proxyIsEmpty: "Bu ülkede vekil yok",
    proxyFilterIncorrect: "Yanlış proxyler!",
    emptyIpOrDomain: "Geçerli bir IP adresi veya etki alanı girin!",
    emptySiteUrl: "Site URL'sini girin!",
    emptyUserAgent: "Kullanıcı ajanı seçiniz!",
    invalidUrl: "Geçersiz URL formatı!",
    timeError: "Birkaç dakika içinde deneyin!",
    IncorrectUrlFormat: "Yanlış URL biçimi!"
  },
  anonimityTypes: [
    {
      value: "an-anonim.txt",
      label: "Anonim",
      defaultChecked: false,
      route: "anonimnyj"
    },
    {
      value: "el-elit.txt",
      label: "Seçkinler",
      defaultChecked: false,
      route: "elite"
    },
    {
      value: "pr-proz.txt",
      label: "Şeffaf",
      defaultChecked: false,
      route: "transparent"
    }
  ],
  browsers: [
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/chrome.svg",
      value: "Google Chrome"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/safari.svg",
      value: "Safari"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/opera.svg",
      value: "Opera"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/firefox.svg",
      value: "Firefox"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/yandex.svg",
      value: "Yandex"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/android.svg",
      value: "Android"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/explorer.svg",
      value: "Internet Explorer"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/browser1.svg",
      value: "Browser"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      value: "+",
      last: true
    }
  ],
  checkerProxyTypes: [
    {
      value: "HTTP,HTTPS",
      label: "HTTP/HTTPS",
      defaultChecked: true
    },
    {
      value: "SOCKS4,SOCKS5",
      label: "SOCKS 4/5",
      defaultChecked: true
    },
    {
      value: "NO",
      label: "Calışmayan",
      defaultChecked: true
    }
  ],
  checkPortsTypes: [
    {
      value: "POPULAR",
      label: "Popüler Portlar",
      defaultChecked: true
    },
    {
      value: "SERVER",
      label: "Sunucu Portları",
      defaultChecked: false
    },
    {
      value: "GAME",
      label: "Oyun Portları",
      defaultChecked: false
    },
    {
      value: "APPLICATION",
      label: "Uygulama Portları",
      defaultChecked: false
    },
    {
      value: "P2P",
      label: "P2P Portları",
      defaultChecked: false
    }
  ],
  utmInputs: [
    {
      label: "URL Açılış sayfası",
      name: "url",
      placeholder: "Bağlantıyı veya açılış sayfasını buraya ekleyin"
    },
    {
      label: "UTM-source*",
      name: "source",
      placeholder: "Trafik kaynağı. Örneğin: google"
    },
    {
      label: "UTM-medium*",
      name: "medium",
      placeholder: "Trafik türü. Örneğin: cpc, email, banner"
    },
    {
      label: "UTM-campaign*",
      name: "campaign",
      placeholder: "örneğin reklam şirketi: google-poisk"
    },
    {
      label: "UTM-content",
      name: "content",
      placeholder: "Örneğin: cpc, email, banner"
    },
    {
      label: "UTM-term",
      name: "term",
      placeholder: "Anahtar kelime. Örneğin: makro (keyword)"
    }
  ],
  yesNo: [
    { label: "Evet", value: true },
    { label: "Değil", value: false }
  ],
  timeout: [
    { label: "10 ikinci", value: 10 },
    { label: "15 ikinci", value: 15 },
    { label: "30 ikinci", value: 30 },
    { label: "60 ikinci", value: 60 }
  ],
  countries: [
    {
      dataBaseValue: "Andorra",
      value: "Andora",
      label: "Andora",
      imgSrc: "/img/flags/ad.svg"
    },
    {
      dataBaseValue: "UAE",
      value: "BAE",
      label: "BAE",
      imgSrc: "/img/flags/ae.svg"
    },
    {
      dataBaseValue: "Afghanistan",
      value: "Afganistan",
      label: "Afganistan",
      imgSrc: "/img/flags/af.svg"
    },
    {
      dataBaseValue: "Antigua and Barbuda",
      value: "Antigua ve Barbuda",
      label: "Antigua ve Barbuda",
      imgSrc: "/img/flags/ag.svg"
    },
    {
      dataBaseValue: "Anguilla",
      value: "Anguilla",
      label: "Anguilla",
      imgSrc: "/img/flags/ai.svg"
    },
    {
      dataBaseValue: "Albania",
      value: "Arnavutluk",
      label: "Arnavutluk",
      imgSrc: "/img/flags/al.svg"
    },
    {
      dataBaseValue: "Armenia",
      value: "Ermenistan",
      label: "Ermenistan",
      imgSrc: "/img/flags/am.svg"
    },
    {
      dataBaseValue: "Antilles",
      value: "Antiller",
      label: "Antiller",
      imgSrc: "/img/flags/an.svg"
    },
    {
      dataBaseValue: "Angola",
      value: "Angola",
      label: "Angola",
      imgSrc: "/img/flags/ao.svg"
    },
    {
      dataBaseValue: "Antarctic",
      value: "Antarktika",
      label: "Antarktika",
      imgSrc: "/img/flags/aq.svg"
    },
    {
      dataBaseValue: "Argentina",
      value: "Arjantin",
      label: "Arjantin",
      imgSrc: "/img/flags/ar.svg"
    },
    {
      dataBaseValue: "American Samoa",
      value: "Amerikan Samoası",
      label: "Amerikan Samoası",
      imgSrc: "/img/flags/as.svg"
    },
    {
      dataBaseValue: "Austria",
      value: "Avusturya",
      label: "Avusturya",
      imgSrc: "/img/flags/at.svg"
    },
    {
      dataBaseValue: "Australia",
      value: "Avustralya",
      label: "Avustralya",
      imgSrc: "/img/flags/au.svg"
    },
    {
      dataBaseValue: "Aruba",
      value: "Aruba",
      label: "Aruba",
      imgSrc: "/img/flags/aw.svg"
    },
    {
      dataBaseValue: "Åland Islands",
      value: "Aland adaları",
      label: "Aland adaları",
      imgSrc: "/img/flags/ax.svg"
    },
    {
      dataBaseValue: "Azerbaijan",
      value: "Azerbaycan",
      label: "Azerbaycan",
      imgSrc: "/img/flags/az.svg"
    },
    {
      dataBaseValue: "Bosnia and Herzegovina",
      value: "Bosna Hersek",
      label: "Bosna Hersek",
      imgSrc: "/img/flags/ba.svg"
    },
    {
      dataBaseValue: "Barbados",
      value: "Barbados",
      label: "Barbados",
      imgSrc: "/img/flags/bb.svg"
    },
    {
      dataBaseValue: "Bangladesh",
      value: "Bangladeş",
      label: "Bangladeş",
      imgSrc: "/img/flags/bd.svg"
    },
    {
      dataBaseValue: "Belgium",
      value: "Belçika",
      label: "Belçika",
      imgSrc: "/img/flags/be.svg"
    },
    {
      dataBaseValue: "Burkina Faso",
      value: "Burkina Faso",
      label: "Burkina Faso",
      imgSrc: "/img/flags/bf.svg"
    },
    {
      dataBaseValue: "Bulgaria",
      value: "Bulgaristan",
      label: "Bulgaristan",
      imgSrc: "/img/flags/bg.svg"
    },
    {
      dataBaseValue: "Bahrain",
      value: "Bahreyn",
      label: "Bahreyn",
      imgSrc: "/img/flags/bh.svg"
    },
    {
      dataBaseValue: "Burundi",
      value: "Burundi",
      label: "Burundi",
      imgSrc: "/img/flags/bi.svg"
    },
    {
      dataBaseValue: "Benin",
      value: "Benin",
      label: "Benin",
      imgSrc: "/img/flags/bj.svg"
    },
    {
      dataBaseValue: "Saint Barthélemy",
      value: "Aziz Barthelemy",
      label: "Aziz Barthelemy",
      imgSrc: "/img/flags/bl.svg"
    },
    {
      dataBaseValue: "Bermuda",
      value: "Bermuda",
      label: "Bermuda",
      imgSrc: "/img/flags/bm.svg"
    },
    {
      dataBaseValue: "Brunei",
      value: "Brunei",
      label: "Brunei",
      imgSrc: "/img/flags/bn.svg"
    },
    {
      dataBaseValue: "Bolivia",
      value: "Bolivya",
      label: "Bolivya",
      imgSrc: "/img/flags/bo.svg"
    },
    {
      dataBaseValue: "Bonaire",
      value: "Bonaire",
      label: "Bonaire",
      imgSrc: "/img/flags/bq.svg"
    },
    {
      dataBaseValue: "Brazil",
      value: "Brezilya",
      label: "Brezilya",
      imgSrc: "/img/flags/br.svg"
    },
    {
      dataBaseValue: "Bahamas",
      value: "Bahamalar",
      label: "Bahamalar",
      imgSrc: "/img/flags/bs.svg"
    },
    {
      dataBaseValue: "Butane",
      value: "Bütan",
      label: "Bütan",
      imgSrc: "/img/flags/bt.svg"
    },
    {
      dataBaseValue: "Bouvet Island",
      value: "Bouvet Adası",
      label: "Bouvet Adası",
      imgSrc: "/img/flags/bv.svg"
    },
    {
      dataBaseValue: "Botswana",
      value: "Botsvana",
      label: "Botsvana",
      imgSrc: "/img/flags/bw.svg"
    },
    {
      dataBaseValue: "Belarus",
      value: "Belarus",
      label: "Belarus",
      imgSrc: "/img/flags/by.svg"
    },
    {
      dataBaseValue: "Belize",
      value: "Belize",
      label: "Belize",
      imgSrc: "/img/flags/bz.svg"
    },
    {
      dataBaseValue: "Canada",
      value: "Kanada",
      label: "Kanada",
      imgSrc: "/img/flags/ca.svg"
    },
    {
      dataBaseValue: "Coconut islands",
      value: "Hindistan cevizi adaları",
      label: "Hindistan cevizi adaları",
      imgSrc: "/img/flags/cc.svg"
    },
    {
      dataBaseValue: "DR Congo",
      value: "DR Kongo",
      label: "DR Kongo",
      imgSrc: "/img/flags/cd.svg"
    },
    {
      dataBaseValue: "Central African Republic",
      value: "Orta Afrika Cumhuriyeti",
      label: "Orta Afrika Cumhuriyeti",
      imgSrc: "/img/flags/cf.svg"
    },
    {
      dataBaseValue: "Congo",
      value: "Kongo",
      label: "Kongo",
      imgSrc: "/img/flags/cg.svg"
    },
    {
      dataBaseValue: "Switzerland",
      value: "İsviçre",
      label: "İsviçre",
      imgSrc: "/img/flags/ch.svg"
    },
    {
      dataBaseValue: "Ivory Coast",
      value: "Fildişi Sahili",
      label: "Fildişi Sahili",
      imgSrc: "/img/flags/ci.svg"
    },
    {
      dataBaseValue: "Cook Islands",
      value: "Cook Adaları",
      label: "Cook Adaları",
      imgSrc: "/img/flags/ck.svg"
    },
    {
      dataBaseValue: "Chile",
      value: "Şili",
      label: "Şili",
      imgSrc: "/img/flags/cl.svg"
    },
    {
      dataBaseValue: "Cameroon",
      value: "Kamerun",
      label: "Kamerun",
      imgSrc: "/img/flags/cm.svg"
    },
    {
      dataBaseValue: "China",
      value: "Çin",
      label: "Çin",
      imgSrc: "/img/flags/cn.svg"
    },
    {
      dataBaseValue: "Colombia",
      value: "Kolombiya",
      label: "Kolombiya",
      imgSrc: "/img/flags/co.svg"
    },
    {
      dataBaseValue: "Costa Rica",
      value: "Kosta Rika",
      label: "Kosta Rika",
      imgSrc: "/img/flags/cr.svg"
    },
    {
      dataBaseValue: "Cuba",
      value: "Küba",
      label: "Küba",
      imgSrc: "/img/flags/cu.svg"
    },
    {
      dataBaseValue: "Cape Verde",
      value: "Yeşil Burun Adaları",
      label: "Yeşil Burun Adaları",
      imgSrc: "/img/flags/cv.svg"
    },
    {
      dataBaseValue: "Curacao",
      value: "Curacao",
      label: "Curacao",
      imgSrc: "/img/flags/cw.svg"
    },
    {
      dataBaseValue: "Christmas Island",
      value: "Noel Adası",
      label: "Noel Adası",
      imgSrc: "/img/flags/cx.svg"
    },
    {
      dataBaseValue: "Cyprus",
      value: "Kıbrıs",
      label: "Kıbrıs",
      imgSrc: "/img/flags/cy.svg"
    },
    {
      dataBaseValue: "Czech Republic",
      value: "Çek",
      label: "Çek",
      imgSrc: "/img/flags/cz.svg"
    },
    {
      dataBaseValue: "Germany",
      value: "Almanya",
      label: "Almanya",
      imgSrc: "/img/flags/de.svg"
    },
    {
      dataBaseValue: "Djibouti",
      value: "Cibuti",
      label: "Cibuti",
      imgSrc: "/img/flags/dj.svg"
    },
    {
      dataBaseValue: "Denmark",
      value: "Danimarka",
      label: "Danimarka",
      imgSrc: "/img/flags/dk.svg"
    },
    {
      dataBaseValue: "Dominica",
      value: "Dominika",
      label: "Dominika",
      imgSrc: "/img/flags/dm.svg"
    },
    {
      dataBaseValue: "Dominican Republic",
      value: "Dominik Cumhuriyeti",
      label: "Dominik Cumhuriyeti",
      imgSrc: "/img/flags/do.svg"
    },
    {
      dataBaseValue: "Algeria",
      value: "Cezayir",
      label: "Cezayir",
      imgSrc: "/img/flags/dz.svg"
    },
    {
      dataBaseValue: "Ecuador",
      value: "Ekvador",
      label: "Ekvador",
      imgSrc: "/img/flags/ec.svg"
    },
    {
      dataBaseValue: "Estonia",
      value: "Estonya",
      label: "Estonya",
      imgSrc: "/img/flags/ee.svg"
    },
    {
      dataBaseValue: "Egypt",
      value: "Mısır",
      label: "Mısır",
      imgSrc: "/img/flags/eg.svg"
    },
    {
      dataBaseValue: "West Sahara",
      value: "Batı Sahra",
      label: "Batı Sahra",
      imgSrc: "/img/flags/eh.svg"
    },
    {
      dataBaseValue: "Eritrea",
      value: "Eritre",
      label: "Eritre",
      imgSrc: "/img/flags/er.svg"
    },
    {
      dataBaseValue: "Spain",
      value: "Ispanya",
      label: "Ispanya",
      imgSrc: "/img/flags/es.svg"
    },
    {
      dataBaseValue: "Ethiopia",
      value: "Etiyopya",
      label: "Etiyopya",
      imgSrc: "/img/flags/et.svg"
    },
    {
      dataBaseValue: "Europe",
      value: "Avrupa",
      label: "Avrupa",
      imgSrc: "/img/flags/eu.svg"
    },
    {
      dataBaseValue: "Finland",
      value: "Finlandiya",
      label: "Finlandiya",
      imgSrc: "/img/flags/fi.svg"
    },
    {
      dataBaseValue: "Netherlands",
      value: "Hollanda",
      label: "Hollanda",
      imgSrc: "/img/flags/nl.svg"
    },
    {
      dataBaseValue: "Fiji",
      value: "Fiji",
      label: "Fiji",
      imgSrc: "/img/flags/fj.svg"
    },
    {
      dataBaseValue: "Falkland Islands",
      value: "Falkland adaları",
      label: "Falkland adaları",
      imgSrc: "/img/flags/fk.svg"
    },
    {
      dataBaseValue: "Federated States of Micronesia",
      value: "Mikronezya Federe Devletleri",
      label: "Mikronezya Federe Devletleri",
      imgSrc: "/img/flags/fm.svg"
    },
    {
      dataBaseValue: "Faroe Islands",
      value: "Faroe Adaları",
      label: "Faroe Adaları",
      imgSrc: "/img/flags/fo.svg"
    },
    {
      dataBaseValue: "France",
      value: "Fransa",
      label: "Fransa",
      imgSrc: "/img/flags/fr.svg"
    },
    {
      dataBaseValue: "Georgia",
      value: "Gürcistan",
      label: "Gürcistan",
      imgSrc: "/img/flags/ge.svg"
    },
    // {
    //   dataBaseValue: "Great Britain",
    //   value: "Büyük Britanya",
    //   label: "Büyük Britanya",
    //   imgSrc: "/img/flags/en.svg"
    // },
    {
      dataBaseValue: "Northern Ireland",
      value: "Kuzey Irlanda",
      label: "Kuzey Irlanda",
      imgSrc: "/img/flags/nir.svg"
    },
    {
      dataBaseValue: "Scotland",
      value: "İskoçya",
      label: "İskoçya",
      imgSrc: "/img/flags/sct.svg"
    },
    {
      dataBaseValue: "Wales",
      value: "Galler",
      label: "Galler",
      imgSrc: "/img/flags/wls.svg"
    },
    {
      dataBaseValue: "Grenada",
      value: "Grenada",
      label: "Grenada",
      imgSrc: "/img/flags/gd.svg"
    },
    {
      dataBaseValue: "French Guiana",
      value: "Fransız Guyanası",
      label: "Fransız Guyanası",
      imgSrc: "/img/flags/gf.svg"
    },
    {
      dataBaseValue: "Guernsey",
      value: "Guernsey",
      label: "Guernsey",
      imgSrc: "/img/flags/gg.svg"
    },
    {
      dataBaseValue: "Ghana",
      value: "Gana",
      label: "Gana",
      imgSrc: "/img/flags/gh.svg"
    },
    {
      dataBaseValue: "Gibraltar",
      value: "Cebelitarık",
      label: "Cebelitarık",
      imgSrc: "/img/flags/gi.svg"
    },
    {
      dataBaseValue: "Greenland",
      value: "Grönland",
      label: "Grönland",
      imgSrc: "/img/flags/gl.svg"
    },
    {
      dataBaseValue: "Gambia",
      value: "Gambiya",
      label: "Gambiya",
      imgSrc: "/img/flags/gm.svg"
    },
    {
      dataBaseValue: "Guinea",
      value: "Gine",
      label: "Gine",
      imgSrc: "/img/flags/gn.svg"
    },
    {
      dataBaseValue: "Guadeloupe",
      value: "Guadelup",
      label: "Guadelup",
      imgSrc: "/img/flags/gp.svg"
    },
    {
      dataBaseValue: "Equatorial Guinea",
      value: "Ekvator Ginesi",
      label: "Ekvator Ginesi",
      imgSrc: "/img/flags/gq.svg"
    },
    {
      dataBaseValue: "Greece",
      value: "Yunanistan",
      label: "Yunanistan",
      imgSrc: "/img/flags/gr.svg"
    },
    {
      dataBaseValue: "South Georgia and the South Sandwich Islands",
      value: "Güney Georgia ve Güney Sandwich Adaları",
      label: "Güney Georgia ve Güney Sandwich Adaları",
      imgSrc: "/img/flags/gs.svg"
    },
    {
      dataBaseValue: "Guatemala",
      value: "Guatemala",
      label: "Guatemala",
      imgSrc: "/img/flags/gt.svg"
    },
    {
      dataBaseValue: "Guam",
      value: "Guam",
      label: "Guam",
      imgSrc: "/img/flags/gu.svg"
    },
    {
      dataBaseValue: "Guinea-Bissau",
      value: "Gine-Bissau",
      label: "Gine-Bissau",
      imgSrc: "/img/flags/gw.svg"
    },
    {
      dataBaseValue: "Guyana",
      value: "Guyana",
      label: "Guyana",
      imgSrc: "/img/flags/gy.svg"
    },
    {
      dataBaseValue: "Hong Kong",
      value: "Hong Kong",
      label: "Hong Kong",
      imgSrc: "/img/flags/hk.svg"
    },
    {
      dataBaseValue: "Honduras",
      value: "Honduras",
      label: "Honduras",
      imgSrc: "/img/flags/hn.svg"
    },
    {
      dataBaseValue: "Croatia",
      value: "Hırvatistan",
      label: "Hırvatistan",
      imgSrc: "/img/flags/hr.svg"
    },
    {
      dataBaseValue: "Haiti",
      value: "Haiti",
      label: "Haiti",
      imgSrc: "/img/flags/ht.svg"
    },
    {
      dataBaseValue: "Hungary",
      value: "Macaristan",
      label: "Macaristan",
      imgSrc: "/img/flags/hu.svg"
    },
    {
      dataBaseValue: "Indonesia",
      value: "Endonezya",
      label: "Endonezya",
      imgSrc: "/img/flags/id.svg"
    },
    {
      dataBaseValue: "Ireland",
      value: "İrlanda",
      label: "İrlanda",
      imgSrc: "/img/flags/ie.svg"
    },
    {
      dataBaseValue: "Israel",
      value: "İsrail",
      label: "İsrail",
      imgSrc: "/img/flags/il.svg"
    },
    {
      dataBaseValue: "Isle of Man",
      value: "Man Adası",
      label: "Man Adası",
      imgSrc: "/img/flags/im.svg"
    },
    {
      dataBaseValue: "India",
      value: "Hindistan",
      label: "Hindistan",
      imgSrc: "/img/flags/in.svg"
    },
    {
      dataBaseValue: "British Indian Ocean Territory",
      value: "Britanya Hint Okyanusu Toprakları",
      label: "Britanya Hint Okyanusu Toprakları",
      imgSrc: "/img/flags/io.svg"
    },
    {
      dataBaseValue: "Iraq",
      value: "Irak",
      label: "Irak",
      imgSrc: "/img/flags/iq.svg"
    },
    {
      dataBaseValue: "Iran",
      value: "İran",
      label: "İran",
      imgSrc: "/img/flags/ir.svg"
    },
    {
      dataBaseValue: "Italy",
      value: "İtalya",
      label: "İtalya",
      imgSrc: "/img/flags/it.svg"
    },
    {
      dataBaseValue: "Jersey",
      value: "Jersey",
      label: "Jersey",
      imgSrc: "/img/flags/je.svg"
    },
    {
      dataBaseValue: "Jamaica",
      value: "Jamaika",
      label: "Jamaika",
      imgSrc: "/img/flags/jm.svg"
    },
    {
      dataBaseValue: "Jordan",
      value: "Ürdün",
      label: "Ürdün",
      imgSrc: "/img/flags/jo.svg"
    },
    {
      dataBaseValue: "Japan",
      value: "Japonya",
      label: "Japonya",
      imgSrc: "/img/flags/jp.svg"
    },
    {
      dataBaseValue: "Kenya",
      value: "Kenya",
      label: "Kenya",
      imgSrc: "/img/flags/ke.svg"
    },
    {
      dataBaseValue: "Kyrgyzstan",
      value: "Kırgızistan",
      label: "Kırgızistan",
      imgSrc: "/img/flags/kg.svg"
    },
    {
      dataBaseValue: "Cambodia",
      value: "Kamboçya",
      label: "Kamboçya",
      imgSrc: "/img/flags/kh.svg"
    },
    {
      dataBaseValue: "Kiribati",
      value: "Kiribati",
      label: "Kiribati",
      imgSrc: "/img/flags/ki.svg"
    },
    {
      dataBaseValue: "Comoros",
      value: "Komorlar",
      label: "Komorlar",
      imgSrc: "/img/flags/km.svg"
    },
    {
      dataBaseValue: "Saint Kitts and Nevis",
      value: "Saint Kitts ve Nevis",
      label: "Saint Kitts ve Nevis",
      imgSrc: "/img/flags/kn.svg"
    },
    {
      dataBaseValue: "Democratic People's Republic of Korea",
      value: "Kore Demokratik Halk Cumhuriyeti",
      label: "Kore Demokratik Halk Cumhuriyeti",
      imgSrc: "/img/flags/kp.svg"
    },
    {
      dataBaseValue: "South Korea",
      value: "Güney Kore",
      label: "Güney Kore",
      imgSrc: "/img/flags/kr.svg"
    },
    {
      dataBaseValue: "Kuwait",
      value: "Kuveyt",
      label: "Kuveyt",
      imgSrc: "/img/flags/kw.svg"
    },
    {
      dataBaseValue: "Cayman Islands",
      value: "Cayman Adaları",
      label: "Cayman Adaları",
      imgSrc: "/img/flags/ky.svg"
    },
    {
      dataBaseValue: "Kazakhstan",
      value: "Kazakistan",
      label: "Kazakistan",
      imgSrc: "/img/flags/kz.svg"
    },
    {
      dataBaseValue: "Laos",
      value: "Laos",
      label: "Laos",
      imgSrc: "/img/flags/la.svg"
    },
    {
      dataBaseValue: "Lebanon",
      value: "Lübnan",
      label: "Lübnan",
      imgSrc: "/img/flags/lb.svg"
    },
    {
      dataBaseValue: "Saint Lucia",
      value: "Aziz Lucia",
      label: "Aziz Lucia",
      imgSrc: "/img/flags/lc.svg"
    },
    {
      dataBaseValue: "Liechtenstein",
      value: "Lihtenştayn",
      label: "Lihtenştayn",
      imgSrc: "/img/flags/li.svg"
    },
    {
      dataBaseValue: "Sri Lanka",
      value: "Sri Lanka",
      label: "Sri Lanka",
      imgSrc: "/img/flags/lk.svg"
    },
    {
      dataBaseValue: "Liberia",
      value: "Liberya",
      label: "Liberya",
      imgSrc: "/img/flags/lr.svg"
    },
    {
      dataBaseValue: "Lesotho",
      value: "Lesoto",
      label: "Lesoto",
      imgSrc: "/img/flags/ls.svg"
    },
    {
      dataBaseValue: "Lithuania",
      value: "Litvanya",
      label: "Litvanya",
      imgSrc: "/img/flags/lt.svg"
    },
    {
      dataBaseValue: "Luxembourg",
      value: "Lüksemburg",
      label: "Lüksemburg",
      imgSrc: "/img/flags/lu.svg"
    },
    {
      dataBaseValue: "Latvia",
      value: "Letonya",
      label: "Letonya",
      imgSrc: "/img/flags/lv.svg"
    },
    {
      dataBaseValue: "Libya",
      value: "Libya",
      label: "Libya",
      imgSrc: "/img/flags/ly.svg"
    },
    {
      dataBaseValue: "Morocco",
      value: "Fas",
      label: "Fas",
      imgSrc: "/img/flags/ma.svg"
    },
    {
      dataBaseValue: "Monaco",
      value: "Monako",
      label: "Monako",
      imgSrc: "/img/flags/mc.svg"
    },
    {
      dataBaseValue: "Moldova",
      value: "Moldova",
      label: "Moldova",
      imgSrc: "/img/flags/md.svg"
    },
    {
      dataBaseValue: "Montenegro",
      value: "Karadağ",
      label: "Karadağ",
      imgSrc: "/img/flags/me.svg"
    },
    {
      dataBaseValue: "Saint Martin",
      value: "Aziz Martin",
      label: "Aziz Martin",
      imgSrc: "/img/flags/mf.svg"
    },
    {
      dataBaseValue: "Madagascar",
      value: "Madagaskar",
      label: "Madagaskar",
      imgSrc: "/img/flags/mg.svg"
    },
    {
      dataBaseValue: "Marshall Islands",
      value: "Marşal Adaları",
      label: "Marşal Adaları",
      imgSrc: "/img/flags/mh.svg"
    },
    {
      dataBaseValue: "Macedonia",
      value: "Makedonya",
      label: "Makedonya",
      imgSrc: "/img/flags/mk.svg"
    },
    {
      dataBaseValue: "Mali",
      value: "Mali",
      label: "Mali",
      imgSrc: "/img/flags/ml.svg"
    },
    {
      dataBaseValue: "Myanmar",
      value: "Myanmar",
      label: "Myanmar",
      imgSrc: "/img/flags/mm.svg"
    },
    {
      dataBaseValue: "Mongolia",
      value: "Moğolistan",
      label: "Moğolistan",
      imgSrc: "/img/flags/mn.svg"
    },
    {
      dataBaseValue: "Macau",
      value: "Makao",
      label: "Makao",
      imgSrc: "/img/flags/mo.svg"
    },
    {
      dataBaseValue: "Northern Mariana Islands",
      value: "Kuzey Mariana Adaları",
      label: "Kuzey Mariana Adaları",
      imgSrc: "/img/flags/mp.svg"
    },
    {
      dataBaseValue: "Martinique",
      value: "Martinik",
      label: "Martinik",
      imgSrc: "/img/flags/mq.svg"
    },
    {
      dataBaseValue: "Mauritius",
      value: "Moritanya",
      label: "Moritanya",
      imgSrc: "/img/flags/mr.svg"
    },
    {
      dataBaseValue: "Montserrat",
      value: "Montserrat",
      label: "Montserrat",
      imgSrc: "/img/flags/ms.svg"
    },
    {
      dataBaseValue: "Malta",
      value: "Malta",
      label: "Malta",
      imgSrc: "/img/flags/mt.svg"
    },
    {
      dataBaseValue: "O. Mauritius",
      value: "hakkında. Mauritius",
      label: "hakkında. Mauritius",
      imgSrc: "/img/flags/mu.svg"
    },
    {
      dataBaseValue: "Maldives",
      value: "Maldivler",
      label: "Maldivler",
      imgSrc: "/img/flags/mv.svg"
    },
    {
      dataBaseValue: "Malawi",
      value: "Malawi",
      label: "Malawi",
      imgSrc: "/img/flags/mw.svg"
    },
    {
      dataBaseValue: "Mexico",
      value: "Meksika",
      label: "Meksika",
      imgSrc: "/img/flags/mx.svg"
    },
    {
      dataBaseValue: "Malaysia",
      value: "Malezya",
      label: "Malezya",
      imgSrc: "/img/flags/my.svg"
    },
    {
      dataBaseValue: "Mozambique",
      value: "Mozambik",
      label: "Mozambik",
      imgSrc: "/img/flags/mz.svg"
    },
    {
      dataBaseValue: "Namibia",
      value: "Namibya",
      label: "Namibya",
      imgSrc: "/img/flags/na.svg"
    },
    {
      dataBaseValue: "New Caledonia",
      value: "Yeni Kaledonya",
      label: "Yeni Kaledonya",
      imgSrc: "/img/flags/nc.svg"
    },
    {
      dataBaseValue: "Niger",
      value: "Nijer",
      label: "Nijer",
      imgSrc: "/img/flags/ne.svg"
    },
    {
      dataBaseValue: "Norfolk Island",
      value: "Norfolk Adası",
      label: "Norfolk Adası",
      imgSrc: "/img/flags/nf.svg"
    },
    {
      dataBaseValue: "Nigeria",
      value: "Nijerya",
      label: "Nijerya",
      imgSrc: "/img/flags/ng.svg"
    },
    {
      dataBaseValue: "Nicaragua",
      value: "Nikaragua",
      label: "Nikaragua",
      imgSrc: "/img/flags/ni.svg"
    },
    {
      dataBaseValue: "Norway",
      value: "Norveç",
      label: "Norveç",
      imgSrc: "/img/flags/no.svg"
    },
    {
      dataBaseValue: "Nepal",
      value: "Nepal",
      label: "Nepal",
      imgSrc: "/img/flags/np.svg"
    },
    {
      dataBaseValue: "Nauru",
      value: "Nauru",
      label: "Nauru",
      imgSrc: "/img/flags/nr.svg"
    },
    {
      dataBaseValue: "Niue",
      value: "Niue",
      label: "Niue",
      imgSrc: "/img/flags/nu.svg"
    },
    {
      dataBaseValue: "New Zealand",
      value: "Yeni Zelanda",
      label: "Yeni Zelanda",
      imgSrc: "/img/flags/nz.svg"
    },
    {
      dataBaseValue: "Oman",
      value: "Umman",
      label: "Umman",
      imgSrc: "/img/flags/om.svg"
    },
    {
      dataBaseValue: "Panama",
      value: "Panama",
      label: "Panama",
      imgSrc: "/img/flags/pa.svg"
    },
    {
      dataBaseValue: "Peru",
      value: "Peru",
      label: "Peru",
      imgSrc: "/img/flags/pe.svg"
    },
    {
      dataBaseValue: "French polynesia",
      value: "Fransız Polinezyası",
      label: "Fransız Polinezyası",
      imgSrc: "/img/flags/pf.svg"
    },
    {
      dataBaseValue: "Papua New Guinea",
      value: "Papua Yeni Gine",
      label: "Papua Yeni Gine",
      imgSrc: "/img/flags/pg.svg"
    },
    {
      dataBaseValue: "Philippines",
      value: "Filipinler",
      label: "Filipinler",
      imgSrc: "/img/flags/ph.svg"
    },
    {
      dataBaseValue: "Pakistan",
      value: "Pakistan",
      label: "Pakistan",
      imgSrc: "/img/flags/pk.svg"
    },
    {
      dataBaseValue: "Poland",
      value: "Polonya",
      label: "Polonya",
      imgSrc: "/img/flags/pl.svg"
    },
    {
      dataBaseValue: "Saint Pierre and Miquelon",
      value: "Aziz Pierre ve Miquelon",
      label: "Aziz Pierre ve Miquelon",
      imgSrc: "/img/flags/pm.svg"
    },
    {
      dataBaseValue: "Pitcairn Islands",
      value: "Pitcairn Adaları",
      label: "Pitcairn Adaları",
      imgSrc: "/img/flags/pn.svg"
    },
    {
      dataBaseValue: "Puerto Rico",
      value: "Porto Riko",
      label: "Porto Riko",
      imgSrc: "/img/flags/pr.svg"
    },
    {
      dataBaseValue: "Palestine",
      value: "Filistin",
      label: "Filistin",
      imgSrc: "/img/flags/ps.svg"
    },
    {
      dataBaseValue: "Portugal",
      value: "Portekiz",
      label: "Portekiz",
      imgSrc: "/img/flags/pt.svg"
    },
    {
      dataBaseValue: "Paraguay",
      value: "Paraguay",
      label: "Paraguay",
      imgSrc: "/img/flags/py.svg"
    },
    {
      dataBaseValue: "Qatar",
      value: "Katar",
      label: "Katar",
      imgSrc: "/img/flags/qa.svg"
    },
    {
      dataBaseValue: "Reunion",
      value: "Yeniden birleşme",
      label: "Yeniden birleşme",
      imgSrc: "/img/flags/re.svg"
    },
    {
      dataBaseValue: "Romania",
      value: "Romanya",
      label: "Romanya",
      imgSrc: "/img/flags/ro.svg"
    },
    {
      dataBaseValue: "Serbia",
      value: "Sırbistan",
      label: "Sırbistan",
      imgSrc: "/img/flags/rs.svg"
    },
    {
      dataBaseValue: "Russia",
      value: "Rusya",
      label: "Rusya",
      imgSrc: "/img/flags/ru.svg"
    },
    {
      dataBaseValue: "Rwanda",
      value: "Ruanda",
      label: "Ruanda",
      imgSrc: "/img/flags/rw.svg"
    },
    {
      dataBaseValue: "South Africa",
      value: "Güney Afrika",
      label: "Güney Afrika",
      imgSrc: "/img/flags/sa.svg"
    },
    {
      dataBaseValue: "Solomon Islands",
      value: "Solomon Adaları",
      label: "Solomon Adaları",
      imgSrc: "/img/flags/sb.svg"
    },
    {
      dataBaseValue: "Seychelles",
      value: "Seyşeller",
      label: "Seyşeller",
      imgSrc: "/img/flags/sc.svg"
    },
    {
      dataBaseValue: "Sudan",
      value: "Sudan",
      label: "Sudan",
      imgSrc: "/img/flags/sd.svg"
    },
    {
      dataBaseValue: "Sweden",
      value: "İsveç",
      label: "İsveç",
      imgSrc: "/img/flags/se.svg"
    },
    {
      dataBaseValue: "Singapore",
      value: "Singapur",
      label: "Singapur",
      imgSrc: "/img/flags/sg.svg"
    },
    {
      dataBaseValue: "O. St. Helena",
      value: "hakkında. Aziz Helena",
      label: "hakkında. Aziz Helena",
      imgSrc: "/img/flags/sh.svg"
    },
    {
      dataBaseValue: "Slovenia",
      value: "Slovenya",
      label: "Slovenya",
      imgSrc: "/img/flags/si.svg"
    },
    {
      dataBaseValue: "Svalbard",
      value: "Svalbard",
      label: "Svalbard",
      imgSrc: "/img/flags/sj.svg"
    },
    {
      dataBaseValue: "Slovakia",
      value: "Slovakya",
      label: "Slovakya",
      imgSrc: "/img/flags/sk.svg"
    },
    {
      dataBaseValue: "Sierra Leone",
      value: "Sierra Leone",
      label: "Sierra Leone",
      imgSrc: "/img/flags/sl.svg"
    },
    {
      dataBaseValue: "San Marino",
      value: "San Marino",
      label: "San Marino",
      imgSrc: "/img/flags/sm.svg"
    },
    {
      dataBaseValue: "Senegal",
      value: "Senegal",
      label: "Senegal",
      imgSrc: "/img/flags/sn.svg"
    },
    {
      dataBaseValue: "Somalia",
      value: "Somali",
      label: "Somali",
      imgSrc: "/img/flags/so.svg"
    },
    {
      dataBaseValue: "Suriname",
      value: "Surinam",
      label: "Surinam",
      imgSrc: "/img/flags/sr.svg"
    },
    {
      dataBaseValue: "South Sudan",
      value: "Güney Sudan",
      label: "Güney Sudan",
      imgSrc: "/img/flags/ss.svg"
    },
    {
      dataBaseValue: "Sao Tome and Principe",
      value: "Sao Tome ve Principe",
      label: "Sao Tome ve Principe",
      imgSrc: "/img/flags/st.svg"
    },
    {
      dataBaseValue: "Salvador",
      value: "Salvador",
      label: "Salvador",
      imgSrc: "/img/flags/sv.svg"
    },
    {
      dataBaseValue: "Sint Maarten",
      value: "Sint Maarten",
      label: "Sint Maarten",
      imgSrc: "/img/flags/sx.svg"
    },
    {
      dataBaseValue: "Syria",
      value: "Suriye",
      label: "Suriye",
      imgSrc: "/img/flags/sy.svg"
    },
    {
      dataBaseValue: "Eswatini",
      value: "Esvatini",
      label: "Esvatini",
      imgSrc: "/img/flags/sz.svg"
    },
    {
      dataBaseValue: "Turks and Caicos Islands",
      value: "Turks and Caicos Islands",
      label: "Turks and Caicos Islands",
      imgSrc: "/img/flags/tc.svg"
    },
    {
      dataBaseValue: "Chad",
      value: "Chad",
      label: "Chad",
      imgSrc: "/img/flags/td.svg"
    },
    {
      dataBaseValue: "Togo",
      value: "Togo",
      label: "Togo",
      imgSrc: "/img/flags/tg.svg"
    },
    {
      dataBaseValue: "Thailand",
      value: "Tayland",
      label: "Tayland",
      imgSrc: "/img/flags/th.svg"
    },
    {
      dataBaseValue: "Tajikistan",
      value: "Tacikistan",
      label: "Tacikistan",
      imgSrc: "/img/flags/tj.svg"
    },
    {
      dataBaseValue: "Tokelau",
      value: "Tokelau",
      label: "Tokelau",
      imgSrc: "/img/flags/tk.svg"
    },
    {
      dataBaseValue: "East Timor",
      value: "Doğu Timor",
      label: "Doğu Timor",
      imgSrc: "/img/flags/tl.svg"
    },
    {
      dataBaseValue: "Turkmenistan",
      value: "Türkmenistan",
      label: "Türkmenistan",
      imgSrc: "/img/flags/tm.svg"
    },
    {
      dataBaseValue: "Tunisia",
      value: "Tunus",
      label: "Tunus",
      imgSrc: "/img/flags/tn.svg"
    },
    {
      dataBaseValue: "Turkey",
      value: "Türkiye",
      label: "Türkiye",
      imgSrc: "/img/flags/tr.svg"
    },
    {
      dataBaseValue: "Trinidad and Tobago",
      value: "Trinidad ve Tobago",
      label: "Trinidad ve Tobago",
      imgSrc: "/img/flags/tt.svg"
    },
    {
      dataBaseValue: "Tuvalu",
      value: "Tuvalu",
      label: "Tuvalu",
      imgSrc: "/img/flags/tv.svg"
    },
    {
      dataBaseValue: "Taiwan",
      value: "Tayvan",
      label: "Tayvan",
      imgSrc: "/img/flags/tw.svg"
    },
    {
      dataBaseValue: "Tanzania",
      value: "Tanzanya",
      label: "Tanzanya",
      imgSrc: "/img/flags/tz.svg"
    },
    {
      dataBaseValue: "Ukraine",
      value: "Ukrayna",
      label: "Ukrayna",
      imgSrc: "/img/flags/ua.svg"
    },
    {
      dataBaseValue: "Uganda",
      value: "Uganda",
      label: "Uganda",
      imgSrc: "/img/flags/ug.svg"
    },
    {
      dataBaseValue: "Minor Outlying Islands (USA)",
      value: "Küçük Dış Adalar (ABD)",
      label: "Küçük Dış Adalar (ABD)",
      imgSrc: "/img/flags/um.svg"
    },
    {
      dataBaseValue: "United States",
      value: "Amerika Birleşik Devletleri",
      label: "Amerika Birleşik Devletleri",
      imgSrc: "/img/flags/us.svg"
    },
    {
      dataBaseValue: "United Kingdom",
      value: "Birleşik Krallık",
      label: "Birleşik Krallık",
      imgSrc: "/img/flags/uk.svg"
    },
    {
      dataBaseValue: "Uruguay",
      value: "Uruguay",
      label: "Uruguay",
      imgSrc: "/img/flags/uy.svg"
    },
    {
      dataBaseValue: "Uzbekistan",
      value: "Özbekistan",
      label: "Özbekistan",
      imgSrc: "/img/flags/uz.svg"
    },
    {
      dataBaseValue: "Vatican City State",
      value: "Vatikan Şehir Devleti",
      label: "Vatikan Şehir Devleti",
      imgSrc: "/img/flags/va.svg"
    },
    {
      dataBaseValue: "Saint Vincent and the Grenadines",
      value: "Saint Vincent ve Grenadinler",
      label: "Saint Vincent ve Grenadinler",
      imgSrc: "/img/flags/vc.svg"
    },
    {
      dataBaseValue: "Venezuela",
      value: "Venezuela",
      label: "Venezuela",
      imgSrc: "/img/flags/ve.svg"
    },
    {
      dataBaseValue: "British Virgin Islands",
      value: "Britanya Virjin Adaları",
      label: "Britanya Virjin Adaları",
      imgSrc: "/img/flags/vg.svg"
    },
    {
      dataBaseValue: "US Virgin Islands",
      value: "Amerika Birleşik Devletleri Virgin Adaları",
      label: "Amerika Birleşik Devletleri Virgin Adaları",
      imgSrc: "/img/flags/vi.svg"
    },
    {
      dataBaseValue: "Vietnam",
      value: "Vietnam",
      label: "Vietnam",
      imgSrc: "/img/flags/vn.svg"
    },
    {
      dataBaseValue: "Vanuatu",
      value: "Vanuatu",
      label: "Vanuatu",
      imgSrc: "/img/flags/vu.svg"
    },
    {
      dataBaseValue: "Wallis and Futuna Islands",
      value: "Wallis ve Futuna Adaları",
      label: "Wallis ve Futuna Adaları",
      imgSrc: "/img/flags/wf.svg"
    },
    {
      dataBaseValue: "Samoa",
      value: "Samoa",
      label: "Samoa",
      imgSrc: "/img/flags/ws.svg"
    },
    {
      dataBaseValue: "Kosovo",
      value: "Kosova",
      label: "Kosova",
      imgSrc: "/img/flags/xk.svg"
    },
    {
      dataBaseValue: "Yemen",
      value: "Yemen",
      label: "Yemen",
      imgSrc: "/img/flags/ye.svg"
    },
    {
      dataBaseValue: "Mayotte",
      value: "Mayo",
      label: "Mayo",
      imgSrc: "/img/flags/yt.svg"
    },
    {
      dataBaseValue: "South Africa",
      value: "Güney Afrika",
      label: "Güney Afrika",
      imgSrc: "/img/flags/za.svg"
    },
    {
      dataBaseValue: "Zambia",
      value: "Zambiya",
      label: "Zambiya",
      imgSrc: "/img/flags/zm.svg"
    },
    {
      dataBaseValue: "Zimbabwe",
      value: "Zimbabve",
      label: "Zimbabve",
      imgSrc: "/img/flags/zw.svg"
    }
  ],
  filterProxyItems: [
    {
      id: 1,
      value: "Biçim IP:PORT",
      regExp: /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(\s{0,}|:)\d{1,5}/,
      divider: [":"]
    },
    // {
    //   id: 2,
    //   value: "Biçim KeyCollector",
    //   regExp:
    //     /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(\s{0,}|:)\d{1,5}(\s{0,}|@)\S+(\s{0,}|:)\S+/,
    //   divider: [":", "@"]
    // },
    {
      id: 3,
      value: "Biçim IP:PORT@Login:Password",
      regExp:
        /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(\s{0,}|:)\d{1,5}(\s{0,}|@)\S+(\s{0,}|:)\S+/,
      divider: [":", "@"]
    },
    {
      id: 4,
      value: "Biçim IP.PORT:Login:Password",
      regExp:
        /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(\s{0,}|:)\d{1,5}(\s{0,}|:)\S+(\s{0,}|:)\S+/,
      divider: [":"]
    }
  ],
  quantity: [
    { label: "10 şeyler", value: 10 },
    { label: "15 şeyler", value: 15 },
    { label: "20 şeyler", value: 20 },
    { label: "50 şeyler", value: 50 }
  ],
  regions: [
    { value: "MOSCOW", label: "Moskova" },
    { value: "KYIV", label: "Kiev" },
    { value: "ST. PETERSBURG", label: "Petersburg" }
  ]
};
