export const saveClientInfoSuccessReducer = (state, action) => {
  state.client.isFetchError = null;
  state.client.data = action.payload;
  state.client.isDataLoaded = true;
  state.client.isLoading = false;
};

export const saveAnonimityScoreSuccessReducer = (state, action) => {
  state.anonimityScore.isFetchError = null;
  state.anonimityScore.data = action.payload;
  state.anonimityScore.isDataLoaded = true;
  state.anonimityScore.isLoading = false;
};

export const getClientInfoSuccessReducer = (state, action) => {
  state.client.isFetchError = null;
  state.client.data = action.payload;
  state.client.isDataLoaded = true;
  state.client.isLoading = false;
};

export const getClientInfoPendingReducer = (state) => {
  state.client.isLoading = true;
};

export const getClientInfoRejectedReducer = (state, action) => {
  state.client.isFetchError = action.payload;
  state.client.isDataLoaded = false;
  state.client.isLoading = false;
};

export const getAnonimityScoreSuccessReducer = (state, action) => {
  state.anonimityScore.isFetchError = null;
  state.anonimityScore.data = action.payload;
  state.anonimityScore.isDataLoaded = true;
  state.anonimityScore.isLoading = false;
};

export const getAnonimityScorePendingReducer = (state) => {
  state.anonimityScore.isLoading = true;
};

export const getAnonimityScoreRejectedReducer = (state, action) => {
  state.anonimityScore.isFetchError = action.payload;
  state.anonimityScore.isDataLoaded = false;
  state.anonimityScore.isLoading = false;
};

export const getAnonimityInfoSuccessReducer = (state, action) => {
  state.anonimityInfo.isFetchError = null;
  state.anonimityInfo.data = action.payload;
  state.anonimityInfo.isDataLoaded = true;
  state.anonimityInfo.isLoading = false;
};

export const getAnonimityInfoPendingReducer = (state) => {
  state.anonimityInfo.isLoading = true;
};

export const getAnonimityInfoRejectedReducer = (state, action) => {
  state.anonimityInfo.isFetchError = action.payload;
  state.anonimityInfo.isDataLoaded = false;
  state.anonimityInfo.isLoading = false;
};

export const getBlackListSuccessReducer = (state, action) => {
  state.blacklist.isFetchError = null;
  state.blacklist.data = action.payload;

  const keys = Object.keys(action.payload);
  const values = Object.values(action.payload);
  const inSpam = values.filter((value) => value === true).length;

  state.blacklist.checkCount = keys.length;
  state.blacklist.find = inSpam;
  state.blacklist.inBlackList = inSpam > 0;

  state.blacklist.isDataLoaded = true;
  state.blacklist.isLoading = false;
};

export const getBlackListPendingReducer = (state) => {
  state.blacklist.isLoading = true;
};

export const getBlackListRejectedReducer = (state, action) => {
  state.blacklist.isFetchError = action.payload;
  state.blacklist.isDataLoaded = false;
  state.blacklist.isLoading = false;
};

export const getOpenPortsSuccessReducer = (state, action) => {
  state.openPorts.isFetchError = null;
  state.openPorts.data = action.payload;
  state.openPorts.isDataLoaded = true;
  state.openPorts.isLoading = false;
};

export const getOpenPortsPendingReducer = (state) => {
  state.openPorts.isLoading = true;
};

export const getOpenPortsRejectedReducer = (state, action) => {
  state.openPorts.isFetchError = action.payload;
  state.openPorts.isDataLoaded = false;
  state.openPorts.isLoading = false;
};
