import { useCookies } from "react-cookie";

import { addIndexedDB, setStorage } from "../utils/helpers";

export const useEvercookie = () => {
  const [cookie, setCookie, removeCookie] = useCookies(["uid"]);

  if (typeof window === "undefined") {
    return {
      // eslint-disable-next-line no-empty-function
      setEvercookie: () => {},
      cookie: {},
      localUid: null,
      sessionUid: null,
      globalUid: null,
      indexedDB: null,

      // eslint-disable-next-line no-empty-function
      clear: () => {}
    };
  }

  const pngCookieName = "everCookie_png";
  const cacheCookieName = "everCookie_cache";
  const etagCookieName = "everCookie_etag";
  const key = "uid";

  const indexedDb =
    window?.indexedDB ||
    window?.mozIndexedDB ||
    window?.webkitIndexedDB ||
    window?.msIndexedDB;

  if (!window.globalStorage) {
    window.globalStorage = {};
  }
  if (!window.globalStorage[document.domain]) {
    window.globalStorage[document.domain] = {};
  }

  const globalStorage = window.globalStorage[document.domain];

  const clear = () => {
    sessionStorage.removeItem(key);

    removeCookie(key, { path: "/" });
    removeCookie(cacheCookieName, { path: "/" });
    removeCookie(etagCookieName, { path: "/" });

    window.windowUid = "";
  };

  const getLocalStorage = () => {
    const uid = localStorage.getItem("uid");

    if (!uid) return;

    return uid;
  };

  const getSessionStorage = () => {
    const uid = sessionStorage.getItem(key);

    if (!uid) return;

    return uid;
  };

  const getGlobalStorage = () => {
    const uid = +globalStorage[key];

    if (!isNaN(uid)) return uid;
  };

  const getIndexedDB = () =>
    // eslint-disable-next-line no-undef
    new Promise((resolve) => {
      try {
        if (indexedDb) {
          const request = indexedDb.open("idb_evercookie", 1);

          request.onupgradeneeded = (event) => {
            const db = request.result || event.target.result;

            db.createObjectStore("evercookie", {
              keyPath: "name"
            });
          };

          request.onsuccess = (event) => {
            const idb = request.result || event.target.result;

            if (!idb.objectStoreNames.contains("evercookie")) {
              resolve();
            } else {
              const tx = idb.transaction(["evercookie"]);
              const objst = tx.objectStore("evercookie");
              const qr = objst.get("uid");

              qr.onsuccess = () => {
                // eslint-disable-next-line no-undefined
                resolve(qr.result !== undefined ? qr.result.value : undefined);
              };

              qr.onerror = () => {
                resolve();
              };
            }
            idb.close();
          };

          request.onerror = () => {
            resolve();
          };
        }
      } catch (error) {
        resolve();
      }
    });

  const setCookies = (value) => {
    if (!value) return;

    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 30);

    setCookie(key, value, { path: "/", expires: expirationDate });
    setCookie(cacheCookieName, value, { path: "/", expires: expirationDate });
    setCookie(etagCookieName, value, { path: "/", expires: expirationDate });
    setCookie(pngCookieName, value, { path: "/", expires: expirationDate });
  };

  const setGlobalStorage = (value) => {
    if (!value) return;

    globalStorage[key] = value;
  };

  const setEvercookie = (uid) => {
    setGlobalStorage(uid);
    setStorage(uid, key);
    setCookies(uid);

    addIndexedDB(uid, key, indexedDb);
  };

  return {
    setEvercookie,
    cookie,
    localUid: getLocalStorage(),
    sessionUid: getSessionStorage(),
    globalUid: getGlobalStorage(),
    indexedDB: getIndexedDB(),
    clear
  };
};
