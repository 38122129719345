import { useEffect, useState } from "react";

import { useClientSelector } from "../../../store/selectors/useClientSelector";

import {
  CheckingWarning,
  Img,
  Ip,
  Location,
  LocationWraper,
  PotentialWarning,
  Text,
  WarningText,
  Wraper
} from "./TopLeak.styled";

export const TopLeak = ({ t, client, countryInfo, status = true }) => {
  const [delayedStatus, setDelayedStatus] = useState(status);
  const [showCheckingWarning, setShowCheckingWarning] = useState(true);

  const { anonymityInfo } = useClientSelector();

  const isLoaded = anonymityInfo?.isDataLoaded;

  useEffect(() => {
    if (isLoaded) {
      const timer = setTimeout(() => {
        setDelayedStatus(status);
        setShowCheckingWarning(false);
      }, 200);

      // eslint-disable-next-line no-undef
      return () => clearTimeout(timer);
    }
  }, [status, isLoaded]);

  return (
    <Wraper>
      <div>
        <Text>{t.top.myIp}</Text>

        <Ip>{client.data.ipv4}</Ip>
      </div>

      <div>
        <Text>{t.top.from}</Text>
        <LocationWraper>
          {countryInfo?.imgSrc && (
            <Img
              src={countryInfo?.imgSrc}
              width="28"
              height="28"
              loading="lazy"
              alt={countryInfo?.location}
            />
          )}

          <Location>{countryInfo?.location}</Location>
        </LocationWraper>
      </div>

      <div>
        <Text>{t.top.state}</Text>
        {!isLoaded || showCheckingWarning ? (
          <CheckingWarning>
            <WarningText>{t.status.checking}</WarningText>
          </CheckingWarning>
        ) : (
          <PotentialWarning warning={delayedStatus}>
            <WarningText>{t.top.warning}</WarningText>
          </PotentialWarning>
        )}
      </div>
    </Wraper>
  );
};
