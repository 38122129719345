import styled from "@emotion/styled";

export const Wrapper = styled.div`
  margin-top: 67px;

  @media (max-width: 767px) {
    margin-top: 35px;
  }
`;

export const IndexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 320px;
  min-height: 100vh;
  transition: transform 0.3ms;
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
