import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useMatchRoute } from "../../../hooks";
import { Container } from "../../ui";

import "./Seo.scss";

export const Seo = () => {
  const [seoPageText, setSeoPageText] = useState([]);
  const [countyContent, setCountyContent] = useState("");

  const { lang, filter } = useParams();

  const tag = useMatchRoute()?.tag;

  const seoText = useSelector(
    (state) =>
      state.content.content?.store?.[filter || tag]?.[lang || "ru"]?.seoText
  );
  const { country } = useSelector((state) => state.content);

  useEffect(() => {
    if (seoText) {
      setSeoPageText(seoText);
    }
  }, [seoText]);

  useEffect(() => {
    if (Object.keys(country.data).length) {
      const content = country.data.content.find(
        ({ languageCode }) => languageCode === lang.toUpperCase()
      )?.downerSeoText;

      setCountyContent(content);
    }
  }, [country.data, lang]);

  useEffect(() => {
    if (!filter) {
      setCountyContent("");
    }
  }, [filter]);

  if (
    seoText?.length === 0 &&
    !country?.data?.content?.find(
      (el) => el.languageCode === lang.toUpperCase()
    ).downerSeoText
  ) {
    return null;
  }

  return (
    <section className="seo">
      <Container>
        {countyContent ? (
          <section
            dangerouslySetInnerHTML={{
              __html: countyContent
            }}
          />
        ) : (
          seoPageText?.map((item, index) => (
            <section key={index} dangerouslySetInnerHTML={{ __html: item }} />
          ))
        )}
      </Container>
    </section>
  );
};
