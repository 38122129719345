import { useTranslation } from "../../../../../../../hooks";

const HeaderCompanyItem = ({ href, onClick, text }) => {
  const t = useTranslation();

  return (
    <li className="header__mobile-subitem" onClick={onClick}>
      <a href={href} target="_blank" rel="noreferrer">
        {t?.navigation?.[text]}
      </a>
    </li>
  );
};

export default HeaderCompanyItem;
