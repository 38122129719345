import "./Loader.scss";

export const Loader = (props) => {
  // **Props
  const {
    absolute,
    fixed,
    mini,
    width = 15,
    height = 15,
    color = "#fff",
    ...rest
  } = props;

  const defineClassname = () => {
    let className = "loader";

    if (fixed) {
      className += " loader--fixed";
    }

    if (absolute) {
      className += " loader--absolute";
    }

    return className;
  };

  if (mini) {
    return (
      <div
        style={{
          "--width": `${width}px`,
          "--height": `${height}px`,
          "--color": color
        }}
        className="loader__mini"
        {...rest}
      ></div>
    );
  }

  return (
    <div className={defineClassname()} {...rest}>
      <div className="loader__inner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
