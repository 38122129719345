import { createAsyncThunk } from "@reduxjs/toolkit";

import { ApiService } from "../../../services/ApiService";

export const getRequestHeaders = createAsyncThunk(
  "headers/getHttpHeaders",
  async ({ url, userAgent, httpVersion, method }, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.getHeaders(
        url,
        userAgent,
        httpVersion,
        method
      );
      return data;
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);
