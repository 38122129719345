export const addIndexedDB = (value, key, indexedDB) => {
  if (!value) return;
  const name = key;
  try {
    if (indexedDB) {
      const request = indexedDB.open("idb_evercookie", 1);

      request.onupgradeneeded = (event) => {
        const db = request.result || event.target.result;
        db.createObjectStore("evercookie", {
          keyPath: "name"
        });
      };

      request.onsuccess = (event) => {
        const idb = request.result || event.target.result;

        if (idb.objectStoreNames.contains("evercookie")) {
          try {
            const tx = idb.transaction(["evercookie"], "readwrite");
            const objst = tx.objectStore("evercookie");
            objst.put({ name, value });
            // eslint-disable-next-line no-empty
          } catch (error) {}
        }
        idb.close();
      };
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
};
