import { RestartRtcButton } from "./RestartButton.styled";

export const RestartButton = ({ t, status }) => {
  const handleClick = () => {
    window.location.reload();
  };

  return (
    <RestartRtcButton onClick={handleClick} status={status}>
      {t.resetButton}
    </RestartRtcButton>
  );
};
