export const De = {
  header: {
    ariaLabelMain: "Gehen Sie zur Startseite",
    altImgLogo: "Firmenlogo FreeProxySale",
    other: "Mehr Werkzeuge",
    ariaLabelOther: "Ausbaurate zusätzlicher Menüpunkte",
    close: "Schließen"
  },
  footer: {
    logo: {
      alt: "Proxy-Sale-Firmenlogo"
    },
    rights: "Alle Rechte vorbehalten",
    lang: {
      title: "Sprache wählen",
      selectLanguage: [
        { value: "de", label: "Deutsch" },
        { value: "en", label: "Englisch" },
        { value: "es", label: "Spanisch" },
        { value: "fr", label: "Französisch" },
        { value: "it", label: "Italienisch" },
        { value: "pl", label: "Polnisch" },
        { value: "ru", label: "Russisch" },
        { value: "tr", label: "Türkisch" }
      ]
    },
    tools: "Instrumente",
    company: "Gesellschaft"
  },
  navigation: {
    freeProxy: "Kostenloser Proxy",
    personalProxy: "Persönlicher Stellvertreter",
    proxyIpv6: "Proxy IPv6",
    articles: "Artikel",
    contacts: "Kontakte",
    proxyChecker: "Proxy Checker",
    myIp: "Meine IP",
    checkPorts: "Port Scanner",
    pingIp: "Ping IP",
    anonimityCheck: "Anonymitätsprüfung",
    utm: "UTM Builder",
    speed: "Proxy-Geschwindigkeitstest",
    ipv6Check: "IPv6 Validierer",
    spamBase: "IP Blacklist Check",
    filterProxy: "Proxy-Listenfilter",
    traceIp: "IP-Nachverfolgung",
    claster: "Anfragen gruppieren",
    webRtcChecker: "WebRTC-Prüfung",
    dnsChecker: "DNS-Prüfung",
    fingerPrint: "Fingerabdruck-Scanner",
    evercookie: "Evercookie-Check",
    httpHeaders: "HTTP-Header anzeigen",
    browserInfo: "Browserinformationen",
    resedentialProxy: "Wohn-Proxys",
    ispProxy: "ISP-Proxy"
  },
  mainPage: {
    proxyTable: {
      sidebarPart: {
        title: "Konfigurieren eines Proxys im Browser:",
        countries: {
          placeholder: "Länder auswählen Konstant"
        },
        type: {
          title: "Eine Art von:"
        },
        anonimity: {
          title: "Anonym:"
        },
        export: {
          title: "Exportieren:",
          ip: "IP:Port",
          excel: "Excel",
          window: "In einem neuen Fenster"
        },
        applyFilter: "Filter anwenden",
        clearFilter: "Filter löschen",
        infoLeft: "Kostenlose Proxys schließen",
        infoRight:
          "jede Möglichkeit aus, mit sozialen Netzwerken zu arbeiten, Suchmaschinen zu parsen und mit privater Software zu arbeiten. Wir sind nicht verantwortlich für die Nutzung der kostenlosen Proxy-Liste. Die IP-Liste dient nur zu Informationszwecken.",

        buyProxy: "Kaufen Sie persönliche Proxys",
        personalProxyAdvantage: [
          "Persönliche Proxys bieten Ihnen eine hohe Anonymität und Stabilität der Arbeit"
        ]
      }
    },
    faq: "Fast jeder Internetnutzer hat davon gehört, was ein Proxy-Server ist und warum er benötigt wird. Sie wurden besonders beliebt, als die Blockierung beliebter Webressourcen - soziale Netzwerke, Yandex-Dienste, Videohosting und dergleichen - massiv umgangen werden musste Die meisten interessieren sich natürlich für kostenlose Proxys, davon gibt es jede Menge im Netz, aber nicht immer ist ersichtlich, wie man sie richtig auswählt und wofür sie sich eignen und für welche Aufgaben man besser einen Proxy kaufen sollte. "
  },
  anonimityCheck: {
    mainTitle: "Meine Anonymität",
    headText:
      "Mit diesem Dienst können Sie überprüfen, wie anonym Sie im Netzwerk sind und inwieweit die von Ihrem Computer/Browser bereitgestellten Daten mit den von Ihrer IP-Adresse bereitgestellten Daten übereinstimmen.",
    top: {
      ip: "Ihre IP :",
      from: "Woher kommst du:",
      camouflage: "Deine Verkleidung:",
      anon: "Ihre Anonymität:",
      restartButton: "Scan neu starten",
      waitButton: "Wird geladen",

      result: {
        title: "Ihr Ergebnis:",
        notAnonymity: "Keine Anonymität",
        badAnonymity: "Schlechte Anonymität",
        lackAnonymity: "Unzureichende Anonymität",
        sufficientAnonymity: "Ausreichende Anonymität",
        checking: "Überprüfung, bitte warten",
        highAnonymity: "Hohe Anonymität"
      }
    },
    table: {
      titleIpInfo: "IP-Informationen",
      device: "Gerät",
      standardTimeZone: "Standardzeitzone",
      anonymityTitle: "Anonymität und Sicherheit",
      hour: "Std.",
      ip: "Meine IP",
      hideip: "IP verbergen",
      host: "Gastgeber/Anbieter",
      country: "Land",
      city: "Stadt",
      index: "Index",
      coordinates: "Koordinaten",
      os: "OS",
      browser: "Browser",
      timezone: "Zeitzone",
      ipTime: "IP-Zeit",
      systemTime: "Systemzeit",
      userAgent: "Benutzeragent",
      userAgentJs: "UserAgent JS",
      language: "Sprache",
      languageJs: "Sprache JS",
      screen: "Bildschirm",
      proxy: "Proxy",
      vpn: "VPN",
      tor: "Tor",
      anonimazer: {
        title: "Anonymisierer",
        info: "Es ist erforderlich, Ports zu schließen. Offene Ports können Ihrem Gerät erheblichen Schaden zufügen oder bereits zugefügt haben."
      },
      cookies: {
        info: "Das Deaktivieren kann das Tracking durch Werbenetzwerke und verschiedene Ressourcen verhindern, was zu mehr Privatsphäre führt. Es wird empfohlen, Cookies zu deaktivieren oder regelmäßig zu löschen."
      },
      blackList: {
        title: "Schwarze Liste",
        checks: "Schecks:",
        inBlackList: "Blacklisted:",
        buyProxy: "Reinen Proxy kaufen"
      },
      flash: "Flash",
      java: "Java",
      activeX: {
        title: "ActiveX",
        info: "Das ActiveX-Steuerelement weist Sicherheitslücken auf und kann dazu verwendet werden, Ihren Computer zu infizieren. Je mehr ActiveX-Steuerelemente Sie installieren, desto mehr Websites können deren Schwachstellen ausnutzen, um Ihr Gerät zu beschädigen."
      },
      webRtc: "WebRTC",
      webRtcIp: "WebRTC-IPs",
      openPorts: {
        title: "Offene Ports",
        links: "Portscanner"
      },
      openPortsHTTP: {
        title: "Offene HTTP-Proxy-Ports",
        info: "Die Ports müssen geschlossen werden. Offene Ports können Ihrem Gerät erheblichen Schaden zufügen oder bereits zugefügt haben."
      },
      openPortsVPN: {
        title: "Offene VPN-Ports",
        info: "Es ist erforderlich, die Ports zu schließen. Offene Ports können Ihrem Gerät erheblichen Schaden zufügen oder bereits zugefügt haben."
      },
      openPortsWeb: "Öffnen Sie Web-Proxy-Ports",

      doNotTrack: {
        title: "DoNotTrack",
        info: "Das Aktivieren dieser Option trägt zur Einzigartigkeit des Browser-Fingerabdrucks bei.",
        link: "DoNotTrack aktivieren"
      },
      dnsLeak: {
        title: "DNS-Leck",
        info: "Dritte Parteien können alle Ihre Aktivitäten im Netzwerk verfolgen. Die DNS-Einstellungen müssen geändert werden.",
        links: "DNS ändern"
      },
      suspiciousHeadlines: "Verdächtige schlagzeilen",
      suspiciousPorts: "Verdächtige ports",
      twowayPing: "Zweiseitiges ping",
      replacementUserAgent: {
        title: "Ersatz von userAgent",
        info: "Basierend auf den Daten zur Bildung des UserAgents vom Gerät, wenn die Daten unterschiedlich sind, bedeutet dies, dass eine Ersetzung stattfindet."
      },
      yourHeadings: "Ihre Überschriften",
      showButton: {
        more: "Mehr anzeigen",
        less: "Weniger anzeigen"
      }
    },
    faq: {
      text: []
    }
  },
  checkPorts: {
    analis: "Analyse läuft... Warten.",
    port: "Port",
    status: "Status",
    statusTrue: "verfügbar",
    statusFalse: "nicht verfügbar",
    description: "Beschreibung",
    portStatus: "Portstatus auf dem Server",
    scanType: "Scan-Typ",
    mainTitle: "Offene Ports online prüfen",
    videoTitle: "Was ist ein Port-Scanner und warum?",
    headText:
      "Die Überprüfung offener Ports mit einem Scanner zeigt, welche Ports auf Ihrem oder dem Computer / Standort einer anderen Person geöffnet sind und was sie antworten. Die Überprüfung wird durch den NMAP-Scanner durchgeführt und zeigt, wie offen Ihr Zugriff auf den PC / die Website für die Außenwelt ist.",
    ipTitle: "Anzeige:",
    ipElem: {
      placeholder: "Geben Sie die IP-Adresse oder Domäne ein",
      insertIp: "Fügen Sie meine IP-Adresse ein"
    },
    scanTypes: {
      title: "Arten von gescannten Ports:"
    },
    scanTitle: "Scan-Typ:",
    startScan: "Scan starten",
    resultCheck: "Testergebnisse:",
    resultCaption:
      "* Wenn das Ergebnis „Host ist ausgefallen“ lautet, wird die Firewall oder der Router überprüft und blockiert IP-Adressen.",
    faq: {
      text: []
    }
  },
  claster: {
    mainTitle: "Tool für Keyword-Gruppierung",
    checker: {
      regionTitle: "Region prüfen",
      selectPlaceholder: "Region auswählen",
      clasterTitle: "Clustering-Schwellenwert",
      groupBtn: "Gruppe",
      queryTitle: "Anfragen"
    },
    headText:
      "Dank dieses Dienstes steht Ihnen Online-Suchanfragen-Clustering zur Verfügung. Damit können Sie Schlüsselwörter automatisch auf der Grundlage von Yandex- und Google-Suchergebnissen gruppieren. Ein kompetenter Aufbau der Website-Struktur erhöht Ihre Chancen, Ihre Website auf die Spitzenpositionen zu bringen. Unsere Der Online-Clustering-Service für Abfragen hilft Ihnen, Ihre Keywords auf Ihren Seiten so zu kategorisieren, dass Sie den meisten SEO-Traffic erhalten."
  },
  filterProxy: {
    mainTitle: "Proxy-Filter",
    headText:
      "Beim Proxy-Filtern wird ein Proxy-Filter an den Hauptserver gesendet, um bestimmte Empfehlungen auszuwählen.",
    filterOut: "Aussortieren",
    save: "Speichern unter"
  },
  ipv6: {
    mainTitle: "Site-Prüfung auf IPv6-Support",
    headText:
      "Die Version des IPv6-Protokolls, die maximalen Schutz im Netzwerk bietet, erfordert für einen effizienteren Betrieb die Verwendung eines standortspezifischen Support-Tools für das neue Proxy-Protokoll.",
    checker: {
      addressTitle: "Geben Sie die Site-Adresse zur Überprüfung ein",
      checkProxyBtn: "Proxy prüfen",
      tip: "Der Test kann eine Weile dauern",
      resultTitle: "Testergebnisse:",
      result: {
        success: "Herzlichen Glückwunsch, diese Seite unterstützt IPv6",
        error: "Leider unterstützt die Seite derzeit kein IPv6"
      }
    },

    faq: {
      text: []
    }
  },
  myIp: {
    top: {
      title:
        "Und dies ist nur ein kleiner Teil der Informationen, die anhand Ihrer IP-Adresse nachverfolgt werden können:",
      ipv4: "Ihre IPv4-Adresse:",
      ipv6: "Ihre IPv6-Adresse:",
      changeIp: "Ändern Sie Ihre IP-Adresse",
      table: {
        check: "Überprüfen",
        country: "Land",
        city: "Stadt",
        index: "Postcode",
        host: "Gastgeber",
        system: "System",
        browser: "Dein Browser",
        from: "Woher kommst du",
        provider: "Anbieter",
        anon: "Anonymisierer",
        proxy: "Proxy",
        protocol: "Protokoll",
        imgAlt: {
          country: "Land",
          city: "Stadt",
          index: "Postcode",
          screen: "Monitor",
          os: "Betriebssystem",
          browser: "Browser version",
          location: "Standort",
          server: "Server",
          anon: "Anonymisierer",
          proxy: "Proxy",
          protocol: "Protokoll",
          blacklist: "Schwarze liste"
        }
      },
      map: {
        title: "Ihr Standort auf der Karte!",
        hide: "Sie können Ihren Standort verbergen",
        text: "Es reicht aus, unser einfaches, bequemes und zuverlässiges Tool zu verwenden. Verstecken Sie persönliche Daten, schützen Sie den Datenverkehr und entfernen Sie alle Beschränkungen aus Ihrem Internet",
        hideIp: "IP-Adresse verbergen"
      },
      caution: {
        top: "Wie Sie sehen können, liefert IP recht detaillierte Informationen.",
        bottom:
          "Bei Verbindung mit dem Internet wird dem Computer eine Nummer namens IP (Internet Protocol Address) zugewiesen. Dabei ist zu beachten, dass die Zuordnung nicht willkürlich ist, sondern die IP, die dem Provider zur Verfügung steht und kostenlos ist. IP ändert sich nach dem Trennen wann Wiederverbindung. Diese IPs werden als dynamisch bezeichnet. Wenn Sie möchten, dass sie unverändert bleiben, wenden Sie sich an Ihren ISP. Normalerweise ist nicht-dynamisch gegen eine Gebühr verfügbar. Es wird auch als extern bezeichnet. Es wird oft von spezialisierten Online-Programmen verfolgt, die die zugehörigen Informationen ermitteln über den Eigentümer. Als interne IP oder lokal wird die individuelle Adresse eines PCs (Laptop) in einem lokalen Netzwerk bezeichnet."
      }
    },
    faq: {
      text: []
    }
  },
  pingIp: {
    mainTitle: "Ping der IP-Adresse oder Site überprüfen",
    whatIsPing: "Was ist Ping-IP und warum?",
    headText: [
      "Klicken Sie auf die Schaltfläche 'Meine IP-Adresse einfügen' oder verwenden Sie die IP oder Site, zu der Sie den Ping überprüfen möchten. Wählen Sie 3-5 Testpakete aus und klicken Sie auf die Schaltfläche 'Ping-IP'. Sie sehen den gewünschten Wert im Feld ' time = 'Abschnitt.'",
      "Ping gilt als normal, wenn er im Bereich von 40-110 ms liegt. Ab 110-210 ms treten Probleme bei Online-Spielen auf, aber dies wirkt sich kaum auf Ihre Besuche auf Websites aus. Wenn die timeoutConstant 110 ms überschreitet, dann ist dies ein Grund, über die Qualität der Arbeit Ihres ISP nachzudenken."
    ],
    checker: {
      showTitle: "Anzeige:",
      showSubtitle:
        "Geben Sie eine gültige IP-Adresse (0.0.0.0) oder einen Hostnamen (host.com) ein.",
      ipInsert: "Fügen Sie meine IP-Adresse ein",
      addTitle: "Anzahl der Packstücke:",
      selectPlaceholder: "Wähle ein Land",
      startScan: "Scan starten",
      resultTitle: "Scan starten:",
      resultCaption:
        "* Wenn das Ergebnis „Host ist ausgefallen“ lautet, wird die Firewall oder der Router überprüft und blockiert IP-Adressen."
    },
    faq: {
      text: []
    }
  },
  proxyChecker: {
    mainTitle: "Proxy-Checker",
    condition: "Status",
    ping: "Ping",
    type: "Typ",
    status: "Funktioniert nicht",
    workStatus: "Verfügbar",
    head: {
      videoTitle: "Wie und warum man einen Proxy-Checker verwendet?",
      headText:
        "Online-Proxy-Checker zur Überprüfung der Gültigkeit von Proxy-Servern. Analyse eines Proxys oder einer Gruppe von Adressen auf Verfügbarkeit, Land, Geschwindigkeit, Anonymität, Typ.",
      titleFirst: "Proxy-Checker kann:",
      listFirst: [
        "überprüfe IPv4, IPv6 auf Länderbesitz;",
        "Bestimmung des Protokolltyps HTTPS, SOCKS5 und aktuelle Geschwindigkeit;",
        "Prüfung der Anonymität der Adresse;",
        "Gruppe, Hochgeschwindigkeits-Multithread-Proxy-Prüfung in 64 Threads;",
        "die Möglichkeit, eine Liste hochzuladen und das Ergebnis der Prüfung hochzuladen;",
        "Komfortable Anzeige der Ergebnisse durch einen Anzeigefilter."
      ],
      titleSecond:
        "In welchem Format soll dem Checker ein Proxy hinzugefügt werden?",
      listSecond: [
        {
          content:
            "Wenn Sie <span>öffentliche Proxys</span> haben (ohne Login und Passwort), dann - IP: PORT"
        },
        {
          content:
            "Wenn Sie <span>private Proxys</span> haben (mit Autorisierung durch Login und Passwort), dann - IP: PORT: USER: PASS"
        }
      ],
      titleThird:
        "P.S.: Wenn Sie bei uns <a href = 'https://proxy-sale.com/' rel = 'noreferrer' target = '_blank'> einen Proxy gekauft haben </a>, dann privat!"
    },
    faq: {
      text: []
    }
  },
  siteSpeed: {
    mainTitle: "Zugang auf die Site über den Proxy prüfen",
    head: {
      listFirst: [
        "Geschwindigkeit der Datenübertragung über HTTP und SOCKS-Proxy bestimmen",
        "Fähigkeit, die Ladegeschwindigkeit von Seiten direkt zu bestimmen",
        "Site-Zugriff prüfen"
      ],
      caution: "In welchem Format soll die Prüfung erfolgen?",
      subtitle:
        "Um die Geschwindigkeit über einen Proxy zu überprüfen, müssen Sie eine Ressource, auf die Sie Zugriff benötigen, und eine IP-Adresse im Format:",
      listSecond: [
        {
          text: "Öffentliche Proxys (ohne Login und Passwort), dann -",
          value: "IP:PORT"
        },
        {
          text: "Private Proxys (mit Anmelde- und Passwortautorisierung), dann —",
          value: "IP:PORT:USER:PASS"
        }
      ],
      post: "P.S.: Wenn Sie bei uns einen Proxy gekauft haben, dann privat!"
    },
    checker: {
      adress:
        "Geben Sie die Website-URL ein, um die Geschwindigkeit zu überprüfen",
      proxy: "Proxy-Adresse im Format",
      format: "127.0.0.1:8080",
      subtitle: [
        "Um die Geschwindigkeit der Datenübertragung über einen Proxy zu testen, geben Sie die Adresse und den Port des Proxy-Servers ein",
        "Für einen normalen Test der Geschwindigkeit beim Laden von Seiten der Website lassen Sie dieses Feld leer."
      ],
      type: {
        title: "Typ des Proxy-Servers"
      },
      timeout: {
        title: "Auszeit:",
        placeholder: "Auszeit"
      },
      access: {
        title: "Überprüfen Sie den Zugriff auf die Website",
        body: "Zugriffs- und Geschwindigkeitsprüfungen können einige Zeit in Anspruch nehmen"
      },
      btn: "Geschwindigkeit prüfen",
      result: {
        title: "Testergebnisse:",
        result: {
          titleFirst:
            "Überprüfen Sie - die Zugriffsgeschwindigkeit auf die Site über den Proxy",
          checkSite: "Überprüfung der Website",
          checkProxy: "Proxy prüfen",
          timeout: "Auszeit",
          sec: "sek",
          amountOfDownloads: "Testdownloads hergestellt",
          titleSecond: "Prüfung abgeschlossen",
          averageSpeed: "Durchschnittliche Downloadgeschwindigkeit",
          generalSpeed: "Gesamtdurchschnittsgeschwindigkeit",
          speedValue: "Kb/sec",
          access:
            "Zugriff auf die Website - Titel von der heruntergeladenen Seite",
          emptyTitle: "Titel konnte nicht abgerufen werden",
          isProxyUsing: {
            yes: "Proxy verwenden",
            no: "Ohne Proxy direkt laden"
          }
        }
      }
    },
    faq: {
      text: []
    },
    errors: {
      url: "Website eingeben url"
    }
  },
  spamBase: {
    mainTitle: "Verfügbarkeit und Prüfung von IP in Spam-Datenbanken",
    head: {
      text: "Wie Sie wissen, kann Spam häufig dazu führen, dass IP auf eine schwarze Liste gesetzt wird, was den gesamten Netzwerkbetrieb spürbar stört. Um dies zu vermeiden, sollten Sie den IP-Check-Server in Spam-Datenbanken verwenden."
    },
    checker: {
      ip: {
        title: "Bitte geben Sie die richtige IP-Adresse ein",
        insert: "Fügen Sie meine IP-Adresse ein"
      },
      check: "Untersuchung",
      test: "Der Test kann eine Weile dauern.",
      list: {
        checkedAll: "Schecks:",
        checked: "Geprüft:",
        blackList: "Auf der schwarzen Liste:"
      },
      result: {
        listed: "Gelistet",
        notListed: "Nicht aufgeführt"
      }
    },
    faq: {
      text: []
    }
  },
  trace: {
    mainTitle: "IP Verfolgung",
    head: {
      text: "Dank des Dienstes auf dieser Seite haben Sie Zugriff auf eine kostenlose und fast sofortige Verfolgung der IP-Adresse oder Site. Es hilft Ihnen bei der Durchführung einer Netzwerkdiagnose, die die Geschwindigkeit des Sendens von Anfragen über Server im Internet bestimmt, wenn Sie Öffnen Sie eine Webressource in einem Browser. Ermitteln Sie die Ursachen möglicher Probleme im Zusammenhang mit dem langsamen Laden der Website. "
    },
    checker: {
      ip: "Geben Sie die richtige IP-Adresse (0.0.0.0) oder den Hostnamen (domain.ru) ein",
      route: {
        title: "Paketroute nach Land anzeigen",
        placeholder: "Wähle einen Artikel aus"
      },
      btn: "IP verfolgen",
      result: "Testergebnisse:"
    }
  },
  webRtcCheck: {
    mainTitle: "WebRTC-Prüfung",
    head: {
      text: "Überprüfung auf Lecks über WebRTC. Ihre Daten können gefährdet sein, selbst wenn Sie mit einem VPN verbunden sind."
    },
    top: {
      myIp: "Ihre IP-Adresse:",
      from: "Woher sind Sie:",
      state: "Status:",
      warning: "Mögliche Gefahr"
    },
    info: {
      top: "Wie Sie sehen, liefert IP recht detaillierte Informationen.",
      bottom:
        "Wir haben überprüft, ob WebRTC in Ihrem Browser aktiviert ist und ob Sie über eine echte IP-Adresse verfügen. Wie sich herausstellt, besteht die Gefahr eines potenziellen Lecks, WebRTC ist aktiviert und kann Ihre tatsächliche IP-Adresse preisgeben.",
      disableBottom:
        "Wir haben überprüft, ob WebRTC in Ihrem Browser aktiviert ist und ob Sie über eine echte IP-Adresse verfügen. Es stellt sich heraus, dass Ihre tatsächliche IP-Adresse unbekannt bleibt und WebRTC in Ihrem Browser nicht aktiviert ist."
    },
    resetButton: "Starten Sie den WebRTC-Lecktest neu",
    status: {
      type: "Typ",
      state: "Status",
      topText: "WebRTC aktiviert",
      bottomText: "Drohung, Ihre echte IP-Adresse preiszugeben!",
      warning: "Gefahr einer Leckage",
      checking: "Überprüfen Sie, bitte warten Sie"
    }
  },
  dnsCheck: {
    top: {
      myIp: "Ihre IP-Adresse:",
      from: "Woher sind Sie:",
      state: "Status:",
      warning: "Mögliche Gefahr"
    },
    info: {
      top: "Wie Sie sehen können, wurde beim Überprüfen Ihrer IP eine DNS-Leckage festgestellt.",
      bottom:
        "Wir haben Ihre IP gescannt und eine Leckage über DNS festgestellt. Ihre Anfragen sind offenbart worden und die Verbindung ist nicht sicher. Das bedeutet, dass der Administrator Ihres DNS Ihre besuchten Websites und Programme verfolgen kann.",
      disableBottom:
        "Wir haben Ihre IP gescannt und keine Leckage über DNS festgestellt. Ihre Anfragen sind nicht offenbart worden und die Verbindung ist sicher. Das bedeutet, dass der Administrator Ihres DNS keine Informationen über die von Ihnen besuchten Websites und Programme verfolgen kann."
    },
    resetButton: "Starten Sie den DNS-Lecktest neu",
    status: {
      type: "Typ",
      state: "Status",
      topText: "DNS aktiviert",
      bottomText: "Bedrohung, Ihre Anfragen offenzulegen!",
      warning: "Gefahr einer Leckage",
      checking: "Überprüfen Sie, bitte warten Sie"
    }
  },
  fingerPrintScanner: {
    mainTitle: "Browser-Fingerabdruckscanner",
    head: {
      text: "Browser-Fingerabdrücke sind eine einzigartige Momentaufnahme der Einstellungen Ihres Computers und spezielle Website-Mechanismen können Sie verfolgen."
    },
    top: {
      myIp: "Ihre IP-Adresse:",
      fingerprint: "Browser-Fingerabdruck:"
    },
    table: {
      location: "Standort",
      provider: "Anbieter",
      host: "Gastgeber",
      os: "OS",
      browser: "Browser",
      plugin: "Plugin:",
      status: "Status:",
      isOn: "ermöglicht",
      isOff: "ausgeschaltet",
      device: "Gerät",
      browserLanguage: "Browsersprache",
      screenSize: "Bildschirmgröße",
      timeZone: "Zeitzone"
    },
    imgAlt: {
      location: "Standort",
      provider: "Anbieter",
      host: "Gastgeber",
      os: "Operationssystem",
      browser: "Browser",
      language: "Browsersprache",
      size: "Bildschirmgröße",
      timeZone: "Zeitzone"
    },
    bottom: {
      method: "JS-Methode"
    }
  },
  createEvercookie: {
    mainTitle: "Evercookie-Check",
    head: {
      text: "Klicken Sie auf die Schaltfläche, um ein Evercookie zu erstellen. Ihre Anonymität wird nicht gefährdet: Ein Cookie ist eine Zufallszahl zwischen 1 und 1000, die wir zum Testen von Evercookies verwenden. Wir verfolgen Ihre Nutzung nicht."
    },
    top: {
      button: "Erstellen Sie Evercookie"
    }
  },
  httpHeaders: {
    requestHeaders: "Anforderungsheader",
    responseHeaders: "Antwortheader",
    submitButton: "Anfrage einreichen"
  },
  browserInfo: {
    mainTitle: "Informationen von Ihrem Browser",
    head: {
      text:
        "Mithilfe des Browsers haben verschiedene Websites und Dienste die Möglichkeit, den Benutzer zu verfolgen. Dies ist beispielsweise erforderlich, um eine Person auch dann identifizieren zu können, wenn diese ihre IP-Adresse geändert hat. Werbesysteme nutzen auch Tracking-Funktionen, um die Interessen des Nutzers zu kennen.\n" +
        "Unser Service hilft Ihnen herauszufinden, welche Tracking-Methoden in Ihrem Browser aktiviert sind."
    },
    table: {
      type: "Typ",
      discovered: "Entdeckt",
      cookieMeaning: "Cookie-Bedeutung",
      parameter: "Parameter",
      value: "Wert",
      resolution: "Bildschirmauflösung:",
      colorDepth: "Farbtiefe",
      bit: "bisschen"
    },
    button: {
      createCookie: "Cookies erstellen",
      userTracking: "Benutzerverfolgung"
    }
  },
  utm: {
    mainTitle: "Generator UTM-Tags Online",
    checker: {
      linkUtm: "Link mit UTM-Tag erscheint hier",
      copy: "Kopieren",
      shortUrl: "Kurz URL",
      traffic: [
        { text: "Google ADS", source: "google" },
        { text: "Yandex.Direct", source: "yandex" },
        { text: "VK.com", source: "vk" },
        { text: "Facebook", source: "facebook" },
        { text: "Instagram", source: "instagram" },
        { text: "Target.Mail.ru", source: "targetmailru" },
        { reset: true, text: "Reset" }
      ]
    },
    faq: {
      text: []
    }
  },
  pageHead: {
    ip: "Ihre IP",
    changeIp: "IP ändern",
    anon: "Ihre Anonymität",
    moreAnon: "Erfahren Sie mehr über Anonymität"
  },
  checker: {
    country: "Wähle ein Land:",
    add: "Zuerst hinzufügen:",
    show: {
      title: "Anzeige"
    },
    displayType: "Anzeige",
    checkProxy: "Proxy prüfen",
    fileName: "Laden von .txt oder .csv",
    typeProxy: {
      title: "Proxy eingeben:",
      placeholder: "Type your text here"
    },
    info: {
      text: "Befreien Sie sich von Einschränkungen und Unannehmlichkeiten, dem Risiko, gesperrt zu werden oder Ihre IP-Adresse dauerhaft zu ändern. Persönliche Proxys für die Arbeit mit sozialen Netzwerken, Avito, Key Collector und anderen Zwecken.",
      buy: "Schutz kaufen"
    },
    results: {
      title: "Testergebnisse",
      checked: "Geprüft",
      working: "Arbeiten",
      notWorking: "Funktioniert nicht",
      divider: "von",
      btn: "Arbeiten kaufen"
    },
    download: {
      title: "Laden Sie nur den Arbeitsproxy herunter",
      txt: "download .txt",
      csv: "Tisch ein .csv"
    }
  },
  common: {
    faqTitle: "Wir beantworten Ihre Fragen",
    yes: "Ja",
    no: "Nein",
    notDefined: "Nicht definiert",
    copy: "Kopiert!",
    select: {
      placeholderCountry: "Land auswählen"
    },
    buyAnonProxy: "Kaufen Sie Anonymität",
    buyWorkingProxy: "Arbeiten kaufen"
  },
  table: {
    top: {
      workProxy: "Funktionierende Proxys",
      anon: "Anonym",
      socks: "Socks 4/5",
      http: "HTTP/HTTPS",
      proxyInBase: "Proxy in der Datenbank"
    },
    tableColumns: {
      copy: "In die Zwischenablage kopieren",
      country: "Land",
      type: "Eine Art von",
      anon: {
        title: "Anonymität",
        speed: "Geschwindigkeit",
        s: "s",
        ms: "ms",
        m: "m",
        h: "h",
        types: {
          anon: "Anonymität",
          proz: "Transparent",
          elite: "Elite",
          notFounded: "Nicht gegründet"
        }
      },
      check: {
        title: "Untersuchung",
        tip: "Prompt",
        buy: "Arbeiten kaufen",
        reload: "Neustart",
        ago: "min. vor",
        status: {
          work: "Arbeiten",
          notWorked: "Funktioniert nicht"
        }
      },
      condition: {
        title: "Zustand"
      },
      login: "Anmeldung",
      password: "Passwort",
      pagination: {
        rowsPerPage: "Proxys für Seite",
        separatorText: "von"
      },
      requests: "Anfragen",
      requestsCluster: "Anfragen auf Clustern",
      competitors: "Konkurrenten"
    },
    noDataComponent: "Es gibt keine Proxys"
  },
  errorTranslation: {
    somethingWrong: "Etwas ging schief, versuche es erneut",
    largeProxyList:
      "Die Anzahl der zu verifizierenden Proxys sollte 100 nicht überschreiten",
    emptyProxy: "Keine Proxys für den Export",
    apiError: "Etwas ist schief gelaufen!",
    wrongFile: "Ungültiger Dateityp. Nur .txt oder .csv erlaubt",
    nonReadableFile: "Datei kann nicht gelesen werden!",
    emptyProxyList: "Proxy eingeben!",
    emptyClusterQuery: "Geben Sie Anfragen ein!",
    clusterQueryMinValue: "Geben Sie nicht weniger als 2 Anfragen ein!",
    incorrectIpOrDomain: "Falsche IP oder Domäne! ",
    incorrectIp: "Falsche IP! ",
    proxyFilterEmpty: "Proxy eingeben!",
    proxyIsEmpty: "Dieses Land hat keine Proxys!",
    proxyFilterIncorrect: "Falscher Proxy!",
    emptyIpOrDomain: "Geben Sie die richtige IP-Adresse oder Domain ein!",
    emptySiteUrl: "Site-URL eingeben!",
    emptyUserAgent: "Wählen Sie einen Benutzer-Agenten aus!",
    invalidUrl: "Ungültiges URL-Format!",
    timeError: "Versuchen Sie es in ein paar Minuten erneut!",
    IncorrectUrlFormat: "Falsches URL-Format!"
  },
  anonimityTypes: [
    {
      value: "an-anonim.txt",
      label: "Anonym",
      defaultChecked: false,
      route: "anonimnyj"
    },
    {
      value: "el-elit.txt",
      label: "Elite",
      defaultChecked: false,
      route: "elite"
    },
    {
      value: "pr-proz.txt",
      label: "Transparent",
      defaultChecked: false,
      route: "transparent"
    }
  ],
  browsers: [
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/chrome.svg",
      value: "Google Chrome"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/safari.svg",
      value: "Safari"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/opera.svg",
      value: "Opera"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/firefox.svg",
      value: "Firefox"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/yandex.svg",
      value: "Yandex"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/android.svg",
      value: "Android"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/explorer.svg",
      value: "Internet Explorer"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      img: "/img/browsers/browser1.svg",
      value: "Browser"
    },
    {
      route: "https://proxy-sale.com/company/articles/",
      value: "+",
      last: true
    }
  ],
  checkerProxyTypes: [
    {
      value: "HTTP,HTTPS",
      label: "HTTP/HTTPS",
      defaultChecked: true
    },
    {
      value: "SOCKS4,SOCKS",
      label: "SOCKS 4/5",
      defaultChecked: true
    },
    {
      value: "NO",
      label: "Nicht arbeitend",
      defaultChecked: true
    }
  ],
  checkPortsTypes: [
    {
      value: "POPULAR",
      label: "Beliebte Ports",
      defaultChecked: true
    },
    {
      value: "SERVER",
      label: "Server-Ports",
      defaultChecked: false
    },
    {
      value: "GAME",
      label: "Spiel-Ports",
      defaultChecked: false
    },
    {
      value: "APPLICATION",
      label: "Anwendungs-Ports",
      defaultChecked: false
    },
    {
      value: "P2P",
      label: "P2P-Ports",
      defaultChecked: false
    }
  ],
  utmInputs: [
    {
      label: "Zielseite url",
      name: "url",
      placeholder: "Fügen Sie hier Ihren Link oder Ihre Zielseite ein"
    },
    {
      label: "UTM-quelle*",
      name: "source",
      placeholder: "Verkehrsquelle. Zum Beispiel: googeln"
    },
    {
      label: "UTM-mittel*",
      name: "medium",
      placeholder: "Verkehrstyp. Zum Beispiel: CPC, E-Mail, Banner"
    },
    {
      label: "UTM-Kampagne*",
      name: "campaign",
      placeholder: "Werbeunternehmen, zum Beispiel: google-poisk"
    },
    {
      label: "UTM-Inhalt",
      name: "content",
      placeholder: "Zum Beispiel: CPC, E-Mail, Banner"
    },
    {
      label: "UTM-Begriff",
      name: "term",
      placeholder: "Stichwort. Zum Beispiel: Makro (Schlüsselwort)"
    }
  ],
  yesNo: [
    { label: "Ja", value: true },
    { label: "Nein", value: false }
  ],
  timeout: [
    { label: "10 sek", value: 10 },
    { label: "15 sek", value: 15 },
    { label: "30 sek", value: 30 },
    { label: "60 sek", value: 60 }
  ],
  countries: [
    {
      dataBaseValue: "Andorra",
      value: "Andorra",
      label: "Andorra",
      imgSrc: "/img/flags/ad.svg"
    },
    {
      dataBaseValue: "UAE",
      value: "VAE",
      label: "VAE",
      imgSrc: "/img/flags/ae.svg"
    },
    {
      dataBaseValue: "Afghanistan",
      value: "Afghanistan",
      label: "Afghanistan",
      imgSrc: "/img/flags/af.svg"
    },
    {
      dataBaseValue: "Antigua and Barbuda",
      value: "Antigua und Barbuda",
      label: "Antigua und Barbuda",
      imgSrc: "/img/flags/ag.svg"
    },
    {
      dataBaseValue: "Anguilla",
      value: "Anguilla",
      label: "Anguilla",
      imgSrc: "/img/flags/ai.svg"
    },
    {
      dataBaseValue: "Albania",
      value: "Albanien",
      label: "Albanien",
      imgSrc: "/img/flags/al.svg"
    },
    {
      dataBaseValue: "Armenia",
      value: "Armenien",
      label: "Armenien",
      imgSrc: "/img/flags/am.svg"
    },
    {
      dataBaseValue: "Antilles",
      value: "Antillen",
      label: "Antillen",
      imgSrc: "/img/flags/an.svg"
    },
    {
      dataBaseValue: "Angola",
      value: "Angola",
      label: "Angola",
      imgSrc: "/img/flags/ao.svg"
    },
    {
      dataBaseValue: "Antarctic",
      value: "Antarktis",
      label: "Antarktis",
      imgSrc: "/img/flags/aq.svg"
    },
    {
      dataBaseValue: "Argentina",
      value: "Argentinien",
      label: "Argentinien",
      imgSrc: "/img/flags/ar.svg"
    },
    {
      dataBaseValue: "American Samoa",
      value: "Amerikanischen Samoa-Inseln",
      label: "Amerikanischen Samoa-Inseln",
      imgSrc: "/img/flags/as.svg"
    },
    {
      dataBaseValue: "Austria",
      value: "Österreich",
      label: "Österreich",
      imgSrc: "/img/flags/at.svg"
    },
    {
      dataBaseValue: "Australia",
      value: "Australien",
      label: "Australien",
      imgSrc: "/img/flags/au.svg"
    },
    {
      dataBaseValue: "Aruba",
      value: "Aruba",
      label: "Aruba",
      imgSrc: "/img/flags/aw.svg"
    },
    {
      dataBaseValue: "Åland Islands",
      value: "Åland-Inseln",
      label: "Åland-Inseln",
      imgSrc: "/img/flags/ax.svg"
    },
    {
      dataBaseValue: "Azerbaijan",
      value: "Aserbaidschan",
      label: "Aserbaidschan",
      imgSrc: "/img/flags/az.svg"
    },
    {
      dataBaseValue: "Bosnia and Herzegovina",
      value: "Bosnien und Herzegowina",
      label: "Bosnien und Herzegowina",
      imgSrc: "/img/flags/ba.svg"
    },
    {
      dataBaseValue: "Barbados",
      value: "Barbados",
      label: "Barbados",
      imgSrc: "/img/flags/bb.svg"
    },
    {
      dataBaseValue: "Bangladesh",
      value: "Bangladesch",
      label: "Bangladesch",
      imgSrc: "/img/flags/bd.svg"
    },
    {
      dataBaseValue: "Belgium",
      value: "Belgien",
      label: "Belgien",
      imgSrc: "/img/flags/be.svg"
    },
    {
      dataBaseValue: "Burkina Faso",
      value: "Burkina Faso",
      label: "Burkina Faso",
      imgSrc: "/img/flags/bf.svg"
    },
    {
      dataBaseValue: "Bulgaria",
      value: "Bulgarien",
      label: "Bulgarien",
      imgSrc: "/img/flags/bg.svg"
    },
    {
      dataBaseValue: "Bahrain",
      value: "Bahrein",
      label: "Bahrein",
      imgSrc: "/img/flags/bh.svg"
    },
    {
      dataBaseValue: "Burundi",
      value: "Burundi",
      label: "Burundi",
      imgSrc: "/img/flags/bi.svg"
    },
    {
      dataBaseValue: "Benin",
      value: "Benin",
      label: "Benin",
      imgSrc: "/img/flags/bj.svg"
    },
    {
      dataBaseValue: "Saint Barthélemy",
      value: "Sankt Barthélemy",
      label: "Sankt Barthélemy",
      imgSrc: "/img/flags/bl.svg"
    },
    {
      dataBaseValue: "Bermuda",
      value: "Bermudas",
      label: "Bermudas",
      imgSrc: "/img/flags/bm.svg"
    },
    {
      dataBaseValue: "Brunei",
      value: "Brunei",
      label: "Brunei",
      imgSrc: "/img/flags/bn.svg"
    },
    {
      dataBaseValue: "Bolivia",
      value: "Bolivien",
      label: "Bolivien",
      imgSrc: "/img/flags/bo.svg"
    },
    {
      dataBaseValue: "Bonaire",
      value: "Bonaire",
      label: "Bonaire",
      imgSrc: "/img/flags/bq.svg"
    },
    {
      dataBaseValue: "Brazil",
      value: "Brasilien",
      label: "Brasilien",
      imgSrc: "/img/flags/br.svg"
    },
    {
      dataBaseValue: "Bahamas",
      value: "Bahamas",
      label: "Bahamas",
      imgSrc: "/img/flags/bs.svg"
    },
    {
      dataBaseValue: "Butane",
      value: "Butan",
      label: "Butan",
      imgSrc: "/img/flags/bt.svg"
    },
    {
      dataBaseValue: "Bouvet Island",
      value: "Bouvet-Insel",
      label: "Bouvet-Insel",
      imgSrc: "/img/flags/bv.svg"
    },
    {
      dataBaseValue: "Botswana",
      value: "Botswana",
      label: "Botswana",
      imgSrc: "/img/flags/bw.svg"
    },
    {
      dataBaseValue: "Belarus",
      value: "Weißrussland",
      label: "Weißrussland",
      imgSrc: "/img/flags/by.svg"
    },
    {
      dataBaseValue: "Belize",
      value: "Belize",
      label: "Belize",
      imgSrc: "/img/flags/bz.svg"
    },
    {
      dataBaseValue: "Canada",
      value: "Kanada",
      label: "Kanada",
      imgSrc: "/img/flags/ca.svg"
    },
    {
      dataBaseValue: "Coconut islands",
      value: "Kokosinseln",
      label: "Kokosinseln",
      imgSrc: "/img/flags/cc.svg"
    },
    {
      dataBaseValue: "DR Congo",
      value: "DR Kongo",
      label: "DR Kongo",
      imgSrc: "/img/flags/cd.svg"
    },
    {
      dataBaseValue: "Central African Republic",
      value: "Zentralafrikanische Republik",
      label: "Zentralafrikanische Republik",
      imgSrc: "/img/flags/cf.svg"
    },
    {
      dataBaseValue: "Congo",
      value: "Kongo",
      label: "Kongo",
      imgSrc: "/img/flags/cg.svg"
    },
    {
      dataBaseValue: "Switzerland",
      value: "Schweiz",
      label: "Schweiz",
      imgSrc: "/img/flags/ch.svg"
    },
    {
      dataBaseValue: "Ivory Coast",
      value: "Elfenbeinküste",
      label: "Elfenbeinküste",
      imgSrc: "/img/flags/ci.svg"
    },
    {
      dataBaseValue: "Cook Islands",
      value: "Cookinseln",
      label: "Cookinseln",
      imgSrc: "/img/flags/ck.svg"
    },
    {
      dataBaseValue: "Chile",
      value: "Chile",
      label: "Chile",
      imgSrc: "/img/flags/cl.svg"
    },
    {
      dataBaseValue: "Cameroon",
      value: "Kamerun",
      label: "Kamerun",
      imgSrc: "/img/flags/cm.svg"
    },
    {
      dataBaseValue: "China",
      value: "China",
      label: "China",
      imgSrc: "/img/flags/cn.svg"
    },
    {
      dataBaseValue: "Colombia",
      value: "Kolumbien",
      label: "Kolumbien",
      imgSrc: "/img/flags/co.svg"
    },
    {
      dataBaseValue: "Costa Rica",
      value: "Costa Rica",
      label: "Costa Rica",
      imgSrc: "/img/flags/cr.svg"
    },
    {
      dataBaseValue: "Cuba",
      value: "Kuba",
      label: "Kuba",
      imgSrc: "/img/flags/cu.svg"
    },
    {
      dataBaseValue: "Cape Verde",
      value: "Kap Verde",
      label: "Kap Verde",
      imgSrc: "/img/flags/cv.svg"
    },
    {
      dataBaseValue: "Curacao",
      value: "Curacao",
      label: "Curacao",
      imgSrc: "/img/flags/cw.svg"
    },
    {
      dataBaseValue: "Christmas Island",
      value: "Weihnachtsinsel",
      label: "Weihnachtsinsel",
      imgSrc: "/img/flags/cx.svg"
    },
    {
      dataBaseValue: "Cyprus",
      value: "Zypern",
      label: "Zypern",
      imgSrc: "/img/flags/cy.svg"
    },
    {
      dataBaseValue: "Czech Republic",
      value: "Tschechische Republik",
      label: "Tschechische Republik",
      imgSrc: "/img/flags/cz.svg"
    },
    {
      dataBaseValue: "Germany",
      value: "Deutschland",
      label: "Deutschland",
      imgSrc: "/img/flags/de.svg"
    },
    {
      dataBaseValue: "Djibouti",
      value: "Dschibuti",
      label: "Dschibuti",
      imgSrc: "/img/flags/dj.svg"
    },
    {
      dataBaseValue: "Denmark",
      value: "Dänemark",
      label: "Dänemark",
      imgSrc: "/img/flags/dk.svg"
    },
    {
      dataBaseValue: "Dominica",
      value: "Dominika",
      label: "Dominika",
      imgSrc: "/img/flags/dm.svg"
    },
    {
      dataBaseValue: "Dominican Republic",
      value: "Dominikanische Republik",
      label: "Dominikanische Republik",
      imgSrc: "/img/flags/do.svg"
    },
    {
      dataBaseValue: "Algeria",
      value: "Algerien",
      label: "Algerien",
      imgSrc: "/img/flags/dz.svg"
    },
    {
      dataBaseValue: "Ecuador",
      value: "Ecuador",
      label: "Ecuador",
      imgSrc: "/img/flags/ec.svg"
    },
    {
      dataBaseValue: "Estonia",
      value: "Estland",
      label: "Estland",
      imgSrc: "/img/flags/ee.svg"
    },
    {
      dataBaseValue: "Egypt",
      value: "Ägypten",
      label: "Ägypten",
      imgSrc: "/img/flags/eg.svg"
    },
    {
      dataBaseValue: "West Sahara",
      value: "Westsahara",
      label: "Westsahara",
      imgSrc: "/img/flags/eh.svg"
    },
    {
      dataBaseValue: "Eritrea",
      value: "Eritrea",
      label: "Eritrea",
      imgSrc: "/img/flags/er.svg"
    },
    {
      dataBaseValue: "Spain",
      value: "Spanien",
      label: "Spanien",
      imgSrc: "/img/flags/es.svg"
    },
    {
      dataBaseValue: "Ethiopia",
      value: "Äthiopien",
      label: "Äthiopien",
      imgSrc: "/img/flags/et.svg"
    },
    {
      dataBaseValue: "Europe",
      value: "Europa",
      label: "Europa",
      imgSrc: "/img/flags/eu.svg"
    },
    {
      dataBaseValue: "Finland",
      value: "Finnland",
      label: "Finnland",
      imgSrc: "/img/flags/fi.svg"
    },
    {
      dataBaseValue: "Netherlands",
      value: "Niederlande",
      label: "Niederlande",
      imgSrc: "/img/flags/nl.svg"
    },
    {
      dataBaseValue: "Fiji",
      value: "Fidschi",
      label: "Fidschi",
      imgSrc: "/img/flags/fj.svg"
    },
    {
      dataBaseValue: "Falkland Islands",
      value: "Falkland Inseln",
      label: "Falkland Inseln",
      imgSrc: "/img/flags/fk.svg"
    },
    {
      dataBaseValue: "Federated States of Micronesia",
      value: "Die Verbündeten Staaten von Micronesia",
      label: "Die Verbündeten Staaten von Micronesia",
      imgSrc: "/img/flags/fm.svg"
    },
    {
      dataBaseValue: "Faroe Islands",
      value: "Färöer Inseln",
      label: "Färöer Inseln",
      imgSrc: "/img/flags/fo.svg"
    },
    {
      dataBaseValue: "France",
      value: "Frankreich",
      label: "Frankreich",
      imgSrc: "/img/flags/fr.svg"
    },
    {
      dataBaseValue: "Georgia",
      value: "Georgia",
      label: "Georgia",
      imgSrc: "/img/flags/ge.svg"
    },
    // {
    //   dataBaseValue: "United Kingdom",
    //   value: "Großbritannien",
    //   label: "Großbritannien",
    //   imgSrc: "/img/flags/en.svg"
    // },
    {
      dataBaseValue: "Northern Ireland",
      value: "Nordirland",
      label: "Nordirland",
      imgSrc: "/img/flags/nir.svg"
    },
    {
      dataBaseValue: "Scotland",
      value: "Schottland",
      label: "Schottland",
      imgSrc: "/img/flags/sct.svg"
    },
    {
      dataBaseValue: "Wales",
      value: "Wales",
      label: "Wales",
      imgSrc: "/img/flags/wls.svg"
    },
    {
      dataBaseValue: "Grenada",
      value: "Grenada",
      label: "Grenada",
      imgSrc: "/img/flags/gd.svg"
    },
    {
      dataBaseValue: "French Guiana",
      value: "Französisch-Guayana",
      label: "Französisch-Guayana",
      imgSrc: "/img/flags/gf.svg"
    },
    {
      dataBaseValue: "Guernsey",
      value: "Guernsey",
      label: "Guernsey",
      imgSrc: "/img/flags/gg.svg"
    },
    {
      dataBaseValue: "Ghana",
      value: "Ghana",
      label: "Ghana",
      imgSrc: "/img/flags/gh.svg"
    },
    {
      dataBaseValue: "Gibraltar",
      value: "Gibraltar",
      label: "Gibraltar",
      imgSrc: "/img/flags/gi.svg"
    },
    {
      dataBaseValue: "Greenland",
      value: "Grönland",
      label: "Grönland",
      imgSrc: "/img/flags/gl.svg"
    },
    {
      dataBaseValue: "Gambia",
      value: "Gambia",
      label: "Gambia",
      imgSrc: "/img/flags/gm.svg"
    },
    {
      dataBaseValue: "Guinea",
      value: "Guinea",
      label: "Guinea",
      imgSrc: "/img/flags/gn.svg"
    },
    {
      dataBaseValue: "Guadeloupe",
      value: "Guadeloupe",
      label: "Guadeloupe",
      imgSrc: "/img/flags/gp.svg"
    },
    {
      dataBaseValue: "Equatorial Guinea",
      value: "Äquatorialguinea",
      label: "Äquatorialguinea",
      imgSrc: "/img/flags/gq.svg"
    },
    {
      dataBaseValue: "Greece",
      value: "Griechenland",
      label: "Griechenland",
      imgSrc: "/img/flags/gr.svg"
    },
    {
      dataBaseValue: "South Georgia and the South Sandwich Islands",
      value: "Süd-Georgien und die südlichen Sandwich-Inseln",
      label: "Süd-Georgien und die südlichen Sandwich-Inseln",
      imgSrc: "/img/flags/gs.svg"
    },
    {
      dataBaseValue: "Guatemala",
      value: "Guatemala",
      label: "Guatemala",
      imgSrc: "/img/flags/gt.svg"
    },
    {
      dataBaseValue: "Guam",
      value: "Guam",
      label: "Guam",
      imgSrc: "/img/flags/gu.svg"
    },
    {
      dataBaseValue: "Guinea-Bissau",
      value: "Guinea-Bissau",
      label: "Guinea-Bissau",
      imgSrc: "/img/flags/gw.svg"
    },
    {
      dataBaseValue: "Guyana",
      value: "Guyana",
      label: "Guyana",
      imgSrc: "/img/flags/gy.svg"
    },
    {
      dataBaseValue: "Hong Kong",
      value: "Hongkong",
      label: "Hongkong",
      imgSrc: "/img/flags/hk.svg"
    },
    {
      dataBaseValue: "Honduras",
      value: "Honduras",
      label: "Honduras",
      imgSrc: "/img/flags/hn.svg"
    },
    {
      dataBaseValue: "Croatia",
      value: "Kroatien",
      label: "Kroatien",
      imgSrc: "/img/flags/hr.svg"
    },
    {
      dataBaseValue: "Haiti",
      value: "Haiti",
      label: "Haiti",
      imgSrc: "/img/flags/ht.svg"
    },
    {
      dataBaseValue: "Hungary",
      value: "Ungarn",
      label: "Ungarn",
      imgSrc: "/img/flags/hu.svg"
    },
    {
      dataBaseValue: "Indonesia",
      value: "Indonesien",
      label: "Indonesien",
      imgSrc: "/img/flags/id.svg"
    },
    {
      dataBaseValue: "Ireland",
      value: "Irland",
      label: "Irland",
      imgSrc: "/img/flags/ie.svg"
    },
    {
      dataBaseValue: "Israel",
      value: "Israel",
      label: "Israel",
      imgSrc: "/img/flags/il.svg"
    },
    {
      dataBaseValue: "Isle of Man",
      value: "Isle of Man",
      label: "Isle of Man",
      imgSrc: "/img/flags/im.svg"
    },
    {
      dataBaseValue: "India",
      value: "Indien",
      label: "Indien",
      imgSrc: "/img/flags/in.svg"
    },
    {
      dataBaseValue: "British Indian Ocean Territory",
      value: "Britisches Territorium des Indischen Ozeans",
      label: "Britisches Territorium des Indischen Ozeans",
      imgSrc: "/img/flags/io.svg"
    },
    {
      dataBaseValue: "Iraq",
      value: "Irak",
      label: "Irak",
      imgSrc: "/img/flags/iq.svg"
    },
    {
      dataBaseValue: "Iran",
      value: "Iran, Islamische Republik",
      label: "Iran, Islamische Republik",
      imgSrc: "/img/flags/ir.svg"
    },
    {
      dataBaseValue: "Italy",
      value: "Italien",
      label: "Italien",
      imgSrc: "/img/flags/it.svg"
    },
    {
      dataBaseValue: "Jersey",
      value: "Jersey",
      label: "Jersey",
      imgSrc: "/img/flags/je.svg"
    },
    {
      dataBaseValue: "Jamaica",
      value: "Jamaika",
      label: "Jamaika",
      imgSrc: "/img/flags/jm.svg"
    },
    {
      dataBaseValue: "Jordan",
      value: "Jordanien",
      label: "Jordanien",
      imgSrc: "/img/flags/jo.svg"
    },
    {
      dataBaseValue: "Japan",
      value: "Japan",
      label: "Japan",
      imgSrc: "/img/flags/jp.svg"
    },
    {
      dataBaseValue: "Kenya",
      value: "Kenia",
      label: "Kenia",
      imgSrc: "/img/flags/ke.svg"
    },
    {
      dataBaseValue: "Kyrgyzstan",
      value: "Kirgistan",
      label: "Kirgistan",
      imgSrc: "/img/flags/kg.svg"
    },
    {
      dataBaseValue: "Cambodia",
      value: "Kambodscha",
      label: "Kambodscha",
      imgSrc: "/img/flags/kh.svg"
    },
    {
      dataBaseValue: "Kiribati",
      value: "Kiribati",
      label: "Kiribati",
      imgSrc: "/img/flags/ki.svg"
    },
    {
      dataBaseValue: "Comoros",
      value: "Komoren",
      label: "Komoren",
      imgSrc: "/img/flags/km.svg"
    },
    {
      dataBaseValue: "Saint Kitts and Nevis",
      value: "St. Kitts und Nevis",
      label: "St. Kitts und Nevis",
      imgSrc: "/img/flags/kn.svg"
    },
    {
      dataBaseValue: "Democratic People's Republic of Korea",
      value: "Demokratische Volksrepublik Korea",
      label: "Demokratische Volksrepublik Korea",
      imgSrc: "/img/flags/kp.svg"
    },
    {
      dataBaseValue: "South Korea",
      value: "Südkorea",
      label: "Südkorea",
      imgSrc: "/img/flags/kr.svg"
    },
    {
      dataBaseValue: "Kuwait",
      value: "Kuwait",
      label: "Kuwait",
      imgSrc: "/img/flags/kw.svg"
    },
    {
      dataBaseValue: "Cayman Islands",
      value: "Cayman Inseln",
      label: "Cayman Inseln",
      imgSrc: "/img/flags/ky.svg"
    },
    {
      dataBaseValue: "Kazakhstan",
      value: "Kasachstan",
      label: "Kasachstan",
      imgSrc: "/img/flags/kz.svg"
    },
    {
      dataBaseValue: "Laos",
      value: "Laos",
      label: "Laos",
      imgSrc: "/img/flags/la.svg"
    },
    {
      dataBaseValue: "Lebanon",
      value: "Libanon",
      label: "Libanon",
      imgSrc: "/img/flags/lb.svg"
    },
    {
      dataBaseValue: "Saint Lucia",
      value: "St. Lucia",
      label: "St. Lucia",
      imgSrc: "/img/flags/lc.svg"
    },
    {
      dataBaseValue: "Liechtenstein",
      value: "Liechtenstein",
      label: "Liechtenstein",
      imgSrc: "/img/flags/li.svg"
    },
    {
      dataBaseValue: "Sri Lanka",
      value: "Sri Lanka",
      label: "Sri Lanka",
      imgSrc: "/img/flags/lk.svg"
    },
    {
      dataBaseValue: "Liberia",
      value: "Liberia",
      label: "Liberia",
      imgSrc: "/img/flags/lr.svg"
    },
    {
      dataBaseValue: "Lesotho",
      value: "Lesotho",
      label: "Lesotho",
      imgSrc: "/img/flags/ls.svg"
    },
    {
      dataBaseValue: "Lithuania",
      value: "Litauen",
      label: "Litauen",
      imgSrc: "/img/flags/lt.svg"
    },
    {
      dataBaseValue: "Luxembourg",
      value: "Luxemburg",
      label: "Luxemburg",
      imgSrc: "/img/flags/lu.svg"
    },
    {
      dataBaseValue: "Latvia",
      value: "Lettland",
      label: "Lettland",
      imgSrc: "/img/flags/lv.svg"
    },
    {
      dataBaseValue: "Libya",
      value: "Libyen",
      label: "Libyen",
      imgSrc: "/img/flags/ly.svg"
    },
    {
      dataBaseValue: "Morocco",
      value: "Marokko",
      label: "Marokko",
      imgSrc: "/img/flags/ma.svg"
    },
    {
      dataBaseValue: "Monaco",
      value: "Monaco",
      label: "Monaco",
      imgSrc: "/img/flags/mc.svg"
    },
    {
      dataBaseValue: "Moldova",
      value: "Moldawien",
      label: "Moldawien",
      imgSrc: "/img/flags/md.svg"
    },
    {
      dataBaseValue: "Montenegro",
      value: "Montenegro",
      label: "Montenegro",
      imgSrc: "/img/flags/me.svg"
    },
    {
      dataBaseValue: "Saint Martin",
      value: "Sankt Martin",
      label: "Sankt Martin",
      imgSrc: "/img/flags/mf.svg"
    },
    {
      dataBaseValue: "Madagascar",
      value: "Madagaskar",
      label: "Madagaskar",
      imgSrc: "/img/flags/mg.svg"
    },
    {
      dataBaseValue: "Marshall Islands",
      value: "Marshallinseln",
      label: "Marshallinseln",
      imgSrc: "/img/flags/mh.svg"
    },
    {
      dataBaseValue: "Macedonia",
      value: "Mazedonien",
      label: "Mazedonien",
      imgSrc: "/img/flags/mk.svg"
    },
    {
      dataBaseValue: "Mali",
      value: "Mali",
      label: "Mali",
      imgSrc: "/img/flags/ml.svg"
    },
    {
      dataBaseValue: "Myanmar",
      value: "Burma",
      label: "Burma",
      imgSrc: "/img/flags/mm.svg"
    },
    {
      dataBaseValue: "Mongolia",
      value: "Mongolei",
      label: "Mongolei",
      imgSrc: "/img/flags/mn.svg"
    },
    {
      dataBaseValue: "Macau",
      value: "Macau",
      label: "Macau",
      imgSrc: "/img/flags/mo.svg"
    },
    {
      dataBaseValue: "Northern Mariana Islands",
      value: "Nördliche Marianneninseln",
      label: "Nördliche Marianneninseln",
      imgSrc: "/img/flags/mp.svg"
    },
    {
      dataBaseValue: "Martinique",
      value: "Martinique",
      label: "Martinique",
      imgSrc: "/img/flags/mq.svg"
    },
    {
      dataBaseValue: "Mauritius",
      value: "Mauritania",
      label: "Mauritania",
      imgSrc: "/img/flags/mr.svg"
    },
    {
      dataBaseValue: "Montserrat",
      value: "Montserrat",
      label: "Montserrat",
      imgSrc: "/img/flags/ms.svg"
    },
    {
      dataBaseValue: "Malta",
      value: "Malta",
      label: "Malta",
      imgSrc: "/img/flags/mt.svg"
    },
    {
      dataBaseValue: "O. Mauritius",
      value: "O. Mauritius",
      label: "O. Mauritius",
      imgSrc: "/img/flags/mu.svg"
    },
    {
      dataBaseValue: "Maldives",
      value: "Malediven",
      label: "Malediven",
      imgSrc: "/img/flags/mv.svg"
    },
    {
      dataBaseValue: "Malawi",
      value: "Malawi",
      label: "Malawi",
      imgSrc: "/img/flags/mw.svg"
    },
    {
      dataBaseValue: "Mexico",
      value: "Mexiko",
      label: "Mexiko",
      imgSrc: "/img/flags/mx.svg"
    },
    {
      dataBaseValue: "Malaysia",
      value: "Malaysia",
      label: "Malaysia",
      imgSrc: "/img/flags/my.svg"
    },
    {
      dataBaseValue: "Mozambique",
      value: "Mosambik",
      label: "Mosambik",
      imgSrc: "/img/flags/mz.svg"
    },
    {
      dataBaseValue: "Namibia",
      value: "Namibia",
      label: "Namibia",
      imgSrc: "/img/flags/na.svg"
    },
    {
      dataBaseValue: "New Caledonia",
      value: "Neu-Kaledonien",
      label: "Neu-Kaledonien",
      imgSrc: "/img/flags/nc.svg"
    },
    {
      dataBaseValue: "Niger",
      value: "Niger",
      label: "Niger",
      imgSrc: "/img/flags/ne.svg"
    },
    {
      dataBaseValue: "Norfolk Island",
      value: "Norfolkinsel",
      label: "Norfolkinsel",
      imgSrc: "/img/flags/nf.svg"
    },
    {
      dataBaseValue: "Nigeria",
      value: "Nigeria",
      label: "Nigeria",
      imgSrc: "/img/flags/ng.svg"
    },
    {
      dataBaseValue: "Nicaragua",
      value: "Nicaragua",
      label: "Nicaragua",
      imgSrc: "/img/flags/ni.svg"
    },
    {
      dataBaseValue: "Norway",
      value: "Norwegen",
      label: "Norwegen",
      imgSrc: "/img/flags/no.svg"
    },
    {
      dataBaseValue: "Nepal",
      value: "Nepal",
      label: "Nepal",
      imgSrc: "/img/flags/np.svg"
    },
    {
      dataBaseValue: "Nauru",
      value: "Nauru",
      label: "Nauru",
      imgSrc: "/img/flags/nr.svg"
    },
    {
      dataBaseValue: "Niue",
      value: "Niue",
      label: "Niue",
      imgSrc: "/img/flags/nu.svg"
    },
    {
      dataBaseValue: "New Zealand",
      value: "Neuseeland",
      label: "Neuseeland",
      imgSrc: "/img/flags/nz.svg"
    },
    {
      dataBaseValue: "Oman",
      value: "Oman",
      label: "Oman",
      imgSrc: "/img/flags/om.svg"
    },
    {
      dataBaseValue: "Panama",
      value: "Panama",
      label: "Panama",
      imgSrc: "/img/flags/pa.svg"
    },
    {
      dataBaseValue: "Peru",
      value: "Peru",
      label: "Peru",
      imgSrc: "/img/flags/pe.svg"
    },
    {
      dataBaseValue: "French polynesia",
      value: "Französisch Polynesien",
      label: "Französisch Polynesien",
      imgSrc: "/img/flags/pf.svg"
    },
    {
      dataBaseValue: "Papua New Guinea",
      value: "Papua Neu-Guinea",
      label: "Papua Neu-Guinea",
      imgSrc: "/img/flags/pg.svg"
    },
    {
      dataBaseValue: "Philippines",
      value: "Philippinen",
      label: "Philippinen",
      imgSrc: "/img/flags/ph.svg"
    },
    {
      dataBaseValue: "Pakistan",
      value: "Pakistan",
      label: "Pakistan",
      imgSrc: "/img/flags/pk.svg"
    },
    {
      dataBaseValue: "Poland",
      value: "Polen",
      label: "Polen",
      imgSrc: "/img/flags/pl.svg"
    },
    {
      dataBaseValue: "Saint Pierre and Miquelon",
      value: "St. Pierre und Miquelon",
      label: "St. Pierre und Miquelon",
      imgSrc: "/img/flags/pm.svg"
    },
    {
      dataBaseValue: "Pitcairn Islands",
      value: "Pitcairn-Inseln",
      label: "Pitcairn-Inseln",
      imgSrc: "/img/flags/pn.svg"
    },
    {
      dataBaseValue: "Puerto Rico",
      value: "Puerto Rico",
      label: "Puerto Rico",
      imgSrc: "/img/flags/pr.svg"
    },
    {
      dataBaseValue: "Palestine",
      value: "Palästina",
      label: "Palästina",
      imgSrc: "/img/flags/ps.svg"
    },
    {
      dataBaseValue: "Portugal",
      value: "Portugal",
      label: "Portugal",
      imgSrc: "/img/flags/pt.svg"
    },
    {
      dataBaseValue: "Paraguay",
      value: "Paraguay",
      label: "Paraguay",
      imgSrc: "/img/flags/py.svg"
    },
    {
      dataBaseValue: "Qatar",
      value: "Katar",
      label: "Katar",
      imgSrc: "/img/flags/qa.svg"
    },
    {
      dataBaseValue: "Reunion",
      value: "Wiedervereinigung",
      label: "Wiedervereinigung",
      imgSrc: "/img/flags/re.svg"
    },
    {
      dataBaseValue: "Romania",
      value: "Rumänien",
      label: "Rumänien",
      imgSrc: "/img/flags/ro.svg"
    },
    {
      dataBaseValue: "Serbia",
      value: "Serbien",
      label: "Serbien",
      imgSrc: "/img/flags/rs.svg"
    },
    {
      dataBaseValue: "Russia",
      value: "Russische Föderation",
      label: "Russische Föderation",
      imgSrc: "/img/flags/ru.svg"
    },
    {
      dataBaseValue: "Rwanda",
      value: "Ruanda",
      label: "Ruanda",
      imgSrc: "/img/flags/rw.svg"
    },
    {
      dataBaseValue: "South Africa",
      value: "Südafrika",
      label: "Südafrika",
      imgSrc: "/img/flags/sa.svg"
    },
    {
      dataBaseValue: "Solomon Islands",
      value: "Salomon-Inseln",
      label: "Salomon-Inseln",
      imgSrc: "/img/flags/sb.svg"
    },
    {
      dataBaseValue: "Seychelles",
      value: "Seychellen",
      label: "Seychellen",
      imgSrc: "/img/flags/sc.svg"
    },
    {
      dataBaseValue: "Sudan",
      value: "Sudan",
      label: "Sudan",
      imgSrc: "/img/flags/sd.svg"
    },
    {
      dataBaseValue: "Sweden",
      value: "Schweden",
      label: "Schweden",
      imgSrc: "/img/flags/se.svg"
    },
    {
      dataBaseValue: "Singapore",
      value: "Singapur",
      label: "Singapur",
      imgSrc: "/img/flags/sg.svg"
    },
    {
      dataBaseValue: "O. St. Helena",
      value: "O. St. Helena",
      label: "O. St. Helena",
      imgSrc: "/img/flags/sh.svg"
    },
    {
      dataBaseValue: "Slovenia",
      value: "Slowenien",
      label: "Slowenien",
      imgSrc: "/img/flags/si.svg"
    },
    {
      dataBaseValue: "Svalbard",
      value: "Spitzbergen",
      label: "Spitzbergen",
      imgSrc: "/img/flags/sj.svg"
    },
    {
      dataBaseValue: "Slovakia",
      value: "Slowakei",
      label: "Slowakei",
      imgSrc: "/img/flags/sk.svg"
    },
    {
      dataBaseValue: "Sierra Leone",
      value: "Sierra Leone",
      label: "Sierra Leone",
      imgSrc: "/img/flags/sl.svg"
    },
    {
      dataBaseValue: "San Marino",
      value: "San Marino",
      label: "San Marino",
      imgSrc: "/img/flags/sm.svg"
    },
    {
      dataBaseValue: "Senegal",
      value: "Senegal",
      label: "Senegal",
      imgSrc: "/img/flags/sn.svg"
    },
    {
      dataBaseValue: "Somalia",
      value: "Somalia",
      label: "Somalia",
      imgSrc: "/img/flags/so.svg"
    },
    {
      dataBaseValue: "Suriname",
      value: "Surinam",
      label: "Surinam",
      imgSrc: "/img/flags/sr.svg"
    },
    {
      dataBaseValue: "South Sudan",
      value: "Südsudan",
      label: "Südsudan",
      imgSrc: "/img/flags/ss.svg"
    },
    {
      dataBaseValue: "Sao Tome and Principe",
      value: "Sao Tome und Principe",
      label: "Sao Tome und Principe",
      imgSrc: "/img/flags/st.svg"
    },
    {
      dataBaseValue: "Salvador",
      value: "Salvador",
      label: "Salvador",
      imgSrc: "/img/flags/sv.svg"
    },
    {
      dataBaseValue: "Sint Maarten",
      value: "Sint Maarten",
      label: "Sint Maarten",
      imgSrc: "/img/flags/sx.svg"
    },
    {
      dataBaseValue: "Syria",
      value: "Syrien",
      label: "Syrien",
      imgSrc: "/img/flags/sy.svg"
    },
    {
      dataBaseValue: "Eswatini",
      value: "Eswatini",
      label: "Eswatini",
      imgSrc: "/img/flags/sz.svg"
    },
    {
      dataBaseValue: "Turks and Caicos Islands",
      value: "Turks und Caicosinseln",
      label: "Turks und Caicosinseln",
      imgSrc: "/img/flags/tc.svg"
    },
    {
      dataBaseValue: "Chad",
      value: "Tschad",
      label: "Tschad",
      imgSrc: "/img/flags/td.svg"
    },
    {
      dataBaseValue: "Togo",
      value: "Gehen",
      label: "Gehen",
      imgSrc: "/img/flags/tg.svg"
    },
    {
      dataBaseValue: "Thailand",
      value: "Thailand",
      label: "Thailand",
      imgSrc: "/img/flags/th.svg"
    },
    {
      dataBaseValue: "Tajikistan",
      value: "Tadschikistan",
      label: "Tadschikistan",
      imgSrc: "/img/flags/tj.svg"
    },
    {
      dataBaseValue: "Tokelau",
      value: "Tokelau",
      label: "Tokelau",
      imgSrc: "/img/flags/tk.svg"
    },
    {
      dataBaseValue: "East Timor",
      value: "Osttimor",
      label: "Osttimor",
      imgSrc: "/img/flags/tl.svg"
    },
    {
      dataBaseValue: "Turkmenistan",
      value: "Turkmenistan",
      label: "Turkmenistan",
      imgSrc: "/img/flags/tm.svg"
    },
    {
      dataBaseValue: "Tunisia",
      value: "Tunesien",
      label: "Tunesien",
      imgSrc: "/img/flags/tn.svg"
    },
    {
      dataBaseValue: "Turkey",
      value: "Truthahn",
      label: "Truthahn",
      imgSrc: "/img/flags/tr.svg"
    },
    {
      dataBaseValue: "Trinidad and Tobago",
      value: "Trinidad und Tobago",
      label: "Trinidad und Tobago",
      imgSrc: "/img/flags/tt.svg"
    },
    {
      dataBaseValue: "Tuvalu",
      value: "Tuvalu",
      label: "Tuvalu",
      imgSrc: "/img/flags/tv.svg"
    },
    {
      dataBaseValue: "Taiwan",
      value: "Taiwan",
      label: "Taiwan",
      imgSrc: "/img/flags/tw.svg"
    },
    {
      dataBaseValue: "Tanzania",
      value: "Tansania",
      label: "Tansania",
      imgSrc: "/img/flags/tz.svg"
    },
    {
      dataBaseValue: "Ukraine",
      value: "Ukraine",
      label: "Ukraine",
      imgSrc: "/img/flags/ua.svg"
    },
    {
      dataBaseValue: "Uganda",
      value: "Uganda",
      label: "Uganda",
      imgSrc: "/img/flags/ug.svg"
    },
    {
      dataBaseValue: "Minor Outlying Islands (USA)",
      value: "Kleinere vorgelagerte Inseln (USA)",
      label: "Kleinere vorgelagerte Inseln (USA)",
      imgSrc: "/img/flags/um.svg"
    },
    {
      dataBaseValue: "United States",
      value: "Vereinigte Staaten",
      label: "Vereinigte Staaten",
      imgSrc: "/img/flags/us.svg"
    },
    {
      dataBaseValue: "United Kingdom",
      value: "Großbritannien",
      label: "Großbritannien",
      imgSrc: "/img/flags/uk.svg"
    },
    {
      dataBaseValue: "Uruguay",
      value: "Uruguay",
      label: "Uruguay",
      imgSrc: "/img/flags/uy.svg"
    },
    {
      dataBaseValue: "Uzbekistan",
      value: "Usbekistan",
      label: "Usbekistan",
      imgSrc: "/img/flags/uz.svg"
    },
    {
      dataBaseValue: "Vatican City State",
      value: "Staat Vatikanstadt",
      label: "Staat Vatikanstadt",
      imgSrc: "/img/flags/va.svg"
    },
    {
      dataBaseValue: "Saint Vincent and the Grenadines",
      value: "St. Vincent und die Grenadinen",
      label: "St. Vincent und die Grenadinen",
      imgSrc: "/img/flags/vc.svg"
    },
    {
      dataBaseValue: "Venezuela",
      value: "Venezuela",
      label: "Venezuela",
      imgSrc: "/img/flags/ve.svg"
    },
    {
      dataBaseValue: "British Virgin Islands",
      value: "Britische Jungferninseln",
      label: "Britische Jungferninseln",
      imgSrc: "/img/flags/vg.svg"
    },
    {
      dataBaseValue: "US Virgin Islands",
      value: "US Jungferninseln",
      label: "US Jungferninseln",
      imgSrc: "/img/flags/vi.svg"
    },
    {
      dataBaseValue: "Vietnam",
      value: "Vietnam",
      label: "Vietnam",
      imgSrc: "/img/flags/vn.svg"
    },
    {
      dataBaseValue: "Vanuatu",
      value: "Vanuatu",
      label: "Vanuatu",
      imgSrc: "/img/flags/vu.svg"
    },
    {
      dataBaseValue: "Wallis and Futuna Islands",
      value: "Wallis und Futuna-Inseln",
      label: "Wallis und Futuna-Inseln",
      imgSrc: "/img/flags/wf.svg"
    },
    {
      dataBaseValue: "Samoa",
      value: "Samoa",
      label: "Samoa",
      imgSrc: "/img/flags/ws.svg"
    },
    {
      dataBaseValue: "Kosovo",
      value: "Kosovo",
      label: "Kosovo",
      imgSrc: "/img/flags/xk.svg"
    },
    {
      dataBaseValue: "Yemen",
      value: "Jemen",
      label: "Jemen",
      imgSrc: "/img/flags/ye.svg"
    },
    {
      dataBaseValue: "Mayotte",
      value: "Mayotte",
      label: "Mayotte",
      imgSrc: "/img/flags/yt.svg"
    },
    {
      dataBaseValue: "South Africa",
      value: "Südafrika",
      label: "Südafrika",
      imgSrc: "/img/flags/za.svg"
    },
    {
      dataBaseValue: "Zambia",
      value: "Sambia",
      label: "Sambia",
      imgSrc: "/img/flags/zm.svg"
    },
    {
      dataBaseValue: "Zimbabwe",
      value: "Zimbabwe",
      label: "Zimbabwe",
      imgSrc: "/img/flags/zw.svg"
    }
  ],
  filterProxyItems: [
    {
      id: 1,
      value: "Format IP:PORT",
      regExp: /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(\s{0,}|:)\d{1,5}/,
      divider: [":"]
    },
    // {
    //   id: 2,
    //   value: "Format KeyCollector",
    //   regExp:
    //     /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(\s{0,}|:)\d{1,5}(\s{0,}|@)\S+(\s{0,}|:)\S+/,
    //   divider: [":", "@"]
    // },
    {
      id: 3,
      value: "Format IP:PORT@Login:Password",
      regExp:
        /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(\s{0,}|:)\d{1,5}(\s{0,}|@)\S+(\s{0,}|:)\S+/,
      divider: [":", "@"]
    },
    {
      id: 4,
      value: "Format IP.PORT:Login:Password",
      regExp:
        /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(\s{0,}|:)\d{1,5}(\s{0,}|:)\S+(\s{0,}|:)\S+/,
      divider: [":"]
    }
  ],
  quantity: [
    { label: "10 pcs.", value: 10 },
    { label: "15 pcs.", value: 15 },
    { label: "20 pcs.", value: 20 },
    { label: "50 pcs.", value: 50 }
  ],
  regions: [
    { value: "MOSKAU", label: "Moskau" },
    { value: "KIEW", label: "Kiew" },
    { value: "ST. PETERSBURG", label: "St. Petersburg" }
  ]
};
