import { combineReducers, configureStore } from "@reduxjs/toolkit";

import ClientReducer from "./reducers/ClientReducer/Client.slice";
import ContentReducer from "./reducers/ContentReducer/Content.slice";
import DnsReducer from "./reducers/DnsReducer/Dns.slice";
import HeadersReducer from "./reducers/HeadersReducer/Headers.slice";
import ProxyReducer from "./reducers/ProxyReducer/Proxy.slice";
import TranslationReducer from "./reducers/TranslationReducer/Translation.slice";
import WebRtcReducer from "./reducers/WebRtcReducer/WebRtc.slice";

const rootReducer = combineReducers({
  translation: TranslationReducer,
  client: ClientReducer,
  proxy: ProxyReducer,
  content: ContentReducer,
  webRtc: WebRtcReducer,
  dns: DnsReducer,
  headers: HeadersReducer
});

export const setupStore = (preloadedState) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredPaths: ["translation"]
        }
      }),
    preloadedState
  });
