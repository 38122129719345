import styled from "@emotion/styled";

export const Wraper = styled("div")`
  display: flex;
  margin-bottom: 40px;
`;

export const InfoWraper = styled("div")`
  margin-left: 20px;
`;

export const InfoTextTop = styled("p")`
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 18px;
  color: #131430;
`;

export const InfoTextBottom = styled("p")`
  //font-weight: 400;
  font-size: 18px;

  line-height: 1.5;
  color: rgba(19, 20, 48, 0.8);
`;
