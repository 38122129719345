import styled from "@emotion/styled";
import { Link as StyledLink } from "react-router-dom";

export const Link = styled(StyledLink)`
  @media (max-width: 1200px) {
    order: 1;
    margin-right: auto;
    margin-left: auto;
  }
`;

export const Img = styled.img`
  width: 180px;
  height: auto;
  object-fit: cover;

  @media (max-width: 576px) {
    width: 130px;
  }
`;
